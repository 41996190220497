@import './config.scss';

@include media-query($on-phone) {

    :root {
        --shot-zoom: 0.24;
    }

    .ant-layout-header {
        padding-inline: 16px;
    }

    .head-box {
        width: 100%;

        .logo {
            min-width: 160px;

            .logo-text {
                display: none;
            }
        }
    }

}