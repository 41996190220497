@import url('https://fonts.font.im/css?family=Anonymous+Pro|Cousine|Cutive+Mono|Fira+Mono|IBM+Plex+Mono|Inconsolata|Nanum+Gothic+Coding|Nova+Mono|Overpass+Mono|Oxygen+Mono|PT+Mono|Roboto+Mono|Share+Tech+Mono|Source+Code+Pro|Space+Mono|Ubuntu+Mono|VT323');

@font-face {
  font-family: 'Barbie Medium';
  font-style: normal;
  font-weight: bolder;
  font-display: swap;
  src: url(barbie.ttf) format('woff2');
}

@font-face {
  font-family: 'consolas';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(Consolas.ttf) format('woff2');
}


@font-face {
  font-family: 'JetBrains Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(JetbrainsMono.woff2) format('woff2');
}
@font-face {
  font-family: 'CascadiaCode';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(CascadiaCode.ttf) format('woff2');
}


@font-face {
  font-family: 'SF Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(SFMono-Regular.otf) format('opentype');
}


@font-face {
  font-family: 'Makinas 4 Square';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(Makinas-4-Square.otf) format('opentype');
}


// font-family: 'Roboto Mono', monospace;
// font-family: 'Inconsolata', monospace;
// font-family: 'Source Code Pro', monospace;
// font-family: 'IBM Plex Mono', monospace;
// font-family: 'PT Mono', monospace;
// font-family: 'Space Mono', monospace;
// font-family: 'Ubuntu Mono', monospace;
// font-family: 'Overpass Mono', monospace;
// font-family: 'Cousine', monospace;
// font-family: 'VT323', monospace;
// font-family: 'Anonymous Pro', monospace;
// font-family: 'Nanum Gothic Coding', monospace;
// font-family: 'Fira Mono', monospace;
// font-family: 'Share Tech Mono', monospace;
// font-family: 'Oxygen Mono', monospace;
// font-family: 'Cutive Mono', monospace;
// font-family: 'Nova Mono', monospace;

@font-face {
  font-family: 'Barbie';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(barbie.ttf) format('ttf');
}
@font-face {
  font-family: 'Iosevka';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(Iosevka-Regular.ttf) format('woff2');
}

@font-face {
  font-family: 'Github Monaspace';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(MonaspaceNeon-Regular.otf) format('opentype');
}


