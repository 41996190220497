.fav-list .ant-space {
    width: 90%;
    margin: 10px 5% 0 0;

    .ant-space-item .fav-item {
        width: 100%;
        background-color: #999;
        border-radius: 12px;
        text-align: center;
        cursor:pointer;

        &.on .fav-item-thumbnail{
            opacity: 1;
        }
        .fav-item-thumbnail {
            position: relative;
            height: 100px;
            background-color: #aaa;
            border-radius: 10px 10px 0 0;
            opacity: 0.5;
            transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

            .content {
                background-color: transparent;
                background-image: none;

                .content-text {
                    position: relative;
                    top: 10px;
                    margin: 0 auto;
                    height: 80px;
                    text-align: center;
                    border-radius: 6px;

                    &.aspect-square {
                        width: 80px;
                    }

                    &.aspect-landscape {
                        top: 15px;
                        width: 110px;
                        height: 70px;
                    }

                    &.aspect-portrait {
                        width: 60px;
                    }

                    .content-text-text {
                        position: relative;
                        font-size: 12px;
                        text-align: center;
                        transform: translateY(-50%);
                        top: 50%;
                    }
                }
            }
        }

        .ant-btn-group {
            width: 100%;
            margin: 0;

            button {
                width: 50%;

                &:first-child {
                    border-start-start-radius: 0 !important;
                }

                &:last-child {
                    border-end-end-radius: 0 !important;
                }

            }
        }
    }
}