.shot-layout.font-left {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                text-align: justify;
            }
        }
    }
}

.shot-layout.font-center {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                text-align: center;
            }
        }
    }
}

.shot-layout.font-right {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                text-align: right;
            }
        }
    }
}

.shot-layout.font-title-center {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                text-align: justify;

                h1,
                h2,
                h3 {
                    text-align: center;
                    display: inherit !important;
                    //line-height: 0.5 !important;
                    margin-block-start: -0.83em!important;
                    margin-block-end: -0.83em!important;
                }
            }
        }
    }
}


.shot-layout.font-tiny {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-size: 18px;
            }
        }
    }
}

.shot-layout.font-small {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-size: 24px;
            }
        }
    }
}

.shot-layout.font-normal {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-size: 28px;
            }
        }
    }
}

.shot-layout.font-big {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-size: 32px;
            }
        }
    }
}

.shot-layout.font-mega {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-size: 36px;
            }
        }
    }
}




.shot-layout.font-jetbrains_mono {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "JetBrains Mono" !important;
            }
        }
    }
}

.shot-layout.font-fira_mono {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "Fira Mono" !important;
            }
        }
    }
}


.shot-layout.font-source_code_pro {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "Source Code Pro" !important;
            }
        }
    }
}

.shot-layout.font-inconsolata {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "Inconsolata" !important;
            }
        }
    }
}



.shot-layout.font-iosevka {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "SF Mono" !important;
            }
        }
    }
}

.shot-layout.font-ubuntu_mono {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "Ubuntu Mono" !important;
            }
        }
    }
}

.shot-layout.font-github_monaspace {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "Github Monaspace" !important;
            }
        }
    }
}

.shot-layout.font-cascadia_code {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "CascadiaCode" !important;
            }
        }
    }
}

.shot-layout.font-sf_mono {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "SF Mono" !important;
            }
        }
    }
}

.shot-layout.font-consolas {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "Consolas" !important;
            }
        }
    }
}



.shot-layout.font-sarasa_gothic {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "Sarasa Fixed SC" !important;
            }
        }
    }
}

.shot-layout.font-lxgw_wenkai {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "LXGW WenKai Mono Lite" !important;
            }
        }
    }
}


.shot-layout.theme-chinese-ancient.font-sarasa_gothic {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                letter-spacing: 1.3em;

                h1,
                h2,
                h3 {
                    letter-spacing: 1.2em;
                }
            }
        }
    }
}

.shot-layout.theme-chinese-ancient.font-lxgw_wenkai {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                letter-spacing: 1.3em;

                h1,
                h2,
                h3 {
                    letter-spacing: 1.2em;
                }
            }
        }
    }
}

.shot-layout.font-slidefu {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "Slidefu" !important;
            }
        }
    }
}

.shot-layout.font-jiangxizhoukai {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "jiangxizhuokai" !important;
            }
        }
    }
}


.shot-layout.font-mplus2 {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "M PLUS 2" !important;
            }
        }
    }
}



.shot-layout.font-makinas4square {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "Makinas 4 Square" !important;
            }
        }
    }
}



.shot-layout.font-xiaolaispell {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "Mengshen-Handwritten" !important;
            }
        }
    }
}



.shot-layout.font-xiaolai {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "Xiaolai Mono SC" !important;
            }
        }
    }
}



.shot-layout.font-kinghwaoldsong {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "KingHwa_OldSong" !important;
                white-space: nowrap;
                line-height: 2em;

                h1,
                h2,
                h3 {
                    line-height: 2em;
                }

            }
        }
    }
}



.shot-layout.font-hwmct {
    .content-container .content-container-shadow .content {

        .content-text,
        .device-text {
            >pre {
                font-family: "Huiwen-mincho" !important;
            }
        }
    }
}

