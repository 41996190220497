@import './theme-base-background.scss';

.theme-night-owl {
    @extend .bg-night-owl;

    .content {

        .content-text,.device-text {
            background-color: #041c31;
            color: #5f7e97;

            >pre {

                font-family: "Jetbrains Mono" !important;
                color: #5f7e97;

                pre code.hljs {
                    display: block;
                    overflow-x: auto;
                    padding: 1em
                }

                code.hljs {
                    padding: 3px 5px
                }

                .hljs {
                    background: #011627;
                    color: #d6deeb
                }

                .hljs-keyword {
                    color: #c792ea;
                    font-style: italic
                }

                .hljs-built_in {
                    color: #addb67;
                    font-style: italic
                }

                .hljs-type {
                    color: #82aaff
                }

                .hljs-literal {
                    color: #ff5874
                }

                .hljs-number {
                    color: #f78c6c
                }

                .hljs-regexp {
                    color: #5ca7e4
                }

                .hljs-string {
                    color: #ecc48d
                }

                .hljs-subst {
                    color: #d3423e
                }

                .hljs-symbol {
                    color: #82aaff
                }

                .hljs-class {
                    color: #ffcb8b
                }

                .hljs-function {
                    color: #82aaff
                }

                .hljs-title {
                    color: #dcdcaa;
                    font-style: italic
                }

                .hljs-params {
                    color: #7fdbca
                }

                .hljs-comment {
                    color: #637777;
                    font-style: italic
                }

                .hljs-doctag {
                    color: #7fdbca
                }

                .hljs-meta,
                .hljs-meta .hljs-keyword {
                    color: #82aaff
                }

                .hljs-meta .hljs-string {
                    color: #ecc48d
                }

                .hljs-section {
                    color: #82b1ff
                }

                .hljs-attr,
                .hljs-name,
                .hljs-tag {
                    color: #7fdbca
                }

                .hljs-attribute {
                    color: #80cbc4
                }

                .hljs-variable {
                    color: #addb67
                }

                .hljs-bullet {
                    color: #d9f5dd
                }

                .hljs-code {
                    color: #80cbc4
                }

                .hljs-emphasis {
                    color: #c792ea;
                    font-style: italic
                }

                .hljs-strong {
                    color: #addb67;
                    font-weight: 700
                }

                .hljs-formula {
                    color: #c792ea
                }

                .hljs-link {
                    color: #ff869a
                }

                .hljs-quote {
                    color: #697098;
                    font-style: italic
                }

                .hljs-selector-tag {
                    color: #ff6363
                }

                .hljs-selector-id {
                    color: #fad430
                }

                .hljs-selector-class {
                    color: #addb67;
                    font-style: italic
                }

                .hljs-selector-attr,
                .hljs-selector-pseudo {
                    color: #c792ea;
                    font-style: italic
                }

                .hljs-template-tag {
                    color: #c792ea
                }

                .hljs-template-variable {
                    color: #addb67
                }

                .hljs-addition {
                    color: #addb67ff;
                    font-style: italic
                }

                .hljs-deletion {
                    color: #ef535090;
                    font-style: italic
                }
            }
        }
    }
}