.shot-device {
    .shot-html {
        .shot-layout {
            .content-container {
                position: relative;
                margin: 0 !important;
                padding: 0 !important;

                .content-container-shadow {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    &::after {
                        display: none !important;
                    }

                    .content {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        border-radius: 0 !important;
                        background-color: transparent;

                        .device-image-box,
                        .device-text-box {

                            .device-text {
                                position: relative;
                                width: 100%;
                                height: 100%;
                                margin: 0;

                                pre {
                                    margin: 0;
                                }
                            }

                            .device-image {
                                position: relative;
                                width: 100%;
                                height: 100%;
                                width: calc(var(--shot-device-content-width) * var(--shot-device-frame-zoom) );
                                height: calc(var(--shot-device-content-height) * var(--shot-device-frame-zoom) );
                                top: 50%;
                                left: 50%;
                                transform: translateX(-50%) translateY(-50%);

                                img {
                                    position: relative;
                                    width: 100%;
                                    height: 100%;
                                    border-radius: calc(var(--shot-device-content-radius) * var(--shot-device-frame-zoom) );
                                }
                            }

                            &::after {
                                position: absolute;
                                content: "";
                                background-image: var(--shot-device-image);
                                background-size: 100%;
                                background-position: top;
                                background-repeat: no-repeat;
                                z-index: 4;
                                width: calc(var(--shot-device-frame-width) * var(--shot-device-frame-zoom) );
                                height: calc(var(--shot-device-frame-height) * var(--shot-device-frame-zoom) );
                                top: 50%;
                                left: 50%;
                                transform: translateX(-50%) translateY(-50%);
                            }
                        }
                    }

                    .content-mode-text {
                        .device-text-box {
                            .device-text {
                                padding: 80px 40px 40px;
                                border-radius: calc(var(--shot-device-content-radius) * var(--shot-device-frame-zoom) );
                                width: calc(var(--shot-device-content-width) * var(--shot-device-frame-zoom) );
                                height: calc(var(--shot-device-content-height) * var(--shot-device-frame-zoom) );
                                top: 50%;
                                left: 50%;
                                transform: translateX(-50%) translateY(-50%);
                            }
                        }
                    }

                    // .content-mode-image {
                    //     .device-image-box {}
                    // }

                    .content-mode-all1 {
                        .device-text-box {
                            top: 100px;
                            height: 240px;

                            .device-text {
                                padding: 30px calc(30px + var(--shot-padding));
                                background: none !important;
                            }

                            &::after {
                                display: none;
                            }
                        }

                        .device-image-box {
                            // top: 340px;
                            top:calc((var(--shot-height) * 0.35) );
                            .device-image {
                                top: calc(var(--shot-device-content-top)*var(--shot-device-frame-zoom));
                                transform: translateX(-50%) translateY(0);
                            }

                            &::after {
                                top: 0;
                                transform: translateX(-50%) translateY(0);
                            }
                        }
                    }

                    .content-mode-all2 {
                        .device-text-box {
                            bottom: 100px;
                            //height: 240px;
                            height: calc((var(--shot-height) * 0.30) );

                            .device-text {
                                padding: 30px calc(30px + var(--shot-padding));
                                background: none !important;
                            }

                            &::after {
                                display: none;
                            }
                        }

                        .device-image-box {
                            top: calc(((var(--shot-device-frame-height) * (1 - var(--shot-device-frame-zoom))) - (var(--shot-device-frame-height) - var(--shot-height)) - (var(--shot-height) * 0.35)) );

                            .device-image {
                                top: calc(var(--shot-device-content-top)*var(--shot-device-frame-zoom));
                                transform: translateX(-50%) translateY(0);
                            }

                            &::after {
                                top: 0;
                                transform: translateX(-50%) translateY(0);
                                background-position-y: bottom;
                            }
                        }
                    }

                    .content-mode-all3 {

                        .device-text-box {
                            background: none !important;

                            .device-text {
                                padding: 80px 40px 40px;
                                border-radius: calc(var(--shot-device-content-radius) * var(--shot-device-frame-zoom) );
                                width: calc(var(--shot-device-content-width) * var(--shot-device-frame-zoom) );
                                height: calc(var(--shot-device-content-height) * var(--shot-device-frame-zoom) );
                                top: 50%;
                                left: 50%;
                                transform: translateX(-50%) translateY(-50%);
                                background: none !important;
                            }

                            &::after {
                                background: none !important;
                            }
                        }

                        // .device-image-box {
                        //     .device-image {}

                        //     &::after {}
                        // }
                    }

                    .content-mode-all4 {
                        .device-text-box {
                            top: 80px;
                            height: 140px;

                            .device-text {
                                padding: 30px calc(30px + var(--shot-padding));
                                background: none !important;
                            }

                            &::after {
                                display: none;
                            }
                        }

                        .device-image-box {
                            //top: 220px;
                            top:calc((var(--shot-height) * 0.20) );

                            .device-image {
                                top: calc(var(--shot-device-content-top)*var(--shot-device-frame-zoom));
                                transform: translateX(-50%) translateY(0);
                            }

                            &::after {
                                top: 0;
                                transform: translateX(-50%) translateY(0);
                            }
                        }
                    }

                    .content-mode-all5 {
                        .device-text-box {
                            bottom: 80px;
                            //height: 140px;
                            height: calc((var(--shot-height) * 0.15) );

                            .device-text {
                                padding: 0 calc(0 + var(--shot-padding));
                                background: none !important;
                            }

                            &::after {
                                display: none;
                            }
                        }

                        .device-image-box {
                            top: calc(((var(--shot-device-frame-height) * (1 - var(--shot-device-frame-zoom))) - (var(--shot-device-frame-height) - var(--shot-height)) - (var(--shot-height) * 0.20)) );

                            .device-image {
                                top: calc(var(--shot-device-content-top)*var(--shot-device-frame-zoom));
                                transform: translateX(-50%) translateY(0);
                            }

                            &::after {
                                top: 0;
                                transform: translateX(-50%) translateY(0);
                                background-position-y: bottom;
                            }
                        }
                    }
                }

            }

            &.header-1,
            &.header-2,
            &.header-3 {
                .content-container .content-container-shadow .content {
                    padding-top: 0;

                    .header-bar {
                        display: none;
                    }
                }

            }

            &.header-4 {
                .content-container {
                    .content-container-shadow {
                        .content {
                            padding-top: 0;

                            .device-text-box {
                                &::after {
                                    background-image: var(--shot-device-image-mockup);
                                }
                            }

                            .device-image-box {
                                &::after {
                                    background-image: var(--shot-device-image-mockup);
                                }
                            }
                        }
                    }

                }
            }

        }
    }
}

.shot-device-glass1 {
    .shot-html {
        .shot-layout {
            .content-container {

                .content-container-shadow {
                    .content {

                        .device-image-box,
                        .device-text-box {

                            .device-image,
                            .device-text {
                                &::after {
                                    position: absolute;
                                    content: "";
                                    width: 100%;
                                    height: 100%;
                                    top: 0;
                                    left: 0;
                                    border-radius: calc(var(--shot-device-content-radius) * var(--shot-device-frame-zoom) );
                                    background: linear-gradient(108deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.1) 45%, rgba(255, 255, 255, 0.1) 45.1%, rgba(0, 0, 0, 0.2) 100%);
                                    opacity: 0.5;
                                }
                            }
                        }

                    }

                    .content-mode-all1,
                    .content-mode-all2,
                    .content-mode-all4,
                    .content-mode-all5 {
                        .device-text-box {
                            .device-text {
                                &::after {
                                    background: none;
                                    border:none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.shot-device-glass2 {
    .shot-html {
        .shot-layout {
            .content-container {

                .content-container-shadow {
                    .content {

                        .device-image-box,
                        .device-text-box {

                            .device-image,
                            .device-text {
                                &::after {
                                    position: absolute;
                                    content: "";
                                    width: 100%;
                                    height: 100%;
                                    top: 0;
                                    left: 0;
                                    border-radius: calc(var(--shot-device-content-radius) * var(--shot-device-frame-zoom) );
                                    background: linear-gradient(108deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.1) 45%, rgba(0, 0, 0, 0.1) 45.1%, rgba(255, 255, 255, 0.2) 100%);
                                    opacity: 0.5;
                                }
                            }
                        }

                    }

                    .content-mode-all1,
                    .content-mode-all2,
                    .content-mode-all4,
                    .content-mode-all5 {
                        .device-text-box {
                            .device-text {
                                &::after {
                                    background: none;
                                    border:none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.shot-device-glass3 {
    .shot-html {
        .shot-layout {
            .content-container {

                .content-container-shadow {
                    .content {

                        .device-image-box,
                        .device-text-box {

                            .device-image,
                            .device-text {
                                &::after {
                                    position: absolute;
                                    content: "";
                                    width: calc(100% - 20px);
                                    height: calc(100% - 10px);
                                    top: 0;
                                    left: 0;
                                    border: 10px solid rgba(0, 0, 0, 0.1);
                                    border-width: 10px 10px 0 10px;
                                    border-radius: calc( var(--shot-device-content-radius) * var(--shot-device-frame-zoom)  );
                                    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0) 100%);;
                                    background-size: 100%;
                                    background-repeat: no-repeat;
                                    background-position: 0 -10px;
                                }
                            }
                        }

                    }

                    .content-mode-all1,
                    .content-mode-all2,
                    .content-mode-all4,
                    .content-mode-all5 {
                        .device-text-box {
                            .device-text {
                                &::after {
                                    background: none;
                                    border:none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}