/*
fontFamily: jiangxizhuokai
fontSubFamily: Regular
uniqueSubFamily: 1.000;UKWN;jiangxizhuokai-Regular
fullName: jiangxizhuokai Regular
version: Version 1.000
postScriptName: jiangxizhuokai-Regular
tradeMark: fontree
manufacturer: fontree
designer: huang yu chen
urlOfFontVendor: https://www.zcool.com.cn/u/653645
urlOfFontDesigner: https://www.zcool.com.cn/u/653645
 */

@font-face {
            font-family: "jiangxizhuokai";
            src: url("./c0b2d4cdbeee09dfd1a9d20e68f8a42b.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+21,U+22,U+23,U+24,U+25,U+26,U+27,U+28,U+29,U+2A,U+2B,U+2C,U+2D,U+2E,U+2F,U+30,U+31,U+32,U+33,U+34,U+35,U+36,U+37,U+38,U+39,U+3A,U+3B,U+3C,U+3D,U+3E,U+3F,U+40,U+41,U+42,U+43,U+44,U+45,U+46,U+47,U+48,U+49,U+4A,U+4B,U+4C,U+4D,U+4E,U+4F,U+50,U+51,U+52,U+53,U+54,U+55,U+56,U+57,U+58,U+59,U+5A,U+5B,U+5D,U+5F,U+61,U+62,U+63,U+64,U+65,U+66,U+67,U+68,U+69,U+6A,U+6B,U+6C,U+6D,U+6E,U+6F,U+70,U+71,U+72,U+73,U+74,U+75,U+76,U+77,U+78,U+79,U+7A,U+7B,U+7D,U+7E,U+A1,U+A2,U+A3,U+A5,U+A9,U+AB,U+AE,U+B0,U+B1,U+B7,U+BB,U+BF,U+D7,U+F7,U+FC,U+2013,U+2014,U+2018,U+2019,U+201A,U+201C,U+201D,U+201E,U+2022,U+2026,U+2030,U+2039,U+203A,U+20AC,U+2122,U+2212,U+3001,U+3002,U+2016,U+3008,U+3009,U+300A,U+300B,U+300C,U+300D,U+3010,U+3011,U+2248,U+2260,U+2264,U+2265,U+221E,U+203B,U+FF01,U+FF02,U+FF03,U+FF05,U+FF06,U+FF07,U+FF08,U+FF09,U+FF0A,U+FF0C,U+FF0D,U+FF0E,U+FF0F,U+FF1A,U+FF1B,U+FF1F,U+FF20,U+FF3B,U+FF3C,U+FF3D,U+FF5B,U+FF5D,U+FE10,U+FE12,U+FE19,U+FE31,U+FE33,U+FE35,U+FE36,U+FE37,U+FE38,U+FE3B,U+FE3C,U+FE3D,U+FE3E,U+FE3F,U+FE40,U+FE41,U+FE42,U+7684,U+5E74,U+4E00,U+5728,U+4E3A,U+662F,U+4E2D,U+56FD,U+4EBA,U+4E8E,U+5927,U+6709,U+548C,U+4EE5,U+6708,U+65E5,U+540E,U+4E86,U+5B66,U+65F6,U+4E2A,U+4F1A,U+5730,U+6210,U+516C,U+4E0A,U+4E0D,U+51FA,U+884C,U+4ED6,U+90E8,U+65AF,U+4E4B,U+4E0E,U+533A,U+540D,U+53D1,U+5176,U+4E3B,U+4F5C,U+751F,U+5C14,U+5206,U+65B9,U+4E0B,U+897F,U+7528,U+5230,U+7B2C,U+4F4D,U+800C,U+5BB6,U+519B,U+7531,U+53CA,U+91CC,U+88AB,U+5E76,U+672C,U+6C11,U+6CD5,U+5357,U+8FD9,U+5E02,U+6765,U+957F,U+540C,U+53EF,U+591A,U+653F,U+5BF9,U+5B50,U+81EA,U+4EFB,U+52A8,U+5F00,U+7279,U+6587,U+5317,U+9AD8,U+52A0,U+4E1C,U+5FB7,U+6700,U+6D77,U+65B0,U+514B,U+53F0,U+5458,U+4E5F,U+524D,U+4EE3,U+7B49,U+5229,U+4E9A,U+5E73,U+6240,U+4E09,U+9762,U+7ECF,U+5EA6,U+9053,U+7535,U+56E0;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./824ad885220ec403ebe8de8db4d681ab.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+73B0,U+6B64,U+5F97,U+5EFA,U+7F8E,U+8FDB,U+7269,U+8FC7,U+8981,U+673A,U+95F4,U+5185,U+6559,U+5C71,U+81F3,U+4E8B,U+5C0F,U+7ACB,U+8DEF,U+62C9,U+53E3,U+8F66,U+5F53,U+5165,U+6218,U+4F53,U+80FD,U+5C06,U+4E16,U+4E8C,U+7406,U+5DE5,U+8868,U+5408,U+9A6C,U+5916,U+5929,U+5DDE,U+8BE5,U+5B9A,U+5341,U+7403,U+671F,U+79CD,U+4F46,U+5316,U+8D5B,U+4E1A,U+4F7F,U+79D1,U+5236,U+6B21,U+5168,U+5E03,U+79F0,U+6027,U+573A,U+901A,U+961F,U+603B,U+8005,U+7EBF,U+5171,U+8BBE,U+53BF,U+5F0F,U+6570,U+91CD,U+90FD,U+7CFB,U+4E24,U+7F57,U+529B,U+5B89,U+58EB,U+8054,U+6BD4,U+738B,U+76F8,U+539F,U+57FA,U+57CE,U+9662,U+7AD9,U+8FBE,U+7701,U+5C5E,U+660E,U+8FD0,U+76EE,U+6C34,U+53F8,U+6B63,U+91D1,U+6CBB,U+7C73,U+82F1,U+683C,U+5C3C,U+9547,U+53F7,U+7EDF,U+534E,U+5E9C,U+5170,U+7EA6,U+5173,U+7EC4,U+4EEC,U+56DB,U+5982,U+6216,U+5217,U+5143,U+5DF4,U+9009,U+8BA1,U+6E2F,U+8D77,U+661F,U+5F71,U+8BED,U+4ECE,U+4EA7,U+9996,U+5E38,U+5973,U+63D0,U+963F,U+6539,U+6F14,U+6797,U+91CF,U+5FC3,U+7136,U+5411,U+5C31,U+59CB,U+63A5,U+4E50,U+8BAE,U+5B9E,U+65E0,U+754C,U+53C2,U+4E66,U+52A1,U+7EA7,U+66FE,U+610F,U+5219,U+4E49,U+5E7F,U+7BA1,U+5355,U+8BF4,U+6CB3,U+5361,U+624B,U+53D7,U+59D4,U+7A0B,U+5F62,U+8272,U+89C6,U+4FDD,U+4EA4,U+6307,U+95E8,U+5305,U+56E2,U+7EF4,U+7ED3,U+6D41,U+6E7E,U+578B,U+5DF2,U+5904,U+97F3,U+4F20,U+4E9B,U+9999,U+76F4,U+7C7B,U+515A,U+592A,U+96C6,U+83B7,U+53F2,U+53D8,U+8EAB,U+4E07,U+592B,U+70B9,U+5E94,U+5B57,U+4E94,U+5BFC,U+8BA4,U+5C9B,U+54C1,U+6821,U+7A7A,U+671D,U+9886,U+56DE,U+66F4,U+6C5F,U+8FD8,U+5C55,U+53E4,U+793E,U+79EF,U+94C1,U+793A,U+5F20,U+5E08,U+6301,U+5404,U+56FE,U+6D32,U+5E26,U+5965,U+8457,U+5B98,U+53EA,U+53CD,U+522B,U+547D,U+4E13,U+7EAA,U+98CE,U+6E38,U+4EAC,U+53D6,U+6D3B,U+8D44,U+636E,U+9020,U+5149,U+62A5,U+4E3E,U+51FB,U+4FE1,U+5C11,U+672F,U+77F3,U+652F,U+65CF,U+5386,U+795E,U+8FD1,U+66F2,U+666E,U+6CA1,U+6E05,U+5C40,U+7EB3,U+6751,U+6807,U+89E3,U+8F6C,U+5B83,U+5267,U+53C8,U+60C5,U+9645,U+975E,U+8282,U+9F99,U+6BCF,U+8BBA,U+82CF,U+7814,U+8BB0,U+6CE2,U+6743,U+4EF6,U+6761,U+89D2,U+6C14;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./d133291006c68ecad50ac872904fceaf.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+653E,U+51B3,U+4E61,U+8F96,U+7247,U+6B66,U+5934,U+5373,U+5BC6,U+7248,U+4F0A,U+8FDE,U+5546,U+5979,U+674E,U+5148,U+62EC,U+5468,U+804C,U+6536,U+77E5,U+5EA7,U+5668,U+5F80,U+53BB,U+767D,U+79BB,U+672A,U+521B,U+5F3A,U+679C,U+6839,U+6B4C,U+518D,U+521D,U+798F,U+8DB3,U+9646,U+529E,U+7A76,U+516D,U+4E14,U+4ECA,U+6D3E,U+5723,U+4EB2,U+6C49,U+5175,U+590D,U+5F15,U+9633,U+8C03,U+5C45,U+4EA6,U+5B8C,U+6253,U+5212,U+57DF,U+5E1D,U+89C2,U+88C5,U+8BB8,U+5854,U+5B97,U+5B63,U+8D28,U+7F51,U+9664,U+64AD,U+4E89,U+7F16,U+9898,U+5A01,U+968F,U+7EED,U+6BB5,U+5F88,U+82B1,U+670D,U+8F83,U+5956,U+4EE4,U+516B,U+80B2,U+7ED9,U+6784,U+901F,U+8A00,U+534F,U+56ED,U+7231,U+89C1,U+620F,U+571F,U+529F,U+53E6,U+4FEE,U+6B7B,U+9EC4,U+7F6E,U+5E0C,U+8428,U+62C5,U+95EE,U+7236,U+5B58,U+597D,U+5BA2,U+63A8,U+8425,U+89C4,U+67E5,U+7EC8,U+8D85,U+6B27,U+5BA3,U+7387,U+96F7,U+6D1B,U+6837,U+9879,U+5F55,U+753B,U+6211,U+5409,U+4F9B,U+62A4,U+5177,U+6295,U+54C8,U+4E5D,U+6E90,U+536B,U+73ED,U+8857,U+51C6,U+9986,U+535A,U+74E6,U+9752,U+5174,U+4F26,U+9C7C,U+6BCD,U+6C42,U+8D1F,U+767E,U+6E56,U+73AF,U+5370,U+7530,U+8FB9,U+7537,U+6D4E,U+4F2F,U+8D23,U+8BD5,U+4F8B,U+6848,U+9648,U+653B,U+6545,U+5DF1,U+706B,U+90A3,U+52D2,U+843D,U+6C99,U+52A9,U+4F17,U+5907,U+513F,U+81F4,U+7687,U+4EFD,U+822A,U+6781,U+70ED,U+83B1,U+8BFA,U+65E9,U+6280,U+6574,U+827A,U+9C81,U+5BB9,U+5747,U+589E,U+526F,U+5FD7,U+6A21,U+5347,U+534A,U+767B,U+7FA4,U+7EA2,U+5C42,U+4E03,U+56F4,U+98DE,U+5DDD,U+8D8A,U+5802,U+7EE7,U+5931,U+6258,U+53CC,U+544A,U+6613,U+5199,U+98DF,U+6C0F,U+8BC4,U+5883,U+6DF1,U+72EC,U+5BA4,U+9ED1,U+5C04,U+6392,U+585E,U+9700,U+8BC1,U+9650,U+7B51,U+58F0,U+7532,U+50CF,U+8C61,U+7EC7,U+592E,U+8001,U+771F,U+5360,U+54CD,U+533B,U+4F55,U+8D39,U+624D,U+91C7,U+6740,U+4ECD,U+8BA9,U+6728,U+4FBF,U+68EE,U+6D0B,U+6B65,U+5E2D,U+76DF,U+5947,U+7259,U+8230,U+8003,U+60F3,U+65BD,U+697C,U+611F,U+914D,U+9632,U+54E5,U+4F4E,U+5C81,U+5218,U+987F,U+4F4F,U+4E4C,U+6548,U+6865,U+6B62,U+8D1D,U+5C4A,U+7559,U+59C6,U+5F8B,U+8303,U+51E0,U+628A,U+519C,U+5343,U+5E95;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./e6cb72d6d5fcdb2fd86e763ca400561a.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+75C5,U+57C3,U+6CF0,U+66FC,U+6599,U+4F5B,U+51A0,U+8F91,U+94F6,U+6069,U+72B6,U+8B66,U+4E91,U+964D,U+8499,U+5E93,U+8BDD,U+770B,U+666F,U+5B81,U+80DC,U+8FEA,U+606F,U+4EC5,U+6D4B,U+4FC4,U+5531,U+5BDF,U+79FB,U+7D20,U+7956,U+6279,U+7740,U+8D25,U+9769,U+663E,U+5EB7,U+5FF5,U+7B97,U+6D88,U+627F,U+5E72,U+7D22,U+6E29,U+6388,U+63A7,U+8BCD,U+5178,U+85CF,U+7968,U+8FDC,U+5F39,U+8F7D,U+97E9,U+601D,U+4E60,U+6237,U+505A,U+53CB,U+5B88,U+786E,U+4F59,U+6001,U+7CBE,U+6EE1,U+745E,U+53F6,U+5BAB,U+7763,U+9644,U+671B,U+4F9D,U+4EF7,U+6CE8,U+9884,U+5F81,U+7167,U+623F,U+8FF0,U+590F,U+62D4,U+6BDB,U+7AE0,U+8BF7,U+6297,U+6267,U+8349,U+5374,U+538B,U+867D,U+7B80,U+9000,U+505C,U+5C01,U+5BCC,U+6C38,U+6FB3,U+822C,U+5DE6,U+4E39,U+503C,U+5510,U+4E01,U+724C,U+5821,U+4E3D,U+6597,U+9A8C,U+5FEB,U+62FF,U+52BF,U+5019,U+5766,U+5B9D,U+4FA7,U+8239,U+7834,U+9686,U+5E84,U+6885,U+90A6,U+5BFA,U+96BE,U+690D,U+542B,U+5E86,U+90E1,U+83AB,U+65AD,U+5382,U+8BC6,U+4F3C,U+7EC6,U+7A81,U+76AE,U+76D1,U+5FC5,U+95FB,U+51B5,U+793C,U+5C1A,U+65C5,U+8482,U+8D70,U+62E5,U+4E45,U+5609,U+8BFB,U+5CB8,U+7EDD,U+5207,U+5E97,U+5EF6,U+53F3,U+6717,U+9EA6,U+6BD5,U+5385,U+6362,U+67B6,U+91CE,U+8F6E,U+6770,U+5F1F,U+6838,U+552E,U+6625,U+4E34,U+65E7,U+4EA1,U+4F18,U+677E,U+4E25,U+5F52,U+6CB9,U+827E,U+5F17,U+5224,U+67AA,U+4F73,U+6768,U+5F02,U+96C4,U+9057,U+7AEF,U+77ED,U+5B59,U+7801,U+5434,U+4F24,U+80A1,U+7075,U+8036,U+906D,U+7EC3,U+7B56,U+9010,U+8D6B,U+8F7B,U+5E8F,U+5E15,U+987A,U+5F79,U+8DDD,U+65D7,U+6469,U+987B,U+83F2,U+7C4D,U+6811,U+9152,U+9001,U+82E5,U+5B8B,U+665A,U+4E58,U+96C5,U+9500,U+672B,U+66B4,U+6742,U+675F,U+5FAE,U+9635,U+4EC0,U+739B,U+949F,U+6CFD,U+6444,U+8F93,U+8BD7,U+79C0,U+80E1,U+51B2,U+9F50,U+5362,U+8BD1,U+826F,U+517C,U+4EBF,U+9636,U+8840,U+7F72,U+5EAD,U+7565,U+6C83,U+6B3E,U+836F,U+9891,U+5C3E,U+8363,U+5584,U+4E30,U+9A7B,U+76D6,U+9970,U+6D25,U+52B3,U+751A,U+6563,U+8D35,U+542F,U+514D,U+5A5A,U+62CD,U+5C3D,U+5CF0,U+5DEE,U+7EBD,U+5047,U+9876,U+7B7E,U+9C9C,U+559C,U+4E88,U+81E3,U+675C,U+8FC1,U+677F,U+51CF,U+664B;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./4b04c5e059113db8c44cc9a24532ba2d.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+6731,U+8FFD,U+5179,U+8C37,U+676F,U+8D22,U+70C8,U+8D2D,U+4EC1,U+517B,U+79D8,U+80CC,U+5BB3,U+51EF,U+7206,U+5BBE,U+821E,U+6CBF,U+76DB,U+6E10,U+593A,U+4F01,U+6881,U+5EF7,U+9669,U+4E92,U+91CA,U+9ECE,U+97E6,U+9178,U+8BAD,U+7389,U+8BAF,U+6551,U+725B,U+63F4,U+8D27,U+7F6A,U+5A92,U+8F68,U+5706,U+660C,U+68C0,U+8F6F,U+591F,U+5C3A,U+8C22,U+4ECB,U+8865,U+5C4B,U+900F,U+541B,U+5740,U+5BA1,U+6620,U+6EAA,U+5761,U+5E01,U+5E99,U+4EEA,U+6269,U+6325,U+8BB2,U+8377,U+667A,U+6750,U+8BBF,U+6309,U+5DE8,U+5F92,U+9897,U+9002,U+4E54,U+6566,U+96B6,U+90CE,U+654C,U+7AE5,U+73A9,U+62DC,U+76CA,U+90D1,U+51B0,U+5DE1,U+6DF7,U+5F84,U+8F86,U+84DD,U+63A2,U+5BBD,U+7FF0,U+67D0,U+9014,U+8BF8,U+7981,U+591C,U+7A46,U+8BA8,U+65CB,U+89C9,U+970D,U+66FF,U+523B,U+773C,U+732E,U+989D,U+9526,U+523A,U+6BBF,U+7686,U+8D75,U+63CF,U+8DDF,U+5438,U+57F9,U+5CA9,U+9047,U+520A,U+501F,U+5E2E,U+7B26,U+4E71,U+4F60,U+627E,U+64A4,U+4F69,U+7ADE,U+805A,U+6B22,U+5348,U+70AE,U+7235,U+8111,U+7FBD,U+5145,U+5B87,U+79C1,U+4F11,U+8033,U+9B54,U+56FA,U+5949,U+90BB,U+88AD,U+5E55,U+6D2A,U+552F,U+6F2B,U+8FD4,U+6BD2,U+5751,U+6208,U+9AA8,U+96E8,U+5426,U+9003,U+5750,U+5E9F,U+8328,U+6052,U+96EA,U+64CD,U+9760,U+5FE0,U+6FC0,U+5377,U+7AF9,U+7EDC,U+53EC,U+8D5E,U+62E9,U+4E4E,U+8D64,U+6816,U+7F3A,U+7A7F,U+94B1,U+4E1D,U+6355,U+9707,U+6000,U+76D8,U+9732,U+5FA1,U+6C47,U+7597,U+7EFF,U+5BAA,U+4E48,U+5211,U+706D,U+72AF,U+80AF,U+5A1C,U+955C,U+9A91,U+5C24,U+8D21,U+8BC9,U+5144,U+5F90,U+8BFE,U+59BB,U+7FFB,U+987E,U+521A,U+7EB2,U+8FEB,U+59D3,U+542C,U+635F,U+62B5,U+575A,U+5F85,U+9CCD,U+7533,U+6851,U+9675,U+6BC1,U+67CF,U+6D59,U+8BA2,U+79CB,U+80DE,U+547C,U+613F,U+4FB5,U+70B8,U+8C0B,U+5B69,U+6C7D,U+6A2A,U+8109,U+6CDB,U+7BC7,U+8C08,U+624E,U+6F6E,U+9E1F,U+60E0,U+6076,U+8463,U+7EE9,U+9B4F,U+6CC9,U+7518,U+5B54,U+878D,U+4E70,U+7ECD,U+814A,U+504F,U+6234,U+8F9B,U+820D,U+907F,U+82AC,U+9ED8,U+7F29,U+7840,U+6025,U+8131,U+594F,U+5065,U+4F50,U+7591,U+52AA,U+5957,U+5371,U+8BEF,U+6982,U+7FFC,U+8089,U+5448,U+58A8,U+8863,U+5893,U+8389,U+699C,U+5757,U+9488;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./0993d928996beb00eb142b16205c7c81.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+5B9C,U+7D27,U+4E95,U+574F,U+6B96,U+88C1,U+53EB,U+79E6,U+51B7,U+8FCE,U+626C,U+5BFB,U+8D56,U+6BEB,U+67D3,U+864E,U+9694,U+5708,U+7F18,U+73E0,U+6D89,U+8FF7,U+6377,U+8FF9,U+5EC9,U+6E21,U+963B,U+9634,U+54F2,U+5F69,U+4EAE,U+5251,U+4FAF,U+6790,U+9521,U+6C60,U+4ED9,U+8D38,U+4ED8,U+8461,U+866B,U+6050,U+5F03,U+8F85,U+5356,U+6C27,U+51FD,U+6697,U+62DB,U+5473,U+51E4,U+7ED5,U+7434,U+5339,U+5BFF,U+5C0A,U+6EE8,U+9519,U+68A6,U+6D1E,U+7ED8,U+4FD7,U+94A2,U+9EBB,U+75C7,U+4F0D,U+59B9,U+4EAB,U+811A,U+4FF1,U+786C,U+8D62,U+9881,U+8C6A,U+745F,U+753A,U+7B14,U+8F90,U+5012,U+79DF,U+5D07,U+7EEA,U+51AC,U+7E41,U+622A,U+7EFC,U+6DB2,U+9A71,U+77FF,U+5FBD,U+8A89,U+66F9,U+5948,U+9759,U+900A,U+695A,U+7A33,U+94DC,U+5188,U+6602,U+5987,U+901D,U+72B9,U+9601,U+662D,U+5415,U+86CB,U+638C,U+67F1,U+9F7F,U+4F30,U+4F10,U+4E59,U+5899,U+8F9E,U+6D66,U+8404,U+8FBD,U+95ED,U+90ED,U+8F74,U+6F5C,U+632F,U+6DE1,U+5CAD,U+88C2,U+4E43,U+7EB8,U+5E7C,U+6863,U+9080,U+5F31,U+6682,U+76D0,U+62DF,U+96F6,U+518C,U+8D4F,U+9F13,U+88D4,U+62D2,U+9676,U+8DE8,U+6D6A,U+6ED1,U+89E6,U+838E,U+6062,U+6606,U+7C89,U+5E78,U+85E4,U+9677,U+56F0,U+83DC,U+65C1,U+5242,U+4F1F,U+707E,U+5076,U+83CC,U+89C8,U+8521,U+60A3,U+969C,U+989C,U+7267,U+6B8B,U+6D85,U+9A76,U+5F7C,U+7CD6,U+7EAF,U+6C88,U+5BBF,U+58C1,U+4E18,U+7A0E,U+796D,U+848B,U+90AE,U+72F1,U+4F34,U+52C7,U+9690,U+67EF,U+53E5,U+4EA8,U+6C57,U+4ED4,U+654F,U+53A6,U+6843,U+7199,U+9F84,U+8861,U+706F,U+952E,U+642D,U+904D,U+6B8A,U+5141,U+6C64,U+71C3,U+73CD,U+7B54,U+7586,U+57D4,U+6C89,U+4FC3,U+5B5F,U+604B,U+5B85,U+70E7,U+8336,U+5974,U+5E45,U+62C6,U+8FC8,U+4F8D,U+7D2B,U+7EAC,U+865A,U+539A,U+78C1,U+68D2,U+83B2,U+7070,U+6298,U+5E7B,U+70DF,U+846C,U+8DD1,U+5858,U+575B,U+68B0,U+8D74,U+5B5D,U+8258,U+53D9,U+9093,U+656C,U+6C9F,U+950B,U+7891,U+8D24,U+5A31,U+9910,U+7BEE,U+9655,U+50A8,U+7965,U+6148,U+6676,U+8F89,U+9063,U+8427,U+7B79,U+67F4,U+52CB,U+68CB,U+7EB5,U+7BAD,U+6311,U+500D,U+8C31,U+845B,U+59D0,U+87BA,U+649E,U+53DB,U+5CE1,U+8651,U+51AF,U+6162,U+719F,U+8D3E,U+67F3,U+5C16,U+96D5,U+82E6;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./27845f74eb725eadd1bf6328d0138f88.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+9042,U+8247,U+4F0F,U+5200,U+4ED3,U+59AE,U+4EF0,U+8DF3,U+71D5,U+5308,U+9A7E,U+7EB9,U+51E1,U+6842,U+8FDD,U+75BE,U+670B,U+6492,U+4F38,U+74DC,U+679A,U+730E,U+8D3A,U+8854,U+8986,U+5403,U+8D2F,U+8FC5,U+7F13,U+5F6D,U+8C13,U+6F58,U+8BE6,U+6155,U+517D,U+7433,U+5FC6,U+7C92,U+52E4,U+9B3C,U+6CCA,U+8102,U+9756,U+8DA3,U+756A,U+732A,U+718A,U+54B8,U+7F55,U+7D2F,U+9E7F,U+6DAF,U+8BDA,U+6389,U+82B3,U+86C7,U+5C7F,U+8083,U+5C6F,U+65FA,U+79D2,U+64CE,U+8A79,U+996E,U+7EB7,U+80F6,U+8D60,U+60CA,U+641C,U+602A,U+503E,U+68AF,U+7984,U+7855,U+58F3,U+6302,U+7F8A,U+7384,U+9875,U+6D6E,U+4FCA,U+62D3,U+6749,U+4EF2,U+629A,U+6668,U+829D,U+4E73,U+5C4F,U+4E7E,U+9F20,U+8BDE,U+5CB3,U+51ED,U+75DB,U+8179,U+7F05,U+9501,U+732B,U+5353,U+6CE5,U+63D2,U+85E9,U+574E,U+6167,U+51CC,U+5F18,U+6653,U+9274,U+7554,U+8881,U+8C46,U+5384,U+676D,U+5D0E,U+96A7,U+5851,U+708E,U+65E6,U+7B11,U+52B1,U+6B32,U+6591,U+7538,U+537F,U+5580,U+9E21,U+6212,U+5B8F,U+8DC3,U+4E4F,U+64B0,U+8F70,U+82D7,U+5733,U+72EE,U+7275,U+6EB6,U+6270,U+4EAD,U+73BB,U+4EFF,U+7F5A,U+6D17,U+8D50,U+5BC4,U+962E,U+76D7,U+5C09,U+5B99,U+8D1E,U+5F7B,U+795D,U+4E27,U+574A,U+8212,U+9CA4,U+53D4,U+5DE7,U+5FAA,U+7126,U+8896,U+9668,U+6CF3,U+60AC,U+50E7,U+7BB1,U+80C1,U+626E,U+5983,U+8302,U+5428,U+90CA,U+8BE2,U+58EE,U+65E2,U+5F25,U+6E7F,U+9605,U+8000,U+7CA4,U+758F,U+8870,U+9738,U+6C61,U+78B3,U+5C97,U+67A2,U+5D14,U+4FA6,U+7FC1,U+76FE,U+6447,U+5951,U+4E32,U+5F70,U+679D,U+4F19,U+9022,U+5398,U+6EDA,U+6350,U+98D3,U+6D53,U+94A6,U+5E8A,U+6C22,U+5269,U+6B67,U+55B7,U+62F3,U+63AA,U+52C3,U+6D69,U+7B3C,U+9E3F,U+8352,U+5238,U+6D45,U+5E05,U+535C,U+5085,U+6EE9,U+586B,U+9012,U+6E14,U+5937,U+9C8D,U+642C,U+88D5,U+996D,U+8776,U+6DA6,U+704C,U+5272,U+7A97,U+884D,U+6012,U+6DEE,U+4E08,U+60EF,U+4FA0,U+94FE,U+7CAE,U+5E9E,U+5112,U+80A5,U+683D,U+8D34,U+9E70,U+5C38,U+65E8,U+8E2A,U+6B23,U+62BD,U+72C2,U+5C1D,U+73CA,U+5782,U+659C,U+8D76,U+788D,U+6D01,U+4ED1,U+5792,U+80CE,U+819C,U+6089,U+817E,U+5A18,U+5B64,U+8FA9,U+95FD,U+5192,U+7164,U+6E58,U+8D2B,U+67D4,U+75AB,U+7C97,U+8058;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./c5d7cc3816fed23e9d5f88b6040dcba3.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+5ECA,U+7A3F,U+543E,U+63E1,U+6746,U+8017,U+9640,U+576A,U+8FDF,U+72C4,U+7409,U+5F66,U+5BE8,U+58E4,U+5988,U+9006,U+8C8C,U+7CB9,U+51C0,U+6734,U+5A46,U+582A,U+62A2,U+7985,U+63ED,U+7FE0,U+5BD2,U+9F9F,U+4FD8,U+94FA,U+6F6D,U+9614,U+8584,U+6070,U+4E1B,U+72D7,U+77E9,U+5F26,U+5FCD,U+7A0D,U+9B42,U+7FCC,U+5410,U+8D4B,U+9F3B,U+632A,U+575D,U+9887,U+7483,U+6276,U+5806,U+788E,U+8BCF,U+9075,U+903B,U+5237,U+59EC,U+764C,U+4F2A,U+810F,U+59DA,U+94ED,U+86DB,U+80F8,U+6DB5,U+9E23,U+5352,U+77F6,U+53E0,U+82D1,U+7F62,U+6C6A,U+962A,U+6DD8,U+4E19,U+5077,U+6016,U+540F,U+8350,U+72FC,U+5146,U+6DFB,U+786B,U+6CE1,U+680F,U+529D,U+6B47,U+6D8C,U+5BF8,U+60DF,U+5954,U+626B,U+7940,U+62BC,U+810A,U+59FB,U+83CA,U+902E,U+8D8B,U+96C7,U+903C,U+743C,U+84B8,U+9176,U+78E8,U+643A,U+55E3,U+8FB0,U+5C60,U+5C48,U+4EC7,U+5915,U+9192,U+8150,U+96C0,U+7A23,U+53C9,U+594E,U+731B,U+5ACC,U+5375,U+97F5,U+59DC,U+68D8,U+8BCA,U+789F,U+52AB,U+6D82,U+62FC,U+507F,U+65A4,U+8292,U+5634,U+5AC1,U+9E64,U+968B,U+51C9,U+53A2,U+6108,U+859B,U+66F0,U+51DD,U+7901,U+60B2,U+72AC,U+9CB6,U+6446,U+5976,U+6562,U+5A03,U+4F3D,U+6398,U+94F8,U+77DB,U+503A,U+95EA,U+5351,U+5FD9,U+5999,U+8096,U+6C2F,U+6241,U+8FE6,U+59CA,U+8BB7,U+808C,U+9187,U+6291,U+7960,U+5FFD,U+96CD,U+5439,U+72ED,U+7A77,U+65EC,U+4FA8,U+8D29,U+4E11,U+9F0E,U+78A7,U+7816,U+5BB0,U+90AA,U+8231,U+888B,U+5E7D,U+7802,U+9E9F,U+7089,U+752B,U+6C70,U+94C3,U+9CDE,U+62B1,U+682A,U+7FD4,U+680B,U+6015,U+5C39,U+7A3B,U+5939,U+8568,U+7EF3,U+70BC,U+9C88,U+7ADF,U+606D,U+76C6,U+62D6,U+60E8,U+8E48,U+5DEB,U+8C2D,U+7EA4,U+6590,U+526A,U+9091,U+572D,U+6C40,U+4E38,U+5D29,U+5BD3,U+6293,U+5F2F,U+5BEE,U+6BC5,U+6CAA,U+8335,U+8F9F,U+94BB,U+6C5D,U+8679,U+8C6B,U+80C6,U+95F8,U+9102,U+9B41,U+87F9,U+6E0A,U+8305,U+8346,U+658B,U+8FA8,U+8944,U+57CB,U+54A8,U+5021,U+6C28,U+65A9,U+82F9,U+5ED6,U+56CA,U+90C1,U+5DE2,U+96FE,U+8154,U+96CC,U+8702,U+6263,U+9A73,U+84EC,U+4E1E,U+817A,U+73C0,U+5496,U+95F2,U+594B,U+9E2D,U+6696,U+5B55,U+541E,U+94CE,U+6316,U+64C5,U+5D16,U+62AB,U+8C26,U+52B2,U+82EF,U+62E8,U+5427;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./d907ac7d6312dfa15488dcc16c7b15e3.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+80A0,U+543B,U+6EAF,U+966A,U+8015,U+6ED5,U+52DF,U+74F6,U+9177,U+821F,U+8138,U+6F20,U+8010,U+8361,U+524A,U+5FD8,U+820C,U+77EE,U+6BD7,U+806A,U+8C25,U+5742,U+5587,U+6817,U+84B2,U+857E,U+4EA5,U+4ED5,U+871C,U+8700,U+66A8,U+62F1,U+9888,U+90B1,U+5676,U+8587,U+50AC,U+5DF7,U+672D,U+6E20,U+78B1,U+59D1,U+63A0,U+5E3D,U+9CB8,U+9065,U+5378,U+85AA,U+5E10,U+7A00,U+6467,U+9877,U+742A,U+6E83,U+9510,U+80FA,U+8BF1,U+8C0A,U+65A5,U+5BA0,U+5A74,U+5FE7,U+8C79,U+6853,U+85FB,U+7B52,U+8E0F,U+6DD1,U+6251,U+68C9,U+751C,U+5561,U+690E,U+8D3C,U+6124,U+90B5,U+7545,U+5EB6,U+62D8,U+7761,U+5B9B,U+68A8,U+8C01,U+60DC,U+70EF,U+78F7,U+7237,U+7A74,U+5507,U+54EA,U+6F84,U+5195,U+68B5,U+5E06,U+5C3F,U+5BB4,U+8475,U+79BE,U+63A9,U+4EA9,U+8910,U+5824,U+6984,U+6F0F,U+8F69,U+51F6,U+78B0,U+5367,U+7EF5,U+83E9,U+80BA,U+74F7,U+8A93,U+9E45,U+5F13,U+73B2,U+9038,U+6321,U+817F,U+6021,U+997C,U+6A31,U+68D5,U+9E4F,U+5938,U+8D2A,U+7624,U+80A4,U+4EC6,U+684C,U+59FF,U+4F1E,U+540A,U+60A0,U+7FC5,U+971E,U+614E,U+7EE5,U+5A36,U+98A0,U+81C2,U+6850,U+6846,U+82A6,U+722A,U+5960,U+4FA3,U+7ED1,U+5180,U+5F04,U+67C4,U+539D,U+80A2,U+6068,U+6284,U+60E7,U+6D9B,U+8760,U+5978,U+629B,U+6170,U+8D37,U+7A84,U+8C10,U+8170,U+7272,U+9A97,U+9E26,U+80BF,U+5220,U+8D4C,U+8153,U+809D,U+9A9A,U+8F88,U+6BB7,U+626D,U+6CBC,U+830E,U+76C8,U+5401,U+80A9,U+9896,U+9882,U+52A3,U+8680,U+8DF5,U+54C0,U+7EA0,U+6DA1,U+5996,U+70F7,U+7948,U+5E87,U+6C76,U+5FCC,U+6EE4,U+7F34,U+731C,U+575F,U+56DA,U+5C18,U+8BBC,U+69FD,U+903E,U+88F8,U+6D78,U+8D54,U+9551,U+6735,U+722C,U+6ECB,U+70AD,U+5C61,U+600E,U+575E,U+57E0,U+9CC4,U+79E9,U+53A5,U+9976,U+60A6,U+796F,U+7978,U+5154,U+641E,U+6247,U+7709,U+8C23,U+867E,U+82AD,U+5BC7,U+8DCB,U+7F1D,U+5D4C,U+5E9A,U+51F0,U+745A,U+5AE9,U+84C4,U+8087,U+906E,U+7A9D,U+8D81,U+6CC4,U+5760,U+8299,U+8DCC,U+79A7,U+5EB8,U+7334,U+6905,U+7EBA,U+711A,U+609F,U+6614,U+8725,U+9A8F,U+6D4F,U+633A,U+864F,U+5C27,U+9505,U+6A61,U+6C1F,U+8D2C,U+8206,U+8343,U+8BBD,U+80BE,U+8543,U+6B20,U+6674,U+67DC,U+5389,U+64E6,U+7720,U+60E9,U+9CB7,U+4F51,U+55BB;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./6213d8dd0712fe25111ac94a2c8d69f7.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+67EC,U+5524,U+8304,U+745C,U+6D46,U+978B,U+65F1,U+6349,U+5764,U+82CD,U+9493,U+7238,U+72D0,U+5265,U+660F,U+94DD,U+6324,U+548F,U+6500,U+6B49,U+52D8,U+538C,U+841D,U+80C0,U+51BB,U+6028,U+6F02,U+916E,U+8E22,U+94A9,U+6A44,U+748B,U+9189,U+559D,U+68F1,U+5C65,U+584C,U+75AF,U+7F38,U+836B,U+70B3,U+8D63,U+81C0,U+6C2E,U+70C2,U+6DF3,U+7194,U+775B,U+7A57,U+4E22,U+54FA,U+88F4,U+8EB2,U+7A91,U+50DA,U+65ED,U+9525,U+6491,U+5A05,U+9576,U+53A8,U+6EE5,U+77E2,U+6B7C,U+7CD5,U+8C28,U+76F2,U+5492,U+76D2,U+5320,U+59A5,U+51F8,U+69B4,U+6DA8,U+8273,U+658C,U+532A,U+7AE3,U+727A,U+79C9,U+621A,U+51A2,U+917F,U+5C90,U+5DE9,U+9600,U+75D5,U+68DA,U+755C,U+5323,U+714C,U+7F14,U+5F99,U+8FB1,U+57A6,U+5E16,U+6454,U+5589,U+94A0,U+9716,U+978D,U+68AD,U+5EB5,U+916F,U+6CEA,U+9E93,U+6F06,U+620A,U+8042,U+52FE,U+9EDB,U+86D9,U+9971,U+961C,U+561B,U+94C5,U+865E,U+7574,U+7476,U+8D3F,U+754F,U+7CCA,U+82D4,U+86EE,U+83F1,U+7B03,U+6B3A,U+67AB,U+95F9,U+5E90,U+7784,U+9965,U+6F8E,U+6912,U+54ED,U+6D74,U+6F33,U+4F63,U+5EFF,U+725F,U+6DC0,U+7F69,U+960E,U+8650,U+7459,U+620C,U+57A3,U+94A7,U+7B1B,U+7977,U+5E62,U+62E6,U+5E1C,U+62EF,U+5F57,U+9A70,U+6714,U+97F6,U+8FA3,U+51F9,U+8C0D,U+86E4,U+692D,U+914B,U+566A,U+5F27,U+95EF,U+68E0,U+60BC,U+9CA8,U+70E6,U+5F6C,U+7EC5,U+7FE1,U+988C,U+6960,U+8C34,U+6FD2,U+6D51,U+80C3,U+785D,U+81A8,U+5417,U+7C98,U+8759,U+74E3,U+6EF4,U+5480,U+674F,U+5BC5,U+4E5E,U+6C1B,U+8D66,U+9171,U+71E5,U+9AA4,U+6E5B,U+7130,U+60D1,U+5413,U+7470,U+7262,U+9E43,U+79B9,U+78C5,U+5835,U+9ECF,U+9972,U+51B6,U+6458,U+6CA7,U+6A59,U+73AB,U+69DF,U+6DF9,U+7F06,U+51A5,U+68CD,U+7A9F,U+6C41,U+7F00,U+5B5C,U+8C15,U+7A83,U+61C2,U+8086,U+7E82,U+9610,U+82BD,U+90B8,U+527F,U+7C27,U+620E,U+8FC4,U+7AA6,U+9647,U+9170,U+8270,U+6CCC,U+9CD7,U+6FD1,U+7011,U+6C9B,U+94A5,U+95F5,U+533F,U+6CA6,U+52BE,U+6EDE,U+5BE1,U+5955,U+716E,U+7A3D,U+6A58,U+7EE3,U+916C,U+5B5A,U+4F84,U+9699,U+8367,U+90B9,U+853D,U+8BC8,U+5986,U+5BA6,U+7EF0,U+63B7,U+6C55,U+558A,U+7ED2,U+5BC2,U+5783,U+5564,U+9A82,U+6D4A,U+7F20,U+97AD,U+5CFB,U+7B4B,U+6893,U+9523;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./7308f747e0e5d62c75c08603d8e1c489.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+502A,U+52C9,U+6556,U+53F9,U+5993,U+8C1C,U+6E17,U+9570,U+58EC,U+85C9,U+4F3A,U+96C1,U+573E,U+8354,U+4FDE,U+7115,U+9885,U+9175,U+4ED7,U+7AD6,U+6EC7,U+7EB1,U+840D,U+50B2,U+5AE1,U+5462,U+6986,U+524C,U+70E4,U+8BF5,U+8BDB,U+88F9,U+72E9,U+5784,U+7F2A,U+6B89,U+5F5D,U+72F8,U+5885,U+66DD,U+840C,U+58A9,U+8339,U+84C9,U+952F,U+62FE,U+7898,U+8574,U+85D3,U+589F,U+81ED,U+803F,U+8E44,U+6DCB,U+631D,U+7A1A,U+5C91,U+5A49,U+6691,U+6094,U+6876,U+7F09,U+9499,U+54C7,U+5F6A,U+8C0F,U+8877,U+8681,U+82AF,U+80AA,U+7281,U+6A1F,U+7F8C,U+821C,U+7C9F,U+8BE0,U+7426,U+5D69,U+7AED,U+633D,U+804A,U+7261,U+9B45,U+50F5,U+8F7F,U+8D5A,U+780D,U+5578,U+7845,U+889C,U+611A,U+5566,U+831C,U+723D,U+9AB8,U+8DBE,U+829C,U+7897,U+9F9A,U+4E52,U+79BD,U+88E4,U+54AC,U+6670,U+6577,U+58F6,U+57AB,U+64D2,U+5F3C,U+6DEB,U+8859,U+8205,U+780C,U+5815,U+7ECE,U+851A,U+72D9,U+6A80,U+8C5A,U+85AF,U+8513,U+9524,U+8D26,U+5F0A,U+4E8F,U+6F9C,U+5321,U+8180,U+9E66,U+560E,U+5AD4,U+5ED3,U+839E,U+502D,U+7F50,U+7696,U+6109,U+6E24,U+8721,U+6BD3,U+7092,U+9A79,U+9CC3,U+8D4E,U+98D8,U+6656,U+949E,U+77AC,U+5256,U+852C,U+9709,U+54E9,U+6DC6,U+91DC,U+8718,U+6A0A,U+6BD9,U+8912,U+70F9,U+9A7C,U+5395,U+6273,U+94BE,U+90AF,U+6E2D,U+90DD,U+4FEF,U+997F,U+7504,U+971C,U+80DA,U+8549,U+644A,U+5300,U+94AE,U+58F9,U+7329,U+7678,U+80E4,U+6A90,U+64E2,U+7941,U+777F,U+5CB1,U+7F79,U+62AC,U+5B62,U+6756,U+5366,U+68A7,U+6C81,U+9489,U+5D1B,U+9A86,U+6CFC,U+5239,U+6CAB,U+54E8,U+51D1,U+5151,U+8106,U+8FED,U+51FF,U+6C82,U+83BD,U+6572,U+5A04,U+90A2,U+77E3,U+6995,U+75B2,U+83BA,U+6897,U+632B,U+8774,U+919B,U+88D9,U+6C50,U+8C05,U+733F,U+6977,U+84BF,U+888D,U+65BC,U+8749,U+5A9B,U+6E1D,U+60F9,U+7280,U+96CF,U+59BE,U+6EA5,U+819D,U+5DFE,U+76D4,U+5BB5,U+62B9,U+7B20,U+854A,U+6CD7,U+5599,U+9CD5,U+533E,U+83B9,U+6664,U+707F,U+82F7,U+4E53,U+8236,U+9AD3,U+809A,U+57A9,U+819B,U+6401,U+7737,U+5A25,U+7C3F,U+94C0,U+9A7F,U+9ED4,U+818F,U+797A,U+6DA9,U+96EF,U+6E34,U+508D,U+52FF,U+6805,U+808B,U+8C1F,U+5CD9,U+6CFE,U+8471,U+8BB3,U+7405,U+6866,U+5364,U+655B,U+5A77,U+6E89,U+5A7F;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./c74e0866195714d47e98ee65614e971e.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+75BC,U+6C7E,U+6555,U+8F9C,U+6E23,U+8006,U+5962,U+5E4C,U+536F,U+90F8,U+8717,U+4FED,U+5CE8,U+803B,U+51A4,U+4FE9,U+7766,U+8C0E,U+6EA2,U+5830,U+8654,U+60A8,U+82FB,U+6868,U+6D3C,U+987D,U+5C9A,U+7F9E,U+621F,U+68FA,U+70C3,U+7F9A,U+67AF,U+9E49,U+5BDD,U+954D,U+83C1,U+74A7,U+868C,U+664F,U+4F8F,U+7EF8,U+6643,U+6D3D,U+541F,U+710A,U+554A,U+99A8,U+5319,U+9A84,U+78FA,U+7F9F,U+7626,U+95F0,U+587E,U+6380,U+614C,U+7F2E,U+8BEC,U+67A3,U+96F3,U+7AFA,U+5203,U+4E15,U+7CDF,U+80D6,U+6478,U+82B9,U+6CF5,U+9E92,U+9988,U+63FD,U+5CAC,U+8537,U+949D,U+843C,U+635E,U+5632,U+607C,U+9149,U+6C30,U+6292,U+6BB4,U+950C,U+741B,U+9739,U+4F65,U+4FAC,U+886C,U+8C2C,U+78D0,U+9ABC,U+5C94,U+68B3,U+68F5,U+8747,U+5FD2,U+501A,U+9502,U+747E,U+5140,U+9E3D,U+7A79,U+8424,U+8155,U+83C7,U+5A07,U+65A7,U+9E20,U+79C3,U+6795,U+8BC3,U+6DDE,U+640F,U+886B,U+7693,U+6C5E,U+68E3,U+82DE,U+54AA,U+8237,U+7109,U+745B,U+515C,U+8712,U+8340,U+9508,U+6367,U+620D,U+77BB,U+5156,U+873F,U+70AC,U+6D5A,U+9706,U+6D12,U+7F1A,U+61BE,U+55DC,U+946B,U+8517,U+7779,U+71B9,U+82B8,U+5631,U+6495,U+62A8,U+80F0,U+9661,U+7CD9,U+7BE1,U+9776,U+6E9C,U+8331,U+89C5,U+6869,U+86FE,U+566C,U+73BA,U+8695,U+8403,U+9619,U+5E1B,U+97E7,U+9704,U+7A20,U+82AE,U+54C9,U+6CB8,U+87FE,U+606A,U+7076,U+6808,U+601C,U+6E25,U+826E,U+5B75,U+795A,U+8393,U+968D,U+6760,U+783E,U+6EB4,U+5CED,U+82A5,U+715E,U+9017,U+953B,U+634D,U+717D,U+5984,U+9EDC,U+663C,U+9791,U+6CBD,U+655E,U+7B95,U+4FFE,U+66D9,U+86A4,U+7FDF,U+5055,U+7682,U+660A,U+85B0,U+6DC7,U+7EDE,U+7CAA,U+77FD,U+7BF7,U+90BA,U+6168,U+9CC5,U+82E3,U+5C51,U+6655,U+915A,U+8D41,U+70DB,U+7B5B,U+80BD,U+79BA,U+57E4,U+8EAF,U+6F7C,U+9556,U+59A8,U+9890,U+6055,U+9EBE,U+7B28,U+607A,U+81E7,U+761F,U+4FF8,U+4E4D,U+977C,U+7F81,U+9541,U+868A,U+787C,U+768B,U+6EBA,U+5421,U+522E,U+8235,U+9F9B,U+8F97,U+6CFB,U+66AE,U+948A,U+8D31,U+9685,U+5807,U+5E18,U+916A,U+5A1F,U+9977,U+6C90,U+81B3,U+6862,U+76CE,U+73AE,U+6CE3,U+695E,U+6CF8,U+4FAE,U+8B6C,U+54BD,U+65A1,U+66E6,U+631F,U+9774,U+6652,U+6CA5,U+9C91,U+763E,U+9A6F,U+5DF3,U+9E6D,U+8386;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./4ca3950763f427eeaef59436f1477a54.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+5080,U+94F2,U+8BC5,U+7FB2,U+5F64,U+6320,U+575C,U+82DF,U+5435,U+7EB6,U+8BF0,U+598D,U+5EBE,U+5E61,U+6930,U+67E0,U+4F6C,U+97EC,U+6813,U+9761,U+965B,U+714E,U+8C41,U+762B,U+69D0,U+8862,U+6064,U+592D,U+919A,U+88D8,U+5121,U+8165,U+7EEB,U+7EEE,U+7838,U+6CAE,U+8D42,U+6346,U+9F2C,U+6C74,U+846B,U+5446,U+595A,U+9798,U+828B,U+7EF6,U+6FE0,U+5582,U+84DF,U+6DC4,U+7792,U+9631,U+874E,U+5CEA,U+8431,U+9540,U+70AF,U+70AB,U+4F36,U+665F,U+75F4,U+77A9,U+6C26,U+6254,U+62E2,U+6E44,U+9698,U+911E,U+67D1,U+622E,U+7FD8,U+65F7,U+6BEF,U+5029,U+7435,U+6323,U+94B5,U+9E25,U+849C,U+964B,U+6988,U+918B,U+6AAC,U+77EB,U+5189,U+69DB,U+5A34,U+809B,U+7436,U+951A,U+840E,U+949B,U+53F1,U+75B9,U+9E6B,U+8BB6,U+80E5,U+6DE4,U+626F,U+9E3E,U+59E8,U+964C,U+78CA,U+563B,U+63B3,U+7A37,U+4F5A,U+57AE,U+62D0,U+5E42,U+63C6,U+76B1,U+5BF0,U+58D5,U+82C7,U+7AFF,U+94E0,U+62F7,U+6845,U+7CEF,U+73C2,U+8DEA,U+9730,U+7B0B,U+5F08,U+5349,U+5DC5,U+82DB,U+7FCA,U+6E32,U+8559,U+6DA7,U+548E,U+8392,U+81FC,U+673D,U+61D2,U+6C72,U+70BD,U+608D,U+8BE1,U+77BF,U+6096,U+8671,U+6CBE,U+7BC6,U+6F15,U+573B,U+61A9,U+6619,U+91C9,U+5C82,U+8511,U+63A3,U+8BEB,U+7C07,U+8046,U+6627,U+5243,U+6084,U+75EA,U+86F1,U+95F7,U+94EC,U+6BA1,U+5E27,U+5A9A,U+5B7A,U+988D,U+609A,U+996A,U+752C,U+5D2D,U+8F95,U+7750,U+7430,U+9CBD,U+6101,U+4F83,U+94B0,U+574D,U+6FEE,U+9A78,U+8C24,U+6CF7,U+7B19,U+9642,U+7525,U+9A87,U+998F,U+57D5,U+7080,U+7EDB,U+675E,U+54A4,U+50D6,U+8D2E,U+891A,U+886E,U+50BB,U+96BC,U+81FB,U+9A81,U+9550,U+759F,U+94E8,U+54C6,U+6867,U+5992,U+76FC,U+804B,U+74EE,U+7837,U+6BCB,U+8C12,U+8DC6,U+6538,U+82B7,U+8C1A,U+82D3,U+8E29,U+6631,U+64BC,U+4F97,U+631A,U+96C9,U+6B86,U+8E81,U+8734,U+7578,U+7FA7,U+8026,U+817B,U+70D8,U+904F,U+4F88,U+6B6A,U+7688,U+8936,U+707C,U+9B44,U+6A35,U+693F,U+8D48,U+7EEF,U+9CCC,U+7410,U+8446,U+8309,U+8D3B,U+9A74,U+6954,U+988A,U+6D52,U+7EE2,U+74EF,U+5F29,U+8D9F,U+7B60,U+5345,U+9530,U+5026,U+5729,U+9985,U+6809,U+92AE,U+813E,U+6073,U+6854,U+6C85,U+5583,U+5AB3,U+7FA1,U+709C,U+701A,U+869C,U+7889,U+8DE4,U+7118,U+62CC,U+50A3;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./3271289a1be786afe1dc8e90b2c3fbd3.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+8398,U+742E,U+8F8D,U+80F1,U+800D,U+98D9,U+7A9C,U+6363,U+6F88,U+9E4A,U+5598,U+5306,U+80B4,U+54D1,U+54D7,U+81BA,U+5A62,U+5703,U+6127,U+6FC2,U+5201,U+7AA5,U+6405,U+8FE5,U+6F4D,U+88F3,U+77B3,U+6F5E,U+89D0,U+8F6D,U+7FCE,U+6E0E,U+72E0,U+9041,U+895F,U+8038,U+8EBA,U+6666,U+6D5C,U+98A4,U+62C2,U+5455,U+7980,U+831B,U+7455,U+94C2,U+9095,U+835A,U+87D2,U+7337,U+6F62,U+8F99,U+8682,U+543C,U+71B5,U+9773,U+9074,U+8815,U+7C7D,U+4E2B,U+5420,U+5043,U+8BFD,U+6D19,U+5AC9,U+6248,U+52D0,U+5197,U+8822,U+94B4,U+9035,U+79FD,U+5F0B,U+67FF,U+7B5D,U+95FE,U+6D47,U+4FE8,U+6396,U+7425,U+9AA0,U+8C1B,U+8BC0,U+7CA5,U+678B,U+7852,U+9165,U+909D,U+9621,U+6715,U+8662,U+6EA7,U+680E,U+6615,U+6361,U+66F3,U+6628,U+900D,U+659B,U+50FB,U+83C5,U+75AE,U+74D2,U+6994,U+777E,U+948F,U+5BB8,U+9CB1,U+83AA,U+718F,U+6DA4,U+5958,U+8845,U+5CB7,U+85A8,U+5FCF,U+5511,U+54B3,U+8F7C,U+5254,U+7D6E,U+531D,U+9E58,U+8DFB,U+84FC,U+62D9,U+683E,U+598A,U+5F11,U+6A8E,U+8C82,U+773A,U+749C,U+8782,U+5B40,U+6A3D,U+87AF,U+556E,U+53ED,U+6DBF,U+634F,U+5DFD,U+5B95,U+9893,U+86DF,U+6687,U+955B,U+6C8C,U+9E22,U+90E2,U+4F5F,U+627C,U+5DCD,U+740F,U+56F1,U+4F0E,U+5944,U+5773,U+60D5,U+53EE,U+715C,U+7184,U+7736,U+8098,U+814C,U+766B,U+7F15,U+99A5,U+6328,U+8365,U+6ED4,U+59B2,U+781A,U+6B59,U+76BF,U+9EEF,U+9889,U+8FC2,U+6EE6,U+4F43,U+9119,U+7252,U+7099,U+6177,U+835F,U+80EA,U+6C13,U+618E,U+6E1A,U+63BA,U+8BE3,U+621B,U+8D32,U+9E9D,U+9131,U+749E,U+7BF1,U+6D54,U+7490,U+832B,U+70FD,U+5A7A,U+6CB1,U+8116,U+64C2,U+8BB9,U+704F,U+5C2C,U+76CF,U+4EB5,U+532E,U+7487,U+77B0,U+9AC5,U+94A8,U+4E10,U+80C4,U+53FB,U+5288,U+9AA5,U+60F0,U+5CE6,U+6635,U+6D9F,U+94B3,U+79E4,U+5018,U+61C8,U+9CD6,U+73F2,U+7EA5,U+97A0,U+5544,U+4EC4,U+824B,U+5C4E,U+5C96,U+877D,U+7F0E,U+6487,U+55C5,U+9CAB,U+837C,U+5AC2,U+63B8,U+4E98,U+9A85,U+5440,U+535E,U+52AD,U+8BF2,U+5450,U+62C7,U+6DAA,U+6020,U+65CC,U+69A8,U+67AD,U+5A55,U+8C2F,U+5490,U+739F,U+7F19,U+9AB7,U+78CB,U+5C34,U+9AA7,U+9497,U+6C5B,U+9157,U+7094,U+9975,U+4F57,U+56A3,U+795C,U+4E56,U+914C,U+7A3C,U+6DE6,U+54EE,U+6F47;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./66ddf063531d6ed26bd41679f97686e0.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+8757,U+7F42,U+5C98,U+7949,U+9E5B,U+69FF,U+8BA5,U+9E2B,U+5B09,U+8983,U+5495,U+9753,U+8731,U+8611,U+6296,U+8D30,U+75D2,U+8D43,U+8788,U+728D,U+847A,U+55D3,U+91AE,U+73C9,U+94EF,U+9050,U+9C86,U+5506,U+4EA2,U+8C14,U+4F58,U+7ECA,U+7656,U+67DA,U+51BC,U+6CD3,U+803D,U+7FAF,U+8EAC,U+516E,U+7EBE,U+9511,U+8FF3,U+8E1D,U+51CB,U+7B08,U+6479,U+9AFB,U+606C,U+7CB2,U+777D,U+60F6,U+788C,U+6FA1,U+814B,U+7FB9,U+70E8,U+5A20,U+953A,U+71AC,U+6E4D,U+69CC,U+79E7,U+6EC1,U+51F3,U+8548,U+7078,U+6D63,U+5811,U+84B4,U+94FF,U+87E0,U+5F98,U+5C41,U+637B,U+4FCF,U+6A47,U+70C1,U+6CEE,U+68A2,U+5BE5,U+6DD6,U+7B71,U+94F3,U+5F8A,U+5514,U+6775,U+85DC,U+837B,U+5FF1,U+7529,U+6B79,U+67E9,U+4EB3,U+71E7,U+7A96,U+6C19,U+64AE,U+5BDE,U+602F,U+8BAB,U+7F22,U+553E,U+53E9,U+9E2F,U+8F76,U+7428,U+5D34,U+60AF,U+9F10,U+8D53,U+9F88,U+6F29,U+75B5,U+7A98,U+90F4,U+73D1,U+6F31,U+6E0D,U+5A29,U+8110,U+59A4,U+952D,U+78BE,U+4FD1,U+9AB0,U+6654,U+90AC,U+8F67,U+84D3,U+807F,U+7887,U+7396,U+50ED,U+9617,U+8327,U+7A92,U+51DB,U+75CA,U+6B46,U+6518,U+80EB,U+545C,U+9504,U+8360,U+8E1E,U+9A8A,U+6E85,U+6151,U+56BC,U+8084,U+8BF9,U+72E1,U+6233,U+7BB4,U+9609,U+7B75,U+5785,U+631B,U+5F1B,U+5A3C,U+853B,U+75EB,U+9A6D,U+5464,U+90B3,U+6D31,U+62E3,U+94BC,U+5A11,U+75A3,U+59E5,U+9C9B,U+77D7,U+76C2,U+70B1,U+6252,U+7315,U+94EE,U+73C8,U+7B4F,U+6BE1,U+5B7D,U+8F84,U+6963,U+70EB,U+63C9,U+7F07,U+4FF3,U+8770,U+6D35,U+7422,U+699B,U+94A1,U+873B,U+8368,U+7266,U+6A71,U+7166,U+53DF,U+95FA,U+8713,U+9C7F,U+51C4,U+9E33,U+96BD,U+6043,U+631E,U+6F8D,U+7EDA,U+6979,U+73DE,U+6789,U+949A,U+9A6E,U+638F,U+8715,U+951F,U+7F28,U+7CBD,U+742C,U+7B94,U+7F54,U+9005,U+80B1,U+7572,U+9606,U+9082,U+6772,U+5BA5,U+5A6A,U+75A1,U+6B87,U+8C2A,U+9E6C,U+7EA3,U+8FAB,U+836A,U+778E,U+52D6,U+9E73,U+8693,U+560C,U+4F7C,U+915D,U+69AD,U+956D,U+8338,U+816D,U+8385,U+83C0,U+6289,U+7CB1,U+4F64,U+69BB,U+7957,U+5499,U+5C99,U+736D,U+9522,U+62EE,U+61E6,U+86ED,U+6A84,U+4FD0,U+6C94,U+5E1A,U+8148,U+9CDD,U+918D,U+6221,U+55A7,U+828E,U+731D,U+83E0,U+737E,U+565C,U+9A9E,U+8113,U+7239,U+66A7;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./e88fe3eeb1ddf34a42971f05e1f2397f.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+607D,U+8D49,U+5429,U+8FE9,U+86CE,U+75BD,U+9ECD,U+7119,U+77FE,U+6C10,U+9713,U+9492,U+87A8,U+8708,U+8249,U+810D,U+4FDA,U+7D0A,U+7856,U+54C4,U+557C,U+9549,U+91B4,U+746D,U+9537,U+94E2,U+82F4,U+8BDF,U+86CA,U+7441,U+70D9,U+9190,U+52FA,U+9C82,U+8C4C,U+7F94,U+86A3,U+75C9,U+90E7,U+8D58,U+5E7A,U+5AB2,U+9CA1,U+9CB2,U+6F3E,U+8821,U+9A88,U+6F13,U+89CA,U+86F9,U+82CB,U+59D7,U+8E72,U+98A2,U+6829,U+9150,U+6989,U+57A2,U+9F39,U+728A,U+6634,U+5EA0,U+5669,U+9B03,U+592F,U+7096,U+608C,U+759A,U+975B,U+94AF,U+7F44,U+54AF,U+6753,U+53AE,U+6042,U+9785,U+5315,U+9122,U+6D04,U+5475,U+7E47,U+7CDC,U+9CDF,U+94F0,U+6B81,U+55FD,U+8F78,U+8334,U+7BAB,U+5B6A,U+75A4,U+732C,U+67D8,U+949C,U+7391,U+909B,U+60EE,U+5CD2,U+8C17,U+7729,U+4F6F,U+9E48,U+70EC,U+6D9D,U+9894,U+4FEA,U+997A,U+4F70,U+8892,U+4FDF,U+73E9,U+876E,U+822B,U+768E,U+740A,U+9AA1,U+9697,U+73E5,U+61AC,U+5A23,U+9E35,U+8BE9,U+6600,U+7EFD,U+6E5F,U+6C79,U+63E3,U+8BD8,U+870D,U+6DC5,U+8438,U+97ED,U+94E3,U+8783,U+63BE,U+853A,U+7A14,U+6E6E,U+7B77,U+75D8,U+712F,U+5E37,U+8C0C,U+75F0,U+5D4A,U+5484,U+6F2A,U+57AD,U+9A77,U+90E6,U+83CF,U+6B93,U+5310,U+659F,U+90BE,U+88E8,U+776B,U+5D47,U+8D61,U+7B3A,U+74E2,U+6452,U+954C,U+9567,U+75B1,U+7962,U+94CA,U+5AD6,U+5549,U+5F95,U+901E,U+5AE6,U+90D3,U+579B,U+9688,U+9083,U+7C2A,U+9616,U+8E0A,U+8018,U+61A7,U+9608,U+95F3,U+6E3A,U+64B7,U+60EB,U+53A9,U+95EB,U+8DB4,U+67DD,U+9C87,U+9CAD,U+6641,U+4E9F,U+9701,U+7826,U+5942,U+9611,U+90EF,U+55B5,U+6DA3,U+8BD2,U+76EF,U+5EEA,U+87B3,U+7EF7,U+8F87,U+84AF,U+7741,U+7CE0,U+73B3,U+5E54,U+8BCB,U+5C79,U+6DB8,U+973E,U+530D,U+9992,U+7620,U+5B70,U+9517,U+5D6F,U+6840,U+9AE6,U+6D95,U+9CB3,U+9506,U+54D4,U+5FFF,U+5A32,U+6512,U+708A,U+5AE3,U+6FB9,U+69B7,U+90D7,U+625B,U+54B1,U+4FA5,U+7316,U+783A,U+9C9F,U+9536,U+7825,U+835E,U+7325,U+996C,U+5618,U+7A17,U+82CC,U+8F8E,U+80ED,U+7583,U+8F72,U+7A8D,U+76F1,U+892A,U+7B8D,U+7663,U+56E4,U+8418,U+724D,U+520D,U+8E66,U+62D7,U+73D9,U+6002,U+9535,U+8C7A,U+8317,U+94D0,U+75E2,U+5048,U+7B0F,U+73CF,U+7360,U+5C15,U+8C18,U+599E,U+912F,U+7457;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./1b5ed66053f937c408ba8cf87cfaee21.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+74A8,U+6059,U+9515,U+8BD9,U+80AE,U+9E8B,U+5CAB,U+83DF,U+853C,U+7172,U+8C11,U+5889,U+75F9,U+5208,U+8C19,U+510B,U+816E,U+7FE9,U+7480,U+4E69,U+7893,U+9CBB,U+5412,U+7F24,U+965F,U+901B,U+58D1,U+8E35,U+6F2F,U+67B7,U+86F0,U+8C27,U+85D5,U+714A,U+9553,U+79C6,U+60ED,U+6D93,U+9E51,U+9672,U+874C,U+7800,U+6E43,U+7EFE,U+877E,U+86AA,U+733E,U+8012,U+94CC,U+544B,U+61A8,U+4FFA,U+9649,U+94C6,U+6FE1,U+6A50,U+5D4B,U+8753,U+71CE,U+9E57,U+86DE,U+85AE,U+970F,U+8D6D,U+87D1,U+7719,U+5501,U+6C2A,U+545B,U+80E7,U+96F9,U+6C18,U+63EA,U+9509,U+7317,U+8BA7,U+86A9,U+5486,U+9602,U+871A,U+73D0,U+9552,U+82A9,U+8E7C,U+527D,U+9542,U+789B,U+6A79,U+5FAD,U+55B1,U+9C94,U+6D9E,U+696B,U+6063,U+5FE4,U+6B83,U+7FF1,U+94F1,U+77AA,U+4FE3,U+8E47,U+83D6,U+6C68,U+612B,U+576F,U+6EC2,U+6710,U+866C,U+63D6,U+78A3,U+7350,U+8019,U+8191,U+9E55,U+9CD0,U+7FE6,U+6849,U+5D02,U+8BA3,U+7085,U+693D,U+87C6,U+7747,U+4F91,U+9CD4,U+85D0,U+8132,U+7EC0,U+5636,U+7357,U+9E44,U+795F,U+66F7,U+94FC,U+75DE,U+86BA,U+82CE,U+989B,U+89DE,U+556C,U+7600,U+8737,U+812F,U+8E42,U+7548,U+5768,U+6726,U+8476,U+7BD9,U+6078,U+50F3,U+4FAA,U+8BC2,U+8884,U+6F66,U+7FBF,U+7751,U+6CA3,U+561F,U+73B7,U+604D,U+7EAB,U+9E5E,U+5777,U+8297,U+8DD6,U+94BF,U+7B1E,U+8F8A,U+94BD,U+57ED,U+789A,U+5A76,U+8E8F,U+72D2,U+54CC,U+5088,U+94DF,U+7F1C,U+7ABF,U+6249,U+5F2D,U+60FA,U+60CB,U+95E9,U+90DC,U+5919,U+8902,U+4F5E,U+9A90,U+6CDE,U+64D8,U+9AEF,U+82AA,U+7BAC,U+54FC,U+705E,U+6C1A,U+8DF7,U+6DDD,U+6C29,U+6886,U+5D3D,U+6777,U+541D,U+50EE,U+4F09,U+567B,U+86AF,U+695D,U+9539,U+68C2,U+6657,U+59AB,U+8A07,U+6901,U+961A,U+8C49,U+8E4A,U+8703,U+86C4,U+7F04,U+8F82,U+7827,U+623E,U+82BE,U+81C6,U+97EB,U+972D,U+667E,U+57A0,U+8F98,U+9534,U+74A9,U+6934,U+6C86,U+9CAE,U+753E,U+88DF,U+7477,U+586C,U+8202,U+726F,U+6787,U+83DD,U+6413,U+818A,U+502C,U+8DD7,U+5E3C,U+98E8,U+6D8E,U+828D,U+9E82,U+8FE4,U+759D,U+6414,U+555C,U+84D1,U+741A,U+8E74,U+6115,U+6942,U+5793,U+8888,U+8882,U+84C1,U+82C4,U+5F77,U+87C0,U+795B,U+568E,U+7F2C,U+6DFC,U+563F,U+9B47,U+88B1,U+88A4,U+7C41,U+57F4,U+6E9F;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./5a822fa4bcecad1cfa0a0f5d6f7750c0.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+5432,U+90C5,U+77DC,U+8BAA,U+7B50,U+79E3,U+63C4,U+62ED,U+98D2,U+5BD0,U+9C8E,U+87CB,U+8638,U+677C,U+5228,U+956F,U+8DB8,U+6684,U+93D6,U+8F6B,U+8E76,U+6C93,U+86F8,U+8151,U+54DA,U+520E,U+94E7,U+8722,U+7BDD,U+54AB,U+5CE5,U+5594,U+7708,U+4F76,U+845C,U+55DD,U+7A39,U+72C8,U+94DB,U+8B07,U+84E3,U+77A7,U+523D,U+7728,U+6CE0,U+62FD,U+5D2E,U+655D,U+9997,U+6DCC,U+8913,U+955D,U+8DDB,U+8426,U+8214,U+96B0,U+5D58,U+55E1,U+59D2,U+62F4,U+83F0,U+9545,U+6EE2,U+7629,U+7599,U+54D2,U+8301,U+5D6C,U+9557,U+7634,U+5F87,U+9490,U+90B0,U+7F61,U+6C4A,U+63F6,U+938F,U+80DB,U+72F3,U+72B0,U+7292,U+6DEC,U+8BE4,U+6FB6,U+5739,U+9E2C,U+7B90,U+67DE,U+7F2D,U+98DA,U+94E4,U+9495,U+7481,U+72B8,U+9E2A,U+7C38,U+6A3E,U+690B,U+574C,U+9E5A,U+4F2B,U+8925,U+4EE1,U+572A,U+9CA3,U+5522,U+82DC,U+960F,U+723B,U+557E,U+54E6,U+9ACB,U+85FF,U+679E,U+5162,U+62C8,U+64AC,U+6D39,U+5D74,U+9CA2,U+86F3,U+846D,U+72B7,U+9507,U+83B4,U+7CC5,U+86C0,U+8401,U+78D5,U+7A88,U+7946,U+9E69,U+5A75,U+558B,U+9708,U+8C98,U+8A8A,U+75B8,U+5543,U+8C29,U+8605,U+84FF,U+56FF,U+956B,U+9099,U+78EC,U+9E67,U+9991,U+82EB,U+8188,U+72E8,U+6376,U+9514,U+69AB,U+95FC,U+9163,U+8364,U+5DEF,U+9016,U+6B92,U+59DD,U+4F7E,U+652B,U+95F1,U+61CA,U+5EE8,U+54A9,U+82D5,U+5527,U+7699,U+9162,U+7FF3,U+7F5F,U+6410,U+629F,U+9E4C,U+8BB4,U+60B8,U+5ABE,U+54D9,U+8473,U+7579,U+7131,U+642A,U+83B3,U+80F3,U+71A0,U+67B8,U+5657,U+6CEF,U+4EDD,U+996F,U+8C04,U+90EB,U+870A,U+6390,U+5457,U+51EB,U+7C91,U+549D,U+9E4B,U+86CF,U+577B,U+5014,U+98A7,U+828A,U+57F8,U+5A67,U+7FB8,U+8469,U+7F08,U+783B,U+96CE,U+868B,U+70E9,U+6E86,U+6C16,U+7A03,U+763F,U+9A8B,U+84BD,U+7511,U+74E0,U+4EDE,U+9EB8,U+776C,U+5D03,U+57D9,U+83D8,U+86C6,U+75E3,U+622C,U+4FCE,U+8D5D,U+821B,U+626A,U+5671,U+542E,U+9E38,U+75D4,U+5D82,U+4E5C,U+782D,U+62A1,U+60D8,U+55DF,U+5565,U+97A3,U+6E11,U+9EDF,U+9B13,U+80EF,U+637A,U+9E31,U+948B,U+837D,U+9E42,U+9615,U+4FE6,U+858F,U+5608,U+501C,U+8E6C,U+7ECC,U+867B,U+5466,U+960A,U+7850,U+63B4,U+6345,U+54AD,U+4EDF,U+9EDD,U+69A7,U+59E3,U+551B,U+9E28,U+8C00,U+834F,U+7B06,U+7095;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./6e56f1a9a9534192c81a571b61a52827.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+6019,U+5FA8,U+5261,U+952B,U+9994,U+87AD,U+7F30,U+5241,U+9B32,U+90C7,U+9571,U+7F2F,U+57D2,U+55F2,U+9B3B,U+90FE,U+5B51,U+50A9,U+955E,U+83BC,U+7EC2,U+5471,U+7830,U+88F1,U+82AB,U+7EE6,U+6DAE,U+67A5,U+8564,U+8314,U+62E7,U+9123,U+5D99,U+57C2,U+5106,U+83A8,U+572E,U+5709,U+8222,U+6E6B,U+8BA6,U+7EA8,U+6E8F,U+5C50,U+4EC3,U+8BE7,U+568F,U+8DF8,U+86C9,U+7EE1,U+6D43,U+6FDE,U+5C49,U+9A9C,U+7A95,U+620B,U+9AA2,U+5FB5,U+8C32,U+8169,U+578C,U+54CF,U+9A9D,U+7FE5,U+79ED,U+576D,U+9CB5,U+96E9,U+53C1,U+7C95,U+7B15,U+5478,U+94C4,U+8E4B,U+76E5,U+765E,U+8C62,U+82A1,U+7F11,U+7116,U+67D2,U+5477,U+915E,U+748E,U+8C20,U+84BA,U+67B3,U+6222,U+9CCA,U+9955,U+9004,U+8FE2,U+7B33,U+547B,U+953F,U+81FE,U+7AA0,U+6D4D,U+90A1,U+8966,U+7FD5,U+7F1F,U+7BA7,U+9A7A,U+8378,U+7168,U+64A9,U+8D73,U+606B,U+5D06,U+5A13,U+54A7,U+9981,U+72CE,U+9968,U+9097,U+69CA,U+552C,U+83A0,U+94B7,U+6924,U+9984,U+8BDC,U+887D,U+5CCB,U+556A,U+7601,U+71A8,U+6800,U+6400,U+60DA,U+8E39,U+8028,U+731E,U+59A3,U+5662,U+55E8,U+81EC,U+902F,U+7301,U+6593,U+8D4A,U+84D6,U+832F,U+7B2A,U+75C8,U+6F7A,U+6860,U+670A,U+5494,U+9EE5,U+8941,U+7BE6,U+63B0,U+8C89,U+83FD,U+7CCD,U+59AA,U+9880,U+9565,U+8D67,U+8C78,U+86D4,U+72CD,U+5B71,U+57B8,U+535F,U+87AB,U+549A,U+9EE0,U+880A,U+61F5,U+5431,U+9974,U+88C6,U+9052,U+830C,U+75E4,U+6005,U+5E19,U+54CE,U+9051,U+78D4,U+78B4,U+754B,U+63CD,U+5FE1,U+8174,U+75CD,U+6215,U+9563,U+82A8,U+7BB8,U+6773,U+57DA,U+9568,U+8FF8,U+8C55,U+86B1,U+77A5,U+75A5,U+7352,U+6CFA,U+56B7,U+97EA,U+8C8A,U+6AA9,U+5556,U+75E8,U+50A5,U+9AED,U+93CA,U+895E,U+80FC,U+75C2,U+72DE,U+6D0E,U+5A06,U+8D40,U+7BD3,U+7953,U+9AB6,U+7738,U+6CF1,U+9CBC,U+7BFE,U+75A0,U+6BFD,U+9169,U+67E2,U+4EF5,U+6D60,U+5B17,U+5639,U+9E29,U+4FC5,U+7F74,U+65AB,U+8F8B,U+7691,U+55EF,U+6ED3,U+998D,U+94E1,U+9011,U+891B,U+79F8,U+7619,U+6006,U+55D4,U+548B,U+9604,U+88FE,U+6D94,U+60B4,U+992E,U+610E,U+9068,U+80F4,U+681D,U+53E8,U+8C47,U+67A7,U+8210,U+6DD9,U+607B,U+970E,U+7284,U+54BF,U+7AE6,U+7566,U+6CA4,U+55E4,U+8167,U+7EA1,U+4F7B,U+94D9,U+94A3,U+8FE8,U+8785,U+76C5;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./de8587eb03af7b1e2762a80dffbfce3b.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+6026,U+5567,U+7CB3,U+572F,U+954A,U+83E1,U+8366,U+5769,U+9AC0,U+7F26,U+7A1E,U+5F82,U+56D7,U+51BD,U+9CA0,U+762A,U+6194,U+90B4,U+8C06,U+859C,U+6448,U+8146,U+54DF,U+9B4D,U+887E,U+7BA9,U+772D,U+73A2,U+6BD6,U+84CD,U+6D27,U+9E47,U+914A,U+8BFF,U+8306,U+8228,U+8043,U+7812,U+7638,U+6D5E,U+6832,U+9CCF,U+75FF,U+634E,U+60AD,U+5520,U+7EC9,U+8707,U+53FC,U+9532,U+73E7,U+557B,U+581E,U+9EC9,U+9E5C,U+8093,U+738E,U+7B2E,U+6F74,U+5416,U+9554,U+832D,U+7BC1,U+54FD,U+5452,U+7F25,U+7BD1,U+68F9,U+5600,U+53FD,U+69C1,U+5E44,U+9C8B,U+8C21,U+668C,U+500C,U+9CD9,U+94D2,U+8311,U+70CA,U+8E49,U+8709,U+669D,U+538D,U+5363,U+8885,U+55D2,U+9F0B,U+64B5,U+507B,U+82E1,U+59A9,U+91BA,U+8027,U+5A08,U+9F8B,U+7A51,U+7967,U+6175,U+502E,U+9CA9,U+778C,U+5E96,U+9EE9,U+887F,U+7F36,U+6402,U+6308,U+55D1,U+9B51,U+8BD4,U+6D91,U+56DD,U+9E36,U+6387,U+72F0,U+6B9A,U+696E,U+6855,U+62A0,U+61D1,U+5664,U+9CA5,U+9C85,U+951B,U+8E59,U+765C,U+6E4E,U+60EC,U+605A,U+550F,U+781F,U+5A4A,U+57EF,U+8497,U+8200,U+7C7C,U+725D,U+5537,U+64C0,U+634C,U+62C4,U+8E52,U+8DDA,U+8C35,U+836C,U+80D9,U+6B24,U+5CBF,U+8763,U+835C,U+7F45,U+57A1,U+9B43,U+7F2B,U+9CDC,U+950F,U+8E87,U+8E0C,U+8C94,U+879F,U+6A7C,U+680C,U+60C6,U+5AAA,U+4EEB,U+94E9,U+8BE8,U+7BCC,U+5704,U+55B3,U+72F7,U+60E6,U+57DD,U+5509,U+9E37,U+87BD,U+57CF,U+57B4,U+56F9,U+54BB,U+905B,U+5FFE,U+5FD1,U+5FD0,U+504C,U+8D45,U+8451,U+7791,U+6748,U+504E,U+9CA6,U+9B49,U+6123,U+5FBC,U+5A09,U+54C2,U+908B,U+8C02,U+8238,U+7622,U+723F,U+7145,U+5E5B,U+7C74,U+4F27,U+9062,U+7B9C,U+63F8,U+62CE,U+5443,U+536E,U+9C9A,U+8F75,U+8069,U+7618,U+5E5E,U+5541,U+8E6D,U+8DFA,U+8DDE,U+798A,U+60BB,U+91AA,U+81BB,U+7178,U+9EFE,U+84A1,U+823E,U+7F1B,U+781D,U+76B4,U+8DB5,U+8934,U+7C9C,U+6F4B,U+600F,U+56E1,U+664C,U+564E,U+55BD,U+998A,U+7ED4,U+7B5A,U+70B7,U+6445,U+5C7A,U+9F83,U+8D59,U+77A0,U+6E98,U+6A2F,U+63AE,U+63AC,U+53F5,U+525C,U+9F89,U+8E69,U+877C,U+782C,U+6D0C,U+688F,U+684E,U+549B,U+9AC1,U+839C,U+7BAA,U+6482,U+5454,U+8351,U+6939,U+65D6,U+9A9B,U+831A,U+618B,U+954F,U+8F8F,U+82B4,U+817C,U+7303,U+6342;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./70476944a9716876594fba062d813ea6.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+6041,U+8C2E,U+8BF3,U+8919,U+8298,U+6382,U+6B43,U+5482,U+5028,U+997D,U+87E5,U+7EF2,U+7AA8,U+783C,U+6CC5,U+8E7F,U+83D4,U+55E6,U+4F5D,U+9512,U+9036,U+8E85,U+8872,U+7AFD,U+6484,U+9546,U+848C,U+82C1,U+7B4C,U+69E0,U+5E0F,U+9998,U+98A6,U+8E51,U+7F23,U+7CD7,U+6B84,U+5B53,U+9F8A,U+8921,U+830F,U+7596,U+500F,U+9995,U+8E2F,U+7726,U+72B4,U+5693,U+75B3,U+55F7,U+9FA0,U+9B48,U+933E,U+90D0,U+8556,U+8489,U+7F03,U+4F94,U+8C85,U+86F2,U+6C8F,U+6120,U+5F9C,U+5F89,U+8816,U+8813,U+8004,U+67B0,U+60F4,U+603C,U+5555,U+9E63,U+8DE3,U+82D8,U+75E7,U+755B,U+5577,U+5216,U+9E88,U+72F2,U+6421,U+5A40,U+9CB4,U+9ACC,U+9765,U+845A,U+7F01,U+9F8C,U+9185,U+8723,U+871E,U+863C,U+7F12,U+6F78,U+61D4,U+5588,U+5293,U+50A7,U+998B,U+7823,U+674C,U+65F0,U+5406,U+4FA9,U+9A80,U+948E,U+8F7E,U+8159,U+7EFA,U+7768,U+75FC,U+6A5B,U+609B,U+8844,U+7B47,U+77CD,U+6C46,U+6BB3,U+6426,U+5FED,U+51C7,U+950D,U+9021,U+8DC4,U+848E,U+81CA,U+7CCC,U+65CE,U+623D,U+5FD6,U+5ED1,U+57B2,U+5233,U+4F25,U+9F3E,U+9CC7,U+86B0,U+84E6,U+7EB0,U+7CBC,U+59D8,U+4EC2,U+9A96,U+956A,U+8F73,U+7F02,U+78D9,U+6C69,U+6C32,U+6B39,U+6833,U+630E,U+542D,U+4F22,U+8DCE,U+8BF6,U+6F09,U+6525,U+638E,U+5453,U+833C,U+8118,U+79D5,U+7640,U+74FF,U+64B8,U+566B,U+8BD3,U+8112,U+76F9,U+72FB,U+6B8D,U+9531,U+75B4,U+6F8C,U+6C24,U+558F,U+5250,U+5155,U+4EF3,U+4EC9,U+8E31,U+88E2,U+8869,U+825A,U+7707,U+645E,U+5981,U+57A7,U+4F67,U+8748,U+832C,U+7F21,U+734D,U+6B82,U+5624,U+8114,U+8052,U+7877,U+6485,U+634B,U+5326,U+4EE8,U+9990,U+8E09,U+82CA,U+5C88,U+5AF1,U+51FC,U+9F22,U+9E46,U+9AEB,U+7765,U+772F,U+76CD,U+6441,U+59F9,U+5456,U+800B,U+70BB,U+90C4,U+89E5,U+84B9,U+8198,U+79EB,U+75F1,U+6EE0,U+63E9,U+952C,U+90B6,U+9026,U+825F,U+8037,U+7EFB,U+7023,U+62BF,U+6035,U+559F,U+523F,U+8729,U+84CA,U+840B,U+7F32,U+7F0C,U+6C3D,U+691F,U+60B1,U+5CB5,U+8347,U+8268,U+80AB,U+7ED0,U+727E,U+709D,U+786D,U+6EDF,U+5459,U+9179,U+87DB,U+8765,U+86E9,U+7322,U+6A65,U+636D,U+5A0C,U+59AF,U+54DD,U+9A7D,U+8BCC,U+86D0,U+83A9,U+6CD4,U+5107,U+9E39,U+960D,U+9572,U+8F71,U+83E5,U+839B,U+799A,U+74F4;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./a12185fe0fc41bebf6508de1474733aa.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+6661,U+64DE,U+643D,U+5B6C,U+9F51,U+836D,U+812C,U+7F0D,U+72EF,U+67D9,U+5AEB,U+98D5,U+8DBC,U+8C16,U+8BEE,U+8BCE,U+823B,U+7321,U+55E5,U+5240,U+8E40,U+8233,U+7654,U+7331,U+6C15,U+6434,U+5786,U+55F3,U+5581,U+8315,U+7905,U+775A,U+7610,U+7513,U+6083,U+7F31,U+74E4,U+577C,U+855E,U+7B31,U+763C,U+698D,U+5C63,U+5AD2,U+576B,U+567C,U+562D,U+9164,U+87CA,U+84D0,U+8406,U+80D7,U+6F72,U+6D2B,U+6322,U+6014,U+5C59,U+990D,U+890A,U+872E,U+852B,U+8244,U+5997,U+54D0,U+54A6,U+8E2E,U+851F,U+8459,U+83B0,U+82B0,U+8221,U+7B24,U+7785,U+7615,U+6332,U+54D3,U+5025,U+9C92,U+98E7,U+8930,U+8478,U+7C9D,U+752D,U+9969,U+84E0,U+7F0B,U+7C0F,U+7704,U+6BF3,U+6B37,U+64BA,U+638A,U+54D5,U+4F89,U+9F85,U+7F9D,U+7B0A,U+77EC,U+64E4,U+63F2,U+63BC,U+5AB8,U+506C,U+8B26,U+88B7,U+8585,U+829F,U+827F,U+6F2D,U+62BB,U+600D,U+6003,U+5E80,U+56F5,U+50EC,U+951E,U+928E,U+8DFD,U+88F0,U+8488,U+8016,U+7B85,U+74DE,U+729F,U+65EF,U+65EE,U+56EB,U+5575,U+9CC6,U+8DEC,U+877B,U+840F,U+7FBC,U+7ECB,U+7EC1,U+7CE8,U+5892,U+56D4,U+552A,U+54DE,U+5472,U+522D,U+507E,U+4F1B,U+9EE2,U+9C9E,U+97B2,U+9503,U+8E70,U+8E1F,U+8D33,U+7CC1,U+7811,U+771A,U+754E,U+6004,U+5E31,U+9EF9,U+9516,U+8DF9,U+87B5,U+87AC,U+811E,U+7B7B,U+7735,U+76B2,U+7605,U+6100,U+6029,U+9F87,U+9538,U+8E3D,U+8E23,U+8DB1,U+8D47,U+80F2,U+7EE8,U+7C0C,U+7BFC,U+779F,U+7110,U+6079,U+602B,U+5FEA,U+572C,U+5659,U+55D6,U+4F74,U+4F32,U+8F9A,U+8E6F,U+8BFC,U+7F27,U+63E0,U+5623,U+50E6,U+902D,U+8E05,U+8D46,U+8C30,U+8C07,U+897B,U+87EE,U+8797,U+8764,U+82E4,U+8022,U+5658,U+55EB,U+5550,U+54A3,U+8E3A,U+8BD6,U+817D,U+815A,U+80E8,U+7ED7,U+77A2,U+7625,U+972A,U+88CE,U+868D,U+804D,U+7B38,U+7829,U+641B,U+63DE,U+5685,U+5530,U+54E7,U+8FEE,U+8E14,U+8DBF,U+84F0,U+8160,U+7633,U+760C,U+75C4,U+63CE,U+5EF4,U+5282,U+97AF,U+9792,U+91AD,U+8E90,U+8E41,U+8C1D,U+8793,U+8284,U+8014,U+789C,U+784C,U+72FA,U+727F,U+5776,U+5601,U+553F,U+51B1,U+9161,U+88BC,U+8864,U+8029,U+7A02,U+764D,U+652E,U+5654,U+9EBD,U+9667,U+8234,U+80E9,U+8035,U+802A,U+7B58,U+760A,U+7313,U+717A,U+70C0,U+6D7C,U+6D1A,U+6509,U+640C;
        }
@font-face {
            font-family: "jiangxizhuokai";
            src: url("./f12522c7d0787a0083819d8e0bf95087.woff2");
            font-style: normal;
            font-weight: regular;
            font-display: swap;
            unicode-range:U+562C,U+553C,U+54F3,U+5476,U+50BA,U+4FDC,U+9A92,U+917D,U+8D84,U+87D3,U+86D8,U+82A4,U+8031,U+8020,U+7EF1,U+7C9E,U+7A06,U+7603,U+7338,U+63AD,U+5ADC,U+5523,U+836E,U+6371,U+54B4,U+54DC,U+5F8C,U+7339,U+5925,U+61B7,U+752F,U+8F77,U+8080,U+770D,U+75E6,U+88C9,U+8025,U+8D94,U+8D91,U+8DEB,U+9CBA,U+9DF9,U+20,U+5570,U+5586,U+56E7,U+5C4C,U+6294,U+77AD,U+77FC,U+8E5A,U+2080,U+2081,U+2082,U+2083,U+2084,U+2085,U+2086,U+2087,U+2088,U+2089,U+5C,U+208D,U+208E,U+FE47,U+FE48;
        }