@import '../scss/config.scss';


#box-content-toolbar {
    z-index: 10;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    width: auto;
    height: auto;
    background-color: #252525;
    border-radius: 12px;
    text-align: center;

    >div {
        margin: 8px;
    }

}

@include media-query($on-phone) {

    #box-content-toolbar {
        >div {
            .ant-space-item:nth-child(3) {display: none;}
        }
    }
}