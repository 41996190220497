/*Generated By cn-font-split https://www.npmjs.com/package/@konghayao/cn-font-split;Origin File Name Table:
fontFamily: KingHwa_OldSong
fontSubfamily: Regular
uniqueID: KingHwa_OldSong:Version 1.007
fullName: KingHwa_OldSong
version: Version 1.007;July 28, 2023
postScriptName: KingHwa_OldSong
copyright: Font © Copyright 2022 TerryWang.All rights reserved.
trademark: KingHwa_OldSong is a trademark of TerryWang. 京華老宋体 は、TerryWangの商標です。京華老宋体是王廷瑞的商标。京華老宋体是王廷瑞的商標。
designer: TerryWang王廷瑞
sampleText: 
 */

@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./465046de4edd1175af5856dbc6ad17a9.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2113;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./a4e1bdd99abcea6e18f8b0b11d7671ea.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+101,U+113,U+11b,U+12b,U+141-142,U+14d,U+160-161,U+16b,U+174-178,U+17d-17e,U+1ce,U+1d0,U+1d2,U+1d4,U+1d6,U+1d8,U+1da,U+1dc,U+266,U+1e80-1e85,U+1e9e,U+1ef2-1ef3,U+2074,U+20ac,U+2122,U+2190-2193,U+2212;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./1b005c44e0f0f11dad38785bc6865097.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e25,U+4e48,U+4e50,U+4e5d,U+4e9a,U+4ec5,U+4efd,U+4f4e-4f4f,U+4f55,U+4f59,U+4f60,U+4f9d,U+4fbf,U+513f,U+5149,U+514b,U+516b,U+5174,U+517b,U+518d,U+51b5,U+5207,U+5217,U+5219,U+521d,U+529f,U+52a9,U+52b3,U+533b,U+5343,U+5360,U+5373,U+5386,U+53cc-53cd,U+53f2,U+544a,U+5468,U+547d,U+54cd,U+552e,U+5584,U+571f,U+5747,U+575a,U+5883,U+58eb,U+590d,U+592e,U+5931,U+5956,U+5973,U+5979,U+59cb,U+5b58,U+5b83,U+5ba2,U+5bb9,U+5bcc,U+5c14,U+5c42,U+5c4a,U+5df1,U+5e03,U+5e08,U+5e26,U+5e2d,U+5f71,U+5f80,U+5f8b,U+5fb7,U+606f,U+611f,U+6237,U+623f,U+6253,U+627f,U+6293,U+62a4,U+62c9,U+6309,U+6599,U+65ad,U+65af,U+65cf,U+671b,U+672a,U+6821,U+6839,U+6848,U+68c0,U+6a21,U+6b3e,U+6bb5,U+6c14,U+6cb3,U+6cb9,U+6ce8,U+6e05,U+6e2f,U+6e38,U+6e90,U+6ee1,U+70ed,U+7167,U+7231,U+7248,U+7387,U+738b,U+73af,U+75c5,U+76f4,U+771f,U+77e5,U+77f3,U+7834,U+7840,U+793a,U+795e,U+79f0,U+7a7a,U+7a81,U+7b56,U+7b97,U+7c73,U+7c7b,U+7d20,U+7ea6,U+7eaa,U+7ebf,U+7eed,U+7ef4,U+7fa4,U+8003,U+80a1,U+81f4,U+8272,U+827a,U+8282,U+82b1,U+82f1,U+8303,U+836f,U+83b7,U+843d,U+8ba9,U+8baf,U+8bb8,U+8bbf,U+8bc4,U+8bc6,U+8bdd,U+8bf7,U+8c08,U+8c61,U+8d1f,U+8d22,U+8d27,U+8d2d,U+8d38,U+8d5b,U+8d8a,U+8db3,U+8eab,U+8f83,U+8fb9,U+8fdc,U+8fde,U+9009,U+901f,U+914d,U+91c7,U+94b1,U+94c1,U+94f6,U+95fb,U+9632,U+9650,U+9752,U+975e,U+987b,U+989d,U+98df,U+9999,U+9a6c,U+9a8c,U+9ec4;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./115ebbcacaf047b171a34e1cab20626c.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e13,U+4e16,U+4e1c,U+4e3e,U+4e49,U+4e61,U+4e66,U+4e89,U+4e8c,U+4e94,U+4ea4,U+4eac,U+4ebf,U+4ef6-4ef7,U+4efb,U+4f17-4f18,U+4f20,U+4f46,U+4f9b,U+4fe1,U+503c,U+505a,U+5148,U+515a,U+5171,U+5177,U+519b,U+51b3,U+51c6,U+51e0,U+5212,U+521b,U+522b,U+526f,U+52bf,U+5305,U+534e-534f,U+5355,U+5357,U+5382,U+539f,U+53bf,U+53c2,U+53c8,U+53ca,U+53d6-53d8,U+53e3,U+53ea,U+53f0,U+56db,U+56de,U+56e0,U+56e2,U+578b,U+57ce,U+5904,U+5907,U+5934,U+5982,U+5b89,U+5b8c,U+5bfc,U+5c11,U+5c40,U+5c71,U+5dde,U+5e38,U+5e72,U+5e76,U+5e7f,U+5e9c,U+5f0f,U+5f15,U+5f20,U+5f62,U+5f88,U+5fc5,U+5fd7,U+5feb,U+601d,U+6027,U+60c5,U+60f3,U+610f,U+6216,U+6218,U+624b,U+624d,U+6279,U+628a,U+6295,U+6301,U+6307,U+636e,U+63a5,U+63a8,U+652f,U+653e,U+6548,U+6570,U+6574,U+65bd,U+65e0,U+6613,U+66f4,U+670d,U+671f,U+6743,U+674e,U+6751,U+6781,U+6784,U+6797,U+679c,U+67e5,U+6807,U+6837,U+683c,U+6b64-6b65,U+6bcf,U+6bd4,U+6c42,U+6c5f,U+6ca1,U+6cbb,U+6d3b,U+6d41,U+6d88,U+6df1,U+7136,U+7269,U+754c,U+767e,U+76ca,U+76f8,U+770b,U+7814,U+786e,U+79ef,U+7a0b,U+7a0e,U+7a76,U+7cbe,U+7ea7,U+7ec4,U+7ec7,U+7ed3,U+7ed9,U+7edf,U+8001,U+804c,U+8054,U+80b2,U+81f3,U+8425,U+88ab,U+88c5,U+897f,U+89c1-89c2,U+89c6,U+89e3,U+8ba4,U+8bae,U+8bba,U+8bc1,U+8be5,U+8c03,U+8d23,U+8d28,U+8d39,U+8d70,U+8def,U+8f66,U+8f6c,U+8fd0-8fd1,U+9020,U+9053,U+90a3,U+9500,U+9547,U+95f4,U+961f,U+9645,U+9662,U+96be,U+96c6,U+9700,U+9879,U+9886,U+98ce,U+9996;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./7cad44c33722c5c6003bf890c0ce138e.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e03,U+4e14,U+4e30,U+4e34,U+4e60,U+4e70,U+4e88,U+4e91,U+4eb2,U+4ec0,U+4ecb,U+4ecd,U+4ee4,U+4f8b,U+4fc3,U+4fee,U+5065,U+50cf,U+5145,U+516d,U+5170,U+5175,U+5199,U+51cf,U+51fb,U+5218,U+5267,U+52aa,U+5347,U+534a,U+5356,U+536b,U+5370,U+5374,U+53cb,U+53e4,U+53e6,U+53f7,U+5403,U+5428,U+542c,U+5668,U+56f0,U+56f4,U+56fe,U+57df,U+57f9,U+58f0,U+592a-592b,U+5957,U+5b57,U+5b81,U+5b98,U+5b9d,U+5ba1,U+5ba3-5ba4,U+5bb3,U+5bc6,U+5bdf,U+5c3d,U+5c45,U+5c5e,U+5c81,U+5dee,U+5df4,U+5e0c,U+5e95,U+5e97,U+5ea7,U+5ead,U+5eb7,U+5f81,U+5f85,U+5ff5,U+6001,U+6267,U+6269,U+62c5,U+62db,U+6325,U+635f,U+6362,U+6392,U+63a7,U+63aa,U+641e,U+6597,U+65c5,U+65e9,U+661f,U+6625,U+663e,U+666e-666f,U+66fe,U+6750,U+67d0,U+6811,U+6838,U+6b27,U+6b62,U+6b66,U+6bdb,U+6c47,U+6c7d,U+6c99,U+6ce2,U+6cfd,U+6d32,U+6d3e,U+6e56,U+6f14,U+706b,U+70c8,U+7247,U+724c,U+72af,U+72b6,U+72ec,U+732e,U+73ed,U+7403,U+7530,U+753b,U+7559,U+7565,U+767d,U+76d1,U+773c,U+7763,U+77ff,U+7968,U+798f,U+79bb,U+79c1,U+7a33,U+7ad9,U+7ade,U+7ae0,U+7cae,U+7d27,U+7ea2,U+7eb3,U+7ec8,U+7ee7,U+7efc,U+7f16,U+7f57,U+7f6e,U+822a,U+826f,U+82cf,U+82e6,U+8363,U+83dc,U+8457,U+878d,U+8865,U+8a00,U+8bad,U+8bb2,U+8bd5,U+8d2b,U+8d85,U+8f7b,U+9001-9002,U+9010,U+9047,U+94a2,U+9633,U+9636,U+963f,U+9648,U+964d,U+9664,U+9669,U+968f,U+9760,U+987e,U+9884,U+98de,U+9986,U+9ed1,U+9f99;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./3e6625407bd34fd0a03911ac0c03c984.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3001-3002,U+3008-3011,U+3014-3017,U+4e00,U+4e07,U+4e09-4e0b,U+4e0d-4e0e,U+4e1a,U+4e24,U+4e2a,U+4e2d,U+4e3a-4e3b,U+4e4b,U+4e5f,U+4e86,U+4e8b,U+4e8e,U+4e9b,U+4ea7,U+4eba,U+4eca,U+4ece,U+4ed6,U+4ee3,U+4ee5,U+4eec,U+4f01,U+4f1a,U+4f4d,U+4f53,U+4f5c,U+4f7f,U+4fdd,U+5143,U+5165,U+5168,U+516c,U+5173,U+5176,U+5185,U+519c,U+51fa,U+5206,U+5229,U+5230,U+5236,U+524d,U+529b,U+529e,U+52a0-52a1,U+52a8,U+5316-5317,U+533a,U+5341,U+53bb,U+53d1,U+53ef,U+53f8,U+5404,U+5408,U+540c-540e,U+5411,U+5458,U+548c,U+54c1,U+5546,U+56fd,U+5728,U+5730,U+573a,U+57fa,U+589e,U+5916,U+591a,U+5927,U+5929,U+597d,U+59d4,U+5b50,U+5b66,U+5b9a,U+5b9e,U+5bb6,U+5bf9,U+5c06,U+5c0f,U+5c31,U+5c55,U+5de5,U+5df2,U+5e02,U+5e73-5e74,U+5e94,U+5ea6,U+5efa,U+5f00,U+5f3a,U+5f53,U+5f97,U+5fc3,U+603b,U+6210-6211,U+6240,U+6280,U+62a5,U+63d0,U+6536,U+6539,U+653f,U+6559,U+6587,U+65b0,U+65b9,U+65e5,U+65f6,U+660e,U+662f,U+6700,U+6708-6709,U+672c,U+672f,U+673a,U+6761,U+6765,U+6b21,U+6b63,U+6c11,U+6c34,U+6cd5,U+6d4e,U+6d77,U+70b9,U+7279,U+73b0,U+7406,U+751f,U+7528,U+7531,U+7535,U+7684,U+76ee,U+7740,U+793e,U+79cd,U+79d1,U+7acb,U+7b2c,U+7b49,U+7ba1,U+7cfb,U+7d93,U+7ecf,U+7f8e,U+8005,U+800c,U+80fd,U+81ea,U+884c,U+8868,U+8981,U+89c4,U+8ba1,U+8bb0,U+8bbe,U+8bf4,U+8d44,U+8d77,U+8fbe,U+8fc7,U+8fd8-8fd9,U+8fdb,U+901a,U+90e8,U+90fd,U+91cc-91cd,U+91cf,U+91d1,U+957f,U+95e8,U+95ee,U+9762,U+9769,U+9898,U+9ad8,U+fe10-fe19,U+fe39-fe44;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f43a4f2274869cd6e5c634b78aea30c6.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e3d,U+4e71,U+4e8f,U+4eea,U+4f0a,U+4f11,U+4f24,U+4f30,U+4f3c,U+4f73,U+5012,U+501f,U+507f,U+50a8,U+5178,U+517c,U+518c,U+51a0,U+51b7,U+52e4,U+5371,U+539a,U+53eb,U+53f6,U+542b,U+542f,U+5462,U+5473,U+54c8,U+54ea,U+5531,U+5757,U+5761,U+5766,U+5802,U+585e,U+590f,U+591c,U+5b59,U+5b63,U+5b88,U+5b97,U+5b9c,U+5bbd-5bbe,U+5bfb,U+5c01,U+5c04,U+5c24,U+5c97,U+5cb8,U+5e55,U+5e84,U+5ef6,U+5f02,U+5f52,U+5f90,U+5fae,U+6000,U+60e0,U+6276,U+6297,U+62b5,U+62d3,U+62e9,U+6302,U+63e1,U+6444,U+653b,U+6563,U+660c,U+6653,U+667a,U+66f2,U+672b,U+6731,U+6742,U+677e-677f,U+6790,U+67b6,U+67d3,U+6863,U+68b0,U+6b8b,U+6bcd,U+6bd2,U+6bd5,U+6c61,U+6cdb,U+6ce5,U+6cf0,U+6d59,U+6d89,U+6da6,U+6da8,U+6fb3,U+7075,U+719f,U+7236,U+725b,U+73e0,U+745e,U+74e6,U+75be,U+76ae,U+76db,U+76fe,U+786c,U+793c,U+7956,U+7981,U+79cb,U+79d8,U+79df,U+7adf,U+7ae5,U+7b26,U+7b54,U+7bc7,U+7d2f,U+7eaf,U+7eba,U+7f5a,U+7f72,U+8089,U+80a5,U+80e1,U+8131,U+82e5,U+8336,U+8499,U+8651,U+8863,U+88c1,U+8bd7,U+8bfe,U+8c01,U+8c22,U+8d21,U+8d25,U+8d5e,U+8d75,U+8d8b,U+8dc3,U+8de8,U+8df5,U+8f68,U+8f6e,U+8f86,U+8f89,U+9014,U+904d,U+90ae,U+90d1,U+9274,U+949f,U+952e,U+96c4,U+96e8,U+96f6-96f7,U+9732,U+97e9,U+996e,U+9a7b,U+9aa8,U+9c7c,U+9c81,U+9e4f,U+9f50;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./e365ac94d0a44735cccfd307503ccbca.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e45,U+4e4e,U+4e92,U+4ea9,U+4ed8,U+4f0d,U+4f1f,U+4fc4,U+500d,U+5019,U+503a,U+5047,U+505c,U+514d,U+51b2,U+520a-520b,U+521a,U+5224,U+5238,U+523b,U+52b1,U+5348,U+535a,U+5361,U+5385,U+538b,U+53ec,U+53f3,U+5409,U+5426,U+5434,U+5438,U+547c,U+559c,U+56ed,U+56fa,U+5733,U+591f,U+5947,U+594b,U+5965,U+5987,U+5a01,U+5b69,U+5b8f,U+5c1a,U+5c3c,U+5c9b,U+5ddd,U+5de6,U+5de8,U+5e01,U+5e2e,U+5e45,U+5e86,U+5e8f,U+5e93,U+5f55,U+5f69,U+5f7b,U+600e,U+6025,U+613f,U+6258,U+626c,U+627e,U+62cd,U+62e5,U+62ec,U+62ff,U+632f,U+6388,U+63a2,U+64ad,U+6545,U+6551,U+65a4,U+65e2,U+65e7,U+6620,U+665a,U+671d,U+6728,U+675f,U+6768,U+6865,U+68c9,U+690d,U+697c,U+6b22,U+6b4c,U+6b7b,U+6c38,U+6c49,U+6cbf,U+6d0b,U+6d25,U+6d2a,U+6d4b,U+6e29,U+6e7e,U+6f6e,U+6fc0,U+707e,U+70df,U+7164,U+7389,U+751a,U+7533,U+7537,U+7597,U+767b,U+77ed,U+79c0,U+79fb,U+7b14,U+7b51,U+7b79,U+7b7e,U+7b80,U+7d22,U+7e41,U+7eb7-7eb8,U+7ec3,U+7ec6,U+7ecd,U+7edd,U+7ee9,U+7eff,U+7f3a,U+7f51,U+7f6a,U+80dc,U+8111,U+821e,U+822c,U+8239,U+8270,U+8349,U+85cf,U+867d,U+8840,U+8857,U+89c9,U+89d2,U+8a89,U+8b66,U+8ba2,U+8ba8,U+8bc9,U+8bed,U+8bfb,U+8d2f,U+8d35,U+8d37,U+8f93,U+8fc5,U+8fce,U+8fdd,U+8ff0,U+8ffd,U+9000,U+9093,U+9152,U+9488,U+9646,U+969c,U+97f3,U+987a,U+987f,U+996d,U+9c9c,U+9f13;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./a7e3be9a82e747df403d0bfcfc4ea905.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4fa7,U+4ff1,U+5077-5078,U+5085,U+5144,U+5146,U+5151,U+51af,U+51b6,U+51cc,U+523a,U+5251,U+5272,U+52d8,U+5353,U+5389,U+53f9,U+541b,U+54f2,U+554a,U+559d,U+5806,U+5821,U+586b,U+594f,U+59a5,U+59b9,U+59c6,U+59d1,U+59dc,U+5b64,U+5b87,U+5bbf,U+5c1d,U+5cad,U+5de1,U+5de7,U+5de9,U+5f04,U+5f70,U+5f79,U+5fc6,U+6070,U+6084,U+60b2,U+60dc,U+60e9,U+6167,U+6170,U+61c2,U+6291,U+62df,U+62f3,U+6324,U+6377,U+6398,U+640f,U+642c,U+6458,U+6478,U+654c,U+658c,U+65cb,U+65ec,U+6696-6697,U+6734,U+67dc,U+67f4,U+684c,U+699c,U+6c0f,U+6c1b,U+6c57,U+6c6a,U+6d3d,U+6db5,U+6dee,U+6eaa,U+6ecb,U+6ede,U+6ee9,U+6f0f,U+6f20,U+6f58,U+704c,U+7070,U+7262,U+7275,U+72e0,U+741b,U+7434,U+7483,U+74f6-74f7,U+75ab,U+764c,U+79d2,U+7a00,U+7a3b,U+7c97,U+7ea4,U+7eb2,U+7ed2,U+7f18,U+7fbd,U+8000,U+8010,U+8096,U+81e3,U+8206,U+8212,U+8302,U+8361,U+8377,U+83f2,U+848b,U+8870,U+8881,U+88ad,U+88c2,U+8986,U+8d24,U+8d2a,U+8d3e-8d3f,U+8d64,U+8d6b,U+8e0f,U+8f70,U+8f85,U+9003,U+90b9,U+90ce,U+94a6,U+95ef,U+95f2,U+95f9,U+9605,U+966a,U+9677,U+9690,U+9694,U+96d5,U+9896-9897,U+9972,U+9a71,U+9a7e,U+9e1f,U+9e23;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./1fdb1627e8f320fdb10ab492dacd774b.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e1d,U+4e39,U+4e4c,U+4e4f,U+4e54,U+4e58,U+4e95,U+4ea1,U+4eab,U+4eae,U+4ec1,U+4f10,U+4f19,U+4fb5,U+503e,U+5192,U+51ac,U+51b0,U+51e1,U+5211,U+5242,U+52a3,U+52c7,U+52d2,U+5377,U+53a6,U+53e5,U+5417,U+54e5,U+5510,U+574f-5750,U+5851,U+5854,U+58ee,U+593a,U+5949,U+5954,U+5a5a,U+5b8b,U+5c0a,U+5ce1,U+5cf0,U+5e8a,U+5ec9,U+5f31,U+5f84,U+5fd8,U+5fe0,U+6015,U+6062,U+6069,U+6089,U+60a3,U+60ca,U+620f,U+624e,U+6298,U+62a2,U+62bd,U+6311,U+6389,U+638c,U+63f4,U+6446,U+6469,U+64cd,U+6562,U+65d7,U+66b4,U+670b,U+676f-6770,U+6881,U+6885,U+68ee,U+6b8a,U+6c88-6c89,U+6cc9,U+6d01,U+6d17,U+6d1b,U+6d6a,U+6df7,U+6e10,U+6e20-6e21,U+6f5c,U+7267,U+73cd,U+7518,U+7586,U+7591,U+75db,U+76d0,U+76d6,U+76d8,U+76df,U+77db,U+795d,U+7a7f,U+7aef,U+7b11,U+7bb1,U+7edc,U+7f13,U+7f29,U+7ffb,U+8015,U+8058,U+805a,U+8083,U+80af,U+80c6,U+80cc,U+811a,U+8150,U+8352,U+83ab,U+8428,U+8463,U+852c,U+8861,U+89c8,U+8bcd,U+8bda,U+8be2,U+8bef,U+8c0b,U+8d34,U+8d74,U+8d76,U+8dd1,U+8ddd,U+8ddf,U+8f6f,U+8f7d,U+8fbd,U+8feb,U+8ff9,U+900f,U+9057,U+907f-9080,U+91ce,U+9519,U+9526,U+95ed,U+9635,U+9644,U+96ea,U+9707,U+9759,U+9910,U+9e21,U+9ea6,U+9f84;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./e02d106653e559175eb40589948fe141.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e43,U+4ea6,U+4ed3,U+4ef2,U+4eff,U+4f38,U+4f69,U+5021,U+5141,U+51dd,U+5200,U+5237,U+5269,U+5401,U+5427,U+5609,U+5708,U+5723,U+5740,U+575b,U+57c3,U+5899,U+58c1,U+5976,U+5988,U+59d0,U+5a18,U+5a31,U+5a92,U+5b54,U+5b5f,U+5b85,U+5bc4,U+5bd2,U+5be8,U+5bff,U+5c65,U+5ca9,U+5d07,U+5e1d,U+5e7c,U+5f03,U+5f6d,U+5f92,U+5ffd,U+602a,U+6050,U+6108,U+6234,U+626b,U+626d,U+6270,U+62b1,U+62bc,U+62c6,U+62d2,U+62dc,U+6316,U+6355,U+63ed,U+6447,U+6566,U+65c1,U+65e8,U+65f1,U+6628,U+664b,U+6668,U+66f9,U+66ff,U+6717,U+679a,U+67aa,U+67ec,U+67f3,U+680f,U+6851,U+6b32,U+6bc1,U+6c60,U+6cea,U+6cf3,U+6d53,U+6d66,U+6d69,U+6d6e,U+6d9b,U+6de1,U+6dfb,U+6ed1,U+6eda,U+6ee8,U+6f2b,U+706d,U+7089,U+708e,U+70ae,U+70b8,U+7126,U+71d5,U+73bb,U+74dc,U+7532,U+75c7,U+76d7,U+788d,U+78e8,U+7a46,U+7a97,U+7af9,U+7bee,U+7eb5,U+7ebd,U+7ed8,U+8017,U+8033,U+80c0,U+8138,U+81a8,U+827e,U+82b3,U+83b1,U+864e,U+866b,U+888b,U+8bca,U+8bde,U+8c0a,U+8c46,U+8d29,U+8d4f,U+8d56,U+8d5a,U+8d60,U+8d62,U+8f7f,U+8f88,U+8f96,U+8f9f,U+8fdf,U+8ff7,U+9012,U+90bb,U+90ca,U+9178,U+94bb,U+94dc,U+9501,U+9505,U+950b,U+955c,U+9634,U+963b,U+9675,U+9887,U+9891,U+9971,U+9a76,U+9a97,U+9ed8;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./acfccc8e41ef6297e09e1982c005f00a.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e01,U+4f26,U+4f2a,U+4f2f,U+4f34,U+4f5b,U+4fa8,U+4fca,U+4fd7,U+504f,U+51c0,U+51e4,U+51ed,U+52b2,U+52c3,U+5362,U+5448,U+54a8,U+552f,U+5706,U+58a8,U+59bb,U+59d3,U+5baa-5bab,U+5c4b,U+5e10,U+5e78,U+5e9f,U+5f1f,U+5f39,U+5faa,U+5fbd,U+5fd9,U+5fe7,U+6076,U+60a8,U+60ef,U+6162,U+622a,U+6263,U+62d4,U+62d6,U+62e8,U+6350,U+644a,U+64a4,U+654f,U+656c,U+65e6,U+65fa,U+6606,U+6682,U+66fc,U+6740,U+675c,U+676d,U+67f1,U+6842,U+68a6,U+68cb,U+695a,U+6982,U+6a2a,U+6b20,U+6b23,U+6b96,U+6bc5,U+6beb,U+6c9f,U+6d1e,U+6d8c,U+6db2,U+6e14,U+706f,U+70ad,U+70bc,U+70e7,U+714c,U+71c3,U+7206,U+7259,U+731b,U+732a,U+73a9,U+7545,U+755c,U+756a,U+7687,U+76c8,U+76f2,U+7801,U+7965,U+79e6,U+79e9,U+7a3f,U+7a77,U+7c4d,U+7c89,U+7cd6,U+7ea0,U+7ed5,U+7f34,U+7f8a,U+80de,U+80f6,U+817e,U+820d,U+82d7,U+84c4,U+84dd,U+8584,U+865a,U+86cb,U+88d5,U+89e6,U+8bf8,U+8bfa,U+8c13,U+8c37,U+8c6a,U+8c8c,U+8d1d,U+8d3a,U+8d54,U+8da3,U+8df3,U+8f91,U+8f9b,U+8f9e,U+8fc1,U+8fc8,U+8fd4,U+8fea,U+906d,U+9075,U+90a6,U+90ed,U+9192,U+91ca,U+94fa,U+9521,U+9614,U+9655,U+9676,U+9686,U+96c5,U+978b,U+9876,U+9881,U+9970,U+9ebb,U+9ece;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./a5753bfaea199f14e15e536b8d4c9e61.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e08,U+4e1b,U+4e27,U+4e59,U+4e73,U+4ed4,U+4ed9,U+4f0f,U+4faf,U+51bb,U+51c9,U+51fd,U+524a,U+52ab,U+5339,U+53d9,U+540a,U+5415,U+5439,U+5524,U+5564,U+55b7,U+5634,U+575d,U+57a6,U+57d4,U+5824,U+582a,U+5858,U+5893,U+5915,U+5960,U+5999,U+5bb4,U+5bfa,U+5c16,U+5c18,U+5c3e,U+5c4f,U+5c9a,U+5cb3,U+5cfb,U+5d1b,U+5eca,U+5ef7,U+5f18,U+5fcd,U+6052,U+60a0,U+60d1,U+614e,U+6155,U+61c8,U+6208,U+629a,U+62ac,U+62fc,U+632a,U+633a,U+63cf,U+63d2,U+642d,U+643a,U+649e,U+6500,U+659c,U+6676,U+6746,U+679d,U+67cf,U+682a,U+683d,U+6843,U+6846,U+68af,U+6b67,U+6c27,U+6c64,U+6cca,U+6d12,U+6d82,U+6e58,U+6ee5,U+6f02,U+7092,U+70c2,U+718a,U+7237-7238,U+7272,U+72b9,U+72d7,U+751c,U+758f,U+775b,U+7761,U+77a9,U+7816,U+7855,U+7891,U+78c1,U+7a9d,U+7eea,U+7ef8,U+809d,U+80a9,U+80c1,U+80ce,U+80f8,U+8109,U+810f,U+8170,U+817f,U+8230,U+8236,U+8273,U+82ac,U+8350,U+83b2,U+83cc,U+840d,U+8461,U+8482,U+84ec,U+8521,U+85aa,U+8679,U+8854,U+8877,U+8bd1,U+8be6,U+8bf1,U+8c31,U+8d41,U+8d4b,U+8dcc,U+8e2a,U+8fa9,U+901d,U+9022,U+903e,U+9065,U+94dd,U+94f8,U+95ea,U+9601,U+971e,U+9738,U+9875,U+989c,U+9e3f;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./8d2257943e6bdaa38d98ed5dc07aa414.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e18,U+4e22,U+4e32,U+4e52,U+4ead,U+4ef0,U+4fa6,U+5076,U+50ac,U+5179,U+51ef,U+526a,U+529d,U+5306,U+537f,U+5410,U+541e,U+54b1,U+54ed,U+558a,U+574a,U+5751,U+57cb,U+5835,U+58e4,U+5951,U+5986,U+59da,U+59fb,U+59ff,U+5a03,U+5a46,U+5ac1,U+5b5d,U+5c3a,U+5c48,U+5c61,U+5e15,U+5e3d,U+5e99,U+5e9e,U+5f0a,U+5f17,U+5f25,U+5f7c,U+6028,U+60ac,U+6168,U+6212,U+6251,U+629b,U+62ab,U+6323,U+6491-6492,U+64b0,U+64c5,U+6572,U+6602,U+6614,U+662d,U+6664,U+6670,U+67d4,U+680b,U+68da,U+6b3a,U+6c5b,U+6c70,U+6caa,U+6ce1,U+6d45,U+6dd1,U+6dd8,U+6e34,U+6e7f,U+707f,U+70e6,U+7115,U+727a,U+72c2,U+739b,U+73b2,U+743c,U+75b2,U+7686,U+76c6,U+76d2,U+76fc,U+788e,U+7897,U+78b0,U+79bd,U+7a0d,U+7a83,U+7a91,U+7ae3,U+7bad,U+7cca,U+7d2b,U+7eb1,U+7f06,U+7f14,U+7f1d,U+7f50,U+7ffc,U+8036,U+80bf,U+8179,U+819c,U+821f,U+829d,U+82cd,U+82f9,U+8305,U+83ca,U+8404,U+8427,U+886b,U+8bbc,U+8c28,U+8c6b,U+8e48,U+8f90,U+8fb0,U+903c,U+90c1,U+916c,U+917f,U+94a9,U+94c3,U+94ed,U+9510,U+953b,U+96c7,U+970d,U+9877,U+987d,U+98d8,U+9a70,U+9a91,U+9aa4,U+9b42,U+9b4f,U+9e2d,U+9e7f,U+9f20;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./954f87256dfa28dfcd8a80c105e3c9ff.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+1,U+1d,U+20-7e,U+a0-ff,U+131,U+152-153,U+2c6,U+2da,U+2dc,U+304-305,U+308,U+2002-2003,U+2010,U+2012-2016,U+2018-201a,U+201c-201e,U+2020-2022,U+2025-2026,U+2030,U+2032-2033,U+2039-203b,U+203e,U+2044,U+2116,U+2121,U+223c,U+22ee,U+3004,U+3385-3387,U+3389,U+338e-3390,U+3396-3398,U+339c,U+339e-339f,U+33a1-33a3,U+33a5-33a6,U+33b0-33b1,U+33b3,U+33c8,U+33cb,U+33cd,U+33d4,U+fb01-fb02,U+fe30-fe33,U+fe35-fe38,U+ff01-ff0c,U+ff0e-ff5d,U+ffe3-ffe5,U+ffe8;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./3b55589ad07dc3d29e27de6e921019c8.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4ed7,U+4fcf,U+4fe9,U+4fed,U+5112,U+5180,U+5265,U+52cb,U+5367,U+5378,U+5395,U+5398,U+53d4,U+543e,U+5440,U+5446,U+5565,U+5580,U+55bb,U+56ca,U+572d,U+573e,U+574e,U+5782-5784,U+58f3,U+5939,U+5948,U+5a1c,U+5a74,U+5ae9,U+5b55,U+5bb0,U+5bd3,U+5bf8,U+5c3f,U+5d14,U+5d2d,U+5df7,U+5dfe,U+5e05-5e06,U+5e1c,U+5e62,U+5e7b,U+5e7d,U+5ed6,U+5f66,U+5f6c,U+5fa1,U+604b,U+609f,U+60a6,U+60e8,U+6101,U+6148,U+61be,U+6247,U+62da,U+633d,U+635e,U+6380,U+63fd,U+64e6,U+6691,U+67a2-67a3,U+6876,U+6905,U+6a0a,U+6a61,U+6b79,U+6bb7,U+6bbf,U+6c55,U+6c83,U+6ca7,U+6d46,U+6dc0,U+6e17,U+6e24,U+6ea2,U+6f6d,U+70e4,U+710a,U+722c,U+725f,U+7261,U+72f1,U+730e,U+732b,U+7538,U+7709,U+7802,U+7898,U+78a7,U+78b1,U+78cb,U+7b3c,U+7b4b,U+7c92,U+7ca4,U+7ef3,U+7ef5,U+7f05,U+7f55,U+7f62,U+7fc1,U+806a,U+80a0,U+80a4,U+8102,U+8106,U+8154,U+8247,U+8258,U+845b,U+846c,U+84b8,U+8574,U+86c7,U+8702,U+886c,U+8896,U+88b1,U+8bc8,U+8c26,U+8c2d,U+8d4c,U+8d63,U+8f67,U+8f74,U+8fc4,U+9006,U+9063,U+90a2,U+90b1,U+9177,U+9189,U+9493,U+949e,U+9610,U+961c,U+97e6,U+997c,U+9a84,U+9b45,U+9f0e;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./692b2c2cb027e08883df441c6fd2fd18.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e11,U+4e53,U+4e7e,U+4e9f,U+4f50,U+502a,U+50b2,U+5188,U+51f6,U+522e,U+52df,U+52fe,U+5349,U+53a8,U+53c9,U+54b8,U+5566,U+566a,U+5764,U+576a,U+5792,U+57ab,U+584c,U+5938,U+594e,U+59a8,U+5b5c,U+5bc2,U+5c39,U+5c60,U+5eb8,U+5f2f,U+6124,U+6127,U+62d8,U+62e6,U+6321,U+632b,U+6349,U+6367,U+638f,U+63a9,U+641c,U+655e,U+6591,U+65a5,U+65ed,U+660f,U+66a8,U+6735,U+674f,U+67ef,U+6850,U+68d2,U+6bef,U+6c41,U+6c82,U+6c9b,U+6cc4,U+6cfc,U+6d51,U+6d74,U+6d9d,U+6da4,U+6daf,U+6deb,U+6df9,U+6e5b,U+6e89,U+6ef4,U+6f06,U+6f47,U+6f9c,U+70b3,U+70ef,U+7199,U+723d,U+72ac,U+72ee,U+7433,U+754f,U+7554,U+75bc,U+7624,U+7626,U+7750,U+7779,U+7784,U+780d,U+786b,U+78b3,U+78f7,U+7978,U+7984,U+7aed,U+7c98,U+7eb9,U+7ee3,U+7fd4,U+7fe0,U+7ff0,U+8042,U+804a,U+80ba,U+80c3,U+814a,U+81c2,U+8328,U+832b,U+8389,U+838e,U+857e,U+859b,U+867e,U+8680,U+8695,U+884d,U+88e4,U+8944,U+8c10,U+8d42,U+8f9c,U+8fa3,U+8fb1,U+903b,U+90b5,U+94fe,U+95fd,U+960e,U+96a7,U+96fe,U+978d,U+97ad,U+97f5,U+9882,U+9888,U+9b3c,U+9b44,U+9b54,U+9c8d,U+9e45,U+9e64,U+9e70,U+9f3b,U+9f9a;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./58fddf653d21a7d2b2dc25d5a1cf5514.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e19,U+4e38,U+4ec6,U+4fde,U+5154,U+517d,U+51d1,U+51f0,U+5239,U+52c9,U+5308,U+532a,U+535c,U+5384,U+53a2,U+53e0,U+5496,U+54ac,U+54c0,U+54c4,U+54e8,U+5561,U+5582,U+561b,U+5631,U+5885,U+58f6,U+5acc,U+5c38,U+5c6f,U+5c82,U+5c90,U+5d16,U+5dcd,U+5e37,U+5f6a,U+5fcc,U+6012,U+6068,U+6073,U+6094,U+6109,U+621a,U+626e,U+6284,U+62d0,U+62fe,U+6328,U+6454,U+6749,U+6760,U+67af,U+6854,U+6869,U+68a8,U+68f5,U+6912,U+6b49,U+6b6a,U+6c28,U+6c5d,U+6cab,U+6cb8,U+6cf5,U+6d47,U+6d78,U+6dc4,U+6dcb,U+6e0a,U+6eb6,U+6f84,U+6fd2,U+7076,U+70ac,U+72ed,U+7476,U+752b,U+76ef,U+7720,U+7766,U+77ac,U+7838,U+79b9,U+79c9,U+79e7,U+7a3d,U+7a84,U+7a9f,U+7b0b,U+7b52,U+7f1a,U+7fc5,U+8086-8087,U+808c,U+809a,U+810a,U+8180,U+818f,U+81ed,U+8231,U+8292,U+829c,U+82a6,U+8346,U+83c7,U+83f1,U+8403,U+8513,U+85e4,U+871c,U+87ba,U+87f9,U+8a93,U+8c05,U+8d2c,U+8d2e,U+8d43,U+8dfb,U+8e22,U+8eba,U+8f69,U+8fa8,U+900a,U+9038,U+9042,U+904f,U+90dd,U+9102,U+9187,U+94a7,U+94c5,U+9523,U+95f8,U+964b-964c,U+96c1,U+9709,U+971c,U+9976,U+9988,U+99a8,U+9a82,U+9a9a,U+9b41,U+9e9f,U+9f7f;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./beccf0328e23b0d884229a336be1c404.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4ed1,U+4f1e,U+4f7c,U+4f83,U+4fd1,U+4ffa,U+50bb,U+50f5,U+50fb,U+5162,U+5256,U+5319,U+5320,U+538c,U+5413,U+5475,U+54bd,U+54d1,U+57e0,U+5974,U+59ae,U+5a1f,U+5ab3,U+5b99,U+5b9b,U+5ba0,U+5be5,U+5c27,U+5de2,U+5e90,U+5e9a,U+5f26,U+5f8a,U+5f98,U+607c,U+60bc,U+60d5,U+611a,U+6254,U+626f,U+6292,U+62b9,U+62e2,U+631a,U+6320,U+6346,U+63ba,U+658b,U+663c,U+6652,U+6674,U+66d9,U+66f0,U+673d,U+67ab,U+6817,U+68a7,U+6986,U+69fd,U+6b47,U+6c1f,U+6cbe,U+6e23,U+6e9c,U+6ed4,U+6f4d,U+718f,U+72fc,U+731c,U+7334,U+7470,U+7529,U+75d5,U+75f4,U+762b,U+7696,U+7737,U+7845,U+788c,U+78ca,U+7901,U+796d,U+7a3c,U+7a57,U+7a74,U+7b5b,U+7c7d,U+7caa,U+7cd5,U+7eac,U+7eb6,U+7f20,U+7f69,U+7fa1,U+8018,U+8038,U+803f,U+80a2,U+80be,U+820c,U+82af,U+82bd,U+82d1,U+8327,U+836b,U+839e,U+840c,U+840e,U+8471,U+849c,U+84c9,U+8517,U+851a,U+8681,U+8721,U+8776,U+88d9,U+8c1c,U+8c34,U+8d81,U+8d9f,U+8e0a,U+8eb2,U+8fed,U+901b,U+902e,U+90aa,U+90af,U+915d,U+9171,U+946b,U+9489,U+94a5,U+9508,U+9551,U+95f7,U+96b6,U+96c0,U+9756,U+98a0,U+9965,U+997f,U+9a73,U+9ad3,U+9ed4;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./31431ced3e7a504e910ffc4e702f40b7.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4ea8,U+4ec7,U+4f51,U+4f63,U+4fa0,U+5018,U+5026,U+508d,U+515c,U+51a4,U+51ff,U+5254,U+5375,U+541f,U+5587,U+5589,U+5598,U+55a7,U+575f,U+576f,U+5777,U+57ae,U+5937,U+5978,U+5a49,U+5bb5,U+5bc5,U+5be1,U+5c09,U+5d29,U+5e18,U+5f57,U+6021,U+606d,U+60e7,U+614c,U+61d2,U+625b,U+6296,U+631d,U+6467,U+64bc,U+64c2,U+6590,U+65a7,U+6643,U+6656,U+6687,U+66dd,U+6816,U+68ad,U+68cd,U+68d8,U+68e0,U+6930,U+6bd7,U+6c2e-6c2f,U+6c7e,U+6ca5,U+6d3c,U+6de4,U+6e1d,U+6e83,U+6ed5,U+70eb,U+70f9,U+7130,U+716e,U+71ac,U+71e5,U+7316,U+73ca,U+7422,U+7426,U+745f,U+7574,U+75af,U+763e,U+7741,U+7792,U+77a7,U+77bb,U+77e9,U+77ee,U+785d,U+78c5,U+7985,U+79e4,U+79fd,U+7ad6,U+7c3f,U+7cb9,U+7cdf,U+7ece,U+7ed1,U+7ee5,U+7f09,U+7f2a,U+7f38,U+804b,U+80d6,U+817a,U+81fb,U+82ad,U+8354,U+835f,U+8367,U+841d,U+84b2,U+8549,U+88f9,U+89c5,U+8bb3,U+8beb,U+8c23,U+8d1e,U+8e72,U+8e81,U+906e,U+9091,U+9499,U+94be,U+94ee,U+950c,U+9524,U+952d,U+9576,U+9600,U+9647,U+96cf,U+9716,U+97e7,U+97f6,U+98a4,U+9a7c,U+9a86,U+9e3d;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./e2579f0fca1e53847b97d5ca2493a4a1.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e4d,U+4e5e,U+4ed5,U+50da,U+50e7,U+5203,U+5300,U+533e,U+53ee,U+5435,U+543b,U+548b,U+548f,U+54d7,U+54fa,U+560e,U+5760,U+5830,U+58a9,U+5962,U+59e8,U+5a07,U+5a3c,U+5b5a,U+5bde,U+5c7f,U+5ce8,U+5cea,U+5d4c,U+5f5d,U+5f87,U+6016,U+601c,U+6177,U+62e3,U+62f1,U+63a0,U+6401,U+6512,U+6577,U+65a9,U+65f7,U+6627,U+6655,U+6714,U+6795,U+67d1,U+67ff,U+68d5,U+6960,U+6977,U+69bb,U+69d0,U+6a31,U+6b7c,U+6bb4,U+6c22,U+6c72,U+6c79,U+6c81,U+6cbc,U+6ce3,U+6cfb,U+6da9,U+6df3,U+6eaf,U+6ec7,U+6f33,U+6f62,U+707c,U+708a,U+70c1,U+70d8,U+711a,U+7194,U+7281,U+7357,U+7384,U+7405,U+742a,U+7578,U+75ea,U+7682,U+77d7,U+77eb,U+77f6,U+7a1a,U+7a9c,U+7b5d,U+7bf7,U+7c07,U+7c9f,U+7ca5,U+7eda,U+7f15,U+7f9e,U+7fdf,U+7fe9,U+803b,U+803d,U+80b4,U+813e,U+8155,U+817b,U+819d,U+82b9,U+82df,U+82ef,U+8304,U+83b9,U+853d,U+85af,U+85fb,U+8650,U+865e,U+86d9,U+8700,U+8862,U+889c,U+88d4,U+895f,U+8a79,U+8bb6,U+8bc0,U+8dcb,U+8e29,U+8e44,U+8eaf,U+9050,U+90f8,U+914b,U+948a,U+9540,U+962e,U+9661,U+9699,U+9761,U+9a8f,U+9ae6,U+9cd6;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./d17ddb366fcfa332d654e4ba3448f74c.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4fef,U+50a3,U+5189,U+51f3,U+51f9,U+52ff,U+532e,U+533f,U+5351,U+53db,U+53ed,U+5455,U+5507,U+5543,U+5578,U+55d3,U+59ec,U+5a23,U+5a9a,U+5ac2,U+5ad6,U+5c2c,U+5c34,U+5cb1,U+5d0e,U+5deb,U+5e3c,U+5ed3,U+5fe1,U+5ff1,U+6064,U+606a,U+60df,U+60f9,U+6252,U+62c2,U+62ef,U+634f,U+6361,U+63b7,U+63e3,U+6405,U+6495,U+6518,U+6813,U+6893,U+68b3,U+690e,U+6984,U+6ba1,U+6c13,U+6c90,U+6c93,U+6ca6,U+6ccc,U+6cd3,U+6cd7,U+6d4a,U+6d4f,U+6d5a,U+6d9f,U+6dcc,U+6e2d,U+6ee4,U+6f13,U+6fa1,U+7011,U+701a,U+71a0,U+7280,U+72d0,U+73ab,U+7410,U+745b-745c,U+7480,U+74a7,U+76b1,U+76ce,U+7736,U+77e2,U+780c,U+781a,U+789f,U+7941,U+7a8d,U+7a98,U+7aff,U+7b1b,U+7e82,U+7eab,U+7ede,U+7f00,U+7fd8,U+80aa,U+8116,U+8205,U+821c,U+82c7,U+82db,U+832c,U+8335,U+8446,U+846b,U+8587,U+8611,U+8682,U+868a,U+86ee,U+88d8,U+88f8,U+8bbd,U+8bf5,U+8c41,U+8c79,U+8d3b-8d3c,U+8d50,U+8dea,U+8e35,U+8eac,U+8f8d,U+8fe2,U+9017,U+916f,U+9175-9176,U+918b,U+94ae,U+94ce,U+94f2,U+9541,U+9672,U+97a0,U+9a6d,U+9a85,U+9cd7,U+9cde,U+9e26,U+9e93,U+9f9f;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./8fbfb4cb63c3923df77db579a2b630d3.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4f58,U+4f6c,U+5014,U+524c,U+5315,U+5323,U+540f,U+542d,U+549a,U+54fc,U+5567,U+556a,U+5600,U+5618,U+5669,U+56f1,U+56ff,U+573b,U+58f9,U+595a,U+598d,U+5993,U+5996,U+59d7,U+5b7a,U+5c4e,U+5c96,U+5f08,U+602f,U+606c,U+620c,U+6249,U+62a8,U+62c4,U+62ed,U+6342,U+6345,U+6396,U+6402,U+6413,U+6487,U+64ac,U+659f,U+65a1,U+667e,U+67e0,U+6b86,U+6c50,U+6c5e,U+6c76,U+6c85,U+6cde,U+6d19,U+6da7,U+6e25,U+6e4d,U+6e5f,U+6ec1,U+6f31,U+6f7a,U+6fa7,U+701b,U+70ab,U+70f7,U+717d,U+72e1,U+7315,U+73ae,U+73c0,U+73c2,U+75a4,U+7600-7601,U+768b,U+76d4,U+772f,U+77a0,U+77b0,U+783a,U+78fa,U+7977,U+7a92,U+7b71,U+7b94,U+7cef,U+7f28,U+80e5,U+80eb,U+8110,U+812f,U+814c,U+8235,U+82d4,U+8309,U+83c1,U+8469,U+84bf,U+84d3,U+84e6,U+8511,U+86fe,U+8757,U+8882,U+8892,U+88f3,U+8ba5,U+8bd9,U+8be7,U+8bfd,U+8c1a,U+8d4a,U+8d66,U+8dda,U+8e52,U+8f76,U+8fc2,U+900d,U+90ac,U+90b3,U+90b8,U+9119,U+9131,U+915a,U+94d0,U+94e2,U+94ec,U+94ff,U+9522,U+9535,U+965b,U+9774,U+9981,U+998d,U+998f,U+9a7f,U+9b13,U+9c9f,U+9e43,U+9e8b,U+9e92,U+9eef;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./a4f48d61501cc28b1033cad614bd9cfa.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e10,U+4ec3,U+4f36,U+4f88,U+4f97,U+504c,U+52fa,U+5366,U+543c,U+545c,U+5471,U+5480,U+5495,U+54b3,U+54df,U+557c,U+55dc,U+566c,U+5676,U+56da,U+5815,U+5919,U+5955,U+5983,U+59e5,U+5a13,U+5a34,U+5bd0,U+5c94,U+5ce6,U+5d69,U+5dc5,U+5e27,U+5eb5,U+5f3c,U+604d,U+60e6,U+61a7,U+61ac,U+61cb,U+62ce,U+62e7,U+62ee,U+634e,U+6479,U+6482,U+660a,U+664f,U+66e6,U+672d,U+675e,U+67c4,U+67da,U+6868,U+693f,U+695e,U+69ad,U+6a90,U+6be1,U+6cb1,U+6da3,U+6daa,U+6dc6-6dc7,U+6e0d,U+6e32,U+6e43,U+6eba,U+6f3e,U+6f88,U+6fee,U+70d9,U+7109,U+71ee,U+722a,U+7409,U+7459,U+747e,U+7487,U+748b,U+759a,U+75a1,U+75ca,U+75eb,U+766b,U+7738,U+777f,U+7785,U+77bf,U+796f,U+79a7,U+7b03,U+7bf1,U+7c38,U+7c95,U+7ead,U+7f9a,U+7fcc,U+8046,U+8237,U+82ce,U+82d3,U+8398,U+83e0,U+83e9,U+8426,U+86df,U+871a,U+8910,U+891a,U+8bb4,U+8c0d,U+8c19,U+8db4,U+8e1e,U+8e6c,U+8f84,U+901e,U+90f4,U+9165,U+91dc,U+94b3,U+94e8,U+9504,U+9530,U+9532,U+954c-954d,U+95fa,U+962a,U+9685,U+9776,U+9985,U+9a6f,U+9aa5,U+9cb8,U+9e25,U+9e4a;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./aab5fd2c7162470a5664ab8defbb695a.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4ea2,U+4ea5,U+4f84,U+4f8d,U+501a,U+5029,U+516e,U+51a5,U+51c4,U+51f8,U+5201,U+5321,U+5352,U+53e9,U+54c7,U+5632,U+56b7,U+56bc,U+56e4,U+5703,U+5742,U+57a2-57a3,U+58d1,U+592d,U+5a05,U+5a25,U+5b70,U+5b75,U+5bdd,U+5bf0,U+5c41,U+5c79,U+5c91,U+5ced,U+5e16,U+5f95,U+6020,U+6055,U+6123,U+618b,U+62d9,U+631f,U+634d,U+6452,U+64ce,U+64d2,U+655b,U+6726,U+6809,U+6853,U+68f1,U+68fa,U+6942,U+6995,U+69b4,U+6a71,U+6b89,U+6bcb,U+6bd9,U+6c40,U+6cf8,U+6d85,U+6e0e,U+7099,U+70fd,U+7184,U+733f,U+73f2,U+749c,U+749e,U+75d2,U+7620,U+773a,U+778e,U+77aa,U+78be,U+7948,U+795b,U+7960,U+7a20,U+7a96,U+7aa5,U+7b28,U+7b50,U+7b77,U+7c27,U+7d0a,U+7ec5,U+7ee2,U+7ef0,U+7efd,U+7f0e,U+7f2e,U+7f79,U+8098,U+80da,U+80e7,U+80f0,U+80f3,U+81e7,U+8238,U+8299,U+837b,U+83bd,U+8475,U+85c9,U+85d5,U+85dc,U+85e9,U+8749,U+888d,U+8be3,U+8bec,U+8bf2,U+8c06,U+8de4,U+8e66,U+8f97,U+9083,U+90e1,U+91c9,U+94b0,U+94f5,U+956d,U+95f5,U+9668,U+9698,U+96bd,U+9704,U+9773,U+9890,U+997a,U+9a74,U+9a8b,U+9cc4,U+9ccc,U+3064e;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./2a26ebaff5e1a558027437a79c5f9219.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4fa3,U+4fae,U+4fd8,U+5195,U+51db,U+5220,U+5228,U+527f,U+5288,U+5450,U+5484,U+5490,U+54c9,U+54e9,U+5501,U+56a3,U+5729,U+575e,U+589f,U+5984,U+5a04,U+5a36,U+5a77,U+5a9b,U+5ab2,U+5bc7,U+5c51,U+5cd9,U+5e87,U+5f13,U+5f27,U+5f64,U+5ffb,U+6096,U+60eb,U+60f6,U+6151,U+61a8,U+620d-620e,U+6241,U+6273,U+627c,U+6289,U+62c7,U+62cc,U+6363,U+66ae,U+6756,U+6789,U+6829,U+6862,U+6866,U+6897,U+68e3,U+69a8,U+69cc,U+6a1f,U+6a44,U+6a59,U+6bd3,U+6cbd,U+6cfe,U+6da1,U+6e44,U+6ea5,U+6ee6,U+6f2f,U+6f8e,U+7078,U+7095,U+709c,U+70af,U+70bd,U+70db,U+70e8,U+714e,U+715e,U+71ce,U+7235,U+7239,U+72f8,U+74a8,U+74e3,U+75ae,U+75f9,U+7693,U+76cf,U+776b,U+77e3,U+7852,U+797a,U+79ba,U+79be,U+79c3,U+79c6,U+79f8,U+7aa6,U+7bc6,U+7cd9,U+7d6e,U+7eee,U+7f24,U+7f2d,U+800d,U+80fa,U+8151,U+818a,U+81b3,U+82b8,U+8339,U+8386,U+83cf,U+868c,U+8747,U+8774,U+88f4,U+8912,U+8b6c,U+8c0e,U+8d26,U+8d31,U+8d48,U+8e4a,U+8f99,U+8fe9,U+914c,U+94a0,U+951a,U+952f,U+9640,U+968b,U+96cd,U+96ef,U+9713,U+97ec,U+9885,U+996a,U+9992,U+9a79,U+9cbb;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./e64d688d0210457e35ed4496907661c8.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e56,U+4e98,U+4f3a,U+4f5f,U+4f70,U+4fa5,U+4fe8,U+5197,U+5364,U+53e8,U+5406,U+54e6,U+54ee,U+5583,U+55e3,U+592f,U+5944,U+59ca,U+5a7f,U+5b09,U+5e4c,U+5eb6,U+5f1b,U+607a,U+608d,U+60cb,U+6233,U+62a0,U+62f4,U+62f7,U+6382,U+63c9,U+63ea,U+6400,U+645e,U+6556,U+6593,U+6615,U+6805,U+6808,U+68a2,U+69db,U+6a80,U+6b83,U+6c30,U+6cad,U+6cf1,U+6d31,U+6d93,U+6dae,U+6dbf,U+6e3a,U+6e85,U+6f5e,U+6f7c,U+71a8,U+7252,U+72b7,U+72c4,U+72e9,U+73ba,U+73d1,U+7435-7436,U+745a,U+74e2,U+7504,U+752c-752d,U+7599,U+759f,U+75f0,U+761f,U+7629,U+77a5,U+78d5,U+7934,U+7940,U+7b19,U+7cb1,U+7ce0,U+7eca,U+7ef7,U+7f2b,U+7f81,U+8148,U+8165,U+819b,U+81ba,U+828b,U+82ae,U+82b7,U+8301,U+830e,U+831c,U+8338,U+837c,U+8393,U+83ba,U+853c,U+8654,U+86db,U+8712,U+873f,U+874e,U+8783,U+8859,U+88a4,U+8925,U+8bb7,U+8bff,U+8c1b,U+8c24,U+8c2c,U+8d4e,U+8d61,U+8f8a,U+8fe5,U+8ff8,U+912f,U+9163,U+9170,U+949b,U+94a8,U+94c0,U+9525,U+9539,U+9550,U+955b,U+96cc,U+96f9,U+988d,U+9975,U+9ab8,U+9c7f,U+9ca4,U+9e35;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./d51006c02e1353c72dac183b7e8b342d.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4fd0,U+5055,U+5156,U+51bd,U+535e,U+53ae,U+53f1,U+5431,U+545b,U+547b,U+548e,U+54ab,U+54fd,U+5509,U+563f,U+574d,U+579b,U+57a0,U+57b8,U+57c2,U+586c,U+59e3,U+5a29,U+5b62,U+5ba6,U+5ce5,U+5e44,U+5f99,U+6043,U+6059,U+60b8,U+60ed,U+60f0,U+61a9,U+62fd,U+630e,U+63a3,U+63ac,U+642a,U+64a9,U+64ae,U+64b7,U+6525,U+65ee-65ef,U+66f3,U+67b8,U+683e,U+6886,U+68b5,U+6963,U+6988,U+69df,U+6aac,U+6b9a,U+6c8c,U+6cef,U+6d52,U+6db8,U+6dc5,U+6e1a,U+6ea7,U+6fe1,U+7096,U+7131,U+7166,U+736d,U+740f,U+74ef,U+7663,U+76bf,U+7728,U+776c,U+77f8,U+77fe,U+78d0,U+7a37,U+7afa,U+7b60,U+7b75,U+7edb,U+7fb2,U+7fb9,U+7fe1,U+808b,U+8113,U+81c3,U+8317,U+8431,U+84df,U+853a,U+85b9,U+8638,U+86c0,U+8715,U+8822,U+8884-8885,U+88e8,U+892a,U+8983,U+8be0-8be1,U+8c1f,U+8c27,U+8c5a,U+8e0c,U+8e74,U+8e87,U+8f98,U+8fe6,U+9068,U+9074,U+90e7,U+916e,U+93d6,U+949d,U+94b4,U+94e3-94e4,U+9556,U+9893,U+9a6e,U+9a87,U+9a8a,U+9aa1,U+9e3e,U+9e5c,U+9e6d,U+9e9d,U+9edb;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./d3063460b1cfae46cba22640082a7208.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4f2b,U+4f3d,U+4fac,U+5043,U+5140,U+51cb,U+5243,U+531d,U+53a5,U+541d,U+5492,U+54a4,U+54aa,U+54ce,U+5520,U+553e,U+557b,U+55c5,U+55e1,U+55f7,U+5608,U+5636,U+563b,U+5773,U+5811,U+587e,U+58d5,U+5a6a,U+5a7a,U+5ac9,U+5b95,U+5c49,U+5c8c,U+5cab,U+5cb7,U+5d02,U+5d58,U+5eff,U+5f29,U+5f89,U+5f9c,U+5fa8,U+6005,U+60ec,U+6115,U+618e,U+637a,U+6390,U+63b0,U+64de,U+6538,U+6631,U+6636,U+6654,U+677c,U+67d8,U+6979,U+6c69,U+6c74,U+6cae,U+6ce0,U+6d95,U+6dde,U+6de6,U+6dfc,U+6f15,U+6f29,U+70c3,U+715c,U+7266,U+7317,U+731d,U+7329,U+73e9,U+7425,U+7455,U+7490,U+7519,U+75b5,U+75b9,U+75de,U+7656,U+7691,U+7729,U+783e,U+787c,U+795a,U+7a79,U+7abf,U+7b3a,U+7b4f,U+7b8d,U+7bb4,U+7bd3,U+7be1,U+7cbc,U+7f1c,U+7f8c,U+7fce,U+7ff1,U+810d,U+82a5,U+82aa,U+82de,U+8343,U+835e,U+8364,U+836a,U+8543,U+854a,U+8559,U+8568,U+85b0,U+864f,U+86e4,U+8845,U+88f1,U+8c82,U+8d58,U+8dbe,U+9035,U+90a1,U+9149,U+9157,U+94c2,U+95eb,U+95f0,U+9611,U+9619,U+968d,U+9706,U+970f,U+97ed,U+98e8,U+9a77,U+9abc,U+9cdd,U+9e2f,U+9e33,U+9e44,U+9edd;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./dbbaa43240566ed3327dbd58a107499d.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e15,U+4e1e,U+4e2b,U+4eb3,U+4f0e,U+4f64,U+51a2,U+51bc,U+52d0,U+536f,U+53fd,U+5429,U+5494,U+54af,U+5506,U+5511,U+5522,U+55d2,U+55e6,U+55fd,U+561f,U+5639,U+5659,U+5662,U+5693,U+572a,U+5892,U+598a,U+5992,U+59a9,U+5a20,U+5a76,U+5ae3,U+5b7d,U+5d34,U+5e7a,U+5f0b,U+5f77,U+6026,U+6035,U+6063,U+60bb,U+60d8,U+60ee,U+61e6,U+61f5,U+620a,U+6248,U+62a1,U+62d7,U+6376,U+652b,U+65bc,U+65ce,U+65d6,U+6666,U+6684,U+66b9,U+6777,U+6787,U+67de,U+692d,U+693d,U+6994,U+6a35,U+6b59,U+6d54,U+6d8e,U+6dd6,U+6eb4,U+704f,U+7118,U+7172,U+71b9,U+724d,U+728a,U+733e,U+7396,U+73b7,U+73cf,U+7428,U+742c,U+742e,U+74ee,U+7525,U+753a,U+75d4,U+765c,U+768e,U+777d,U+7825,U+7837,U+78b4,U+79ed,U+7a1e,U+7b06,U+7cdc,U+7f30,U+7f42,U+7f94,U+8004,U+8019,U+80ae,U+80f1,U+8146,U+81c6,U+81fc,U+822b,U+830f,U+832f,U+8340,U+8365,U+8385,U+8717-8718,U+87fe,U+8902,U+8c12,U+8d32,U+8df7,U+8e7c,U+8f95,U+8fab,U+909d,U+919b,U+948e,U+9492,U+949a,U+94bc,U+94c6,U+94f1,U+9536,U+956f-9570,U+9642,U+988a,U+998b,U+99a5,U+9a9e;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./90b0b30dd473a12c1223a9a27b172477.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4f09,U+4f5a,U+4fce,U+4ff3,U+500f,U+504e,U+52be,U+5420,U+5457,U+5499,U+549b,U+54d2,U+558b,U+55bd,U+55d6,U+565c,U+57a9,U+57ed,U+59f9,U+5a11,U+5ae6,U+5b6a,U+5b93,U+5c15,U+5c99,U+5cc1,U+5d2e,U+5e54,U+5e61,U+5fcf,U+6006,U+6014,U+60c6,U+60da,U+60f4,U+621f,U+62c8,U+631b,U+631e,U+6619,U+6635,U+6641,U+67b0,U+67b7,U+684e,U+688f,U+695d,U+696b,U+6a58,U+6d35,U+6d9e,U+6dd9,U+6dec,U+6e11,U+6ee2,U+6f09,U+6f66,U+6f8d,U+6fc9,U+72c8,U+73de,U+7430,U+7566,U+7579,U+75c9,U+75e2,U+75fc,U+762a,U+77dc,U+7800,U+781d,U+782d,U+783c,U+78a3,U+78ec,U+7980,U+7b95,U+7c0c,U+7c41,U+7cc5,U+7ecc,U+8006,U+8069,U+807f,U+80bd,U+80ed,U+814b,U+8198,U+82cb,U+834f,U+8360,U+84d6,U+84e5,U+8537,U+8671,U+86a4,U+86f9,U+8737,U+873b,U+8815,U+8936,U+8bc3,U+8bcf,U+8be9,U+8c0c,U+8c0f,U+8c4c,U+8d45,U+8d73,U+8e31,U+8e76,U+8fe4,U+9041,U+90d7,U+9169,U+9485,U+94db,U+94e7,U+9503,U+9506,U+9528,U+9542,U+9549,U+95fe,U+96c9,U+96f3,U+970e,U+9739,U+9798,U+98d2,U+98d9,U+9968,U+996f,U+9984,U+9997,U+9acb,U+9e49,U+9e51,U+9e66;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./78a76b726311cf60ea36a32ae218c162.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4ec9,U+4f8f,U+4fdf,U+501c,U+50a9,U+510b,U+527d,U+542e,U+5486,U+54c6,U+552c,U+556c,U+55b3,U+567c,U+568e,U+5768,U+577b,U+5b17,U+5bb8,U+5d3d,U+5d4a,U+5d82,U+5e1a-5e1b,U+5ea5,U+5fd0-5fd1,U+5fd6,U+5fff,U+60af,U+60b4,U+612b,U+6194,U+61ca,U+637b,U+63e9,U+64b5,U+655d,U+65cc,U+664c,U+665f,U+6773,U+67e9,U+6845,U+69b7,U+6c26,U+6d43,U+6d5c,U+6e6e,U+6e9f,U+6ef9,U+6f2a,U+6f78,U+6fc2,U+70ec,U+714a,U+7337,U+74f4,U+7572,U+7638,U+7678,U+76c2,U+76f9,U+7762,U+77cd,U+77fd,U+795f,U+7b20,U+7ba9,U+7bab,U+7bdd,U+7c7c,U+7cb3,U+7cbd,U+7ec9,U+7ef6,U+7f19,U+7f44,U+7f54,U+800b,U+809b,U+80c4,U+816e,U+817c,U+81c0,U+81fe,U+8392,U+83a0,U+8424,U+84af,U+869c,U+86ce,U+8713,U+87c0,U+87cb,U+8821,U+89d1,U+8bb9,U+8bd8,U+8d53,U+8d5d,U+8f7c,U+9052,U+905b,U+9095,U+90c5,U+911e,U+9122,U+916a,U+92ae,U+94a1,U+94b5,U+94c4,U+94c9,U+9502,U+9511,U+9517,U+9537,U+9602,U+9616,U+9621,U+9631,U+9753,U+98da,U+9a81,U+9b03,U+9c85,U+9ca8,U+9cab,U+9ebe,U+9f10,U+9f8b;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./b1cb981fa650e1c31e6eaae60dd9e785.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4eeb,U+50f3,U+520d,U+52ad,U+530d,U+5310,U+53a9,U+5421,U+5477,U+54e7,U+551b,U+5530,U+557e,U+5599,U+5623,U+562d,U+5654,U+56eb,U+56f5,U+57d5,U+57dd,U+584d,U+5880,U+58ec,U+59dd,U+5a32,U+5a75,U+5b51,U+5b71,U+5b73,U+5cd2,U+5ce4,U+5e96,U+636d,U+63ff,U+661d,U+6657,U+66dc,U+6841,U+6b46,U+6c4a,U+6c94,U+6ca3,U+6d39,U+71e7,U+726f,U+72cd,U+72f0,U+7325,U+75b1,U+75b8,U+766f,U+7699,U+7751,U+789c,U+7be6,U+7cb2,U+7ea3,U+7eb0,U+7ebe,U+7eeb,U+7f25,U+7f2c,U+7fb8,U+8026,U+8037,U+8153,U+8171,U+8191,U+8214,U+821b,U+8222,U+826e,U+82eb,U+8314,U+8334,U+8418,U+84ff,U+8564,U+8616,U+8693,U+86a3,U+86af,U+86b6,U+86c6,U+86ca,U+870d,U+8759,U+8760,U+87af,U+87c6,U+8869,U+88c6,U+89d0,U+8b07,U+8baa,U+8be4,U+8bf0,U+8c2a,U+8d6d,U+8d84,U+8db8,U+8e3d,U+8e47,U+8e7f,U+9005,U+9051,U+9088,U+90b0,U+90d3,U+949c,U+94b9,U+94e0,U+9509,U+9512,U+9552,U+96b0,U+9791,U+9990,U+9a9c,U+9c88,U+9cb2,U+9cb6-9cb7,U+9cdc,U+9e22,U+9e2a,U+9e57,U+9e67,U+9e73,U+9e82,U+9eb8;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f2f83e9638f9a3de2598b11a105079b9.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4eb5,U+4f22,U+4f57,U+4f6f,U+4fea,U+4ff8,U+500c,U+507b,U+5088,U+5345,U+53fb,U+54a7,U+54cc,U+550f,U+5544,U+5594,U+559f,U+55e8,U+564e,U+5785,U+57ad,U+5914,U+59aa,U+5a06,U+5a40,U+5ae1,U+5b40,U+5c9c,U+5ccb,U+5d47,U+5d4b,U+5d99,U+6002,U+603f,U+6078,U+607d,U+607f,U+608c,U+60fa,U+621b,U+6414,U+6448,U+64d8,U+6710,U+6748,U+6772,U+67ad,U+680e,U+69ab,U+6c68,U+6c8f,U+6d2e,U+6e98,U+6ec2,U+6fe0,U+7094,U+70e9,U+7119,U+723f,U+729f,U+73c9,U+74e4,U+753e,U+7548,U+7634,U+76c5,U+76f1,U+7708,U+778c,U+77b3,U+7830,U+783b,U+7893,U+7949,U+79e3,U+7a14,U+7a23,U+7afd,U+7c91,U+7f04,U+7fca,U+8200,U+828d,U+82c4,U+82d2,U+82f7,U+832d,U+840b,U+8438,U+847a,U+852b,U+85d0,U+86f0,U+8703,U+8707,U+8782,U+87a8,U+87d2,U+87e0,U+8839,U+8913,U+891b,U+8941,U+8a07,U+8bcb,U+8bd2,U+8c11,U+8c29,U+8c32,U+8dfa,U+8e6d,U+9004,U+9036,U+90dc,U+91b4,U+938f,U+950f,U+9563,U+9573,U+961a,U+9649,U+9701,U+972d,U+97a3,U+97eb,U+988c,U+98a6,U+98d3,U+9977,U+997d,U+9e20,U+9e4c,U+9f3e;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./e596f434776f7ec1bcb9f7a530586c42.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4ee8,U+4f43,U+4f5d,U+4fda,U+502d,U+5106,U+53df,U+544b,U+5482,U+5555,U+55c4,U+55d1,U+55df,U+55e4,U+55ea,U+55ec,U+55ef,U+56f9,U+5704,U+576d,U+5958,U+599e,U+59be,U+5abe,U+5bee,U+5cbf,U+5cc4,U+607b,U+609a,U+61d1,U+61ff,U+622e,U+626a,U+6371,U+63ae,U+63cd,U+63d6,U+6410,U+6421,U+64c0,U+67a5,U+6954,U+6a47,U+6ca4,U+6d94,U+6e4e,U+705e,U+7116,U+72de,U+75bd,U+75c2,U+75cd,U+7618,U+7719,U+777e,U+7791,U+7823,U+7827,U+7889,U+795c,U+7a88,U+7a95,U+7aa0,U+7b90,U+7bd1,U+7bfe,U+7da6,U+7ec2,U+7eef,U+7f03,U+7f08,U+7f58,U+7f61,U+7f9f,U+8174,U+82d5,U+82dc,U+835a,U+866c,U+869d,U+86a7,U+86ac,U+86d0,U+87d1,U+8934,U+89ca,U+89ce,U+8ba3,U+8bab,U+8bc5,U+8bdb,U+8c15,U+8dc4,U+8dc6,U+8dce,U+8ddb,U+8e09,U+8e1d,U+8e39,U+8e42,U+8e49,U+8e4b,U+8e8f,U+8f71-8f72,U+9097,U+90e2,U+90e6,U+90ef,U+9104,U+9150,U+919a,U+9497,U+951f,U+9534,U+9557,U+9562,U+9606,U+965f,U+973e,U+9889,U+9894,U+9974,U+9a90,U+9a9d,U+9aa7,U+9aef,U+9ca2,U+9cc5,U+9ccd,U+9cdf,U+9e6b,U+9f9b;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./a57da1cdeea21168f2335afda9758d32.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4edd-4ede,U+4ee1,U+4ffe,U+5025,U+506c,U+50a5,U+51fc,U+5241,U+525c,U+539d,U+53fc,U+5514,U+556e,U+55b9,U+55d4,U+5671,U+568f,U+56d7,U+57a1,U+57a7,U+57d9,U+5942,U+5a55,U+5c98,U+5d06,U+5d6f,U+5dfd,U+5e5b,U+5fd2,U+600f,U+606b,U+62bb,U+62bf,U+634b,U+6387,U+63b3,U+63b8,U+63c4,U+63c6,U+63f6,U+6441,U+6555,U+66a7,U+6849,U+6867,U+6901,U+699b,U+6a28,U+6a79,U+6b92,U+6c21,U+6c24,U+6c29,U+6c32,U+6c35,U+6c86,U+6cc5,U+6d63,U+6d6f,U+6ddd,U+6ed3,U+6edf,U+6fb9,U+6fd1,U+6fef,U+712f,U+7350,U+7391,U+73c8,U+73d9,U+741a,U+757f,U+7583,U+759d,U+75b4,U+75f1,U+75ff,U+76e5,U+78b2,U+7a17,U+7b08,U+7b33,U+7c2a,U+8012,U+8297,U+82be,U+830c,U+8331,U+83c5,U+83d6,U+83fd,U+843c,U+8473,U+84a1,U+8572,U+85c1,U+86aa,U+8708-8709,U+89c7,U+8a3e,U+8bc2,U+8c49,U+8c62,U+8c89,U+8d49,U+8d94,U+8df9,U+8e2e,U+8e51,U+9016,U+907d,U+9082,U+914a,U+9495,U+94a3-94a4,U+94ba,U+94cb-94cc,U+94e1,U+9553,U+955d,U+9697,U+96bc,U+975b,U+998a,U+9995,U+9cb3,U+9ccf,U+9edc,U+9edf-9ee0,U+9f83,U+9f89;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./5a1ba92e12d0fe3831808f67f4274350.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4ebb,U+4fa9,U+502c,U+51c7,U+523d,U+530f,U+53f5,U+5464,U+549d,U+54a3,U+5527,U+555c,U+5576,U+55b1,U+55eb,U+5624,U+564c,U+5685,U+56e1,U+5a67,U+5c50,U+5c7a,U+5d27,U+5df3,U+5e19,U+5ea0,U+5eb9,U+5eea,U+5ffe,U+6215,U+622c,U+6266,U+6308,U+659b,U+6677,U+6775,U+678b,U+679e,U+6840,U+6860,U+68c2,U+6910,U+6a2f,U+6b93,U+6bc2,U+6bfd,U+6d0c,U+6d60,U+6da0,U+6e0c,U+6e86,U+7023,U+7080,U+70ca,U+7145,U+7284,U+732c,U+740a,U+7441,U+7457,U+7596,U+75a3,U+75d8,U+75e3-75e4,U+7622,U+7688,U+76b4,U+7818,U+7887,U+789a,U+7a51,U+7ccc,U+7ea8,U+7ec0,U+7efa,U+7fa7,U+7fe6,U+8084,U+8093,U+80e4,U+80ef,U+81bb,U+82a8,U+82e3,U+8366,U+84ca,U+84d1,U+853b,U+857b,U+85d3,U+8605,U+8662,U+86b1,U+86d4,U+86ed,U+86f3,U+8748,U+874c,U+8763,U+879f,U+89de,U+89e5,U+8ba6,U+8c00,U+8c21,U+8c7a,U+8d30,U+8e59,U+8f6b,U+8f73,U+8ff3,U+9026,U+902f,U+9099,U+909b,U+90c7,U+91ae,U+91ba,U+94af,U+94bf,U+94f0,U+9531,U+95f3,U+977c,U+9880,U+98a2,U+9994,U+9a9b,U+9ab7,U+9ac5,U+9c91,U+9cd5,U+9e29,U+9f88;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./457fbc00017c3c6bc5077c69e8c19b56.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e93,U+4ec4,U+4ef5,U+4f27,U+4f7b,U+4fe3,U+5080,U+5121,U+51eb,U+5208,U+52f0,U+5453,U+5466,U+54bf,U+54d0,U+5549,U+556d,U+558f,U+55f2,U+55f5,U+571c,U+5739,U+57b4,U+5807,U+58c5,U+59a4,U+59af,U+59d8,U+5a09,U+5a0c,U+5a4a,U+5ad2,U+5b6c,U+5ca2,U+5d03,U+5d6c,U+5ebe,U+5fea,U+6042,U+6120,U+6175,U+6221,U+6339,U+643d,U+64e2,U+67b3,U+6832,U+68f0,U+6934,U+6966,U+6987,U+6998,U+69ce,U+6a3d,U+6a84,U+6aa9,U+6b87,U+6bd6,U+6c16,U+6cd4,U+6ecf,U+6f4b,U+70b7,U+7168,U+72d9,U+7352,U+74d2,U+75e8,U+7610,U+765e,U+772d,U+7812,U+784c,U+7850,U+789b,U+78f4,U+7b15,U+7b1e,U+7b24,U+7b5a,U+7bb8,U+7bc1,U+7bd9,U+7ee6,U+7f1b,U+7f22,U+7f71,U+7ff3,U+80b1,U+80f4,U+81ec,U+8202,U+8210,U+828a,U+828e,U+8369,U+83a8,U+83b4,U+83e1,U+84fc,U+85ae,U+86b4,U+86c9,U+86cf,U+8725,U+87b3,U+887d,U+88fe,U+8a8a,U+8c07,U+8c14,U+8c47,U+8dd7,U+8e69,U+8e70,U+8e85,U+8f87,U+9143,U+948f,U+94d2,U+9609-960a,U+9765,U+97ea,U+996c,U+9980,U+9991,U+9afb,U+9c87,U+9cb5,U+9cc7,U+9e42,U+9e58,U+9ecd;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./7d086de1237c84603d93d398e35c466c.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e47,U+4e8d,U+4edf,U+4f89,U+50ee,U+520e,U+54c2,U+5591,U+55e5,U+560c,U+5769,U+578c,U+5793,U+57e4,U+5889,U+593c,U+59ab,U+5ad8,U+5af1,U+5b53,U+5edb,U+5fbc,U+6004,U+6041,U+60ad,U+61b7,U+647a,U+64ba,U+65c6,U+65f0,U+6600,U+66f7,U+67e2,U+6874,U+691f,U+6989,U+6a17,U+6b81,U+6c3d,U+6d91,U+6e6b,U+6fde,U+70bb,U+723b,U+72b0,U+7301,U+731e,U+74ff,U+7633,U+771a,U+7768,U+781c,U+7829,U+7957,U+79eb,U+7a70,U+7b25,U+7b47,U+7b72,U+7ba6,U+7dae,U+7efe,U+7f26,U+7f31,U+7f35,U+801c,U+81cc,U+8244,U+824f,U+82a1,U+82b4,U+8306,U+833a,U+839c,U+83b3,U+83bc,U+8734,U+87ca,U+886e,U+887e,U+8921,U+8c04,U+8c1d,U+8c25,U+8c2f,U+8c36,U+8c55,U+8d3d,U+8d59,U+8d67,U+8dbf,U+8dec,U+8dfd,U+8e41,U+900b,U+9044,U+9062,U+90cf,U+9123,U+9146,U+9172,U+918d,U+93ca,U+948c,U+94aa,U+94b2,U+94c8,U+94df,U+94e9,U+94f7,U+94fc,U+951b,U+954f,U+9554,U+9571,U+960f,U+97af,U+98d5,U+992e,U+9955,U+9ab0,U+9ac2,U+9c90,U+9c9e,U+9cad,U+9cc3,U+9e47,U+9ee7;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./cc285ba582b59b1ba86b434535c44005.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4f65,U+5261,U+52ac,U+5416,U+5454,U+5478,U+54bb,U+54d3,U+54de,U+566b,U+572e,U+57ef,U+581e,U+5997,U+59a3,U+5ad4,U+5ba5,U+5c59,U+5c63,U+5d5b,U+5e0f,U+5e14,U+5ed1,U+5f40,U+6019,U+610e,U+624c,U+634c,U+65d2,U+670a,U+67f0,U+680c,U+686b,U+696e,U+6980,U+6b84,U+6bf3,U+6c06-6c07,U+6d07,U+6d27,U+6d2b,U+6e8f,U+726e,U+72ce,U+72f2,U+7322,U+737e,U+7477,U+748e,U+755a,U+7594,U+75b3,U+7654,U+7726,U+7765,U+7817,U+78d4,U+78d9,U+7913,U+79d5,U+7a86,U+7b38,U+7ba7,U+7c9d,U+7ee1,U+7f17,U+7f36,U+7fee,U+8043,U+809f,U+80ab,U+80cd,U+80d7,U+8118,U+8188,U+823e,U+8298,U+82c1,U+82d8,U+82e4,U+82f4,U+835c,U+83d4,U+8401,U+8451,U+846d,U+84ba,U+851f,U+8556,U+8585,U+867a,U+868b,U+868d,U+8765,U+8872,U+88a2,U+88c9,U+8bb5,U+8bce,U+8bf3,U+8c17,U+8c73,U+8c78,U+8d40,U+8d91,U+8deb,U+8e14,U+8f8e,U+9011,U+9162,U+9185,U+918c,U+9190,U+92c8,U+94ca,U+94d5,U+94ea,U+94f3,U+94fd,U+951d,U+9559,U+9566,U+9572,U+95f1,U+9608,U+9878,U+988f,U+98d1,U+9ac1,U+9acc,U+9b32,U+9b49,U+9ca5,U+9ca9,U+9cb1,U+9f87;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./886b1740138a809bee8428352a048ac9.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4f25,U+4f32,U+5250,U+535f,U+54a6,U+54d4,U+5533,U+5541,U+5550,U+5556,U+55dd,U+5627,U+5657-5658,U+5664,U+567b,U+56d4,U+57b2,U+5a62,U+5aeb,U+5c3b,U+5cac,U+5d9d,U+5db7,U+5f2d,U+5fb5,U+5ff8,U+6222,U+623e,U+638a,U+63e0,U+6485,U+64b8,U+66e9,U+67c1,U+67d2,U+67fd,U+6877,U+6924,U+69c1,U+6a97,U+6b8d,U+6baa,U+6c18,U+6ceb,U+6cee,U+6d04,U+6d4d,U+6de0,U+6eb1,U+7110,U+71f9,U+728d,U+73b3,U+73d0,U+7511,U+75a5,U+75c8,U+75e7,U+7619,U+782c,U+7856,U+7877,U+7a39,U+7ae6,U+7b0f,U+7baa,U+7ed0,U+7f0d,U+7f1f,U+7f45,U+7fbf,U+7fe5,U+8052,U+80db,U+80fc,U+8132,U+816d,U+8228,U+8249,U+82cc,U+8315,U+8378,U+83aa,U+83ea,U+8538,U+859c,U+8722,U+876e,U+87e5,U+8ba7,U+8bcc,U+8bdf,U+8be8,U+8c30,U+8d36,U+8db5,U+8e1f,U+8e2f,U+8f78,U+8f8b,U+8f8f,U+8f9a,U+90ba,U+90c4,U+90d0,U+90fe,U+917d,U+9487,U+94cd,U+94ef,U+9507,U+954a,U+9604,U+9615,U+96b9,U+96d2,U+9708,U+989e,U+98a7,U+9a88,U+9ab6,U+9b47,U+9c9b,U+9cd9,U+9e2c,U+9e5a,U+9e5e,U+9ecf,U+9f8a,U+9f8c;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./820348cb559f8c7719ccc8076ec33b1f.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e5c,U+4f5e,U+4f76,U+4faa,U+4fe6,U+5028,U+5048,U+538d,U+53c1,U+5412,U+5443,U+54dd,U+5577,U+55f3,U+560f,U+562c,U+56af,U+575c,U+577c,U+57da,U+5800,U+5ca3,U+5d26,U+5f01,U+5fdd,U+6029,U+6079,U+60b1,U+629f,U+6332,U+63bc,U+65ab,U+65c3,U+668c,U+669d,U+66be,U+6800,U+68fc,U+690b,U+6978,U+69a7,U+6a3e,U+6a50,U+6a5b,U+6b24,U+6c10,U+6c54,U+6ebd,U+71b3,U+7230,U+7292,U+72b8,U+72d2,U+7360,U+73a2,U+75a0,U+779f,U+7826,U+7aa8,U+7b04,U+7bac,U+7c1f,U+7ccd,U+7ecb,U+7ed4,U+7ed7,U+7efb,U+7f5f,U+7faf,U+7fd5,U+8027,U+80bc,U+80dd,U+815a,U+8167,U+81ca,U+82a9,U+82ab,U+8351,U+8368,U+83b8,U+83d8,U+83f0,U+8497,U+84c1,U+858f,U+85ff,U+867b,U+86a8-86a9,U+870a,U+877c,U+8888,U+88df,U+8919,U+8bee,U+8c20,U+8df8,U+8e05,U+9021,U+908b,U+90b4,U+90eb,U+91aa,U+933e,U+9486,U+948d,U+9490,U+94ad,U+94bd,U+94d6,U+94d9,U+9546,U+955e,U+956b,U+95e9,U+960b,U+9612,U+9617,U+989a-989b,U+9a78,U+9a7d,U+9aa0,U+9aa2,U+9b23,U+9b3b,U+9c82,U+9cca,U+9e28,U+9e6c,U+9efe,U+9f0b;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./7794c87d03a9b38e411760e6153c5cec.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+5240,U+531a,U+5363,U+5432,U+5452,U+5456,U+5472,U+553f,U+5581,U+55cc,U+55fe,U+5601,U+57d2,U+5924,U+5981,U+5ab8,U+5b34,U+5d5d,U+5def,U+5e11,U+5e91,U+5ef4,U+600d,U+605a,U+6092,U+60ab,U+6217,U+623d,U+6369,U+6753,U+67a7,U+6855,U+68f9,U+6939,U+6a7c,U+6aab,U+6b82,U+6bf9,U+6c19-6c1a,U+6ca9,U+6cf6,U+6d1a,U+6f74,U+7085,U+7198,U+71b5,U+7256,U+725d,U+727e,U+72fa,U+738e,U+760c,U+7615,U+7630,U+763f,U+77ec,U+78a1,U+7b2a,U+7b2e,U+7b62,U+7c26,U+7c9c,U+7e47,U+7e9b,U+7e9f,U+7ee8,U+7ef1,U+7f01,U+7f11,U+7f7e,U+802a,U+8112,U+8169,U+8234,U+8279,U+82ca,U+82e1,U+83c0,U+83df,U+845a,U+8476,U+8478,U+84e0,U+8548,U+86e9,U+86f4,U+86f8,U+8785,U+87ab,U+87ee,U+8832,U+88b7,U+88e2-88e3,U+8bd3,U+8bd6,U+8bf9,U+8c16,U+8d5c,U+8dde,U+8f6d,U+8f94,U+8fe8,U+915e,U+94d1,U+9515,U+9558,U+9567,U+96ce,U+96e9,U+9785,U+987c,U+9954,U+9963,U+9a93,U+9b1f,U+9b4d,U+9ca7,U+9cae,U+9cce,U+9cd3,U+9e37,U+9e46,U+9f22,U+9f2f,U+9f39;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./e823294c4fd884b53bea85ae68a1b73a.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e69,U+4f67,U+50a7,U+50e6,U+5196,U+5202,U+54ad,U+5537,U+5575,U+561e,U+56df,U+5709,U+580d,U+59b2,U+5aaa,U+5d24,U+5d74,U+5e42,U+5eb3,U+5ed2,U+5fad,U+6003,U+601b,U+603c,U+6100,U+6206,U+62ca,U+646d,U+6535,U+65c4,U+6661,U+6715,U+6861,U+69ca,U+69ff,U+6c05,U+6c0d,U+6cb2,U+6cd6,U+6cf7,U+6cfa,U+6dab,U+6e54,U+6fb6,U+709d,U+72ad,U+72fb,U+73e5,U+74ba,U+750f,U+754b,U+75ac,U+75d6,U+7617,U+76b2,U+775a,U+781f,U+7905,U+79b3,U+7b2b,U+7b3e,U+7b85,U+7b9c,U+7bcc,U+7bea,U+7c74,U+7cc1,U+7ea5,U+7ee0,U+7f27,U+7fb0,U+8031,U+8071,U+8160,U+81a6,U+81c1,U+82fb,U+836c,U+83f8,U+841c,U+8489,U+848c,U+84bd,U+8627,U+8629,U+872e,U+877d,U+8813,U+8816,U+88ce,U+897b,U+89da,U+8bfc,U+8c35,U+8d46,U+8d4d,U+8dba,U+8f75,U+9179,U+917e,U+94ab,U+94d7,U+94e5,U+952a,U+952c,U+9545,U+961d,U+96e0,U+9883,U+989f,U+990d,U+9993,U+9ac0,U+9aeb,U+9b51,U+9c8b,U+9e39,U+9e41,U+9e48,U+9ee9,U+9f17,U+9f19,U+9f80,U+9f85;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./45735d1436b5dcaac7cb90e2d61a3d95.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+80d9;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./bb512bf59c2d16134881adf3755db48a.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4f1b,U+4f7e,U+4fdc,U+51ab,U+5233,U+523f,U+52a2,U+536e,U+5476,U+54cf,U+56dd,U+572c,U+576b,U+57cf,U+57f8,U+5881,U+589a,U+5941,U+5b5b,U+5c25,U+5d6b,U+5e8b,U+5f58,U+5f73,U+6083,U+6126,U+638e,U+63b4,U+6426,U+66db,U+6769,U+6798,U+67c3,U+698d,U+69b1,U+69e0,U+69ed,U+69f2,U+6a18,U+6b39,U+6bb3,U+6d33,U+6e16,U+6e53,U+6ebb,U+6ed7,U+7178,U+7228,U+72b4,U+72f7,U+7313,U+739f,U+7481,U+7540,U+755b,U+758b,U+7635,U+7640,U+76a4,U+77bd,U+7b31,U+7b6e,U+7c0b,U+7c9e,U+7ce8,U+7f21,U+7f74,U+7fbc,U+8016,U+80ea,U+8114,U+825f,U+829f,U+82a4,U+8308,U+831a,U+8333,U+83b6,U+840f,U+8411,U+855e,U+85a4,U+866e,U+86b5,U+8731,U+877b,U+87ea,U+8864,U+88e5,U+890a,U+892b,U+89cb,U+89ef,U+89f3,U+8c18,U+8e3a,U+8f7e,U+8fb6,U+8fd3,U+90be,U+9161,U+91a3,U+94ac,U+953a,U+9565,U+9568,U+956a,U+972a,U+9730,U+97b4,U+98e7,U+9967,U+9aa3,U+9ae1,U+9aed,U+9af9,U+9c86,U+9ca0-9ca1,U+9ca3,U+9ce2,U+9e2b,U+9e6a,U+9e87,U+9ee2,U+9f2c;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./7c06550f062e8b268f0fa1eec067763b.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4ef3,U+50d6,U+50ec,U+51b1,U+54a9,U+54da,U+55be,U+55cd,U+564d,U+57d8,U+57fd,U+5844,U+59d2,U+5ae0,U+5b37,U+5b80,U+5d1e,U+5efe,U+5f11,U+5f56,U+5f8c,U+5fe4,U+602b,U+6106,U+610d,U+641b,U+6634,U+676a,U+681d,U+6883,U+6b37,U+6b9b,U+6d7c,U+6f36,U+6f72,U+6f8c,U+7035,U+7173,U+728f,U+72ef,U+7331,U+74e0,U+75e6,U+763c,U+76cd,U+7704,U+7743,U+7780,U+7847,U+786a,U+7962,U+7a02,U+7b4c,U+7b7b,U+7bfc,U+7c0f,U+7c16,U+7cc7,U+7cf8,U+7e3b,U+7ea9,U+7ef2,U+7f02,U+7f07,U+7f0c,U+7f23,U+8020,U+812c,U+8136,U+8182,U+8268,U+8284,U+8288,U+8291,U+8311,U+835b,U+836d,U+845c,U+84b4,U+850c,U+863c,U+86ba,U+86c4,U+86de,U+86f1,U+873e,U+87db,U+880a,U+883c,U+887f,U+88f0,U+8a48,U+8bdc,U+8c94,U+8dcf,U+8de3,U+8e6f,U+8e90,U+902d,U+91af,U+936a,U+948b,U+9513,U+956c,U+9654,U+9688,U+9aba,U+9b08,U+9c8e,U+9c94,U+9c9a,U+9e36,U+9e4b,U+9e55,U+9e63,U+9e68-9e69,U+9ebd,U+9ec9,U+9f0d,U+9f37,U+9f51;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./270fa7de2b70e942ccbe9bb2861b1c64.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4f74,U+507e,U+50ed,U+5182,U+525e,U+5282,U+52d6,U+5326,U+537a,U+5459,U+54b4,U+54d9,U+572f,U+574c,U+5925,U+5a08,U+5b16,U+5b84,U+5cb5,U+5cbd,U+5e31,U+5e5e,U+5f82,U+5fc4,U+5fc9,U+600a,U+605d,U+609d,U+60dd,U+6243,U+6322,U+63de,U+63f8,U+643f,U+64e4,U+656b,U+67b5,U+680a,U+69d4,U+6c2a,U+6c46,U+6d0e,U+6eb7,U+6f89,U+7039,U+7113,U+71d4,U+727f,U+72f4,U+74de,U+75c3,U+7605,U+760a,U+761b,U+7625,U+7643,U+764d,U+7747,U+77b5,U+78a5,U+78b9,U+7a03,U+7a06,U+7aac-7aad,U+7ab3,U+7b0a,U+7be5,U+7c40,U+7ca2,U+7d77,U+7ea1,U+7f0b,U+7f2f,U+7f9d,U+80e8,U+811e,U+822f,U+8233,U+823b,U+827d,U+828f,U+82c8,U+831b,U+83a9,U+83dd,U+8406,U+84b9,U+84e3,U+864d,U+867f,U+8770,U+87ac-87ad,U+87bd,U+88bc,U+895e,U+89cf,U+89d6,U+8bd4,U+8c2b,U+8c33,U+8db1,U+8dd6,U+8f7a,U+8f82,U+90db,U+9164,U+91ad,U+94d8,U+950d,U+9514,U+9529,U+963c,U+966c,U+96b3,U+97b2,U+996b,U+9987,U+9998,U+9a75,U+9a7a,U+9a92,U+9adf,U+9b43,U+9cb4,U+9cbd,U+9cd4,U+9e31,U+9e4e,U+9e71;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./2a5d863becaa0cccb8c2d24db517b184.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e36,U+4ea0,U+4f91,U+4f94,U+4fc5,U+50ba,U+5181,U+51f5,U+52f9,U+53a3,U+5423,U+55c9,U+57f4,U+580b,U+5902,U+5ab5,U+5be4,U+5c22,U+5c6e,U+5d3e,U+5ddb,U+5e80,U+5ee8,U+5fed,U+609b,U+63ce,U+640b-640c,U+6445,U+64d7,U+6534,U+6549,U+6603,U+674c,U+67a8,U+6864,U+6971,U+6a65,U+6c5c,U+6d48,U+6e2b,U+6eb2,U+706c,U+70b1,U+72f3,U+7303,U+7321,U+7339,U+736c,U+736f,U+74a9,U+750d,U+7513,U+7592,U+75c4,U+762d,U+7707,U+7735,U+7839,U+784e,U+7924,U+793b,U+798a,U+7a78,U+7a80,U+7ba8,U+7cc8,U+7ec1,U+7f0f,U+7f12,U+7f68,U+8025,U+809c,U+80ad,U+80b7,U+8204,U+8221,U+8223,U+822d,U+824b,U+825a,U+827f,U+8307,U+8347,U+837d,U+839b,U+83f9,U+8487,U+8579,U+86b0,U+86d1,U+86d8,U+86f2,U+8764,U+8788,U+8797,U+87b5,U+881b,U+8844,U+88fc,U+8930,U+8ba0,U+8c02,U+8c85,U+8e23,U+8e94,U+8f81,U+8fd5,U+90b6,U+914e,U+943e,U+94b7-94b8,U+94eb,U+9516,U+9518,U+9538,U+953f,U+954e,U+955f,U+9561,U+95fc,U+9667,U+9792,U+98a1,U+9969,U+9a80,U+9a96,U+9b2f,U+9cd0,U+9e3a,U+9ee5,U+9eea,U+9ef9,U+9fa0;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./cedf2e6885d1ed1d932a95c9191bf581.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e0c,U+4e28,U+4e3f,U+4ec2,U+502e,U+522d,U+5290,U+5369,U+54d5,U+54dc,U+54ff,U+552a,U+5588,U+55b5,U+5686,U+5776,U+5786,U+57a4,U+5820,U+5865,U+58bc,U+5b32,U+5b65,U+5c1c,U+5c66,U+5f2a,U+5f50,U+6067,U+619d,U+61d4,U+620b,U+6224-6225,U+6343,U+63f2,U+6496,U+64d0,U+6509,U+652e,U+6833,U+6844,U+684a,U+6957,U+6a91,U+6bea,U+6c15,U+6cd0,U+6f24,U+6f2d,U+70c0,U+721d,U+728b,U+72c3,U+730a,U+7338,U+734d,U+752f,U+770d,U+77a2,U+786d,U+791e,U+7953,U+7b58,U+7bda,U+7f33,U+8022,U+8028-8029,U+8035,U+804d,U+8080,U+80e9,U+80ec,U+80f2,U+8274,U+82b0,U+82e0,U+83b0,U+8488,U+848e,U+8539,U+857a,U+85a8,U+867c,U+871e,U+8803,U+88d2,U+89cc,U+8c8a,U+8c98,U+8dbc,U+8e40,U+8f77,U+8f79,U+9058,U+91a2,U+91b5,U+9494,U+94b6,U+94de,U+94f9,U+950a,U+950e,U+951e,U+952b,U+953e,U+9544,U+9564,U+9569,U+95f6,U+9603,U+960d,U+963d,U+9674,U+9794,U+97ab,U+9ab1,U+9ad1,U+9b0f,U+9c92,U+9c95,U+9cbc,U+9cc6,U+9cd8,U+9e38,U+9e5b,U+9e7e,U+9efb-9efc;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./dfd30be672d8ddb3201427f15a72b708.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4e2c,U+5107,U+5155,U+5216,U+5281,U+5293,U+53b6,U+54f3,U+5523,U+553c,U+570a,U+5819,U+5adc,U+5c88,U+5c8d,U+5e3b,U+5f61,U+5fee,U+614a,U+615d,U+62f6,U+63ad,U+63be,U+6420,U+6434,U+6484,U+6499,U+67d9,U+67dd,U+6920,U+6a8e,U+6aa0,U+6b43,U+6bf5,U+6d5e,U+6ee0,U+6f46,U+717a,U+71e0,U+72c1,U+72e8,U+73e7,U+746d,U+754e,U+75b0,U+7603,U+7722,U+778d,U+77e7,U+7809,U+7811,U+7857,U+78c9,U+78f2,U+7946,U+7967,U+799a,U+7b45,U+7b9d,U+7ba2,U+7cd7,U+7f32,U+8014,U+80c2,U+810e,U+8159,U+817d,U+81a3,U+81aa,U+8201,U+833c,U+836e,U+83e5,U+8459,U+84cd,U+84d0,U+84f0,U+85b7,U+8723,U+8729,U+8753,U+877e,U+878b,U+8793,U+87d3,U+8966,U+89dc,U+89eb,U+8b26,U+8bf6,U+8c2e,U+8d33,U+8d47,U+8d55,U+8e2c,U+8e9c,U+8e9e,U+8ece,U+8fee,U+9139,U+914f,U+9174,U+9191,U+928e,U+94f4,U+953c,U+960c,U+9622,U+98a5,U+9a98,U+9a9f,U+9b48,U+9ca6,U+9cb0,U+9cba,U+9ccb,U+9e32,U+9e88,U+9eb4,U+9f3d,U+9f44,U+9f86;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./c6667233716658fa0dbc73bd2b495fd8.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3457-34dc;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./94bd530f8362620df8a1c7b5efe6c167.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+34dd-3574;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./fe4c26c187c534d42971e22d33c17da5.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3607-3680;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./4369125c6e5b9ea4e97d3baa7744822a.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3575-3606;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./4160dc66fd11da77a365d3bbe4342583.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2f86-2fd5,U+2ff0-2ffb,U+3000,U+3003,U+3005-3007,U+3012-3013,U+301c-3029,U+3036,U+3041-3096,U+3099-30a3,U+30ab,U+30ad,U+30af-30b0,U+30b3,U+30b7-30b8,U+30bb,U+30bf,U+30c3-30c4,U+30c8-30c9,U+30d0-30d1,U+30d3,U+30d5,U+30d8,U+30da-30db,U+30dd-30e1,U+30e4,U+30e7,U+30e9-30eb,U+30ed,U+30ef,U+30f3,U+30fc,U+3300,U+3303,U+330d,U+3314-3316,U+3318,U+331e,U+3326,U+332b,U+3331,U+3333,U+3336,U+3339,U+333b,U+3342,U+3347,U+3349-334a,U+334d-334e,U+3351,U+3357;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./169f4f99a8fdeee67ee5b11d3d7dde83.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+32-33,U+61,U+63,U+69,U+6c-6e,U+30a4-30aa,U+30ac,U+30ae,U+30b1-30b2,U+30b4-30b6,U+30b9-30be,U+30c0-30c2,U+30c4-30c8,U+30ca-30cf,U+30d2,U+30d4,U+30d6-30d7,U+30d9,U+30dc,U+30e2-30e3,U+30e5-30e6,U+30e8,U+30ec,U+30ee,U+30f0-30fb,U+30fd-30ff,U+3105-312c,U+31c2,U+322a-3243,U+3291-3294,U+3296,U+3298-3299,U+329d-329e,U+32a4-32b0,U+3305,U+3322-3323,U+3327,U+332a,U+337b-337f,U+3388,U+339d,U+33a0,U+33a4,U+33b2,U+33c4,U+33cc,U+3400-3456,U+ff12-ff13,U+ff41,U+ff43,U+ff49,U+ff4c-ff4e;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./0d95a02759d9df150bff724cb36e88eb.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3681-3710;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./9042d36debaaa3e8d9b7a11fabeb788e.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3711-378e;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./764be394f53ebc94389d667f465678ff.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+301-302,U+306-307,U+30a-30c,U+328,U+336,U+361,U+37e,U+384-38a,U+38c,U+38e-3a1,U+3a3-3ce,U+401,U+410-44f,U+451,U+456,U+45e,U+20dd,U+2103,U+2109-210a,U+212b,U+2160-216b,U+2170-217b,U+217f,U+21c4-21c6,U+21d2,U+21d4,U+21e6-21e9,U+2200,U+2202-2203,U+2207-2208,U+220b,U+2211,U+221a,U+221d-2220,U+2227-222e,U+2234-2235,U+223d,U+2252,U+2260-2261,U+2266-2267,U+226a-226b,U+2282-2283,U+2286-2287,U+22a5,U+22bf,U+2460-2490,U+249c-24b5,U+24ea,U+2500-254b,U+2550,U+255e,U+2561,U+256a,U+256d-2573,U+2581-258f,U+2594-2595,U+25a0-25a2,U+25b2-25b3,U+25b7,U+25bc-25bd,U+25c1,U+25c6-25c7,U+25c9,U+25cb,U+25ce-25cf,U+25e2-25e5,U+2600-2603,U+2605-2606,U+260e,U+261c-261f,U+262d,U+2640,U+2642,U+2660-2667,U+266a,U+266d,U+266f,U+2742,U+2776-277e,U+27a1,U+2f00-2f85;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f4c485cfefd2ad15ebabb39b5a1b9c78.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+378f-381d;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./263e0a6c7976a49c8eccdb600f3121fb.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+38a2-393c;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./b8de6ca9ab7eeb46da2d596e7857e45c.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+381e-38a1;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./819527e43ae287dfa0a1ae1d7fc3468b.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+393d-39b0;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f78a9f59d1b3e234c1390c0bc16591ca.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3a43-3aad;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./975548a97d429ecd7a3f1336d83f9f69.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+39b1-3a42;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./4b74fc6b8bd483943381108f6ae6a4cd.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3b36-3bbb;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f9668cffed02a0e769b4a4186ca64842.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3aae-3b35;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./b3c9cec7d6175f09c60c45c22f892d8a.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3bbc-3c19;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./1d6349be1bb6a451524927867ebc8018.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3c1a-3c8e;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./4546e28619305e686f38a06eca3ed24b.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3d24-3d8d;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./9eda14e324e4cb2dbf93a46cda2030ac.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3c8f-3d23;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f733fc95f0299561a8852c9a6358ef0a.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3d8e-3e01;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./2c702e56c185508826f5b9a19238b8fc.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3e02-3e84;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./dbb7d7bfff09231822a0cdd75917749e.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3e85-3efe;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./539703d9e049a9c94bf4782ec1fb247a.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3eff-3f6c;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./697c040e25a3fc2688b71e3bfcf88f52.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+406a-40df;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./263c388e32cd3f91195f5d524c8988e8.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3fe4-4069;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./36f9bceefc4f1faf26b0225a523d8487.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+40e0-4156;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./36fb80c3d9eab70099df34c809e83f9e.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+3f6d-3fe3;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f625418dac5cfd735455b447c1919372.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4300-435e;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./6717568e8413481e5449365ca6ee592c.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+41ca-423b;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./3d4942ef90db80683fc9aaf782431916.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4299-42ff;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./8c3ba5a28da6529f623773fcf4173215.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+423c-4298;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./e0bc3e0173174932b2c2cafbf4c8237b.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+435f-43cd;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f2598380f9c3c2d01735cafc07913c27.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4157-41c9;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./5026ba5738056794f43e06f5da66f954.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+43ce-4446;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./04af5e87ff980de7d438ecd5bd27c340.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4543-459c;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f993831b926e5aa4d805fc5f557a8fd8.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+44c8-4542;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f40eaa8caf5ca135aae414d86de260e9.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4447-44c7;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./526c2a95a86ccf84a280675be2a681aa.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4608-466e;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./ceae1981b75a9957d55a14ad709bfe65.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+459d-4607;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./07fb1deac66988e5649d0881c0e17fcf.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+466f-46e1;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./8ab2d54225faa08e518b2dec6accca47.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+46e2-4748;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./5d6750bd808c1a9dc300c117d6fb7cd3.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+47b9-4824;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./59710c1c064d6ad797c9991ea749dc78.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4889-490c;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./a8e9cf25149b435697f511a4c4ea9044.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4825-4888;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./d5d045fddf5f5efd0bce7b867b2dd19c.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+490d-4965;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./d179be5b0e7e1dee33a711e09732d606.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4749-47b8;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./8b63e5a07cdd054df7b5f7152960cff7.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4966-49d9;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./3864c57f99f5b74c2fa2c276019a76b4.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+49da-4a3c;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./d654c090936a645920369898def4f9c4.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4b61-4bba;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./1cad3067687a1ab23fb3412076a2fa09.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4b01-4b60;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./0da2c5a0303424c7bad3c5f35e794a42.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4a3d-4aa0;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./8df6fbc4da366e72453bdef895820e6a.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4aa1-4b00;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./422662fcf88bd041d995c99f3566812c.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4c70-4cc0;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./b3235a87593ff73a68a81b5335841059.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4bbb-4c16;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./1bed682ed25fcd4c66feca30a2021529.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4c17-4c6f;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./2eaab391380ce55302a2ac29b253690f.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4cc1-4d10;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./5cda44d174242ca12a1286a3eccbc2aa.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4d66-4db2;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./5c2dfb29d27057607fa22dda5cf483fe.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4d11-4d65;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./99bce9bd5fbdfee141c119fc2254de74.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4fbc-4fbe,U+4fc0-4fc2,U+4fc6-4fc9,U+4fcb-4fcd,U+4fd2-4fd6,U+4fd9,U+4fdb,U+4fe0,U+4fe2,U+4fe4-4fe5,U+4fe7,U+4feb-4fec,U+4ff0,U+4ff2,U+4ff4-4ff7,U+4ff9,U+4ffb-4ffd,U+4fff-500b,U+500e,U+5010-5011,U+5013,U+5015-5017,U+501b,U+501d-501e,U+5020,U+5022-5024,U+5027,U+502b,U+502f-5039,U+503b,U+503d,U+503f-5042,U+5044-5046,U+5049-504b,U+504d,U+5050-5054,U+5056-5059,U+505b,U+505d-5064,U+5066-506b,U+506d-5075,U+5079-507a,U+507c-507d,U+5081-5084,U+5086-5087,U+5089-508c,U+508e-509c;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./bf26bef9f2169291d35426314d4c5c90.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+4db3-4dbf,U+4e02,U+4e04-4e06,U+4e0f,U+4e12,U+4e17,U+4e1f-4e21,U+4e23,U+4e26,U+4e29,U+4e2e-4e2f,U+4e31,U+4e33,U+4e35,U+4e37,U+4e3c,U+4e40-4e42,U+4e44,U+4e46,U+4e4a,U+4e51,U+4e55,U+4e57,U+4e5a-4e5b,U+4e62-4e65,U+4e67-4e68,U+4e6a-4e6f,U+4e72,U+4e74-4e7d,U+4e7f-4e85,U+4e87,U+4e8a,U+4e90,U+4e96-4e97,U+4e99,U+4e9c-4e9e,U+4ea3,U+4eaa,U+4eaf-4eb1,U+4eb4,U+4eb6-4eb9,U+4ebc-4ebe,U+4ec8,U+4ecc,U+4ecf-4ed0,U+4ed2,U+4eda-4edc,U+4ee0,U+4ee2,U+4ee6-4ee7,U+4ee9,U+4eed-4eef,U+4ef1,U+4ef4,U+4ef8-4efa,U+4efc,U+4efe,U+4f00,U+4f02-4f08,U+4f0b-4f0c,U+4f12-4f16,U+4f1c-4f1d,U+4f21,U+4f23,U+4f28-4f29,U+4f2c-4f2e,U+4f31,U+4f33,U+4f35,U+4f37,U+4f39,U+4f3b,U+4f3e-4f42,U+4f44-4f45,U+4f47-4f4c,U+4f52,U+4f54,U+4f56,U+4f61-4f62,U+4f66,U+4f68,U+4f6a-4f6b,U+4f6d-4f6e,U+4f71-4f72,U+4f75,U+4f77-4f7a,U+4f7d,U+4f80-4f82,U+4f85-4f87,U+4f8a,U+4f8c,U+4f8e,U+4f90,U+4f92-4f93,U+4f95-4f96,U+4f98-4f9a,U+4f9c,U+4f9e-4f9f,U+4fa1-4fa2,U+4fa4,U+4fab,U+4fad,U+4fb0-4fb4,U+4fb6-4fbb;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./e309f47569069c96cb8c1866b8b93956.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+5129-513e,U+5142,U+5147,U+514a,U+514c,U+514e-5150,U+5152-5153,U+5157-5159,U+515b,U+515d-5161,U+5163-5164,U+5166-5167,U+5169-516a,U+516f,U+5172,U+517a,U+517e-517f,U+5183-5184,U+5186-5187,U+518a-518b,U+518e-5191,U+5193-5194,U+5198,U+519a,U+519d-519f,U+51a1,U+51a3,U+51a6-51aa,U+51ad-51ae,U+51b4,U+51b8-51ba,U+51be-51bf,U+51c1-51c3,U+51c5,U+51c8,U+51ca,U+51cd-51ce,U+51d0,U+51d2-51da,U+51dc,U+51de-51df,U+51e2-51e3,U+51e5-51ea,U+51ec,U+51ee,U+51f1-51f2,U+51f4,U+51f7,U+51fe,U+5204-5205,U+5209,U+520c,U+520f-5210,U+5213-5215,U+521c,U+521e-521f,U+5221-5223,U+5225-5227,U+522a,U+522c,U+522f,U+5231-5232,U+5234-5235,U+523c,U+523e,U+5244-5249,U+524b,U+524e-524f,U+5252-5253,U+5255,U+5257-5258;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./4be251b9930b74d521a6b352ba740af6.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+509d-50a2,U+50a4,U+50a6,U+50aa-50ab,U+50ad-50b1,U+50b3-50b9,U+50bc-50ce,U+50d0-50d5,U+50d7-50d9,U+50db-50e5,U+50e8-50eb,U+50ef-50f2,U+50f4,U+50f6-50fa,U+50fc-5105,U+5108-510a,U+510c-5111,U+5113-5120,U+5122-5128;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./2784ddb2745ca496ed6111c6f1ccb232.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+5259-525b,U+525d,U+525f-5260,U+5262-5264,U+5266,U+5268,U+526b-526e,U+5270-5271,U+5273-527c,U+527e,U+5280,U+5283-5287,U+5289-528f,U+5291-5292,U+5294-529a,U+529c,U+52a4-52a7,U+52ae-52b0,U+52b4-52bd,U+52c0-52c2,U+52c4-52c6,U+52c8,U+52ca,U+52cc-52cf,U+52d1,U+52d3-52d5,U+52d7,U+52d9-52de,U+52e0-52e3,U+52e5-52ef,U+52f1-52f8,U+52fb-52fd,U+5301-5304,U+5307,U+5309-530c,U+530e,U+5311-5314;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./24d222999cbfe483a38df4ed8ec4bae6.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+54a5,U+54ae,U+54b0,U+54b2,U+54b5-54b7,U+54b9-54ba,U+54bc,U+54be,U+54c3,U+54c5,U+54ca-54cb,U+54d6,U+54d8,U+54db,U+54e0-54e4,U+54eb-54ec,U+54ef-54f1,U+54f4-54f9,U+54fb,U+54fe,U+5500,U+5502-5505,U+5508,U+550a-550e,U+5512-5513,U+5515-551a,U+551c-551f,U+5521,U+5525-5526,U+5528-5529,U+552b,U+552d,U+5532,U+5534-5536,U+5538-553b,U+553d,U+5540,U+5542,U+5545,U+5547-5548,U+554b-554f,U+5551-5554,U+5557-555b,U+555d-5560,U+5562-5563,U+5568-5569,U+556b,U+556f-5574,U+5579-557a,U+557d,U+557f,U+5585-5586,U+558c-558e,U+5590,U+5592-5593,U+5595-5597,U+559a-559b,U+559e,U+55a0-55a6,U+55a8-55b0,U+55b2,U+55b4,U+55b6,U+55b8,U+55ba,U+55bc,U+55bf-55c3,U+55c6-55c8,U+55ca-55cb,U+55ce-55d0;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./70648ec49cc971bf41b678fa555cec7d.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+5318,U+531b-531c,U+531e-531f,U+5322,U+5324-5325,U+5327-5329,U+532b-532d,U+532f-5338,U+533c-533d,U+5340,U+5342,U+5344,U+5346,U+534b-534d,U+5350,U+5354,U+5358-5359,U+535b,U+535d,U+5365,U+5368,U+536a,U+536c-536d,U+5372,U+5376,U+5379,U+537b-537e,U+5380-5381,U+5383,U+5387-5388,U+538a,U+538e-5394,U+5396-5397,U+5399,U+539b-539c,U+539e,U+53a0-53a1,U+53a4,U+53a7,U+53aa-53ad,U+53af-53b5,U+53b7-53ba,U+53bc-53be,U+53c0,U+53c3-53c7,U+53ce-53d0,U+53d2-53d3,U+53d5,U+53da,U+53dc-53de,U+53e1-53e2,U+53e7,U+53f4,U+53fa,U+53fe-5400,U+5402,U+5405,U+5407,U+540b,U+5414,U+5418-541a,U+541c,U+5422,U+5424-5425,U+542a,U+5430,U+5433,U+5436-5437,U+543a,U+543d,U+543f,U+5441-5442,U+5444-5445,U+5447,U+5449,U+544c-544f,U+5451,U+545a,U+545d-5461,U+5463,U+5465,U+5467,U+5469-5470,U+5474,U+5479-547a,U+547e-547f,U+5481,U+5483,U+5485,U+5487-548a,U+548d,U+5491,U+5493,U+5497-5498,U+549c,U+549e-54a2;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./31fb8a876d4c5f001e7c5284adc1d0d7.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+55d5,U+55d7-55db,U+55de,U+55e0,U+55e2,U+55e7,U+55e9,U+55ed-55ee,U+55f0-55f1,U+55f4,U+55f6,U+55f8-55fc,U+55ff,U+5602-5607,U+560a-560b,U+560d,U+5610-5617,U+5619-561a,U+561c-561d,U+5620-5622,U+5625-5626,U+5628-562b,U+562e-5630,U+5633,U+5635,U+5637-5638,U+563a,U+563c-563e,U+5640-564b,U+564f-5653,U+5655-5656,U+565a-565b,U+565d-5661,U+5663,U+5665-5667,U+566d-5670,U+5672-5675,U+5677-567a,U+567d-5684,U+5687-5688;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./88667bcab9ca0351694ac5c6f4a83b50.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+5689-568d,U+5690-5692,U+5694-56a2,U+56a4-56ae,U+56b0-56b6,U+56b8-56bb,U+56bd-56c9,U+56cb-56d3,U+56d5-56d6,U+56d8-56d9,U+56dc,U+56e3,U+56e5-56ea,U+56ec,U+56ee-56ef,U+56f2-56f3,U+56f6-56f8,U+56fb-56fc,U+5700-5702,U+5705,U+5707,U+570b-5719;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./244bfb4ffe3e2e31492098884505a62b.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+582b-582f,U+5831-5834,U+5836-5843,U+5845-584b,U+584e-5850,U+5852-5853,U+5855-5857,U+5859-585d,U+585f-5864,U+5866-586a,U+586d-587d,U+587f,U+5882,U+5884,U+5886-5888,U+588a-5891,U+5894-5898,U+589b-589d,U+58a0-58a7,U+58aa-58bb,U+58bd;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./b54b527649f7696f25da498450b6522d.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+571a-571b,U+571d-571e,U+5720-5722,U+5724-5727,U+572b,U+5731-5732,U+5734-5738,U+573c-573d,U+573f,U+5741,U+5743-5746,U+5748-5749,U+574b,U+5752-5756,U+5758-5759,U+5762-5763,U+5765,U+5767,U+576c,U+576e,U+5770-5772,U+5774-5775,U+5778-577a,U+577d-5781,U+5787-578a,U+578d-5791,U+5794-579a,U+579c-579f,U+57a5,U+57a8,U+57aa,U+57ac,U+57af-57b1,U+57b3,U+57b5-57b7,U+57b9-57c1,U+57c4-57ca,U+57cc-57cd,U+57d0-57d1,U+57d3,U+57d6-57d7,U+57db-57dc,U+57de,U+57e1-57e3,U+57e5-57ec,U+57ee,U+57f0-57f3,U+57f5-57f7,U+57fb-57fc,U+57fe-57ff,U+5801,U+5803-5805,U+5808-580a,U+580c,U+580e-5810,U+5812-5814,U+5816-5818,U+581a-581d,U+581f,U+5822-5823,U+5825-5829;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./64719bcabfc0e0c4a2594fd82416b7f5.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+58be-58c0,U+58c2-58c4,U+58c6-58d0,U+58d2-58d4,U+58d6-58e3,U+58e5-58ea,U+58ed,U+58ef,U+58f1-58f2,U+58f4-58f5,U+58f7-58f8,U+58fa-5901,U+5903,U+5905-5906,U+5908-590c,U+590e,U+5910-5913,U+5917-5918,U+591b,U+591d-591e,U+5920-5923,U+5926,U+5928,U+592c,U+5930,U+5932-5933,U+5935-5936,U+593b,U+593d-5940,U+5943,U+5945-5946,U+594a,U+594c-594d,U+5950,U+5952-5953,U+5959,U+595b-595f,U+5961,U+5963-5964,U+5966-5972,U+5975;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./ede31a685d784b090a5ee83c7384954b.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+5a61,U+5a63-5a66,U+5a68-5a69,U+5a6b-5a73,U+5a78-5a79,U+5a7b-5a7e,U+5a80-5a91,U+5a93-5a99,U+5a9c-5aa9,U+5aab-5ab1,U+5ab4,U+5ab6-5ab7,U+5ab9-5abd,U+5abf-5ac0,U+5ac3-5ac8,U+5aca-5acb,U+5acd-5ad1,U+5ad3,U+5ad5,U+5ad7,U+5ad9-5adb,U+5add-5adf,U+5ae2,U+5ae4-5ae5,U+5ae7-5ae8,U+5aea,U+5aec-5af0,U+5af2-5af9;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./7e7a7e04cf615edbbd6711400f35bf8d.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+5977,U+597a-597c,U+597e-5980,U+5985,U+5989,U+598b-598c,U+598e-5991,U+5994-5995,U+5998,U+599a-599d,U+599f-59a2,U+59a6-59a7,U+59ac-59ad,U+59b0-59b1,U+59b3-59b8,U+59ba,U+59bc-59bd,U+59bf-59c5,U+59c7-59c9,U+59cc-59cf,U+59d5-59d6,U+59d9,U+59db,U+59de-59e2,U+59e4,U+59e6-59e7,U+59e9-59eb,U+59ed-59f8,U+59fa,U+59fc-59fe,U+5a00,U+5a02,U+5a0a-5a0b,U+5a0d-5a10,U+5a12,U+5a14-5a17,U+5a19-5a1b,U+5a1d-5a1e,U+5a21-5a22,U+5a24,U+5a26-5a28,U+5a2a-5a30,U+5a33,U+5a35,U+5a37-5a3b,U+5a3d-5a3f,U+5a41-5a45,U+5a47-5a48,U+5a4b-5a54,U+5a56-5a59,U+5a5b-5a60;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./e71cea0c44ded3ddd7707a6e5f48c0c5.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+5afa-5b08,U+5b0a-5b15,U+5b18-5b31,U+5b33,U+5b35-5b36,U+5b38-5b3f,U+5b41-5b4f,U+5b52,U+5b56,U+5b5e,U+5b60-5b61,U+5b67-5b68,U+5b6b,U+5b6d-5b6f,U+5b72,U+5b74,U+5b76-5b79;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./6d22e7282f23016e8b5419743093e766.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+5dd6-5dda,U+5ddc,U+5ddf-5de0,U+5de3-5de4,U+5dea,U+5dec-5ded,U+5df0,U+5df5-5df6,U+5df8-5dfc,U+5dff-5e00,U+5e04,U+5e07,U+5e09-5e0b,U+5e0d-5e0e,U+5e12-5e13,U+5e17,U+5e1e-5e25,U+5e28-5e2c,U+5e2f-5e30,U+5e32-5e36,U+5e39-5e3a,U+5e3e-5e41,U+5e43,U+5e46-5e4b,U+5e4d-5e53,U+5e56-5e5a,U+5e5c-5e5d,U+5e5f-5e60,U+5e63-5e71,U+5e75,U+5e77,U+5e79,U+5e7e,U+5e81-5e83,U+5e85,U+5e88-5e89,U+5e8c-5e8e,U+5e92,U+5e98,U+5e9b,U+5e9d,U+5ea1-5ea4,U+5ea8-5eac,U+5eae-5eb2,U+5eb4,U+5eba-5ebd,U+5ebf-5ec8,U+5ecb;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./27f63df8be47b9dd8317c052b74e239e.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+5d66-5d68,U+5d6a,U+5d6d-5d6e,U+5d70-5d73,U+5d75-5d81,U+5d83-5d98,U+5d9a-5d9c,U+5d9e-5db6,U+5db8-5dc4,U+5dc6-5dcc,U+5dce-5dd5;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f0c6bdcecaf4a103f1fc897945f3a367.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+5ecc-5ed0,U+5ed4-5ed5,U+5ed7-5eda,U+5edc-5ee7,U+5ee9,U+5eeb-5ef3,U+5ef5,U+5ef8-5ef9,U+5efb-5efd,U+5f05-5f07,U+5f09,U+5f0c-5f0e,U+5f10,U+5f12,U+5f14,U+5f16,U+5f19-5f1a,U+5f1c-5f1e,U+5f21-5f24,U+5f28,U+5f2b-5f2c,U+5f2e,U+5f30,U+5f32-5f38,U+5f3b,U+5f3d-5f3f,U+5f41-5f4f,U+5f51,U+5f54,U+5f59-5f5c,U+5f5e-5f60,U+5f63,U+5f65,U+5f67-5f68,U+5f6b,U+5f6e-5f6f,U+5f72,U+5f74-5f76,U+5f78,U+5f7a,U+5f7d-5f7f,U+5f83,U+5f86,U+5f8d-5f8f,U+5f91,U+5f93-5f94,U+5f96,U+5f9a-5f9b,U+5f9d;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./749487ce022ff82b0d9a5ac986a0d0dd.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+5c9d-5ca1,U+5ca4-5ca8,U+5caa,U+5cae-5cb0,U+5cb2,U+5cb4,U+5cb6,U+5cb9-5cbc,U+5cbe,U+5cc0,U+5cc2-5cc3,U+5cc5-5cca,U+5ccc-5cd1,U+5cd3-5cd8,U+5cda-5ce0,U+5ce2-5ce3,U+5ce7,U+5ce9,U+5ceb-5cec,U+5cee-5cef,U+5cf1-5cfa,U+5cfc-5d01,U+5d04-5d05,U+5d08-5d0d,U+5d0f-5d13,U+5d15,U+5d17-5d1a,U+5d1c-5d1d,U+5d1f-5d23,U+5d25,U+5d28,U+5d2a-5d2c,U+5d2f-5d33,U+5d35-5d3c,U+5d3f-5d46,U+5d48-5d49,U+5d4d-5d57,U+5d59-5d5a,U+5d5c,U+5d5e-5d65;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./4a7c58425d7db15cead7f4bd0bcfc493.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+5b7b-5b7c,U+5b7e-5b7f,U+5b82,U+5b86,U+5b8a,U+5b8d-5b8e,U+5b90-5b92,U+5b94,U+5b96,U+5b9f,U+5ba7-5ba9,U+5bac-5baf,U+5bb1-5bb2,U+5bb7,U+5bba-5bbc,U+5bc0-5bc1,U+5bc3,U+5bc8-5bcb,U+5bcd-5bcf,U+5bd1,U+5bd4-5bdc,U+5be0,U+5be2-5be3,U+5be6-5be7,U+5be9-5bed,U+5bef,U+5bf1-5bf7,U+5bfd-5bfe,U+5c00,U+5c02-5c03,U+5c05,U+5c07-5c08,U+5c0b-5c0e,U+5c10,U+5c12-5c13,U+5c17,U+5c19,U+5c1b,U+5c1e-5c21,U+5c23,U+5c26,U+5c28-5c2b,U+5c2d-5c30,U+5c32-5c33,U+5c35-5c37,U+5c43-5c44,U+5c46-5c47,U+5c4c-5c4d,U+5c52-5c54,U+5c56-5c58,U+5c5a-5c5d,U+5c5f,U+5c62,U+5c64,U+5c67-5c6d,U+5c70,U+5c72-5c78,U+5c7b-5c7e,U+5c80,U+5c83-5c87,U+5c89-5c8b,U+5c8e-5c8f,U+5c92-5c93,U+5c95;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./b4a1a3c969ea836e1979339b097a08b9.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+5f9e-5fa0,U+5fa2-5fa7,U+5fa9,U+5fab-5fac,U+5faf-5fb4,U+5fb6,U+5fb8-5fbb,U+5fbe-5fc2,U+5fc7-5fc8,U+5fca-5fcb,U+5fce,U+5fd3-5fd5,U+5fda-5fdc,U+5fde-5fdf,U+5fe2-5fe3,U+5fe5-5fe6,U+5fe8-5fe9,U+5fec,U+5fef-5ff0,U+5ff2-5ff4,U+5ff6-5ff7,U+5ff9-5ffa,U+5ffc,U+6007-6009,U+600b-600c,U+6010-6011,U+6013,U+6017-6018,U+601a,U+601e-601f,U+6022-6024,U+602c-602e,U+6030-6034,U+6036-603a,U+603d-603e,U+6040,U+6044-604a,U+604c,U+604e-604f,U+6051,U+6053-6054,U+6056-6058,U+605b-605c,U+605e-6061,U+6065-6066,U+606e,U+6071-6072,U+6074-6075,U+6077,U+607e,U+6080-6082,U+6085-6088,U+608a-608b,U+608e-6091,U+6093,U+6095,U+6097-6099,U+609c,U+609e,U+60a1-60a2,U+60a4-60a5,U+60a7,U+60a9-60aa,U+60ae,U+60b0,U+60b3,U+60b5-60b7,U+60b9-60ba,U+60bd-60c0;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./4a6794698df5a126b0d3d28b73349ede.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+60c1-60c4,U+60c7-60c9,U+60cc-60d0,U+60d2-60d4,U+60d6-60d7,U+60d9,U+60db,U+60de,U+60e1-60e5,U+60ea,U+60f1-60f2,U+60f5,U+60f7-60f8,U+60fb-60ff,U+6102-6105,U+6107,U+610a-610c,U+6110-6114,U+6116-6119,U+611b-611e,U+6121-6122,U+6125,U+6128-612a,U+612c-613e,U+6140-6147,U+6149,U+614b,U+614d,U+614f-6150,U+6152-6154,U+6156-615c,U+615e-6161,U+6163-6166,U+6169-616f,U+6171-6174;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./6e2b5b1231b5d4ea7c27ffa00bfa09d5.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+6176,U+6178-618a,U+618c-618d,U+618f-6193,U+6195-619c,U+619e-61a6,U+61aa-61ab,U+61ad-61b6,U+61b8-61bd,U+61bf-61c1,U+61c3-61c7,U+61c9,U+61cc-61d0,U+61d3,U+61d5-61e5,U+61e7-61ef;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f5a84a3f77bb101d9454cc55fc018d06.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+6329,U+632c-632e,U+6330-6331,U+6333-6338,U+633b-633c,U+633e-6341,U+6344,U+6347-6348,U+634a,U+6351-6354,U+6356-635d,U+6360,U+6364-6366,U+6368,U+636a-636c,U+636f-6370,U+6372-6375,U+6378-6379,U+637c-637f,U+6381,U+6383-6386,U+638b,U+638d,U+6391,U+6393-6395,U+6397,U+6399-639f,U+63a1,U+63a4,U+63a6,U+63ab,U+63af,U+63b1-63b2,U+63b5-63b6,U+63b9,U+63bb,U+63bd,U+63bf-63c3,U+63c5,U+63c7-63c8,U+63ca-63cc,U+63d1,U+63d3-63d5,U+63d7-63dd,U+63df,U+63e2,U+63e4-63e8,U+63eb-63ec,U+63ee-63f1,U+63f3,U+63f5,U+63f7,U+63f9-63fc,U+63fe,U+6403-6404,U+6406-640a,U+640d-640e,U+6411-6412,U+6415-6416;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./7b09119c00b19b9ba594537985b9ab46.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+64b6,U+64b9,U+64bb,U+64bd-64bf,U+64c1,U+64c3-64c4,U+64c6-64cc,U+64cf,U+64d1,U+64d3-64d6,U+64d9-64dd,U+64df-64e1,U+64e3,U+64e5,U+64e7-64ff,U+6501-6508,U+650a-6511,U+6513-6517,U+6519-6524;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./1a5c81564287ee2fd9f0837d17c5c973.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+6417-641a,U+641d,U+641f,U+6422-6425,U+6427-6429,U+642b,U+642e-6433,U+6435-6439,U+643b-643c,U+643e,U+6440,U+6442-6443,U+6449,U+644b-6451,U+6453,U+6455-6457,U+6459-645d,U+645f-6466,U+6468,U+646a-646c,U+646e-6477,U+647b-6481,U+6483,U+6486,U+6488-6490,U+6493-6494,U+6497-6498,U+649a-649d,U+649f-64a3,U+64a5-64a8,U+64aa-64ab,U+64af,U+64b1-64b4;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./b846336461bf88c1657cf6b41d9bcb79.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+61f0-61f4,U+61f6-61fe,U+6200-6205,U+6207,U+6209,U+6213-6214,U+6219,U+621c-621e,U+6220,U+6223,U+6226-6229,U+622b,U+622d,U+622f-6232,U+6235-6236,U+6238-623c,U+6242,U+6244-6246,U+624a,U+624f-6250,U+6255-6257,U+6259-625a,U+625c-6262,U+6264-6265,U+6268,U+6271-6272,U+6274-6275,U+6277-6278,U+627a-627b,U+627d,U+6281-6283,U+6285-6288,U+628b-6290,U+6294,U+6299,U+629c-629e,U+62a3,U+62a6-62a7,U+62a9-62aa,U+62ad-62b0,U+62b2-62b4,U+62b6-62b8,U+62ba,U+62be,U+62c0-62c1,U+62c3,U+62cb,U+62cf,U+62d1,U+62d5,U+62dd-62de,U+62e0-62e1,U+62e4,U+62ea-62eb,U+62f0,U+62f2,U+62f5,U+62f8-62fb,U+6300,U+6303-6306,U+630a-630d,U+630f-6310,U+6312-6315,U+6317-6319,U+631c,U+6326-6327;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./feeef3b550cea170bbd763a8047e3eb7.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+6526-652a,U+652c-652d,U+6530-6533,U+6537,U+653a,U+653c-653d,U+6540-6544,U+6546-6547,U+654a-654b,U+654d-654e,U+6550,U+6552-6554,U+6557-6558,U+655a,U+655c,U+655f-6561,U+6564-6565,U+6567-656a,U+656d-656f,U+6571,U+6573,U+6575-6576,U+6578-6586,U+6588-658a,U+658d-658f,U+6592,U+6594-6596,U+6598,U+659a,U+659d-659e,U+65a0,U+65a2-65a3,U+65a6,U+65a8,U+65aa,U+65ac,U+65ae,U+65b1-65b8,U+65ba-65bb,U+65be-65c0,U+65c2,U+65c7-65ca,U+65cd,U+65d0-65d1,U+65d3-65d5,U+65d8-65dd;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./76e3f3aef2827b08aae6f394d85add31.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+65de-65df,U+65e1,U+65e3-65e4,U+65ea-65eb,U+65f2-65f5,U+65f8-65f9,U+65fb-65ff,U+6601,U+6604-6605,U+6607-6609,U+660b,U+660d,U+6610-6612,U+6616-6618,U+661a-661c,U+661e,U+6621-6624,U+6626,U+6629-662c,U+662e,U+6630,U+6632-6633,U+6637-663b,U+663d,U+663f-6640,U+6642,U+6644-664a,U+664d-664e,U+6650-6651,U+6658-6659,U+665b-665e,U+6660,U+6662-6663,U+6665,U+6667,U+6669-666d,U+6671-6673,U+6675,U+6678-6679,U+667b-667d,U+667f-6681,U+6683,U+6685-6686,U+6688-668b,U+668d-6690,U+6692-6695,U+6698-669c,U+669e-66a6,U+66a9-66ad,U+66af-66b3,U+66b5-66b8,U+66ba-66bd,U+66bf-66c5;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./2c9f8946fde881049365a5be2689f0dc.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+6a7d-6a7f,U+6a81-6a83,U+6a85-6a8d,U+6a8f,U+6a92-6a96,U+6a98-6a9f,U+6aa1-6aa8,U+6aaa,U+6aad-6ae1;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./ecef4b15f4fcb491755f4c11dc508a4d.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+68b7-68bf,U+68c1,U+68c3-68c8,U+68ca,U+68cc,U+68ce-68d1,U+68d3-68d4,U+68d6-68d7,U+68d9,U+68db-68df,U+68e1-68e2,U+68e4-68ed,U+68ef,U+68f2-68f4,U+68f6-68f8,U+68fb,U+68fd-6900,U+6902-6904,U+6906-690a,U+690c,U+690f,U+6911,U+6913-691e,U+6921-6923,U+6925-692c,U+692e-692f,U+6931-6933,U+6935-6938,U+693a-693c,U+693e,U+6940-6941,U+6943-6953,U+6955-6956,U+6958-6959,U+695b-695c,U+695f,U+6961;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./9be0750881659b7ce4265dea53575f47.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+69fe,U+6a00-6a09,U+6a0b-6a16,U+6a19-6a1e,U+6a20,U+6a22-6a27,U+6a29,U+6a2b-6a2e,U+6a30,U+6a32-6a34,U+6a36-6a3c,U+6a3f-6a43,U+6a45-6a46,U+6a48-6a4f,U+6a51-6a57,U+6a5a,U+6a5c-6a60,U+6a62-6a64,U+6a66-6a70,U+6a72-6a78,U+6a7a-6a7b;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f60e8cfcbf69e03242ec113effcd7d78.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+66c6-66d8,U+66da,U+66de-66e5,U+66e7-66e8,U+66ea-66ef,U+66f1,U+66f5-66f6,U+66f8,U+66fa-66fb,U+66fd,U+6701-6707,U+670c,U+670e-670f,U+6711-6713,U+6716,U+6718-671a,U+671c,U+671e,U+6720-6725,U+6727,U+6729,U+672e,U+6730,U+6732-6733,U+6736-6739,U+673b-673c,U+673e-673f,U+6741,U+6744-6745,U+6747,U+674a-674b,U+674d,U+6752,U+6754-6755,U+6757-675b,U+675d,U+6762-6764,U+6766-6767,U+676b-676c,U+676e,U+6771,U+6774,U+6776,U+6778-677b,U+677d,U+6780,U+6782-6783,U+6785-6786,U+6788,U+678a,U+678c-678f,U+6791-6794,U+6796,U+6799,U+679b,U+679f-67a1,U+67a4,U+67a6,U+67a9,U+67ac,U+67ae,U+67b1;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./a3504b182e371c81802eb109d8996c1b.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+6962,U+6964-6965,U+6967-696a,U+696c-696d,U+696f-6970,U+6972-6976,U+697a-697b,U+697d-697f,U+6981,U+6983,U+6985,U+698a-698c,U+698e-6993,U+6996-6997,U+6999-699a,U+699d-69a6,U+69a9-69aa,U+69ac,U+69ae-69b0,U+69b2-69b3,U+69b5-69b6,U+69b8-69ba,U+69bc-69c0,U+69c2-69c9,U+69cb,U+69cd,U+69cf,U+69d1-69d3,U+69d5-69da,U+69dc-69de,U+69e1-69ec,U+69ee-69f1,U+69f3-69fc;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./6eb2f02f829b787f465a8517825f3eba.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+67b2,U+67b4,U+67b9-67c0,U+67c2,U+67c5-67ce,U+67d5-67d7,U+67db,U+67df,U+67e1,U+67e3-67e4,U+67e6-67e8,U+67ea-67eb,U+67ed-67ee,U+67f2,U+67f5-67fc,U+67fe,U+6801-6804,U+6806,U+680d,U+6810,U+6812,U+6814-6815,U+6818-681c,U+681e-6820,U+6822-6828,U+682b-6831,U+6834-6836,U+683a-683b,U+683f,U+6847,U+684b,U+684d,U+684f,U+6852,U+6856-685f,U+686a,U+686c-6873,U+6875,U+6878-6880,U+6882,U+6884,U+6887-688e,U+6890-6892,U+6894-6896,U+6898-68a1,U+68a3-68a5,U+68a9-68ac,U+68ae,U+68b1-68b2,U+68b4,U+68b6;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./1116eb565264ffd5037c526374325cef.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+6ae2-6b1f,U+6b25-6b26,U+6b28-6b31,U+6b33-6b36,U+6b38,U+6b3b-6b3d,U+6b3f-6b42,U+6b44;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./feb1b7bf15bd73a835c3a6b589d9bd51.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+6b45,U+6b48,U+6b4a-6b4b,U+6b4d-6b58,U+6b5a-6b61,U+6b68-6b69,U+6b6b-6b78,U+6b7a,U+6b7d-6b80,U+6b85,U+6b88,U+6b8c,U+6b8e-6b91,U+6b94-6b95,U+6b97-6b99,U+6b9c-6ba0,U+6ba2-6ba9,U+6bab-6bb2,U+6bb6,U+6bb8-6bbe,U+6bc0,U+6bc3-6bc4,U+6bc6-6bca,U+6bcc,U+6bce,U+6bd0-6bd1,U+6bd8,U+6bda,U+6bdc-6be0,U+6be2-6be9,U+6bec-6bee,U+6bf0;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./35ff910b480811685fa302e09c748d7e.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+6e42,U+6e45-6e4c,U+6e4f-6e52,U+6e55,U+6e57,U+6e59-6e5a,U+6e5c-6e5e,U+6e60-6e6a,U+6e6c-6e6d,U+6e6f-6e7d,U+6e80-6e82,U+6e84,U+6e87-6e88,U+6e8a-6e8e,U+6e91-6e97,U+6e99-6e9b,U+6e9d-6e9e,U+6ea0-6ea1,U+6ea3-6ea4,U+6ea6,U+6ea8-6ea9,U+6eab-6eae,U+6eb0,U+6eb3,U+6eb5,U+6eb8-6eb9,U+6ebc,U+6ebe-6ec0,U+6ec3-6ec6,U+6ec8-6eca,U+6ecc-6ece,U+6ed0,U+6ed2,U+6ed6,U+6ed8-6ed9,U+6edb-6edd,U+6ee3,U+6ee7,U+6eea-6ef3,U+6ef5-6ef8,U+6efa;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./ae0fb565a406dda097c2184dd88fc27a.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+6bf1-6bf2,U+6bf4,U+6bf6-6bf8,U+6bfa-6bfc,U+6bfe-6c04,U+6c08-6c0c,U+6c0e,U+6c12,U+6c17,U+6c1c-6c1e,U+6c20,U+6c23,U+6c25,U+6c2b-6c2d,U+6c31,U+6c33,U+6c36-6c37,U+6c39-6c3c,U+6c3e-6c3f,U+6c43-6c45,U+6c48,U+6c4b-6c4f,U+6c51-6c53,U+6c56,U+6c58-6c5a,U+6c62-6c63,U+6c65-6c67,U+6c6b-6c6f,U+6c71,U+6c73,U+6c75,U+6c77-6c78,U+6c7a-6c7c,U+6c7f-6c80,U+6c84,U+6c87,U+6c8a-6c8b,U+6c8d-6c8e,U+6c91-6c92,U+6c95-6c98,U+6c9a,U+6c9c-6c9e,U+6ca0,U+6ca2,U+6ca8,U+6cac,U+6caf-6cb0,U+6cb4-6cb7,U+6cba,U+6cc0-6cc3,U+6cc6-6cc8,U+6ccb,U+6ccd-6ccf,U+6cd1-6cd2,U+6cd8-6cda,U+6cdc-6cdd,U+6cdf,U+6ce4,U+6ce6-6ce7,U+6ce9,U+6cec-6ced,U+6cf2,U+6cf4,U+6cf9,U+6cff-6d00,U+6d02-6d03,U+6d05-6d06,U+6d08-6d0a,U+6d0d,U+6d0f-6d11,U+6d13-6d16,U+6d18,U+6d1c-6d1d,U+6d1f-6d24,U+6d26,U+6d28-6d29,U+6d2c-6d2d,U+6d2f-6d30,U+6d34,U+6d36-6d38,U+6d3a;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./39405b3292990e4f7a1f294810919529.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+6f91-6f9b,U+6f9d-6fa0,U+6fa2-6fa6,U+6fa8-6fb2,U+6fb4-6fb5,U+6fb7-6fb8,U+6fba-6fbf,U+6fc1,U+6fc3-6fc8,U+6fca-6fd0,U+6fd3-6fdd,U+6fdf,U+6fe2-6fed,U+6ff0-7003;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./0e24a0f900723835e894806427aaa732.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+6d3f-6d40,U+6d42,U+6d44,U+6d49,U+6d4c,U+6d50,U+6d55-6d58,U+6d5b,U+6d5d,U+6d5f,U+6d61-6d62,U+6d64-6d65,U+6d67-6d68,U+6d6b-6d6d,U+6d70-6d73,U+6d75-6d76,U+6d79-6d7b,U+6d7d-6d81,U+6d83-6d84,U+6d86-6d87,U+6d8a-6d8b,U+6d8d,U+6d8f-6d90,U+6d92,U+6d96-6d9a,U+6d9c,U+6da2,U+6da5,U+6dac-6dad,U+6db0-6db1,U+6db3-6db4,U+6db6-6db7,U+6db9-6dbe,U+6dc1-6dc3,U+6dc8-6dca,U+6dcd-6dd0,U+6dd2-6dd5,U+6dd7,U+6dda-6ddc,U+6ddf,U+6de2-6de3,U+6de5,U+6de7-6dea,U+6ded,U+6def-6df0,U+6df2,U+6df4-6df6,U+6df8,U+6dfa,U+6dfd-6e04,U+6e06-6e09,U+6e0b,U+6e0f,U+6e12-6e13,U+6e15,U+6e18-6e19,U+6e1b-6e1c,U+6e1e-6e1f,U+6e22,U+6e26-6e28,U+6e2a,U+6e2c,U+6e2e,U+6e30-6e31,U+6e33,U+6e35-6e37,U+6e39,U+6e3b-6e41;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./45bc6816d64819e8d00e71d1313c5db3.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+6efb-6f01,U+6f03-6f05,U+6f07-6f08,U+6f0a-6f0e,U+6f10-6f12,U+6f16-6f1f,U+6f21-6f23,U+6f25-6f28,U+6f2c,U+6f2e,U+6f30,U+6f32,U+6f34-6f35,U+6f37-6f3d,U+6f3f-6f45,U+6f48-6f4a,U+6f4c,U+6f4e-6f57,U+6f59-6f5b,U+6f5d,U+6f5f-6f61,U+6f63-6f65,U+6f67-6f6c,U+6f6f-6f71,U+6f73,U+6f75-6f77,U+6f79,U+6f7b,U+6f7d-6f83,U+6f85-6f87,U+6f8a-6f8b,U+6f8f-6f90;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./0defc802119de18ca8675e64ccb045be.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+7004-7010,U+7012-7019,U+701c-7022,U+7024-7034,U+7036-7038,U+703a-704b,U+704d-704e,U+7050-705d;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./05528ab4893cf9895b556cec63b26c63.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+71c7-71cd,U+71cf-71d3,U+71d6-71df,U+71e1-71e4,U+71e6,U+71e8-71ed,U+71ef-71f8,U+71fa-7205,U+7207-721c,U+721e-7227,U+7229;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./086950d304ba836a3f41c96f78193048.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+712e,U+7132-7135,U+7137-7144,U+7146-7149,U+714b,U+714d,U+714f-715b,U+715d,U+715f-7163,U+7165,U+7169-716d,U+716f-7171,U+7174-7177,U+7179,U+717b-717c,U+717e-7183,U+7185-7189,U+718b-718e,U+7190-7193,U+7195-7197,U+719a-719e,U+71a1-71a7,U+71a9-71ab,U+71ad-71b2,U+71b4,U+71b6-71b8,U+71ba-71c2,U+71c4-71c6;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./3fc211a9253ed06c096ad4845666387f.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+705f-706a,U+706e,U+7071-7074,U+7077,U+7079-707b,U+707d,U+7081-7084,U+7086-7088,U+708b-708d,U+708f-7091,U+7093,U+7097-7098,U+709a-709b,U+709e-70aa,U+70b0,U+70b2,U+70b4-70b6,U+70ba,U+70be-70bf,U+70c4-70c7,U+70c9,U+70cb-70d7,U+70da,U+70dc-70de,U+70e0-70e3,U+70e5,U+70ea,U+70ee,U+70f0-70f6,U+70f8,U+70fa-70fc,U+70fe-7108,U+710b-710f,U+7111-7112,U+7114,U+7117,U+711b-7125,U+7127-712d,U+7232;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./16c84db4efd80de259ffa90f59999a16.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+730f-7312,U+7314,U+7318-731a,U+731f-7320,U+7323-7324,U+7326-7328,U+732d,U+732f-7330,U+7332-7333,U+7335-7336,U+733a-733d,U+7340-734c,U+734e-734f,U+7351,U+7353-7356,U+7358-735f,U+7361-736b,U+736e,U+7370-737d,U+737f-7383,U+7385-7386,U+7388,U+738a,U+738c-738d,U+738f-7390,U+7392-7395,U+7397-739a,U+739c-739e,U+73a0-73a1,U+73a3-73a8,U+73aa,U+73ac-73ad,U+73b1,U+73b4-73b6,U+73b8-73b9,U+73bc-73bf,U+73c1,U+2487a;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./5d7fedc036b92f89e3b39df630557510.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+722b,U+722d-722f,U+7233-7234,U+723a,U+723c,U+723e,U+7240-7246,U+7249-724b,U+724e-7251,U+7253-7255,U+7257-7258,U+725a,U+725c,U+725e,U+7260,U+7263-7265,U+7268,U+726a-726d,U+7270-7271,U+7273-7274,U+7276-7278,U+727b-727d,U+7282-7283,U+7285-7289,U+728c,U+728e,U+7290-7291,U+7293-729e,U+72a0-72ab,U+72ae,U+72b1-72b3,U+72b5,U+72ba-72c0,U+72c5-72c7,U+72c9-72cc,U+72cf,U+72d1,U+72d3-72d6,U+72d8,U+72da-72dd,U+72df,U+72e2-72e7,U+72ea-72eb,U+72f5-72f6,U+72f9,U+72fd-7300,U+7302,U+7304-7309,U+730b-730d;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./d38a19458e38c7574dad6bac76bc4b7a.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+747b-747d,U+747f,U+7482,U+7484-7486,U+7488-748a,U+748c-748d,U+748f,U+7491-749b,U+749d,U+749f-74a6,U+74aa-74b9,U+74bb-74d1,U+74d3-74db,U+74dd,U+74df,U+74e1,U+74e5,U+74e7-74ed,U+74f0-74f2;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./8c932e3ee4b92c91842272db25339d80.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+73c3-73c7,U+73cb-73cc,U+73ce,U+73d2-73d8,U+73da-73dd,U+73df,U+73e1-73e4,U+73e6,U+73e8,U+73ea-73ec,U+73ee-73f1,U+73f3-7402,U+7404,U+7407-7408,U+740b-740e,U+7411-7419,U+741c-7421,U+7423-7424,U+7427,U+7429,U+742b,U+742d,U+742f,U+7431-7432,U+7437-743b,U+743d-7440,U+7442-7454,U+7456,U+7458,U+745d,U+7460-746c,U+746e-746f,U+7471-7475,U+7478-747a;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./b47e46acfe532b350cef00b2a227e150.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+75e1,U+75e5,U+75e9,U+75ec-75ef,U+75f2-75f3,U+75f5-75f8,U+75fa-75fb,U+75fd-75fe,U+7602,U+7604,U+7606-7609,U+760b,U+760d-760f,U+7611-7614,U+7616,U+761a,U+761c-761e,U+7621,U+7623,U+7627-7628,U+762c,U+762e-762f,U+7631-7632,U+7636-7637,U+7639-763b,U+763d,U+7641-7642,U+7644-764b,U+764e-7653,U+7655,U+7657-765b,U+765d,U+765f-7662,U+7664-766a,U+766c-766e,U+7670-7677,U+7679-767a;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./107be83540c0cb385a60f6eeed841c0a.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+74f3,U+74f5,U+74f8-74fe,U+7500-7503,U+7505-750c,U+750e,U+7510,U+7512,U+7514-7517,U+751b,U+751d-751e,U+7520-7524,U+7526-7527,U+752a,U+752e,U+7534,U+7536,U+7539,U+753c-753d,U+753f,U+7541-7544,U+7546-7547,U+7549-754a,U+754d,U+7550-7553,U+7555-7558,U+755d-7564,U+7567-7569,U+756b-7571,U+7573,U+7575-7577,U+757a-757e,U+7580-7582,U+7584-7585,U+7587-758a,U+758c-758e,U+7590,U+7593,U+7595,U+7598,U+759b-759c,U+759e,U+75a2,U+75a6-75aa,U+75ad,U+75b6-75b7,U+75ba-75bb,U+75bf-75c1,U+75c6,U+75cb-75cc,U+75ce-75d1,U+75d3,U+75d7,U+75d9-75da,U+75dc-75dd,U+75df-75e0;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./cca37f93c028a1e99f91f33ff2e393c4.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+767c,U+767f-7681,U+7683,U+7685,U+7689-768a,U+768c-768d,U+768f-7690,U+7692,U+7694-7695,U+7697-7698,U+769a-76a3,U+76a5-76ad,U+76af-76b0,U+76b3,U+76b5-76be,U+76c0-76c1,U+76c3-76c4,U+76c7,U+76c9,U+76cb-76cc,U+76d3,U+76d5,U+76d9-76da,U+76dc-76de,U+76e0-76e4,U+76e6-76ed,U+76f0,U+76f3,U+76f5-76f7,U+76fa-76fb,U+76fd,U+76ff-7703,U+7705-7706,U+770a,U+770c,U+770e-7718,U+771b-771e,U+7721,U+7723-7725,U+7727,U+772a-772c,U+772e,U+7730-7734,U+7739,U+773b,U+773d-773f,U+7742,U+7744-7746,U+7748-774f,U+7752-7754;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./ac9fcfea92cbb8d81516f2fd30f8d4d6.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+7755-7759,U+775c-7760,U+7764,U+7767,U+7769-776a,U+776d-7778,U+777a-777c,U+7781-7783,U+7786-778b,U+778f-7790,U+7793-779e,U+77a1,U+77a3-77a4,U+77a6,U+77a8,U+77ab,U+77ad-77af,U+77b1-77b2,U+77b4,U+77b6-77ba,U+77bc,U+77be,U+77c0-77cc,U+77ce-77d6,U+77d8-77da,U+77dd-77e1,U+77e4,U+77e6,U+77e8,U+77ea,U+77ef-77f0;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./d757d159ccae9f3a88e8e91139027ab2.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+77f1-77f2,U+77f4-77f5,U+77f7,U+77f9-77fc,U+7803-7808,U+780a-780b,U+780e-7810,U+7813,U+7815,U+7819,U+781b,U+781e,U+7820-7822,U+7824,U+7828,U+782a-782b,U+782e-782f,U+7831-7833,U+7835-7836,U+783d,U+783f,U+7841-7844,U+7846,U+7848-784b,U+784d,U+784f,U+7851,U+7853-7854,U+7858-785c,U+785e-7869,U+786f-7876,U+7878-787b,U+787d-7886,U+7888,U+788a-788b,U+788f-7890,U+7892,U+7894-7896,U+7899,U+789d-789e,U+78a0,U+78a2,U+78a4,U+78a6,U+78a8-78af,U+78b5-78b8,U+78ba-78bd,U+78bf-78c0,U+78c2-78c4,U+78c6-78c8,U+78cc-78cf,U+78d1-78d3,U+78d6-78d7;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./1fbf5d0b6a5d444f7de96ac6ff0f01eb.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+78d8,U+78da-78e7,U+78e9-78eb,U+78ed-78f1,U+78f3,U+78f5-78f6,U+78f8-78f9,U+78fb-7900,U+7902-7904,U+7906-7912,U+7914-791d,U+791f-7923,U+7925-7933,U+7935-7939,U+793d,U+793f,U+7942-7945,U+7947,U+794a-7951;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./b5c0039c3ae536ad58cee857ca75db4f.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+7952,U+7954-7955,U+7958-7959,U+7961,U+7963-7964,U+7966,U+7969-796c,U+796e,U+7970-7976,U+7979,U+797b-797f,U+7982-7983,U+7986-7989,U+798b-798e,U+7990-7999,U+799b-79a6,U+79a8-79b2,U+79b4-79b8,U+79bc,U+79bf,U+79c2,U+79c4-79c5,U+79c7-79c8,U+79ca,U+79cc,U+79ce-79d0,U+79d3-79d4,U+79d6-79d7,U+79d9-79de,U+79e0-79e2,U+79e5,U+79e8,U+79ea,U+79ec,U+79ee,U+79f1-79f7,U+79f9-79fa,U+79fc,U+79fe-79ff,U+7a01,U+7a04-7a05,U+7a07-7a0a,U+7a0c;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./7e6525b1d2b4c906341aae33c45bde30.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+7a0f-7a13,U+7a15-7a16,U+7a18-7a19,U+7a1b-7a1d,U+7a1f,U+7a21-7a22,U+7a24-7a32,U+7a34-7a36,U+7a38,U+7a3a,U+7a3e,U+7a40-7a45,U+7a47-7a50,U+7a52-7a56,U+7a58-7a6f,U+7a71-7a73,U+7a75,U+7a7b-7a7e,U+7a82,U+7a85,U+7a87,U+7a89-7a8c,U+7a8e-7a90,U+7a93-7a94,U+7a99-7a9b,U+7a9e,U+7aa1-7aa4,U+7aa7;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./67af85c6bee1c12d63046591184ea689.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+7b67-7b6d,U+7b6f-7b70,U+7b73-7b74,U+7b76,U+7b78,U+7b7a,U+7b7c-7b7d,U+7b7f,U+7b81-7b84,U+7b86-7b8c,U+7b8e-7b8f,U+7b91-7b93,U+7b96,U+7b98-7b9b,U+7b9e-7ba0,U+7ba3-7ba5,U+7bae-7bb0,U+7bb2-7bb3,U+7bb5-7bb7,U+7bb9-7bc0,U+7bc2-7bc5,U+7bc8-7bcb,U+7bcd-7bd0,U+7bd2,U+7bd4-7bd8,U+7bdb-7bdc,U+7bde-7be0,U+7be2-7be4,U+7be7-7be9,U+7beb-7bed,U+7bef-7bf0,U+7bf2-7bf6,U+7bf8-7bfb,U+7bfd,U+7bff-7c01;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./460de8329aa2e37aa185263ac49642b1.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+7c02-7c06,U+7c08-7c0a,U+7c0d-7c0e,U+7c10-7c15,U+7c17-7c1e,U+7c20-7c25,U+7c28-7c29,U+7c2b-7c37,U+7c39-7c3e,U+7c42-7c4c,U+7c4e-7c64;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./cea99366b6e366d95553278e00a5519a.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+7aa9-7aab,U+7aae-7ab2,U+7ab4-7abe,U+7ac0-7aca,U+7acc-7ad5,U+7ad7-7ad8,U+7ada-7add,U+7ae1-7ae2,U+7ae4,U+7ae7-7aec,U+7aee,U+7af0-7af8,U+7afb-7afc,U+7afe,U+7b00-7b02,U+7b05,U+7b07,U+7b09,U+7b0c-7b0e,U+7b10,U+7b12-7b13,U+7b16-7b18,U+7b1a,U+7b1c-7b1d,U+7b1f,U+7b21-7b23,U+7b27,U+7b29,U+7b2d,U+7b2f-7b30,U+7b32,U+7b34-7b37,U+7b39,U+7b3b,U+7b3d,U+7b3f-7b44,U+7b46,U+7b48,U+7b4a,U+7b4d-7b4e,U+7b53,U+7b55,U+7b57,U+7b59,U+7b5c,U+7b5e-7b5f,U+7b61,U+7b63-7b66;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./09fddd83b3a973ee68d08bdb88859efb.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+7ddf-7e38;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./3b7a3b149e25edc6eecfd5306b7c76f0.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+7cf7,U+7cf9-7cfa,U+7cfc-7d09,U+7d0b-7d1f,U+7d21,U+7d23-7d26,U+7d28-7d2a,U+7d2c-7d2e,U+7d30-7d6d,U+7d6f-7d76;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./fe84ea41a29706f996e424f2679d330b.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+7d78-7d92,U+7d94-7da5,U+7da7-7dad,U+7daf-7dde;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./0e730473cb7e4c2bd93878d501a0cbaa.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+7e39-7e3a,U+7e3c-7e40,U+7e42-7e46,U+7e48-7e81,U+7e83-7e8a;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./65b456d9cca0cc91d4452806a593d6f0.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+7c65-7c72,U+7c75-7c7a,U+7c7e-7c88,U+7c8a-7c90,U+7c93-7c94,U+7c96,U+7c99-7c9b,U+7ca0-7ca1,U+7ca3,U+7ca6-7ca9,U+7cab-7cad,U+7caf-7cb0,U+7cb4-7cb8,U+7cba-7cbb,U+7cbf-7cc0,U+7cc2-7cc4,U+7cc6,U+7cc9,U+7ccb,U+7cce-7cd4,U+7cd8,U+7cda-7cdb,U+7cdd-7cde,U+7ce1-7ce7,U+7ce9-7cee,U+7cf0-7cf6;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f10cfdcc814719412a57121d830b8e7f.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+7e8b-7e9a,U+7e9c-7e9e,U+7eae,U+7eb4,U+7ebb-7ebc,U+7ed6,U+7ee4,U+7eec,U+7ef9,U+7f0a,U+7f10,U+7f1e,U+7f37,U+7f39,U+7f3b-7f41,U+7f43,U+7f46-7f4f,U+7f52-7f53,U+7f56,U+7f59,U+7f5b-7f5e,U+7f60,U+7f63-7f67,U+7f6b-7f6d,U+7f6f-7f70,U+7f73,U+7f75-7f78,U+7f7a-7f7d,U+7f7f-7f80,U+7f82-7f89,U+7f8b,U+7f8d,U+7f8f-7f93,U+7f95-7f99,U+7f9b-7f9c,U+7fa0;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./3782947bc6d64b39707891e31750d70e.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+7fa2-7fa3,U+7fa5-7fa6,U+7fa8-7fae,U+7fb1,U+7fb3-7fb7,U+7fba-7fbb,U+7fbe,U+7fc0,U+7fc2-7fc4,U+7fc6-7fc9,U+7fcb,U+7fcd,U+7fcf-7fd3,U+7fd6-7fd7,U+7fd9-7fde,U+7fe2-7fe4,U+7fe7-7fe8,U+7fea-7fed,U+7fef,U+7ff2,U+7ff4-7ffa,U+7ffd-7fff,U+8002,U+8007-800a,U+800e-800f,U+8011,U+8013,U+801a-801b,U+801d-801f,U+8021,U+8023-8024,U+802b-8030,U+8032,U+8034,U+8039-803a,U+803c,U+803e,U+8040-8041,U+8044-8045,U+8047-8049,U+804e-8051,U+8053,U+8055-8057,U+8059,U+805b-805d;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./408e76dcb53ff768270927e51a73a265.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+8142-8145,U+8147,U+8149,U+814d-814f,U+8152,U+8156-8158,U+815b-815f,U+8161-8164,U+8166,U+8168,U+816a-816c,U+816f,U+8172-8173,U+8175-8178,U+8181,U+8183-8187,U+8189,U+818b-818e,U+8190,U+8192-8197,U+8199-819a,U+819e-81a2,U+81a4-81a5,U+81a7,U+81a9,U+81ab-81b2,U+81b4-81b9,U+81bc-81bf,U+81c4-81c5,U+81c7-81c9,U+81cb,U+81cd-81da;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./bb2cb4815a17452cabdc361697205e13.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+805e-8068,U+806b-8070,U+8072-807e,U+8081-8082,U+8085,U+8088,U+808a,U+808d-8092,U+8094-8095,U+8097,U+8099,U+809e,U+80a3,U+80a6-80a8,U+80ac,U+80b0,U+80b3,U+80b5-80b6,U+80b8-80b9,U+80bb,U+80c5,U+80c7-80cb,U+80cf-80d5,U+80d8,U+80df-80e0,U+80e2-80e3,U+80e6,U+80ee,U+80f5,U+80f7,U+80f9,U+80fb,U+80fe-8101,U+8103-8105,U+8107-8108,U+810b-810c,U+8115,U+8117,U+8119,U+811b-811d,U+811f-812b,U+812d-812e,U+8130,U+8133-8135,U+8137,U+8139-813d,U+813f-8141;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./26604e72d6ddd7872347b69148e14c75.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+81db-81e2,U+81e4-81e6,U+81e8-81e9,U+81eb,U+81ee-81f2,U+81f5-81fa,U+81fd,U+81ff,U+8203,U+8207-820b,U+820e-820f,U+8211,U+8213,U+8215-821a,U+821d,U+8220,U+8224-8227,U+8229,U+822e,U+8232,U+823a,U+823c-823d,U+823f-8243,U+8245-8246,U+8248,U+824a,U+824c-824e,U+8250-8257,U+8259,U+825b-825e,U+8260-8267,U+8269-826d,U+8271,U+8275-8278,U+827b-827c,U+8280-8281,U+8283,U+8285-8287,U+8289,U+828c,U+8290,U+8293;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./8c385959dfbc21d7792156427bc5a3f6.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+8495-8496,U+8498,U+849a-849b,U+849d-84a0,U+84a2-84ae,U+84b0-84b1,U+84b3,U+84b5-84b7,U+84bb-84bc,U+84be,U+84c0,U+84c2-84c3,U+84c5-84c8,U+84cb-84cc,U+84ce-84cf,U+84d2,U+84d4-84d5,U+84d7-84dc,U+84de,U+84e1-84e2,U+84e4,U+84e7-84eb,U+84ed-84ef,U+84f1-84fb,U+84fd-84fe,U+8500-850b,U+850d-8510,U+8512,U+8514-8516,U+8518-8519,U+851b-851e,U+8520,U+8522-8528;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./4afb66e3e487a9cf5d23fa3ad1840a6a.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+8294-8296,U+829a-829b,U+829e,U+82a0,U+82a2-82a3,U+82a7,U+82b2,U+82b5-82b6,U+82ba-82bc,U+82bf-82c0,U+82c2-82c3,U+82c5-82c6,U+82c9,U+82d0,U+82d6,U+82d9-82da,U+82dd,U+82e2,U+82e7-82ea,U+82ec-82ee,U+82f0,U+82f2-82f3,U+82f5-82f6,U+82f8,U+82fa,U+82fc-8300,U+830a-830b,U+830d,U+8310,U+8312-8313,U+8316,U+8318-8319,U+831d-8326,U+8329-832a,U+832e,U+8330,U+8332,U+8337,U+833b,U+833d-833f,U+8341-8342,U+8344-8345,U+8348,U+834a-834e,U+8353,U+8355-8359,U+835d,U+8362,U+8370-8376,U+8379-837a,U+837e-8384,U+8387-8388,U+838a-838d,U+838f-8391,U+8394-8397,U+8399-839a,U+839d,U+839f,U+83a1-83a7,U+83ac-83af,U+83b5,U+83bb,U+83be-83bf,U+83c2-83c4,U+83c6,U+83c8-83c9,U+83cb,U+83cd-83ce,U+83d0-83d3,U+83d5;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./279a9595695d2818f0d4ced17c4146e9.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+83d7,U+83d9-83db,U+83de,U+83e2-83e4,U+83e6-83e8,U+83eb-83ef,U+83f3-83f7,U+83fa-83fc,U+83fe-8400,U+8402,U+8405,U+8407-840a,U+8410,U+8412-8417,U+8419-841b,U+841e-8423,U+8429-8430,U+8432-8437,U+8439-843b,U+843e-8445,U+8447-8450,U+8452-8456,U+8458,U+845d-8460,U+8462,U+8464-8468,U+846a,U+846e-8470,U+8472,U+8474,U+8477,U+8479,U+847b-8481,U+8483-8486,U+848a,U+848d,U+848f-8494;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f7fc1c3d96f3179f8794afcaeb13f82a.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+8529-852a,U+852d-8536,U+853e-8542,U+8544-8547,U+854b-8555,U+8557-8558,U+855a-855d,U+855f-8563,U+8565-8567,U+8569-8571,U+8573,U+8575-8578,U+857c-857d,U+857f-8583,U+8586,U+8588-858e,U+8590-859a,U+859d-85a3,U+85a5-85a7,U+85a9,U+85ab-85ac;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f0bc79ceed5f8baddebb6e2ac8e6486c.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+85ad,U+85b1-85b6,U+85b8,U+85ba-85c0,U+85c2-85c8,U+85ca-85ce,U+85d1-85d2,U+85d4,U+85d6-85db,U+85dd-85e3,U+85e5-85e8,U+85ea-85fa,U+85fc-85fe,U+8600-8604,U+8606-8610,U+8612-8615;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./dd73ced68ac7db9461207d166c3db009.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+868f-8692,U+8694,U+8696-869b,U+869e-86a2,U+86a5-86a6,U+86ab,U+86ad-86ae,U+86b2-86b3,U+86b7-86b9,U+86bb-86bf,U+86c1-86c3,U+86c5,U+86c8,U+86cc-86cd,U+86d2-86d3,U+86d5-86d7,U+86da,U+86dc-86dd,U+86e0-86e3,U+86e5-86e8,U+86ea-86ec,U+86ef,U+86f5-86f7,U+86fa-86fd,U+86ff,U+8701,U+8704-8706,U+870b-870c,U+870e-8711,U+8714,U+8716,U+8719,U+871b,U+871d,U+871f-8720,U+8724,U+8726-8728,U+872a-872d,U+872f-8730,U+8732-8733,U+8735-8736,U+8738-873a,U+873c-873d,U+8740-8746,U+874a-874b,U+874d,U+874f-8752,U+8754-8756,U+8758,U+875a-875d;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./01f6e61e37bf9fd5555f094fb5218e28.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+8617-8626,U+8628,U+862a-8637,U+8639-863b,U+863d-864c,U+8652-8653,U+8655-8659,U+865b-865d,U+865f-8661,U+8663-866a,U+866d,U+866f-8670,U+8672-8678,U+8683-8689,U+868e;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./46bf628b4558a6468b8f4eddcfdfa8d2.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+8855-8856,U+8858,U+885a-8860,U+8866-8867,U+886a,U+886d,U+886f,U+8871,U+8873-8876,U+8878-887c,U+8880,U+8883,U+8886-8887,U+8889-888a,U+888c,U+888e-8891,U+8893-8895,U+8897-889b,U+889d-88a1,U+88a3,U+88a5-88aa,U+88ac,U+88ae-88b0,U+88b2-88b6,U+88b8-88bb,U+88bd-88c0,U+88c3-88c4,U+88c7-88c8,U+88ca-88cd,U+88cf-88d1,U+88d3,U+88d6-88d7,U+88da-88de,U+88e0-88e1,U+88e6-88e7,U+88e9-88ef,U+88f2,U+88f5-88f7,U+88fa-88fb,U+88fd,U+88ff-8901,U+8903-8909,U+890b-890f,U+8911;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./c3f016e10cf7cac0fb7cff700bd77fac.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+8914-8918,U+891c-8920,U+8922-8924,U+8926-8929,U+892c-892f,U+8931-8933,U+8935,U+8937-8940,U+8942-8943,U+8945-895d,U+8960-8965,U+8967-8979;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./769869827f169347deb6240d8eb329e0.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+875e-875f,U+8761-8762,U+8766-876d,U+876f,U+8771-8773,U+8775,U+8777-877a,U+877f-8781,U+8784,U+8786-8787,U+8789-878a,U+878c,U+878e-8792,U+8794-8796,U+8798-879e,U+87a0-87a7,U+87a9-87aa,U+87ae,U+87b0-87b2,U+87b4,U+87b6-87b9,U+87bb-87bc,U+87be-87bf,U+87c1-87c5,U+87c7-87c9,U+87cc-87d0,U+87d4-87da,U+87dc-87df,U+87e1-87e4,U+87e6-87e8;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./ebec0d4cd45bb294896c3102e1e66fd8.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+87e9,U+87eb-87ed,U+87ef-87f8,U+87fa-87fd,U+87ff-8802,U+8804-8809,U+880b-8812,U+8814,U+8817-881a,U+881c-8820,U+8823-8831,U+8833-8838,U+883a-883b,U+883d-883f,U+8841-8843,U+8846-884b,U+884e-8853;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./6ecbd39ee91bb7811e32d1a665c6fa2e.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+897a,U+897c-897e,U+8980,U+8982,U+8984-8985,U+8987-89c0,U+89c3,U+89cd,U+89d3-89d5,U+89d7-89d9,U+89db,U+89dd,U+89df-89e2,U+89e4,U+89e7-89ea,U+89ec-89ee,U+89f0-89f2,U+89f4-89fe;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./7cae69b55fbf65310598dcaf78327994.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+89ff,U+8a01-8a06,U+8a08-8a3d,U+8a3f-8a47,U+8a49-8a78,U+8a7a-8a88,U+8a8b-8a92;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./5eaa407cba95f26568f9ca1e17cb90d8.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+8b05-8b06,U+8b08-8b25,U+8b27-8b63;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./ce90d05ccee0226f219f57956ff805a4.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+8b64-8b65,U+8b67-8b6b,U+8b6d-8b9f,U+8bac,U+8bb1,U+8bbb,U+8bc7,U+8bd0,U+8bea,U+8c09,U+8c1e,U+8c38-8c40,U+8c42-8c45,U+8c48,U+8c4a-8c4b,U+8c4d-8c51;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./1e800346a5d1c3cc0ae9a4b2aadfd9a9.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+8a94-8b04;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./a6ebf23510085cce95cbf28a1a9f8f6b.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+8cdd-8d1c,U+8d20,U+8d51-8d52,U+8d57,U+8d5f,U+8d65,U+8d68-8d6a,U+8d6c,U+8d6e-8d6f,U+8d71-8d72,U+8d78-8d80,U+8d82-8d83,U+8d86-8d89,U+8d8c-8d90,U+8d92-8d93,U+8d95-8d9b;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./3b1bb81557fe9a9c7e666dce489390b5.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+8c52-8c54,U+8c56-8c59,U+8c5b-8c60,U+8c63-8c69,U+8c6c-8c72,U+8c74-8c77,U+8c7b-8c81,U+8c83-8c84,U+8c86-8c88,U+8c8b,U+8c8d-8c93,U+8c95-8c97,U+8c99-8cdc;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./a6c7c30c0c2071f44622d319fca3769b.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+8e56-8e58,U+8e5a-8e65,U+8e67-8e68,U+8e6a-8e6b,U+8e6e,U+8e71,U+8e73,U+8e75,U+8e77-8e7b,U+8e7d-8e7e,U+8e80,U+8e82-8e84,U+8e86,U+8e88-8e8e,U+8e91-8e93,U+8e95-8e9b,U+8e9d,U+8e9f-8eaa,U+8ead-8eae,U+8eb0-8eb1,U+8eb3-8eb9,U+8ebb-8ec3;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./5195bf9bdecbf2d041b19a87668522c1.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+8d9c-8d9e,U+8da0-8da2,U+8da4-8db0,U+8db2,U+8db6-8db7,U+8db9,U+8dbb,U+8dbd,U+8dc0-8dc2,U+8dc5,U+8dc7-8dca,U+8dcd,U+8dd0,U+8dd2-8dd5,U+8dd8-8dd9,U+8ddc,U+8de0-8de2,U+8de5-8de7,U+8de9,U+8ded-8dee,U+8df0-8df2,U+8df4,U+8df6,U+8dfc,U+8dfe-8e04,U+8e06-8e08,U+8e0b,U+8e0d-8e0e,U+8e10-8e13,U+8e15-8e1c,U+8e20-8e21,U+8e24-8e28,U+8e2b,U+8e2d,U+8e30,U+8e32-8e34,U+8e36-8e38,U+8e3b-8e3c,U+8e3e-8e3f,U+8e43,U+8e45-8e46,U+8e4c-8e50,U+8e53-8e55;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./228e51c5090464ae39a07ef69ae80dae.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+8ec4-8ecd,U+8ecf-8f39;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./23846a7e81fc6878d4ed5859d9a1bc63.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+8f3a-8f65,U+8f6a,U+8f80,U+8f8c,U+8f92,U+8f9d,U+8fa0-8fa2,U+8fa4-8fa7,U+8faa,U+8fac-8faf,U+8fb2-8fb5,U+8fb7-8fb8,U+8fba-8fbc,U+8fbf-8fc0,U+8fc3,U+8fc6,U+8fc9-8fcd,U+8fcf,U+8fd2,U+8fd6-8fd7,U+8fda,U+8fe0-8fe1,U+8fe3,U+8fe7,U+8fec,U+8fef,U+8ff1-8ff2,U+8ff4-8ff6,U+8ffa-8ffc,U+8ffe-8fff,U+9007-9008,U+900c,U+900e,U+9013,U+9015,U+9018-9019,U+901c;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./e19eea65db4d10c5b5215d2f082fa891.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+91a6-91a9,U+91ab-91ac,U+91b0-91b3,U+91b6-91b9,U+91bb-91c6,U+91c8,U+91cb,U+91d0,U+91d2-91db,U+91dd-9224;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./aee63f05dff2f3fd30a2cb6b83070fef.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+90fa-90fc,U+90ff-9101,U+9103,U+9105-9118,U+911a-911d,U+911f-9121,U+9124-912e,U+9130,U+9132-9138,U+913a-9142,U+9144-9145,U+9147-9148,U+9151,U+9153-9156,U+9158-9159,U+915b-915c,U+915f-9160,U+9166-9168,U+916b,U+916d,U+9173,U+917a-917c,U+9180-9184,U+9186,U+9188,U+918a,U+918e-918f,U+9193-9199,U+919c-91a1,U+91a4-91a5;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./47db5e1c7960f9476b1641b0e087915b.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9023-9025,U+9027-902c,U+9030-9034,U+9037,U+9039-903a,U+903d,U+903f-9040,U+9043,U+9045-9046,U+9048-904c,U+904e,U+9054-9056,U+9059-905a,U+905c-9061,U+9064,U+9066-9067,U+9069-906c,U+906f-9073,U+9076-907c,U+907e,U+9081,U+9084-9087,U+9089-908a,U+908c-9090,U+9092,U+9094,U+9096,U+9098,U+909a,U+909c,U+909e-90a0,U+90a4-90a5,U+90a7-90a9,U+90ab,U+90ad,U+90b2,U+90b7,U+90bc-90bd,U+90bf-90c0,U+90c2-90c3,U+90c6,U+90c8-90c9,U+90cb-90cd,U+90d2,U+90d4-90d6,U+90d8-90da,U+90de-90e0,U+90e3-90e5,U+90e9-90ea,U+90ec,U+90ee,U+90f0-90f3,U+90f5-90f7,U+90f9;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./782662f19ce6a25dc500cbb6f9291ad1.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9369,U+936b-938e,U+9390-93c4;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./ca327885c26cbdc44421673a9541b417.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9225-9273,U+9275-928d,U+928f-929c;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f49a441854a46d72397595b64260e568.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+93c5-93c9,U+93cb-93d5,U+93d7-9418;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f4e42b5c2a0d57de29604d3b44ec5ce2.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+929d-92ad,U+92af-92c7,U+92c9-9306;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./6865aec216f0907c40feaa17f8fab1f8.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9307-933d,U+933f-9368;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./dfb96492af6f0acbc018fd1103a92678.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9419-943d,U+943f-9465;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./3dd749429e677daff6e27edd95f5124d.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9466-946a,U+946c-9484,U+9491,U+9496,U+9498,U+94c7,U+94cf,U+94d3-94d4,U+94da,U+94e6,U+94fb,U+951c,U+9520,U+9527,U+9533,U+953d,U+9543,U+9548,U+954b,U+9555,U+955a,U+9560,U+956e,U+9574-9575,U+9577-957e,U+9580-95a6;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./b8a3b01e6c561c7af25028675a4dc011.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+97b0-97b1,U+97b3,U+97b5-97e5,U+97e8,U+97ee-97f2,U+97f4,U+97f7-981b;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./50afc6972442dea00d0eef2d5397248c.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+981c-9874,U+988b,U+988e,U+9892,U+9895,U+9899,U+98a3;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./ab4059359651cbd6ccb0b97347a37357.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+968c,U+968e,U+9691-9693,U+9695-9696,U+969a-969b,U+969d-96a6,U+96a8-96af,U+96b1-96b2,U+96b4-96b5,U+96b7-96b8,U+96ba-96bb,U+96bf,U+96c2-96c3,U+96c8,U+96ca-96cb,U+96d0-96d1,U+96d3-96d4,U+96d6-96df,U+96e1-96e7,U+96eb-96ee,U+96f0-96f2,U+96f4-96f5,U+96f8,U+96fa-96fd,U+96ff,U+9702-9703,U+9705,U+970a-970c,U+9710-9712,U+9714-9715,U+9717-971b,U+971d,U+971f-9729,U+972b-972c;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./9c9c44dc7145863237b72412f4633f20.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+95a7-95e7,U+95ec,U+95ff,U+9607,U+9613,U+9618,U+961b,U+961e,U+9620,U+9623-9629,U+962b-962d,U+962f-9630,U+9637-963a,U+963e,U+9641,U+9643,U+964a,U+964e-964f,U+9651-9653,U+9656-965a,U+965c-965e,U+9660,U+9663,U+9665-9666,U+966b,U+966d-9671,U+9673,U+9678-9684,U+9687,U+9689-968a;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./880c641d16ce8f516e76024c1be4b156.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+98a8-98cd,U+98cf-98d0,U+98d4,U+98d6-98d7,U+98db-98dd,U+98e0-98e6,U+98e9-990c,U+990e-990f,U+9911-991a;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./ec90701fbf3580a4c9cc63c2107082e3.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+972e-972f,U+9731,U+9733-9737,U+973a-973d,U+973f-9751,U+9754-9755,U+9757-9758,U+975a,U+975c-975d,U+975f,U+9763-9764,U+9766-9768,U+976a-9772,U+9775,U+9777-977b,U+977d-9784,U+9786-978a,U+978c,U+978e-9790,U+9793,U+9795-9797,U+9799-979f,U+97a1-97a2,U+97a4-97aa,U+97ac,U+97ae;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f98733de5374c37b4ab68f01b3f27eb2.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+991b-992d,U+992f-9953,U+9956-9962,U+9964,U+9966,U+9973,U+9978-9979,U+997b,U+997e,U+9982-9983,U+9989,U+998c,U+998e,U+999a-99a4,U+99a6;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./a38c46affc77efc2c73789eb5edb5db2.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9a0f-9a5d;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f50599304ae1c64cb51611087381bdbf.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9bd0-9c21;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./6297bbc5882fa69032e8afe27a186e68.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9b73-9bcf;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./2881f31b1ecce03df85598b5596895ae.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9b09-9b0e,U+9b10-9b12,U+9b14-9b1e,U+9b20-9b22,U+9b24-9b2e,U+9b30-9b31,U+9b33-9b3a,U+9b3d-9b40,U+9b46,U+9b4a-9b4c,U+9b4e,U+9b50,U+9b52-9b53,U+9b55-9b72;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./1845169873b095e745efd0c83ca70d4d.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+99a7,U+99a9-9a0e;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./46c98b67ab9a7114a171204d01f5e846.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9a5e-9a6b,U+9a72,U+9a83,U+9a89,U+9a8d-9a8e,U+9a94-9a95,U+9a99,U+9aa6,U+9aa9-9aaf,U+9ab2-9ab5,U+9ab9,U+9abb,U+9abd-9abf,U+9ac3-9ac4,U+9ac6-9aca,U+9acd-9ad0,U+9ad2,U+9ad4-9ad7,U+9ad9-9ade,U+9ae0,U+9ae2-9ae5,U+9ae7-9aea,U+9aec,U+9aee,U+9af0-9af8,U+9afa,U+9afc-9b02,U+9b04-9b07;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./52eb84880d073d796f3230bc81a48dfa.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9c22-9c68;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./ec21ca52c1ac0a110b88ed2f6e3ab1b4.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9c69-9c7b,U+9c7d-9c7e,U+9c80,U+9c83-9c84,U+9c89-9c8a,U+9c8c,U+9c8f,U+9c93,U+9c96-9c99,U+9c9d,U+9caa,U+9cac,U+9caf,U+9cb9,U+9cbe-9cc2,U+9cc8-9cc9,U+9cd1-9cd2,U+9cda-9cdb,U+9ce0-9ce1,U+9ce3-9d0b;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./c4a830b33fe2808dc7db3b8e0d4afcd1.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9d68-9db8;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./e45ed29da817344ea2ac9b156353fe3a.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9d0c-9d67;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./2afb97feae9e566b2f957f920b23867b.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9db9-9e00;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./b7d37ccbe8677eb793ae69953855bc5e.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9e01-9e1e,U+9e24,U+9e27,U+9e2e,U+9e30,U+9e34,U+9e3b-9e3c,U+9e40,U+9e4d,U+9e50,U+9e52-9e54,U+9e56,U+9e59,U+9e5d,U+9e5f-9e62,U+9e65,U+9e6e-9e6f,U+9e72,U+9e74-9e7d,U+9e80-9e81,U+9e83-9e86,U+9e89-9e8a,U+9e8c-9e91,U+9e94-9e97;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./c35c0d341c51d41e8b25c7cb6d804b60.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9f0c,U+9f0f,U+9f11-9f12,U+9f14-9f16,U+9f18,U+9f1a-9f1f,U+9f21,U+9f23-9f2b,U+9f2d-9f2e,U+9f30-9f36,U+9f38,U+9f3a,U+9f3c,U+9f3f-9f43,U+9f45-9f4f,U+9f52-9f6c;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./24e772eda9a7094fd9e646a0bb56c21c.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9e98-9e9c,U+9e9e,U+9ea0-9ea5,U+9ea7-9eb3,U+9eb5-9eb7,U+9eb9-9eba,U+9ebc,U+9ebf-9ec3,U+9ec5-9ec8,U+9eca-9ecc,U+9ed0,U+9ed2-9ed3,U+9ed5-9ed7,U+9ed9-9eda,U+9ede,U+9ee1,U+9ee3-9ee4,U+9ee6,U+9ee8,U+9eeb-9eee,U+9ef0-9ef8,U+9efa,U+9efd,U+9eff-9f0a;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./dd5c0973bcf8380c797b75d2f462144f.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9f6d-9f7e,U+9f81-9f82,U+9f8d-9f98,U+9f9c-9f9e,U+9fa1-9fda;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./ed831de3dd1acba131bbef3d19b77f91.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+ff8f-ff9f,U+ffe0-ffe2,U+16ff0-16ff1,U+20001,U+20006,U+2000b,U+2000d-2000e,U+20010,U+20016-20017,U+20024,U+20027-20028,U+2002a-2002b,U+20032-20034,U+2003f-20042,U+20044,U+20051,U+20054,U+20059-2005a,U+2005d,U+2005f,U+20063,U+2006a,U+20073,U+20075,U+20078-2007b,U+20086-20087,U+20089-2008b,U+2008e,U+20091-20094,U+200a1-200a2,U+200a4,U+200aa-200ab,U+200ad,U+200b0,U+200c5,U+200c9-200cc,U+200d3-200d4,U+200db-200dd,U+200e3,U+200e7,U+200e9,U+200ef,U+200f1,U+200f5,U+200f7,U+20100,U+2010c,U+2010e,U+2011c,U+20121,U+20126-20127,U+20129,U+2012f,U+20133,U+20136,U+2013a-2013d,U+20143,U+20147,U+2014d-2014e,U+20150,U+20152,U+20158,U+2015c,U+20164,U+20173,U+2017b-2017c,U+2018d,U+201a2-201a3,U+201a8-201a9,U+201b3,U+201b7,U+201be,U+201c1,U+201c8,U+201cd,U+201d5-201d6,U+201df,U+201e6,U+201e9,U+201ee,U+201f1,U+201f6-201f7,U+201fa,U+201fc-201fd,U+20209,U+2020b-2020c,U+20213,U+2022d,U+20233,U+2024b,U+2025c-20260,U+20263,U+20271,U+20278,U+20281,U+20288,U+2028e,U+20294,U+20299-2029d,U+2029f,U+202a1,U+202ab,U+202af,U+202b3,U+202c2,U+202ce,U+202e1,U+202e5-202e6,U+202f5,U+202fa,U+20302-20303,U+20307,U+2030a,U+20326-20328,U+2032b,U+2032d,U+2032f,U+20335,U+20337,U+20352-20353,U+20363-20366,U+2036d,U+20371-20372,U+20375,U+20381,U+20384,U+2038a,U+2039d,U+203a0,U+203a3,U+203a6,U+203a8-203ac,U+203b4,U+203b7,U+203ba,U+203c6,U+203df-203e0;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./148cad0a15c09c301ec8b910ee4ef529.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+203e1,U+203e5-203e6,U+203ed-203ee,U+203f2-203f3,U+203f9-203fa,U+20405,U+20409,U+20414,U+2041e,U+2042c,U+20433,U+20438,U+20448-2044b,U+20455-20456,U+20462,U+20466,U+2046c-2046d,U+2047d,U+20486,U+20492,U+204a5-204a6,U+204ab-204ac,U+204af,U+204b7,U+204c0-204c1,U+204c5,U+204c7,U+204d0-204d1,U+204d4,U+204e8-204e9,U+204ec,U+204f9-204fb,U+20509,U+20523,U+20526,U+2052d-2052e,U+2053c,U+2053e-2053f,U+20541-20542,U+20545-20547,U+2054b,U+20560,U+20575,U+2057b,U+20584,U+20587-20588,U+2058f,U+20598,U+2059d,U+205a4,U+205a8,U+205ad,U+205af-205b1,U+205be-205bf,U+205d6,U+205e3-205e4,U+205f0,U+205f7-205f9,U+205fb,U+20603,U+20611,U+20627-20628,U+20633,U+20645,U+20663,U+20676,U+2067b,U+2067d,U+2068d,U+20690,U+206a2-206a3,U+206a5,U+206a9-206aa,U+206af,U+206b5,U+206bc,U+206cc,U+206e3-206e4,U+206e8-206e9,U+206ec,U+20705,U+20708,U+2070f,U+2072c-2072f,U+20731-20732,U+20747,U+2074f,U+20753-20757,U+2076b,U+2077a-2077c,U+2079b,U+2079f-207a2,U+207a9,U+207ad,U+207b0,U+207b8-207ba,U+207c0,U+207c8,U+207e4-207e5,U+207f2,U+207f8-207f9,U+20803,U+20807,U+20809-2080a,U+2080d,U+20811,U+20819-2081a,U+2081d,U+20829,U+2082d,U+20835,U+2083a,U+2083c,U+2084e-2084f,U+20852,U+2085a-2085b,U+20867,U+20870,U+2087a,U+2088b,U+2088e,U+20891,U+20895,U+2089d-2089f,U+208ac,U+208b9,U+208c2,U+208db-208dc,U+208ef,U+208f6,U+20906,U+2090c,U+2090e-2090f,U+20915-20916;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./9450101119e0c6221a3164cc07c6b8d2.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+9fdb-9fff,U+f900,U+f92c,U+f979,U+f995,U+f9e7,U+f9f1,U+fa0c-fa0f,U+fa11,U+fa13-fa14,U+fa1f-fa21,U+fa23-fa24,U+fa27-fa29,U+fa30-fa34,U+fa36-fa38,U+fa3a-fa3b,U+fa3d-fa58,U+fa5a-fa5d,U+fa5f-fa6d,U+fe47-fe48,U+ff61-ff8e;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./016bcf54232a843cd42fc8687e845efd.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+20927,U+20929,U+20937,U+2093f,U+20948,U+20964,U+2097c,U+20982,U+20984,U+20986,U+2099d,U+209a9,U+209af,U+209b3,U+209b9-209bb,U+209bf,U+209c6,U+209cc-209cd,U+209d7,U+209e6,U+209e8,U+20a21,U+20a2a,U+20a38,U+20a3f,U+20a55,U+20a64,U+20a75,U+20a7a,U+20a9a,U+20aa2,U+20aa8,U+20ab1,U+20ab8,U+20abe,U+20ac5-20ac6,U+20acf,U+20ad3,U+20ad8,U+20ae4,U+20afe,U+20b03,U+20b09,U+20b12,U+20b15-20b16,U+20b1b,U+20b1d-20b1e,U+20b20,U+20b24,U+20b27,U+20b36,U+20b64,U+20b6f,U+20b99,U+20b9b,U+20b9f,U+20ba5-20ba6,U+20ba8-20ba9,U+20bac,U+20bb1-20bb2,U+20bb5-20bb7,U+20bb9,U+20bbe-20bbf,U+20bc4-20bc8,U+20bcb-20bcc,U+20bd1,U+20bd6-20bd8,U+20bdc,U+20be5-20be6,U+20bea-20beb,U+20bf2-20bf4,U+20bf9,U+20bfb-20c03,U+20c08-20c09,U+20c0b-20c0d,U+20c0f,U+20c12,U+20c18-20c1a,U+20c22,U+20c29-20c2a,U+20c2d-20c2e,U+20c33,U+20c37,U+20c39-20c3c,U+20c40,U+20c44-20c4d,U+20c53-20c54,U+20c5c-20c5d,U+20c64,U+20c76-20c77,U+20c7b,U+20c7e,U+20c83,U+20c85,U+20c8f,U+20c93-20c96,U+20c9c-20ca8,U+20cae,U+20cb0,U+20cb5-20cb7,U+20cb9-20cba,U+20cbf-20cc1,U+20ccb,U+20cd0-20cd3,U+20cd6-20cda,U+20cdd-20cde,U+20ce4,U+20ce8,U+20ced,U+20cf2,U+20cf9-20cfa,U+20cfe-20cff,U+20d02,U+20d06-20d08;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./5a59d4e4c59f62b42384bff6764056d2.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+20d09-20d22,U+20d24-20d25,U+20d2b,U+20d2d,U+20d30,U+20d32,U+20d39,U+20d3c,U+20d45,U+20d47-20d48,U+20d4b-20d4c,U+20d4e-20d50,U+20d58,U+20d5a,U+20d68-20d69,U+20d6b,U+20d6f,U+20d71,U+20d74,U+20d7b-20d7d,U+20d80-20da6,U+20daf,U+20dc0,U+20dc7-20dc8,U+20dca,U+20dcc,U+20dd5,U+20de1,U+20de4-20de6,U+20de8,U+20dec,U+20dee-20def,U+20e02,U+20e04,U+20e0b-20e0d,U+20e12-20e1c,U+20e1e-20e2c,U+20e2f,U+20e3a,U+20e3c-20e3e,U+20e40,U+20e43,U+20e4b-20e4d,U+20e51,U+20e56-20e57,U+20e5a-20e5b,U+20e64,U+20e6d,U+20e6f-20e75,U+20e7c-20e7e,U+20e81,U+20e85,U+20e8c,U+20e92,U+20e95,U+20e98-20e9a,U+20e9f-20ea0,U+20ea5-20eaa,U+20ead-20eb3;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./1f02199225996bf4d0390dec931158dc.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+21035-21037,U+2103d-2103f,U+2104e-2104f,U+21059-21060,U+21064,U+21067,U+21071,U+21075,U+21078-2107a,U+2107c-21089,U+2108c-2108f,U+21091-21093,U+21099,U+210a1,U+210ab,U+210ae-210b3,U+210b5,U+210b7,U+210b9,U+210bc,U+210c4-210c6,U+210ca-210de,U+210e4,U+210f3,U+210f9-21101,U+21106,U+2110d-21110,U+21121,U+21125-21130,U+21133,U+2113e,U+21149-21152,U+21159,U+21160,U+21162,U+21167-2116c,U+2116f,U+21173,U+21177,U+21181-21182,U+21184,U+21188,U+2118c-2118d,U+21191,U+21196-2119a,U+2119e,U+211a7,U+211ab,U+211c5,U+211d0,U+211d2,U+211f8,U+211fb,U+21201,U+21208-21209,U+21221,U+21229,U+2123a,U+2123c-2123d,U+21240-21241,U+2124a,U+2124e-2124f,U+21255-21256,U+21266-21267,U+21274-21275,U+2127b-2127f,U+21289,U+21296,U+212a0,U+212a8,U+212ae,U+212b0-212b3;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./ed9b5f9d19d891b44abe788c872209d0.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+20eb4-20ecb,U+20ed1-20ed3,U+20ed7,U+20edb,U+20ede,U+20ee4-20ee6,U+20ee8-20eea,U+20ef1,U+20ef3-20ef5,U+20ef7,U+20efb-20efd,U+20eff,U+20f07,U+20f0b,U+20f15-20f17,U+20f19-20f1a,U+20f22,U+20f24,U+20f26,U+20f2a,U+20f2f,U+20f31-20f5b,U+20f5e-20f5f,U+20f61,U+20f64,U+20f66,U+20f6c,U+20f6e,U+20f78,U+20f80,U+20f87,U+20f8e-20f8f,U+20f91-20f95,U+20f9a-20f9b,U+20fa1,U+20fa3,U+20fa6,U+20fac,U+20fb2,U+20fb6-20fc4,U+20fc8,U+20fd2,U+20fda,U+20fe0,U+20fe9-20fea,U+20fee-20ff9,U+20ffc,U+20fff-21000,U+21002,U+21007,U+2100a,U+2100d,U+21014,U+21017,U+2101c,U+21020-21034;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./5812184d14cb7b0369999c1cf9d31eac.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2169d,U+216a1-216a2,U+216a6,U+216ac,U+216b4,U+216b6,U+216c7,U+216d4,U+216dc,U+216e4,U+216f0,U+216fe,U+21706,U+21712,U+2171f,U+21724,U+21735,U+21742-21743,U+21754-21756,U+21766,U+21795-21797,U+217f1,U+21804,U+21823,U+2182a,U+21842,U+21845-21847,U+21851,U+21866-21867,U+21883-21884,U+21890,U+218bb-218bd,U+218bf,U+218c4,U+218ce,U+218e7-218e8,U+218fb,U+2190a,U+21913-21914,U+2191f-21920,U+2193c,U+21942,U+21949,U+2194c,U+21959-2195a,U+21964-21965,U+21974-21975,U+2197f,U+21982,U+21985,U+219a7,U+219ab,U+219ae,U+219b6,U+219bc,U+219c3-219c4,U+219ce,U+219dd,U+219e9,U+219ef,U+219f2-219f3,U+219f8,U+21a02,U+21a04,U+21a0c,U+21a18,U+21a1a,U+21a25,U+21a27,U+21a34,U+21a38-21a3a,U+21a4f,U+21a5c,U+21a73,U+21a79,U+21a87,U+21a9c,U+21aa6,U+21abb,U+21ad0,U+21ae1,U+21ae8,U+21aeb,U+21af6,U+21b05,U+21b08,U+21b20,U+21b36-21b37,U+21b3c,U+21b49,U+21b55,U+21b5c,U+21b66-21b67,U+21b6c,U+21b70-21b72,U+21b74,U+21b7a-21b7b,U+21b7d-21b7e,U+21b84-21b8d,U+21b92-21b94,U+21b97-21b98,U+21b9b-21b9c,U+21b9e,U+21ba0,U+21ba3-21ba5,U+21ba8-21baf,U+21bb2-21bb3,U+21bb5-21bba,U+21bbd-21bbe,U+21bc0-21bc1,U+21bd3,U+21be5,U+21c0f,U+21c25,U+21c2a,U+21c2f,U+21c52,U+21c56,U+21c5d,U+21c69,U+21c6e,U+21c76,U+21c78,U+21c88,U+21ca4,U+21cab,U+21cc0-21cc1,U+21cc3,U+21cce,U+21cd2,U+21cdb-21cdd;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./e420493fe1a7e5b3eb2075166ceabe99.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+212b8,U+212bb,U+212c0-212c3,U+212c9,U+212d7,U+212e4-212e6,U+212f1,U+212f5,U+212fd-21300,U+2131b,U+21320-21323,U+21325,U+21327,U+21329,U+21336,U+2133d,U+2133f,U+21344,U+2134b,U+21358-2135f,U+21362-21363,U+2137c,U+2138b,U+21394,U+2139a-213a6,U+213ad,U+213b4,U+213ba,U+213c4-213c5,U+213c9,U+213d8,U+213db,U+213df-213e7,U+213ef,U+213fd,U+21413,U+21417-2141a,U+21421,U+21428,U+2142e-2142f,U+2143f,U+2144d-2144e,U+21452-21453,U+21456-21457,U+2145d-2145e,U+21462,U+21469-2146a,U+2146d-21475,U+21484,U+21494,U+21498,U+214aa-214af,U+214bb,U+214c1-214c8,U+214cb,U+214cd-214cf,U+214d4,U+214de-214e2,U+214e5,U+214ee-214ef,U+214f3,U+214fe,U+21503,U+2150d,U+21511-21512,U+21514,U+21516,U+2153f,U+21556,U+21569,U+21570,U+21575,U+21577,U+21582,U+21585,U+21587,U+2159e,U+215a1,U+215b1,U+215b5,U+215bc,U+215c2,U+215c4-215c5,U+215c9-215cb,U+215ce,U+215d0,U+215d5,U+215d7,U+215dc,U+215f6,U+21606,U+21624,U+2162e-2162f,U+21641,U+21647,U+2165c,U+21666-21667,U+21674-21675,U+21681,U+21683-21684,U+2168e,U+2169a;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./32619f026f9d0a4fa4231570f5f0609d.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+222df-222e0,U+222e8,U+222ec,U+222f4,U+22303,U+2230a,U+2230c,U+2230e,U+2231e,U+2232c,U+22342,U+2237c,U+22399,U+223a5,U+223a8,U+223ad,U+223bf,U+223d1,U+223e0-223e3,U+223ff,U+2240a,U+2240e,U+2241d,U+22482,U+22488,U+2248e,U+2249e,U+224cb,U+224fd,U+22519,U+2251b,U+22521,U+22550,U+22554,U+2255f,U+2256a,U+22578,U+22580,U+22595-22596,U+225ae,U+225b1,U+225b3,U+225b5,U+225ba-225bb,U+225c0,U+225c3,U+225c6-225c7,U+225c9,U+225cb-225cc,U+225d4,U+225d6,U+225da,U+225dd,U+225e0-225e1,U+225ed,U+225f0,U+225f7-225f8,U+225fc-225fe,U+22609,U+2261a,U+2261d,U+22620,U+22629,U+2262c-2262e,U+2263d-2263e,U+22647,U+22649,U+22650,U+22654-22655,U+2265d,U+22668-2266d,U+22671-22672,U+22675,U+2267c-2267d,U+22681,U+22684,U+22695,U+226a9,U+226b2-226b9,U+226bc-226bd,U+226c9,U+226cd,U+226d3,U+226de,U+226e8-226e9,U+226f3,U+22708,U+2270f,U+22717,U+2271c-2271e,U+22720-22722,U+2272b-2272c,U+2273d,U+22740,U+22746,U+22758-2275c,U+22763,U+22773,U+22777-22778,U+22782-22786,U+227ac,U+227b4,U+227ce-227db,U+227de-227df,U+227e6,U+227e8,U+227f2-227f3,U+227f9,U+22804-22809,U+22810,U+22823-2282a,U+2282f,U+22833,U+2283c,U+2283f,U+2285a-2285b,U+22860,U+2287f-22880;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./9c20e286c3fa7e1d5b35d88719a20be2.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+21cde,U+21ce2-21ce5,U+21ce9-21cea,U+21cf0,U+21cf3,U+21cf5-21cf6,U+21cf9-21cfa,U+21cfd,U+21d09,U+21d22,U+21d2d,U+21d2f,U+21d40,U+21d42,U+21d45-21d46,U+21d49,U+21d54,U+21d5b,U+21d62,U+21d78,U+21d7a,U+21d80-21d81,U+21d84,U+21d89,U+21d92,U+21d9c,U+21da1,U+21da8,U+21db7,U+21dd0,U+21dd5-21dd6,U+21dd9,U+21de0,U+21e01,U+21e07-21e08,U+21e0f,U+21e1c,U+21e25,U+21e2b,U+21e33-21e34,U+21e43,U+21e50,U+21e5e-21e61,U+21e75-21e76,U+21e84-21e85,U+21e90,U+21ea5-21ea6,U+21eb7,U+21ebd,U+21ec9,U+21ecb,U+21ece,U+21ed3-21ed7,U+21ed9,U+21ef0,U+21f07-21f08,U+21f17,U+21f1e,U+21f21,U+21f2a,U+21f31,U+21f38-21f39,U+21f3e,U+21f42,U+21f45,U+21f6b,U+21f76,U+21f7e,U+21f85,U+21f87,U+21f9c,U+21fa4,U+21fab-21fad,U+21fb5,U+21fbc,U+21fc3,U+21fc5,U+21fc7,U+21fe8,U+21fea,U+21ffa,U+22016,U+2201b,U+22025-22028,U+2202b-2202e,U+22031-22033,U+22037,U+2204a-2204b,U+2204d,U+22051,U+22068,U+2206f,U+22078,U+22083,U+2208c,U+2208e,U+2209c,U+2209e,U+220b0,U+220be,U+220c4,U+220c7-220ca,U+220d1,U+220db,U+220f1,U+22102-22103,U+22107,U+2210c,U+22113,U+2211d,U+22129,U+2212f,U+22138-22139,U+2213c,U+22140,U+22144-22146,U+22167,U+2216c-2216d,U+2217b,U+22181,U+22189,U+2218d,U+22193,U+22195,U+22198,U+221a0,U+221a5,U+221a7-221a8,U+221aa-221ab,U+221ad,U+221b6,U+221c7,U+221c9,U+221e1-221e2,U+221f1,U+22216,U+22218,U+22231,U+22251,U+22269,U+2227d-2227e,U+22291,U+22297,U+2229b,U+222ab-222ac,U+222c6;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./bb4ddfecdc76a3c508ef38aa636ff8b0.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+22881-22882,U+22884-2288b,U+228ab-228ac,U+228af,U+228b2,U+228bd,U+228c0,U+228c2-228c8,U+228da,U+228e7,U+228e9,U+228f8-228fb,U+2291b-22923,U+2292b,U+22932,U+2293d,U+22945-22949,U+22956,U+2295e,U+22962-22963,U+2296a,U+2296e,U+22975-22977,U+22982,U+22984,U+2298f,U+22991,U+229a4,U+229d0,U+229da,U+229dc,U+229f2-229f3,U+22a03,U+22a27,U+22a35,U+22a49-22a4a,U+22a5b-22a5c,U+22a63-22a64,U+22a67,U+22a6a-22a6b,U+22a6d-22a6e,U+22a71,U+22a73,U+22a75,U+22a7d-22a80,U+22a83,U+22a89-22a8a,U+22a8f,U+22a92,U+22a97,U+22a9b,U+22aa0,U+22aa5,U+22aaa,U+22aac-22ab3,U+22ab7-22ab8,U+22ac3-22ac6,U+22ac8,U+22aca,U+22acc,U+22ad1,U+22ad3-22ae1,U+22ae3,U+22ae6,U+22ae8,U+22aeb,U+22aee,U+22af5,U+22af8,U+22afc,U+22b02,U+22b04-22b05,U+22b07,U+22b10,U+22b14,U+22b17,U+22b22-22b29,U+22b2d-22b2e,U+22b31,U+22b33,U+22b36,U+22b38-22b39,U+22b3d-22b3f,U+22b41-22b42,U+22b44,U+22b46,U+22b4f-22b51,U+22b57,U+22b59,U+22b5c-22b5d,U+22b5f,U+22b62,U+22b69,U+22b6b-22b7b;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./484e19ceea9ebc1967db9ea50b60c05d.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+22b7c-22b81,U+22b8f-22b91,U+22b96,U+22ba5-22ba6,U+22ba9-22bad,U+22bb1,U+22bb5,U+22bbf,U+22bc2,U+22bca,U+22bcf-22be2,U+22be4-22be8,U+22bed-22bee,U+22bf0,U+22bf5,U+22bf7,U+22bfe,U+22c05,U+22c1d,U+22c24-22c26,U+22c32-22c33,U+22c36,U+22c3a,U+22c3d,U+22c4e-22c50,U+22c52-22c54,U+22c56-22c5f,U+22c62,U+22c64,U+22c68,U+22c78,U+22c90,U+22c94,U+22c9b-22c9d,U+22c9f-22ca0,U+22ca2-22caf,U+22cb1-22cb5,U+22cb8,U+22cbc-22cbe,U+22cc0-22cc4,U+22cc6,U+22cd9,U+22cdb,U+22cdd-22ce0,U+22ce5,U+22ce7,U+22cea-22cec,U+22cef,U+22cf3,U+22cf6,U+22d07,U+22d09-22d20,U+22d22-22d23,U+22d29,U+22d3e-22d3f,U+22d49,U+22d4b-22d4c,U+22d54,U+22d57,U+22d67-22d7a;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./a0fc5178820c3fe22931a4a416cbbd7c.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+22d7b-22d7d,U+22d80,U+22d82,U+22d86,U+22d90-22d92,U+22d9f,U+22da2-22da3,U+22da5,U+22db6-22dc6,U+22dcf,U+22dd3,U+22de1,U+22de3,U+22dec,U+22dee-22df5,U+22df9,U+22e18-22e22,U+22e2b,U+22e35,U+22e3e,U+22e42,U+22e45-22e4a,U+22e4c-22e4d,U+22e53,U+22e63-22e6b,U+22e6e,U+22e75,U+22e7d-22e81,U+22e8d-22e8e,U+22e90,U+22e92-22e94,U+22e96,U+22e9b-22e9d,U+22ea4,U+22eb3,U+22eb9-22eba,U+22ec3,U+22efb,U+22f02,U+22f04,U+22f0c,U+22f1b,U+22f32,U+22f38,U+22f59,U+22f5f-22f60,U+22f73,U+22f7c,U+22f96,U+22f9a,U+22fb0,U+22feb,U+22ffc,U+2301f,U+23054,U+23070,U+23072-23073,U+23083,U+2308b,U+23094,U+230aa,U+230b7,U+230ba,U+230e3-230e4,U+230f1-230f2,U+230fa,U+23107,U+23112,U+23116,U+23125,U+2312c,U+2313c,U+23140,U+23143,U+23145,U+2314b,U+23157-23159,U+23175-23176,U+23180,U+23185,U+2318f-23190,U+231a8,U+231ad-231b1,U+231b4,U+231b6,U+231c3-231c4,U+231ca,U+231d9,U+231dc-231e1,U+231eb,U+231f5,U+231fe,U+23206,U+23212-2321d;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./eb9ed37df25edfdfe8368a5c149ff5c6.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2359d-235a2,U+235a7,U+235ae,U+235b3,U+235be,U+235c4,U+235cb-235cc,U+235d1-235d3,U+235d6,U+235e5,U+235ea,U+235ec,U+235f1,U+235f3,U+235fc,U+235fe-235ff,U+23601-23604,U+23606,U+23608-2360a,U+2360c,U+23613,U+23617,U+2361a-2361b,U+23638-2363a,U+2363d-2363e,U+23641,U+23647,U+23659,U+2365c,U+23665-2366f,U+23677-2367a,U+236a6,U+236ad-236ae,U+236bd,U+236dc,U+236df-236ea,U+236ed-236f1,U+236f4,U+236ff,U+2370b-2370c,U+2371c,U+23730-23738,U+2373b,U+2373e-23740,U+23745,U+23749,U+23763-23764,U+23781-23785,U+2378a,U+2378d-2378e,U+237aa,U+237b6,U+237bb,U+237bd-237be,U+237c0,U+237c2-237c4,U+237db,U+237e7,U+237e9-237eb,U+237ed-237ee,U+237f1,U+237ff,U+23811-23813,U+23815-23818,U+23824,U+23829,U+23830-23833,U+23836,U+2383a,U+2383d,U+23853,U+23859-2385a,U+2385d,U+23864,U+2386d,U+2386f,U+23879,U+2389f,U+238b9,U+238f1,U+238fd,U+23924,U+23942,U+23950,U+23956,U+2395c,U+23966,U+23971,U+23986,U+2398d;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./3a1633e638424302cb8f8bc7a32bfb44.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2321e,U+23222,U+23230,U+2324d-23250,U+23255,U+23259,U+23270,U+23272-23279,U+2328b-2328e,U+23298,U+232a1,U+232be-232c3,U+232c6-232c7,U+232d1,U+232da-232dd,U+232e1,U+232e8-232e9,U+232f0-232f1,U+232fb-232fe,U+23300-23302,U+23306,U+23309,U+2330b-2330c,U+23312,U+23314,U+2331b,U+2331d,U+23322,U+23334-23336,U+23339-2333a,U+23340-23342,U+23345,U+2334a-2334b,U+2334d,U+23355,U+23357,U+23369-2336a,U+23372,U+23375,U+2337f-23380,U+23383,U+23386,U+2338a,U+2338f,U+23396,U+2339e-2339f,U+233a2-233a3,U+233a6,U+233ae,U+233b3-233b4,U+233b6-233b7,U+233c2,U+233cc,U+233d0,U+233d2-233d3,U+233d5,U+233da,U+233df,U+233e4,U+233fa,U+233fc,U+233fe-233ff,U+23404-23406,U+23409,U+23411,U+23419,U+2341d,U+23432-23434,U+2343d,U+23441,U+2344a-2344c,U+2344e,U+23451,U+23455,U+23461,U+23465-23466,U+2346f,U+23473-23477,U+2347a-2347b,U+23486,U+2349e,U+234a0,U+234a2-234a3,U+234b1-234b5,U+234be,U+234c3,U+234c6,U+234cb,U+234e4,U+234e6,U+234fb,U+234fe-234ff,U+2350b,U+23513,U+23519,U+2351d-2352a,U+23532,U+2353a-2353b,U+23543,U+2354c,U+23558,U+2355a,U+2355e,U+2356c,U+23572,U+23594,U+23596-2359c;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./52d63c155b3832f3998e180d53c28970.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+23f83,U+23fb4-23fb6,U+23fb8-23fbf,U+23fc3,U+23fc5,U+23fc7,U+23fcc,U+23fd0,U+23fd6,U+23fe6,U+24000,U+2400f-24010,U+24012-2401c,U+2401e,U+24039,U+2403c,U+2404b,U+24053-24059,U+2405b,U+24060-24061,U+24069,U+2406f,U+2407d,U+24082,U+24096,U+240a7-240ad,U+240b1,U+240b7,U+240ba,U+240db-240e2,U+240e4-240e5,U+240e7,U+24103,U+2410b-2410d,U+2412c-24132,U+24136-24137,U+24139,U+24149,U+24159,U+2415c,U+24163,U+24167,U+24176-24177,U+24182,U+24188,U+2418a,U+241a3,U+241ac,U+241b6-241bb,U+241c2,U+241c6,U+241ca,U+241cc-241cd,U+241d2,U+241da,U+241e2-241ea,U+241ee,U+241f2-241f3,U+241fd-241ff,U+24207,U+2420a,U+2420d,U+24210,U+24218,U+2421b-24221,U+24224,U+2422a,U+2423d,U+24247,U+24252-2425c,U+24260,U+24283-24284,U+24292,U+242a0-242ad,U+242b0,U+242b2,U+242b6,U+242ed-242ee,U+242f5-242f7;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./e39cf45a37d13e42ef1bcbfcc89b24a5.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2398e,U+23993,U+2399b,U+239ae,U+239b0,U+239bc,U+239fc,U+23a00,U+23a15,U+23a1f,U+23a27,U+23a2d-23a2e,U+23a30,U+23a41-23a42,U+23a52,U+23a66,U+23a78,U+23a8a,U+23a98,U+23aa0,U+23ac2,U+23ad5,U+23ade,U+23ae0,U+23af2,U+23af7,U+23b09,U+23b1a,U+23b43,U+23b52,U+23b5b-23b5c,U+23b67,U+23b6f-23b70,U+23b72,U+23b75,U+23b7b,U+23b87-23b88,U+23bae,U+23bb9,U+23be1,U+23c0f,U+23c19,U+23c25,U+23c33-23c35,U+23c46,U+23c6c-23c6d,U+23c70,U+23c75-23c76,U+23c7c-23c7f,U+23c86,U+23c8d,U+23c97-23c98,U+23ca0,U+23ca6,U+23ca9-23caa,U+23cad,U+23cb1-23cb2,U+23cb9,U+23cbe,U+23cc7,U+23cd4,U+23ce1-23ce2,U+23cea,U+23cee,U+23cfc,U+23cfe,U+23d00,U+23d0e,U+23d12-23d13,U+23d19-23d1c,U+23d23,U+23d40,U+23d5a,U+23d6e-23d70,U+23d72,U+23d74-23d76,U+23d88,U+23d92,U+23dd3,U+23de0,U+23ded-23df4,U+23df7,U+23df9-23dfa,U+23e0a,U+23e23,U+23e2c,U+23e32,U+23e53-23e59,U+23e5e-23e5f,U+23e8c,U+23ea5,U+23eba-23ebe,U+23ec0-23ec9,U+23ed1,U+23ed5-23ed7,U+23edf-23ee0,U+23ee2,U+23ef1,U+23ef8,U+23efc-23efe,U+23f0d-23f0e,U+23f15,U+23f27,U+23f29-23f34,U+23f36-23f3a,U+23f3c-23f3e,U+23f40,U+23f46,U+23f4a,U+23f57,U+23f64,U+23f7e;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./157b0c01e34c61bbf16bf381d1783ba8.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+242f8-242f9,U+242fb-24300,U+24304-24305,U+2430b,U+2430d,U+24323,U+2432d,U+24333,U+24343-24347,U+24349-2434c,U+2434e,U+24350-24352,U+24356,U+24360,U+24376,U+24389,U+2438d-24396,U+2439b-2439c,U+2439e,U+243b6,U+243bc,U+243d0,U+243d7,U+243e1-243e8,U+243ea-243ec,U+24403,U+24413,U+24419-2441d,U+24420-24421,U+24430,U+24434,U+24448-24449,U+2444d,U+2445a,U+2445f,U+2446b-2446f,U+24478,U+24498-2449b,U+2449f-244a0,U+244a2,U+244a6,U+244a9,U+244b2,U+244b4,U+244c6-244c7,U+244cc,U+244d3,U+244db,U+244e2,U+244e9,U+244fa,U+24504,U+2450c,U+24514,U+2451b,U+2452b,U+2452f,U+24537,U+2453b,U+2453d-2453e,U+24554,U+24564,U+24575,U+2457b,U+245a1,U+245a9,U+245b6,U+245b8-245b9,U+245c6,U+245d6,U+245e4,U+245ef,U+245f2,U+245f9,U+245fc-245fd,U+24601-24602,U+24605,U+2460b,U+24613,U+24629,U+2462f,U+24669,U+2466d-2466e,U+24686-24687,U+246a2,U+246a4-246a7,U+246c7,U+246d4,U+246d8,U+246de,U+246e0,U+24704-24705,U+24720,U+2472c-2472d,U+2472f,U+24731,U+24737,U+2474a-2474c,U+24751,U+24755-24756,U+2475e,U+2476b,U+24770,U+24779,U+2477d,U+24790-24796,U+24798,U+2479a,U+2479e,U+247a6-247ab,U+247ba-247bb;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./dc9bffd4adba5a13429d3d376757388d.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+247bc-247bd,U+247c2,U+247d4-247d5,U+247db,U+247ea,U+247f0-247f1,U+24802-24806,U+2480b,U+24815,U+24819-2481a,U+24824,U+24830-24834,U+24836-24839,U+24841,U+2484c,U+2485a-2485b,U+2486e,U+24871,U+24878,U+24886-24888,U+2488f,U+24894,U+24896-24897,U+248a4-248a5,U+248ab-248ac,U+248bd-248bf,U+248c4,U+248da,U+248e1,U+248e3,U+248e5,U+248e9,U+248ef,U+24930,U+2493b-2493c,U+24940,U+24951,U+2496b,U+2496d,U+24976,U+2499b,U+249aa,U+249b2,U+249db,U+24a0f,U+24a12,U+24a42,U+24a4d,U+24a7d,U+24a8a,U+24a99,U+24ab6,U+24ac9,U+24ada,U+24aff-24b00,U+24b08,U+24b2a,U+24b32,U+24b38,U+24b4f,U+24b56,U+24b5a-24b5b,U+24b6f,U+24b75-24b79,U+24b83-24b84,U+24b91-24b92,U+24b97-24b98,U+24b9e-24ba0,U+24ba5,U+24bbe-24bbf,U+24bc6,U+24bca,U+24bd1,U+24bd4,U+24bdd,U+24bdf,U+24be8-24be9,U+24bed,U+24bf0-24bf1,U+24bf6,U+24bfa,U+24bff,U+24c01,U+24c07-24c08,U+24c16,U+24c1c,U+24c1e,U+24c31,U+24c38,U+24c3b,U+24c48,U+24c4a,U+24c51,U+24c82,U+24c8c-24c8d,U+24cbc,U+24cbf,U+24cc4,U+24cc6-24cc7,U+24ce2,U+24ce7-24ce8,U+24cf0-24cf1,U+24cf7-24cf8,U+24cfd,U+24d01,U+24d0f,U+24d14,U+24d21,U+24d25,U+24d2a,U+24d2c-24d2d,U+24d30,U+24d32,U+24d35-24d36,U+24d3d,U+24d41,U+24d4a,U+24d4c,U+24d52,U+24d55-24d56,U+24d61-24d62,U+24d69-24d6a,U+24d73-24d74,U+24d76,U+24d79-24d7a,U+24d83-24d84,U+24d88,U+24d8a,U+24d8e-24d92,U+24d9b-24d9d;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./c0a32b0a6b6d1f871d967d5b0dff663d.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+24da0,U+24da3,U+24db8-24dbf,U+24dc2,U+24dc4,U+24dc6,U+24dcd,U+24dd2,U+24dd6,U+24dd9,U+24de2,U+24ded-24df6,U+24dff,U+24e01,U+24e04,U+24e0e,U+24e12,U+24e1a,U+24e27-24e28,U+24e2b,U+24e2d,U+24e31,U+24e37,U+24e3c,U+24e51-24e5a,U+24e5d,U+24e60,U+24e6a,U+24e7c-24e7f,U+24e85,U+24e8b,U+24e9b,U+24ea5,U+24eaa,U+24ead-24eb6,U+24eba,U+24ece-24ed4,U+24ee4,U+24eea-24eed,U+24ef3,U+24efb-24efe,U+24f13-24f15,U+24f1e,U+24f35,U+24f38,U+24f40,U+24f44,U+24f57,U+24f74-24f76,U+24f78,U+24f93-24f94,U+24f9f,U+24fa1,U+24fac,U+24faf-24fb1,U+24fb7,U+24fcd,U+24fe4,U+24fe6,U+24fec-24fed,U+24ff0-24ff2,U+24ffa,U+2500c,U+2502a,U+25033,U+2504a,U+25051,U+25053,U+25055,U+2505a,U+2505f,U+25077,U+25089,U+250d1,U+250da-250db,U+250de,U+250e6,U+250e8,U+250ed,U+250f1,U+250f8-250f9,U+25121-25122,U+25128,U+2512b-25130,U+25133,U+25135,U+2513b,U+25141-25143,U+25146,U+25148,U+25158,U+2515e,U+25161,U+25166,U+25172,U+2517d,U+2517f-25184,U+2519f,U+251a1,U+251a9,U+251b9-251c2,U+251c8,U+251cc-251cd,U+251da;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./670f9b976e2291044f4232414882a9f1.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+25596,U+2559c,U+255a2,U+255a7-255a9,U+255ad,U+255b8,U+255ba-255bb,U+255be,U+255c1,U+255cc-255d0,U+255dc,U+255e8-255e9,U+255f3,U+255f9,U+255fd-255fe,U+25600,U+25602-25603,U+25609,U+2560e,U+25611,U+2561e,U+2562a-2562b,U+2562f,U+25635-25638,U+2563d-2563e,U+2564a,U+25652-25653,U+25658-25659,U+25667,U+25669-2566a,U+2567f,U+25684-25687,U+25689,U+2569a-2569b,U+256a4-256a5,U+256af,U+256c9,U+256d7,U+256e3,U+256ed,U+256f2,U+25700,U+25713,U+25721,U+25724,U+2573f,U+2574c,U+2574e,U+25750,U+25757,U+2575d,U+25762,U+25765,U+25771,U+2577d,U+25781,U+2578a,U+2578d,U+25796,U+257a4,U+257a9-257aa,U+257b4-257b5,U+257c2,U+257c8-257ca,U+257cc-257cd,U+257f9,U+257fc,U+25812,U+2582c-2582f,U+2583b,U+25848,U+25856-25858,U+2585e,U+25873-25874,U+25882-25886,U+25889,U+2588d,U+25890,U+258ab-258ac,U+258b3,U+258b6,U+258d0-258d2,U+258d7-258d8,U+258db,U+258e7,U+258eb,U+258fd,U+2591a,U+2592e,U+25946,U+25949,U+25950,U+25994,U+25997,U+2599d,U+259c4,U+259cc,U+259ce,U+259d4,U+259e9-259ea,U+25a0b,U+25a27-25a28,U+25a47,U+25a49,U+25a4d,U+25a64,U+25a6f-25a70,U+25a7c,U+25a7e,U+25a85,U+25a98,U+25a9d-25a9e,U+25aaf,U+25ab1,U+25ab8,U+25acc,U+25ad7,U+25adf,U+25ae3-25ae4,U+25aeb,U+25aed,U+25af1,U+25af9,U+25b09-25b0b,U+25b0e,U+25b1d,U+25b25,U+25b27-25b29,U+25b2e,U+25b4c-25b4f,U+25b51,U+25b57,U+25b5b;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./1a24f8319b090733dc243ffae2bdeb1b.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+251db,U+251e5,U+251f6-251fd,U+251ff,U+25201,U+25217,U+2521c,U+2521e,U+25220,U+25222,U+2522c-2522d,U+2522f-25236,U+25239-2523a,U+2523f,U+25248,U+2524c,U+25265,U+25269-25270,U+25272,U+25274-25275,U+25278-25279,U+25280,U+25283,U+2528b,U+25296-252a6,U+252a9,U+252b3-252b4,U+252cf,U+252d2-252d5,U+252df,U+252f2-252fa,U+252fc-252fd,U+25300,U+25303,U+25308,U+2530e,U+25319-2531a,U+25325-25327,U+2532b-2532c,U+2532e,U+25337-25339,U+25349,U+2534d-2534e,U+25351-25353,U+25356,U+25358,U+2535b,U+25381,U+25389,U+253cb-253cd,U+253ff,U+25406-25409,U+2540b,U+2540d,U+2540f-25410,U+25412,U+25414,U+25426-25429,U+2542e,U+25430,U+25435,U+25439,U+25441-25443,U+2545a,U+25460,U+25465,U+2546d-2546e,U+25472,U+25474,U+25476,U+2547f,U+25482-25483,U+25485,U+2548e,U+25490,U+254a5-254a6,U+254ae,U+254b0,U+254c4-254c5,U+254c9,U+254d9,U+254f3-254f9,U+2550e,U+25518,U+2551c,U+25526-25527,U+25531-25532,U+2553f,U+25544,U+2554a,U+2554c-2554d,U+25562,U+25565,U+2557b,U+2558e,U+25590-25595;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./0f5584630f9a18264dbc824db0163df5.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+25b72,U+25b75-25b76,U+25b7c,U+25b87-25b8b,U+25b8e,U+25bb2,U+25bc7-25bcf,U+25bd2-25bd3,U+25bd8,U+25bdc-25bdd,U+25bdf,U+25be4-25be5,U+25be8,U+25bea,U+25bf0,U+25c04,U+25c06-25c08,U+25c0a,U+25c14,U+25c23,U+25c4b,U+25c64,U+25c68,U+25c6a-25c72,U+25c74,U+25cad,U+25cbb,U+25cc2-25cc5,U+25cc9,U+25ccd,U+25cd0-25cd1,U+25cd3,U+25cd7,U+25cfd,U+25d0a,U+25d17-25d1c,U+25d22,U+25d49,U+25d55-25d5b,U+25d7e-25d84,U+25d87,U+25da1,U+25dae-25db3,U+25dd2-25dd3,U+25de7-25de8,U+25dfa-25dfb,U+25e01,U+25e04,U+25e06,U+25e0d-25e0e,U+25e12-25e13,U+25e1d,U+25e20,U+25e24,U+25e2e,U+25e37,U+25e3f-25e40,U+25e4b,U+25e56,U+25e58-25e59,U+25e5e,U+25e60,U+25e62,U+25e65,U+25e6c,U+25e6f-25e70,U+25e75,U+25e78,U+25e7d,U+25e86,U+25e8a,U+25e8e-25e96,U+25e9d,U+25eae,U+25eb9-25ebb,U+25ebd-25ebf,U+25ec2,U+25ec7,U+25ed7-25ed9,U+25ee0-25ee3,U+25ee7-25ee8,U+25eee,U+25ef8-25efd,U+25f02,U+25f15-25f16,U+25f1c,U+25f23,U+25f31-25f33,U+25f44,U+25f47,U+25f4c-25f4e,U+25f54;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./574eab0ce394b614a96f1f892eb8c1da.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+25f5c,U+25f61-25f62,U+25f64,U+25f69-25f6a,U+25f75,U+25f83,U+25f87,U+25f8b,U+25f92,U+25f97-25f98,U+25f9b,U+25fb5,U+25fb8,U+25fbd-25fc4,U+25fcd,U+25fd4-25fd5,U+25fd7,U+25fe0-25fe1,U+25fe3-25fe5,U+25fef,U+25ff1,U+25ffa-25ffb,U+26005,U+26007,U+2600a-26011,U+26016-26017,U+2601a,U+26024,U+26028,U+2602a-2602b,U+26034-26035,U+26037,U+26039-26042,U+26045,U+26047-26048,U+26060,U+26062-26064,U+2606b,U+26078-2607f,U+26081,U+26084,U+26088,U+2609b,U+260a6,U+260af,U+260b3,U+260b6,U+260ba-260c2,U+260d0,U+260e1,U+260ed-260ef,U+260fe-26108,U+2610b-2610c,U+26113,U+2611e,U+26120,U+26123,U+26134-2613c,U+2613e-26140,U+26142-26144,U+2616d-26172,U+26174,U+26186,U+26195,U+26199-2619a,U+2619f,U+261b9-261bb,U+261c7,U+261d2,U+261d4,U+261ee-261ef,U+26202,U+26221-26224,U+2623d,U+26246,U+2624a,U+2624e,U+26257,U+2626a,U+26270-26271,U+26277,U+2627c,U+26286,U+26293,U+2629a-2629b,U+262b1,U+262c2,U+262d5,U+262e6,U+262f9,U+26349,U+2634c,U+2634e,U+26351-26352;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./31be335aeeddc00736677fd64170b78f.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+26355,U+2635b,U+2636b,U+2637a,U+2638d,U+2639b,U+263a1,U+263ac,U+263c1,U+263c4,U+263d2-263d3,U+26402,U+26407-26408,U+26443,U+26445,U+26457,U+26466,U+26489,U+2648d,U+264ae,U+264b5,U+264b9,U+264c5,U+264c7,U+264c9-264ca,U+264dc,U+264e1,U+264ff,U+26519,U+2652e,U+26533,U+2653b,U+26540,U+26545,U+26548,U+26557,U+2655b,U+2655f,U+26573,U+26587-26588,U+2658a-2658b,U+26591,U+265a1,U+265b1,U+265bb,U+265cc,U+265cf,U+265dd-265de,U+265e0,U+265ea,U+265fd,U+26612,U+26615,U+26627,U+2662d,U+26631,U+26635,U+26639-2663a,U+26640,U+26643,U+26646,U+2664c,U+2664f,U+2665c,U+2665f,U+26663-26666,U+26668,U+2666b,U+2666d,U+26672,U+26674,U+26676,U+26678,U+2667c,U+2667e,U+26688,U+2668f-26690,U+26694-26697,U+2669c,U+266a3,U+266ad,U+266b0,U+266b8,U+266c1,U+266c8-266cd,U+266d7,U+266dc,U+266de,U+266f1-266f2,U+266fa-26700,U+26706,U+26713,U+26718,U+2671d-2671e,U+26721,U+26739,U+26742-2674e,U+26751,U+26754,U+2675a,U+26762-26763,U+26765-26766,U+26769,U+2676c,U+26772,U+26779-2677a,U+2677c,U+26781,U+2678d-26794,U+2679b,U+267a2-267a3,U+267a7,U+267ad,U+267b2,U+267b9,U+267c2,U+267c9-267cb,U+267cd-267d2,U+267d6-267d8,U+267dc,U+267df,U+267e3,U+267e8,U+267ea,U+267f1,U+267f5-267fa;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./1c32d89246dbff1ee3ad3da0adb8c37a.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+267fb-267ff,U+26804,U+26806,U+26818,U+2681c,U+26825,U+2682f-26836,U+2683d,U+26841-26842,U+2684b,U+26859-2685a,U+2685e-26866,U+2686b-2686c,U+2686e-2686f,U+26872,U+26876,U+26884,U+26889,U+2689e-268a2,U+268a9,U+268b3-268b7,U+268be,U+268ce,U+268d5,U+268d7-268d9,U+268dd-268de,U+268ea,U+268f0,U+268fb,U+268fe,U+26900,U+2690e,U+2691e,U+26920,U+2692d-26931,U+26936,U+2693e,U+26943,U+26951,U+26954,U+2695a,U+2696f,U+26977,U+26999,U+269c3,U+269c8,U+269ca,U+269d8,U+269da,U+269dc-269dd,U+269e1,U+269e3,U+269f4,U+269f7,U+269fd,U+26a02,U+26a11,U+26a1c,U+26a1e,U+26a21-26a22,U+26a2d,U+26a34,U+26a3b,U+26a58,U+26a5a-26a5b,U+26a70,U+26a73,U+26a7c,U+26a82-26a83,U+26a8c,U+26a8f-26a91,U+26aa6,U+26aa8,U+26ab5,U+26ab7,U+26abb-26abc,U+26ad2,U+26ad8,U+26af8,U+26afc,U+26aff,U+26b01-26b02,U+26b06,U+26b0e,U+26b11,U+26b29,U+26b2b,U+26b36-26b3b,U+26b52,U+26b5c-26b5d,U+26b5f,U+26b66-26b67,U+26b6a,U+26b77,U+26b7b,U+26b8e,U+26bb2,U+26bb4,U+26bb7,U+26bd5,U+26bec,U+26c1f-26c24,U+26c26,U+26c29,U+26c2f,U+26c3a,U+26c4a,U+26c4c,U+26c73,U+26c7d,U+26c92,U+26c9e,U+26cad,U+26cb2,U+26cb5,U+26cb8-26cbc,U+26cbe-26cbf,U+26cc3,U+26cd9;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./d190f57ea6fb12edb014af25b281cce7.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+26cdd,U+26ce6,U+26d0b,U+26d24,U+26d29,U+26d36,U+26d3a,U+26d40,U+26d44,U+26d4f,U+26d57-26d5d,U+26d60,U+26d64,U+26d77,U+26d80,U+26d9a,U+26da4,U+26da6,U+26dbe,U+26de8-26de9,U+26deb-26def,U+26dfe,U+26e02,U+26e1b,U+26e30,U+26e40,U+26e4f,U+26e63,U+26e65,U+26e6f-26e71,U+26e73-26e7b,U+26e88,U+26e93,U+26e9c,U+26ea3,U+26ef3,U+26f03,U+26f07,U+26f14,U+26f1a-26f20,U+26f25,U+26f28,U+26f4e,U+26f54,U+26f70,U+26f89,U+26f94,U+26fa0,U+26fa2-26fa5,U+26fa7,U+26fbe,U+26fc4,U+26ff6-26ff8,U+2700f-27013,U+2701c,U+2701e-2701f,U+27026,U+27028,U+2702d,U+2703a,U+2703f,U+27042,U+27074-2707a,U+27081,U+27084,U+270ad,U+270c3,U+270cb-270cc,U+270d9,U+270f0,U+270f2,U+270f4-270f7,U+270fd,U+27108,U+2710a,U+2710d,U+27110-27111,U+27132-27135,U+27139,U+27155,U+2716b-2716d,U+27184,U+2718a,U+2718c,U+2719e,U+271ca,U+271cd,U+271f1,U+27223,U+27227,U+2723a,U+2723d,U+27242,U+27244,U+27248,U+27259-2725a,U+2725d,U+2725f,U+27266,U+2726c-2726d,U+27270,U+27274,U+2727b-2727c,U+2727e-2727f,U+27285,U+27289-2728c,U+2728e,U+27290,U+27295,U+27299,U+272a5,U+272b3,U+272b7,U+272ba;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./66468bde08975c7fb9955675c9508300.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+272c0-272c7,U+272c9,U+272cb,U+272cd,U+272d5,U+272d8,U+272df,U+272ec,U+272f5-27300,U+27302-27304,U+27307,U+2730d-2730e,U+27311,U+2732c,U+27338,U+27345-27350,U+27352,U+27354,U+2735d,U+2736d,U+27370,U+27374,U+2739b-2739d,U+273a0,U+273a4,U+273b4,U+273b7-273b8,U+273ba,U+273d9-273db,U+273de,U+273ef-273f7,U+273fe,U+27410,U+27412,U+27416-27417,U+27429,U+27438-2743f,U+27441-27443,U+27445-27446,U+27449,U+27450,U+27452,U+2745e,U+27485,U+27487,U+27489-2748d,U+274b2,U+274bd,U+274c5-274c7,U+274cd,U+274cf,U+274e6,U+274ed-274f0,U+274f8,U+2750b,U+27519-2751c,U+27527,U+2753e-27540,U+27543,U+2755a,U+2756c-2756d,U+27583,U+27590,U+275a6,U+275b0-275b1,U+275c5,U+275f1-275f2,U+275fc,U+27606-27607,U+27614-27615,U+2761a,U+2762a,U+27631-27632,U+27638,U+27640,U+27644,U+2765b-2765c,U+2765f,U+27668,U+27677,U+2767b-2767d,U+27682,U+27684,U+27693,U+2769f-276a0,U+276a2,U+276ad,U+276c2,U+276c6-276cc,U+276ce,U+276db,U+276de,U+276f6-276f7,U+276fc,U+2770e,U+27710,U+27716-27719;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./c7dfd0eac3d0914e175b550033db223e.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2771e,U+27723,U+27734-27735,U+27739-2773b,U+2774e,U+27752-27753,U+27761,U+27766-27769,U+27784,U+2778a-2778b,U+277a3-277a5,U+277b4,U+277be-277c1,U+277dd,U+27805,U+2784a,U+2785f,U+27888,U+27891,U+2789f,U+278b2,U+278d1,U+278d5,U+278e0,U+278e7,U+278f2-278f3,U+27901-27902,U+27925,U+2792d,U+27938-27939,U+27943,U+27947,U+27954,U+2796e,U+2797c,U+2797e,U+27985,U+27988,U+27994-27996,U+2799f,U+279b4,U+279bf-279c0,U+279c4,U+279ef,U+279f7-279f8,U+27a33,U+27a4a,U+27a53,U+27a59,U+27a63,U+27a84,U+27aa2,U+27aa8,U+27ace,U+27afc,U+27b30,U+27b99,U+27bb3,U+27bbe,U+27bc4-27bc5,U+27bc7,U+27be5,U+27bf5-27bf6,U+27c17,U+27c1c,U+27c26,U+27c28,U+27c2c,U+27c3c,U+27c3f,U+27c45,U+27c7a,U+27ca0,U+27cab,U+27caf,U+27cb8,U+27cbc,U+27cc2,U+27cc7,U+27ccb,U+27cd0,U+27cd8,U+27d0b,U+27d24,U+27d2a,U+27d31-27d32,U+27d38,U+27d3b,U+27d46,U+27d48,U+27d51,U+27d53,U+27d5f,U+27d64,U+27d73,U+27d84,U+27d98,U+27da0,U+27dab-27daf,U+27dd4,U+27ddd,U+27df8-27dfa,U+27dfe,U+27e10,U+27e12-27e13,U+27e1d,U+27e30,U+27e3a-27e3b,U+27e45,U+27e4b,U+27e59,U+27e5f,U+27e65-27e66,U+27e6a-27e6b,U+27e70-27e71,U+27e7b-27e7c,U+27e7f-27e83,U+27e95,U+27eae-27eaf,U+27ed0-27ed1,U+27ed7,U+27eda,U+27ee9-27eea,U+27eed,U+27ef4,U+27ef7,U+27f01,U+27f08,U+27f0b-27f0d,U+27f2f,U+27f47-27f48,U+27f8c,U+27fb7,U+27fbf,U+27fc6,U+27fd0-27fd1;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./a6d1f4ec04f98c95c3c2d972e8e2f5a7.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+27fd2,U+27fe8,U+27feb-27fed,U+27fef,U+27ff3,U+27ff9-27ffa,U+27ffc,U+28008,U+2800a-28012,U+28015,U+2801b,U+2801e,U+28024,U+28027,U+28030,U+28033,U+28039-2803f,U+28042,U+2804b,U+2804d-2804f,U+28051-28052,U+28058,U+2805d,U+2805f,U+28061,U+28065-28072,U+28077,U+28079,U+2807c,U+28089-2808a,U+28094-2809a,U+280ba-280bc,U+280be-280bf,U+280c2,U+280c5,U+280c8-280d1,U+280d3,U+280d5,U+280dd,U+280eb,U+280f0,U+280f4-280fe,U+28101-28103,U+28109-2810a,U+28111,U+28119,U+2811e-28120,U+2812e,U+28131,U+28133-28144,U+28147,U+28149,U+2814c-2814e,U+28150,U+28154,U+28157,U+2815e,U+28165,U+2816e,U+28170,U+28177-28183,U+2818f,U+28192-28193,U+2819b,U+2819d-281a5,U+281b6-281ba,U+281be-281c0,U+281c9,U+281cd,U+281d1-281d3,U+281d7-281d8,U+281db-281dc,U+281e3,U+281e9;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./8d9eafb2ff470ca0d0e3d98c4565cd49.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+281ec,U+281f5-281f9,U+28206-28207,U+2820a,U+28212,U+28251,U+28253,U+28256,U+2825e-28260,U+28263,U+28277,U+28282,U+2828b-2828c,U+2829a-2829b,U+282a0,U+282a5,U+282b0,U+282cc,U+282e3-282e4,U+282f3,U+28300,U+28318,U+28337,U+28344,U+28366,U+2836e,U+28386,U+2839e-283a0,U+283bf,U+283c4,U+283cd,U+283e7,U+28408,U+2840c,U+28413,U+2842e,U+28437-28438,U+2843a,U+2843f,U+28455,U+28457,U+2845c,U+28464-28465,U+28468,U+2846e,U+28470,U+28473-28474,U+2847a-2847b,U+28482,U+28487,U+28492,U+28496,U+2849e-2849f,U+284a3,U+284aa,U+284b8-284bb,U+284c8,U+284d0-284d1,U+284e1-284e2,U+284e6,U+284fd,U+28508-2850a,U+2850c-2850d,U+28528,U+2853e-28540,U+28561,U+2856b,U+2856d,U+28585,U+28587,U+28599,U+285a7-285a9,U+285b2,U+285bc,U+285bf,U+285c8-285ca,U+285db-285dc,U+285e0,U+285e5,U+285ee,U+285f5,U+285fa,U+28617-28618,U+28631,U+28638,U+2864c-2864d,U+28656,U+2865b,U+2865d,U+28665,U+2866a,U+28670,U+28678,U+2868c-2868d,U+28695,U+286ab,U+286b4,U+286d7,U+286e6,U+286eb-286ec,U+286ee,U+286fa,U+28710,U+28718-28719,U+2873c,U+2873e,U+2874e,U+2875b,U+2876d,U+28770,U+28772,U+28778,U+28798,U+287aa-287ab,U+287c3,U+287d1,U+287e0,U+287f0,U+287fc,U+28809,U+28823,U+2882f,U+28832-28835,U+28848-2884a,U+28855,U+28867-28868,U+2886f,U+2887c,U+28884,U+28887,U+28891,U+28895,U+2889f,U+288a5,U+288bb-288bc,U+288e4-288e5;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./8eeb103a079a95667b19ece9634d92fa.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+288e8,U+28914,U+28920,U+28927,U+2892e-28930,U+28935-28936,U+2893b-2893c,U+2893e,U+28941,U+28946,U+28949,U+2894c,U+28954,U+28956,U+28959,U+28967,U+2896b,U+2897f-28981,U+28987-28988,U+28999,U+2899f,U+289a1,U+289a5-289a7,U+289a9,U+289ac-289ad,U+289b5,U+289ba-289bb,U+289bd,U+289c0,U+289c6,U+289dd,U+289df-289e0,U+289e2-289e3,U+289ea,U+289f0-289f1,U+28a04-28a05,U+28a0f,U+28a1e,U+28a20,U+28a24,U+28a26-28a2b,U+28a32,U+28a37,U+28a43,U+28a50,U+28a56,U+28a60,U+28a71,U+28a80-28a81,U+28a99-28a9a,U+28a9d-28a9e,U+28aaa,U+28ab7,U+28ac0,U+28ac4-28ac5,U+28ac7-28acb,U+28acd,U+28ad1,U+28add,U+28ae4,U+28aea,U+28af5,U+28b08-28b0b,U+28b36,U+28b46,U+28b49,U+28b4d-28b4f,U+28b51-28b52,U+28b57,U+28b61,U+28b65,U+28b6c,U+28b7a,U+28b81,U+28b87-28b8e,U+28b90-28b92,U+28bb5-28bb6,U+28bb9,U+28bbb,U+28bc1,U+28bc3,U+28bd8,U+28bdb,U+28bef,U+28bf7-28bf9,U+28c07-28c08,U+28c1a,U+28c27,U+28c2a,U+28c32,U+28c34,U+28c3a,U+28c44,U+28c47,U+28c4d-28c4f,U+28c51,U+28c54,U+28c6a,U+28c7d-28c7e,U+28c85,U+28c96,U+28cb5,U+28cb7,U+28ccb,U+28cd2;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./ffec069a7f4b8247a54ffb00ccdd2517.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+28cda,U+28cdd,U+28ce6,U+28ce8,U+28cf0-28cf1,U+28cfd,U+28d10,U+28d26,U+28d63,U+28d71,U+28d8f,U+28d9b,U+28dc8,U+28dd1,U+28def,U+28df6,U+28dfa-28dfb,U+28e08,U+28e0d,U+28e0f,U+28e17,U+28e1a,U+28e1f,U+28e36,U+28e3e,U+28e6c,U+28e89,U+28e97,U+28e99,U+28e9f,U+28eaa,U+28ebb,U+28ec2,U+28eeb-28eec,U+28eee,U+28ef6,U+28f0b,U+28f12,U+28f1a,U+28f32,U+28f3c,U+28f7b,U+28f7e,U+28f88,U+28f8f,U+28ff8,U+28ffd,U+29033,U+29066,U+29071,U+29076,U+29080-29081,U+29084,U+29087,U+2908f-29091,U+29095,U+2909f-290a0,U+290ae,U+290b6,U+290f3,U+2910e-29114,U+29128,U+29130-29136,U+29140,U+29157-2915c,U+29179-2917b,U+2918b,U+2918d,U+2918f-29190,U+291a0-291a2,U+291a7,U+291aa,U+291ac,U+291b4,U+291b7,U+291be,U+291c5-291c6,U+291cd,U+291db,U+291e2,U+291ee,U+291f5,U+291f8,U+29202,U+29218,U+29223,U+29229-2922a,U+292a0,U+292b1,U+292bd-292be,U+292d8,U+292da,U+292e7,U+29302,U+29353,U+29362-29363,U+293f3,U+29401,U+2941b,U+29420,U+29445,U+2945a-2945b,U+29470,U+2947e,U+29490,U+294d0,U+294d9,U+294db-294dc,U+294f3,U+29508,U+29516-29517,U+29546,U+29573,U+29580,U+29585,U+295ab,U+295c0,U+295c2-295c4,U+295cf,U+295d7,U+295f4,U+29604,U+29623,U+29628-2962c,U+2964b-2964d,U+29655,U+29673,U+29679,U+2967b,U+2967f,U+2968f,U+29694,U+296a5-296a8;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./55d7dc450cfe4b5bd7a14bb9ac6a587e.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+296a9,U+296af,U+296b5,U+296b9,U+296c2-296c4,U+296d6,U+296dc,U+296e9,U+296f0,U+296f7-296f8,U+29703-29704,U+2970f,U+29711,U+29719,U+29720,U+2972a-2972b,U+29730,U+2973d,U+29747,U+2974e,U+29750,U+2976d,U+2977a-2977b,U+2979d,U+297af,U+297b7,U+297c2,U+297cb,U+297e1-297e2,U+297ec,U+297f5,U+297fb-297fc,U+29810,U+29813-29814,U+29818,U+29820,U+29827,U+29829,U+2982d,U+29831,U+29833-29835,U+29838,U+2984b,U+29850,U+2985c,U+29864,U+2986d,U+298a6,U+298ac,U+298c6,U+298c9,U+29915,U+2991a,U+29944,U+2996e,U+29993,U+299a2,U+299b2,U+299c4,U+299cd,U+29a1c,U+29a3c,U+29a47,U+29a54,U+29a56,U+29a66,U+29a69-29a6c,U+29a72,U+29a7f,U+29a9e,U+29aa2,U+29ab5,U+29abd,U+29adb,U+29ae6,U+29b06,U+29b08,U+29b11,U+29b2a,U+29b31,U+29b46,U+29b5c,U+29b75-29b77,U+29b94-29b95,U+29bad,U+29bb7,U+29bbf-29bc2,U+29be3,U+29bee-29bef,U+29bf9,U+29c03,U+29c08,U+29c18,U+29c5b,U+29c7b,U+29c89,U+29ca1,U+29ca5,U+29cb5,U+29cca,U+29cf3-29cf4,U+29d05,U+29d1a,U+29d2c,U+29d43,U+29d4b,U+29d56,U+29d5c-29d5e,U+29d6d,U+29d71,U+29d7d-29d7f,U+29d84,U+29d8b,U+29d94,U+29d96,U+29d9c-29d9d,U+29d9f,U+29da2,U+29daa,U+29db1,U+29db7,U+29dbd,U+29dc6-29dca,U+29dce,U+29dd5,U+29ddb,U+29de0,U+29df3,U+29df6,U+29df9,U+29e04,U+29e0e,U+29e10,U+29e15,U+29e18-29e19,U+29e2b,U+29e2d-29e38,U+29e3d-29e3e,U+29e41;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./14c90da3715c798b517d6444b6be4d63.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+29e44,U+29e49-29e4a,U+29e4c,U+29e4e,U+29e5d,U+29e64,U+29e69-29e70,U+29e79,U+29e8a-29e8d,U+29e97,U+29ea1-29eaa,U+29eae-29eaf,U+29eb1,U+29eb3,U+29eba,U+29ebf-29ec0,U+29ec4,U+29ed0-29ed5,U+29edb,U+29edf,U+29ee9-29eea,U+29ef9-29efc,U+29f00-29f01,U+29f08-29f09,U+29f17,U+29f22,U+29f24,U+29f27-29f28,U+29f37-29f38,U+29f48-29f4b,U+29f4d,U+29f4f,U+29f56-29f57,U+29f64-29f65,U+29f67,U+29f78,U+29f7e,U+29f83,U+29f8c,U+29f8f,U+29f9b,U+29fa7,U+29fb0,U+29fb7,U+29fbe,U+29fc7,U+29fce,U+29fd7-29fda,U+29fe0-29fe2,U+29fe8,U+29ff0,U+29ff6,U+29ffa,U+29fff,U+2a004-2a006,U+2a00a,U+2a00e,U+2a010,U+2a013,U+2a01a,U+2a01f,U+2a022,U+2a02c,U+2a02f,U+2a032,U+2a03d,U+2a03f-2a040,U+2a042-2a047,U+2a04b,U+2a04e,U+2a053,U+2a060,U+2a06d,U+2a073-2a076,U+2a082,U+2a08a,U+2a0a0,U+2a0a6,U+2a0ae-2a0b3,U+2a0b6,U+2a0c4,U+2a0cd,U+2a0cf,U+2a0d9,U+2a0db,U+2a0e3,U+2a0f1-2a0f2,U+2a0f4-2a0f5,U+2a0f9,U+2a0ff,U+2a102,U+2a105,U+2a10c,U+2a11e,U+2a124-2a128,U+2a12f,U+2a138,U+2a146,U+2a164-2a16d,U+2a174,U+2a18b;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./d2fbbbfeceaf7b84c28f965990541fb2.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2a854,U+2a857,U+2a85a,U+2a85d,U+2a865-2a866,U+2a869,U+2a86b,U+2a86d,U+2a86f,U+2a871,U+2a874-2a878,U+2a87b,U+2a87d-2a87f,U+2a883,U+2a886,U+2a888,U+2a88a,U+2a88c-2a88d,U+2a88f,U+2a892,U+2a896,U+2a898,U+2a89c,U+2a8a0-2a8a1,U+2a8a3-2a8a4,U+2a8b0-2a8b1,U+2a8b5,U+2a8b7,U+2a8c5,U+2a8c7-2a8c8,U+2a8cb,U+2a8d8,U+2a8e0,U+2a8ee,U+2a8f0-2a8f1,U+2a8f7,U+2a8fb,U+2a8fd-2a8fe,U+2a904,U+2a90d,U+2a917-2a918,U+2a920,U+2a924,U+2a927,U+2a932,U+2a937-2a938,U+2a93a-2a93b,U+2a93d-2a940,U+2a949,U+2a94c,U+2a951,U+2a954,U+2a958,U+2a95b,U+2a95e,U+2a964,U+2a96f,U+2a977,U+2a97b,U+2a97e,U+2a986,U+2a98d-2a98e,U+2a993,U+2a99a,U+2a99c,U+2a99f,U+2a9ad,U+2a9b2,U+2a9b8,U+2a9bc,U+2a9c4,U+2a9cd,U+2a9d8,U+2a9da,U+2a9e1,U+2a9e6,U+2a9e8,U+2a9f1,U+2a9f7-2a9f8,U+2a9fa-2a9fb,U+2a9fe-2aa01,U+2aa03-2aa06,U+2aa08,U+2aa10,U+2aa16-2aa17,U+2aa1a-2aa1e,U+2aa20,U+2aa23,U+2aa30,U+2aa36,U+2aa41,U+2aa4f,U+2aa54,U+2aa58,U+2aa5c,U+2aa64-2aa68,U+2aa6a,U+2aa6c-2aa6f,U+2aa71,U+2aa75,U+2aa7a,U+2aa7c,U+2aa80,U+2aa82,U+2aa84-2aa89,U+2aa8f,U+2aaa9-2aaab,U+2aab3,U+2aabb,U+2aac6,U+2aaca,U+2aad3,U+2aada,U+2aae2,U+2aae6-2aae7,U+2aaf5-2aaf6,U+2aafc,U+2ab0c,U+2ab0e-2ab0f,U+2ab14,U+2ab19,U+2ab21,U+2ab24,U+2ab26-2ab28,U+2ab2a-2ab2b,U+2ab2d-2ab2e,U+2ab32-2ab33,U+2ab36,U+2ab3a,U+2ab3c-2ab3d,U+2ab40,U+2ab43-2ab44,U+2ab47,U+2ab58,U+2ab5a;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./23da39a7aaeaea6ff42a2c74c980b1fc.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2a18f-2a190,U+2a192,U+2a19b,U+2a1a3-2a1a7,U+2a1af-2a1b0,U+2a1b9-2a1bd,U+2a1c7,U+2a1cb-2a1d0,U+2a1e8-2a1eb,U+2a1f1,U+2a1f4,U+2a23e-2a23f,U+2a265,U+2a269,U+2a274,U+2a27d-2a27e,U+2a285,U+2a2b2,U+2a2c6,U+2a2d6,U+2a2df,U+2a330,U+2a38c,U+2a39a,U+2a3ed,U+2a41d-2a41e,U+2a422,U+2a42c,U+2a437,U+2a43e,U+2a451,U+2a497,U+2a4b3,U+2a4b7,U+2a4bd,U+2a4c7-2a4c8,U+2a4ea-2a4eb,U+2a51a,U+2a51d,U+2a520-2a521,U+2a550,U+2a576,U+2a582,U+2a58e,U+2a590,U+2a598,U+2a5a0,U+2a5ab-2a5ad,U+2a5ba,U+2a5c0,U+2a5f1,U+2a602,U+2a60c,U+2a612,U+2a61a,U+2a62c,U+2a635,U+2a693-2a694,U+2a69a,U+2a6a4,U+2a6b2,U+2a6c5,U+2a6c7,U+2a700,U+2a702,U+2a708-2a709,U+2a712,U+2a715,U+2a718,U+2a71a-2a71b,U+2a71d,U+2a722,U+2a72f,U+2a734,U+2a736,U+2a73b,U+2a73e,U+2a744-2a745,U+2a748,U+2a74c,U+2a74e,U+2a755,U+2a75c,U+2a763,U+2a769-2a76a,U+2a76c,U+2a76e-2a76f,U+2a771,U+2a773,U+2a779,U+2a77b-2a77c,U+2a780-2a781,U+2a785,U+2a78d,U+2a7a0,U+2a7a7,U+2a7ad,U+2a7b4,U+2a7b7-2a7b8,U+2a7ba-2a7bd,U+2a7c0,U+2a7c2,U+2a7c4-2a7c6,U+2a7cf-2a7d0,U+2a7d2-2a7d3,U+2a7d5,U+2a7d9-2a7da,U+2a7dd-2a7de,U+2a7e1,U+2a7e4,U+2a7e6,U+2a7f3,U+2a7f6,U+2a7f8,U+2a7fc-2a7fd,U+2a809,U+2a817-2a818,U+2a81d-2a81e,U+2a820,U+2a822,U+2a82a,U+2a82d,U+2a831-2a832,U+2a83a,U+2a83f,U+2a844,U+2a846-2a84a,U+2a84d-2a84e,U+2a851;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./f9c9e61e077a1b1f88cb09f47ce6424e.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2ab5c,U+2ab5f,U+2ab61,U+2ab63,U+2ab65-2ab67,U+2ab69,U+2ab6b,U+2ab6d-2ab6f,U+2ab71,U+2ab73-2ab74,U+2ab78,U+2ab7c-2ab7d,U+2ab80-2ab81,U+2ab85-2ab86,U+2ab88,U+2ab8a,U+2ab8d,U+2ab8f,U+2ab91-2ab92,U+2ab94,U+2ab99-2ab9b,U+2ab9e,U+2aba1,U+2aba3,U+2aba6,U+2aba8-2aba9,U+2abab,U+2abad,U+2abaf,U+2abb3-2abb4,U+2abbb,U+2abbd,U+2abc8,U+2abca,U+2abd5,U+2abd7,U+2abdb,U+2abdd-2abde,U+2abeb,U+2abef-2abf0,U+2abf5,U+2abfc,U+2abfe,U+2ac02-2ac03,U+2ac07,U+2ac0f,U+2ac13,U+2ac1b,U+2ac1f,U+2ac24,U+2ac28-2ac29,U+2ac2c,U+2ac2e,U+2ac30,U+2ac39,U+2ac3f,U+2ac41-2ac42,U+2ac45-2ac46,U+2ac4a,U+2ac4e,U+2ac50,U+2ac55,U+2ac58,U+2ac5a,U+2ac5c,U+2ac5f,U+2ac61,U+2ac68,U+2ac6c,U+2ac6e-2ac6f,U+2ac73-2ac74,U+2ac80,U+2ac82,U+2ac87,U+2ac8d-2ac8e,U+2ac9d,U+2aca3,U+2acad,U+2acbb,U+2acbd-2acbe,U+2acc2,U+2accb-2accd,U+2acd4,U+2acd8,U+2acde,U+2ace8,U+2aceb,U+2acf4-2acf5,U+2acf9,U+2acfc,U+2ad01,U+2ad04,U+2ad09,U+2ad0f,U+2ad17-2ad18,U+2ad1c,U+2ad1e,U+2ad30,U+2ad39,U+2ad3b,U+2ad3d-2ad3e,U+2ad45,U+2ad4c,U+2ad5a,U+2ad5f-2ad60,U+2ad64,U+2ad66-2ad67,U+2ad6b-2ad6c,U+2ad6f-2ad70,U+2ad72-2ad73,U+2ad78,U+2ad8a-2ad8b,U+2ad8e-2ad90,U+2ad9a-2ad9b,U+2ad9f,U+2ada9,U+2adad,U+2adb5,U+2adbc,U+2adbe,U+2adc3-2adc4,U+2adc9,U+2add4,U+2add7-2add8,U+2adde,U+2ade1,U+2ade4-2ade7,U+2ade9-2adea,U+2adee,U+2adf0,U+2adf4,U+2adf6,U+2adf9,U+2ae01,U+2ae04,U+2ae0a,U+2ae14,U+2ae18,U+2ae1d,U+2ae1f,U+2ae27,U+2ae2f,U+2ae38,U+2ae3d,U+2ae42,U+2ae4e-2ae4f,U+2ae58,U+2ae5a,U+2ae5c,U+2ae5f,U+2ae63,U+2ae6a,U+2ae6f;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./83e58710417cc5489f01865de0f3315b.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2ae70,U+2ae77,U+2ae7e,U+2ae83,U+2ae87,U+2ae8d,U+2ae93,U+2ae96,U+2ae98,U+2ae9b,U+2aea4,U+2aea6,U+2aea8,U+2aeac,U+2aeb5,U+2aeb9,U+2aebf,U+2aec3,U+2aec6,U+2aec8-2aecb,U+2aed2,U+2aedf,U+2aef3,U+2af00,U+2af41,U+2af43-2af45,U+2af4b,U+2af4f-2af50,U+2af55-2af57,U+2af5b,U+2af5d-2af5e,U+2af60-2af61,U+2af63,U+2af68-2af69,U+2af6e-2af6f,U+2af71-2af73,U+2af75,U+2af78-2af79,U+2af7d,U+2af80-2af81,U+2af86,U+2af89,U+2af8b,U+2af9d,U+2af9f,U+2afa2,U+2afa4,U+2afa6,U+2afad-2afaf,U+2afba,U+2afc0-2afc1,U+2afc4,U+2afcd,U+2afcf-2afd0,U+2afd2,U+2afd7-2afd9,U+2afe3,U+2afe5,U+2afef,U+2aff1,U+2aff4,U+2aff8-2aff9,U+2affd,U+2b004-2b006,U+2b01f,U+2b022,U+2b025,U+2b035,U+2b045,U+2b04e,U+2b054,U+2b05a-2b05c,U+2b05e,U+2b067,U+2b06b,U+2b070,U+2b075,U+2b077,U+2b079,U+2b080,U+2b086,U+2b08b,U+2b095,U+2b097,U+2b099-2b09a,U+2b09d,U+2b0a8,U+2b0aa,U+2b0ae,U+2b0b1,U+2b0b4,U+2b0b7,U+2b0c0,U+2b0c8-2b0c9,U+2b0cb,U+2b0ce,U+2b0d0,U+2b0d2,U+2b0d4,U+2b0d6,U+2b0d8,U+2b0da,U+2b0e1,U+2b0e4,U+2b0e9,U+2b0eb,U+2b0ee,U+2b0f0,U+2b0f5-2b0f9,U+2b0fc,U+2b101-2b104,U+2b106,U+2b108-2b10c,U+2b10e,U+2b110-2b111,U+2b113,U+2b115,U+2b124,U+2b127-2b128,U+2b137-2b138,U+2b13a,U+2b13c,U+2b141,U+2b155-2b156,U+2b15a,U+2b15c-2b161,U+2b164,U+2b169,U+2b16b,U+2b16e-2b170,U+2b172,U+2b174,U+2b177-2b179,U+2b183,U+2b189,U+2b18d,U+2b198,U+2b19a,U+2b19c-2b19d;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./cf559fed49083aed57eb66c0591ca267.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2b50e,U+2b524,U+2b526,U+2b52c-2b52f,U+2b533-2b534,U+2b536-2b539,U+2b556,U+2b561,U+2b568,U+2b56a,U+2b570,U+2b572,U+2b578-2b579,U+2b57e,U+2b580,U+2b582,U+2b584,U+2b59a,U+2b5a6-2b5a7,U+2b5ae-2b5af,U+2b5b3,U+2b5bc-2b5c4,U+2b5cc,U+2b5d2-2b5d3,U+2b5d7,U+2b5e6-2b5e7,U+2b5eb,U+2b5f4,U+2b5f6-2b5fb,U+2b5fe,U+2b600,U+2b603,U+2b606,U+2b611,U+2b616,U+2b61c-2b61d,U+2b626-2b628,U+2b62a,U+2b62c,U+2b632,U+2b634-2b636,U+2b63c-2b63d,U+2b63f,U+2b642,U+2b64c-2b64d,U+2b64f,U+2b654,U+2b656,U+2b659,U+2b65b,U+2b660,U+2b67d,U+2b695-2b696,U+2b6ad,U+2b6b1,U+2b6b3,U+2b6b6,U+2b6c8,U+2b6ce,U+2b6d0,U+2b6d5,U+2b6dc,U+2b6e9,U+2b6ed,U+2b6f0,U+2b708,U+2b70d,U+2b717,U+2b727-2b728,U+2b72a,U+2b735-2b736,U+2b739,U+2b740,U+2b746,U+2b74f,U+2b751,U+2b753-2b754,U+2b758,U+2b75a-2b75c,U+2b765,U+2b776-2b777,U+2b779-2b77c,U+2b782,U+2b789,U+2b78b,U+2b78e,U+2b794,U+2b7a1,U+2b7a9,U+2b7ac,U+2b7af,U+2b7bc-2b7bd,U+2b7c2,U+2b7c5,U+2b7c9,U+2b7cc,U+2b7cf,U+2b7d2,U+2b7d8,U+2b7e6-2b7e7,U+2b7f0,U+2b7f7,U+2b7f9,U+2b7fc,U+2b803,U+2b806,U+2b809-2b80a,U+2b80d,U+2b813,U+2b817,U+2b81a-2b81c,U+2b820,U+2b822-2b823,U+2b825-2b827;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./d952c8b149c3d240ddebe4cfc3444f64.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2b829-2b82a,U+2b82e-2b830,U+2b833-2b834,U+2b837-2b83d,U+2b83f,U+2b841-2b842,U+2b847,U+2b849,U+2b84c-2b84e,U+2b850,U+2b854,U+2b85b,U+2b85d,U+2b860,U+2b866,U+2b86a,U+2b86d-2b86f,U+2b871-2b872,U+2b875,U+2b878,U+2b87b,U+2b87d-2b87e,U+2b88b,U+2b88d,U+2b896-2b897,U+2b89a,U+2b89e,U+2b8a0,U+2b8a7,U+2b8a9,U+2b8ab,U+2b8b1,U+2b8b5-2b8b6,U+2b8b8,U+2b8bc,U+2b8c2-2b8c3,U+2b8c5,U+2b8cf,U+2b8d1,U+2b8d9-2b8da,U+2b8dd-2b8de,U+2b8e2-2b8e3,U+2b8ea,U+2b8f0-2b8f1,U+2b8f4,U+2b8f8,U+2b8fd,U+2b8ff,U+2b901,U+2b904,U+2b908-2b909,U+2b90b,U+2b90d-2b90f,U+2b911-2b913,U+2b915,U+2b920-2b922,U+2b924,U+2b929,U+2b92d,U+2b92f,U+2b93e,U+2b94a,U+2b94c,U+2b94e-2b94f,U+2b955-2b956,U+2b958,U+2b95b-2b95c,U+2b95e,U+2b963,U+2b965,U+2b967-2b96b,U+2b96e-2b96f,U+2b974,U+2b978,U+2b97f,U+2b987,U+2b989,U+2b991,U+2b993,U+2b998,U+2b9a2,U+2b9a8,U+2b9ac,U+2b9b5,U+2b9bb,U+2b9bd,U+2b9c2,U+2b9c7,U+2b9dc,U+2b9df,U+2b9e3,U+2b9e6-2b9e7,U+2b9ea,U+2b9ec-2b9ed,U+2b9fe,U+2ba05,U+2ba0e,U+2ba29,U+2ba2b,U+2ba2e-2ba30,U+2ba49,U+2ba4d,U+2ba4f,U+2ba51-2ba53,U+2ba56,U+2ba5d,U+2ba5f-2ba61,U+2ba6b,U+2ba71,U+2ba78,U+2ba7a,U+2ba80-2ba81,U+2ba84,U+2ba8c,U+2ba8e-2ba90,U+2ba97,U+2baa3,U+2baa5-2baa6,U+2bab1,U+2bab8-2bab9,U+2babf-2bac0,U+2bac5,U+2bac7,U+2baca,U+2bad1-2bad2,U+2bad6-2bad7,U+2badb-2badc,U+2bade-2badf,U+2bae1-2bae5,U+2baeb,U+2baed,U+2baf4-2baf5,U+2baf7,U+2baf9;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./b37095bb5c8e11d7940d34435990a80c.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2b1a1-2b1a2,U+2b1a5-2b1a7,U+2b1ad-2b1ae,U+2b1b2,U+2b1b4,U+2b1b6,U+2b1b8,U+2b1bb,U+2b1bd,U+2b1bf-2b1c2,U+2b1c4,U+2b1c8,U+2b1ca,U+2b1cc-2b1cd,U+2b1d0,U+2b1d6,U+2b1de-2b1df,U+2b1e1,U+2b1e5-2b1e6,U+2b1ed,U+2b1f0,U+2b1f3,U+2b1f6,U+2b1ff-2b200,U+2b203,U+2b205,U+2b208-2b209,U+2b218,U+2b21c,U+2b223,U+2b225,U+2b228,U+2b23a,U+2b245,U+2b24e,U+2b253,U+2b255,U+2b25a,U+2b261,U+2b269,U+2b26e,U+2b271,U+2b273,U+2b278,U+2b27c,U+2b281,U+2b28d,U+2b290,U+2b294,U+2b29a,U+2b2a5,U+2b2a9,U+2b2ab-2b2ac,U+2b2ae-2b2af,U+2b2b4,U+2b2b6,U+2b2b9,U+2b2bd,U+2b2bf,U+2b2d0-2b2d1,U+2b2d9,U+2b2df,U+2b2e8-2b2e9,U+2b2f6,U+2b2fb,U+2b2ff-2b300,U+2b304,U+2b307,U+2b30c,U+2b30e-2b310,U+2b312,U+2b315-2b317,U+2b320,U+2b322,U+2b335-2b337,U+2b34d,U+2b355,U+2b35a,U+2b35d,U+2b363,U+2b36f,U+2b372,U+2b37d,U+2b384,U+2b387,U+2b391,U+2b396,U+2b39a,U+2b3a4,U+2b3b0,U+2b3bc,U+2b3c4,U+2b3c8,U+2b3ca-2b3cd,U+2b3d3,U+2b3d6,U+2b3d9-2b3da,U+2b3dd,U+2b3e0,U+2b3e2,U+2b3e5-2b3e6,U+2b3ea,U+2b3f1,U+2b3fb,U+2b3fd-2b3fe,U+2b402,U+2b404,U+2b410,U+2b413,U+2b41a,U+2b41d,U+2b41f,U+2b42a,U+2b42d-2b42e,U+2b434,U+2b437,U+2b439,U+2b43b,U+2b43d-2b440,U+2b443,U+2b445-2b447,U+2b44a-2b44c,U+2b44f-2b453,U+2b461,U+2b46f,U+2b471,U+2b479-2b47a,U+2b47d-2b47e,U+2b482-2b483,U+2b485,U+2b4a5,U+2b4a8,U+2b4ac,U+2b4be,U+2b4c6,U+2b4cf,U+2b4d2,U+2b4d4,U+2b4df,U+2b4e7,U+2b4ef,U+2b4f1,U+2b4f6,U+2b4f9,U+2b50d;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./5e0d52f6a74712550a0f0baf16473754.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2bafa,U+2bafd,U+2bb0a,U+2bb0d-2bb0e,U+2bb11,U+2bb15,U+2bb20,U+2bb22-2bb23,U+2bb29-2bb2a,U+2bb2d-2bb2e,U+2bb31,U+2bb45,U+2bb50,U+2bb54-2bb55,U+2bb5a,U+2bb5f,U+2bb62,U+2bb67,U+2bb75,U+2bb7b-2bb7c,U+2bb7f,U+2bb82-2bb83,U+2bb87,U+2bb8b,U+2bb90,U+2bb92,U+2bba1-2bba2,U+2bba5-2bba6,U+2bbb1-2bbb2,U+2bbb7,U+2bbb9,U+2bbc5-2bbc7,U+2bbca,U+2bbcd,U+2bbcf-2bbd0,U+2bbd3,U+2bbd5-2bbd7,U+2bbd9,U+2bbdc-2bbdd,U+2bbe1,U+2bbe7-2bbe9,U+2bbf0,U+2bbf2-2bbf3,U+2bbf5,U+2bbff,U+2bc04-2bc08,U+2bc15,U+2bc1b,U+2bc1d,U+2bc1f,U+2bc23-2bc24,U+2bc2e,U+2bc34,U+2bc38,U+2bc45,U+2bc4b,U+2bc4f,U+2bc51,U+2bc57,U+2bc5e,U+2bc64,U+2bc69-2bc6a,U+2bc6c,U+2bc78,U+2bc83,U+2bc8d-2bc8e,U+2bca2,U+2bca4,U+2bcac,U+2bcb0,U+2bcb2-2bcb4,U+2bcb7,U+2bcbc,U+2bcd8,U+2bced,U+2bcf5,U+2bcf9,U+2bd07,U+2bd0b,U+2bd10,U+2bd1a,U+2bd1e,U+2bd2c-2bd2f,U+2bd31-2bd33,U+2bd35-2bd3a,U+2bd3c,U+2bd3e-2bd40,U+2bd43-2bd44,U+2bd47-2bd4b,U+2bd4d,U+2bd56,U+2bd5b,U+2bd5e-2bd5f,U+2bd61-2bd62,U+2bd65-2bd68,U+2bd6b,U+2bd6d,U+2bd77,U+2bd87,U+2bd9c,U+2bda7,U+2bdac,U+2bdae-2bdb1,U+2bdb3-2bdb5,U+2bdb7-2bdb8,U+2bdba-2bdbc,U+2bdbe,U+2bdc0,U+2bdc4,U+2bdc6-2bdc7,U+2bdca,U+2bdcd,U+2bdcf-2bdd0,U+2bdd3,U+2bdd7,U+2bdd9-2bdda,U+2bddc-2bdde,U+2bde4,U+2bde8,U+2bdf0,U+2bdf7,U+2be08-2be10,U+2be15,U+2be29;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./85e4c903043684827de6b47b53197515.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2c197-2c198,U+2c19c,U+2c19e,U+2c1ae,U+2c1b8,U+2c1c1,U+2c1c5-2c1c6,U+2c1c9,U+2c1cb,U+2c1cf,U+2c1d1-2c1d3,U+2c1d5,U+2c1d9-2c1da,U+2c1e3,U+2c1e7,U+2c1e9,U+2c1eb,U+2c1ed-2c1ee,U+2c1f8-2c1f9,U+2c205,U+2c208-2c209,U+2c20b,U+2c20e,U+2c213,U+2c217,U+2c21a,U+2c21e,U+2c222,U+2c224,U+2c22b,U+2c22d,U+2c230,U+2c234,U+2c23a,U+2c23c-2c23d,U+2c23f,U+2c242,U+2c246,U+2c24a,U+2c24c,U+2c24e,U+2c259-2c25a,U+2c25d,U+2c263-2c264,U+2c26a,U+2c270,U+2c275,U+2c27c-2c27d,U+2c281,U+2c288,U+2c28a,U+2c290,U+2c2a0,U+2c2a2,U+2c2a4,U+2c2a8,U+2c2ac-2c2ad,U+2c2b4,U+2c2bd,U+2c2c6,U+2c2cc,U+2c2d7,U+2c2d9-2c2da,U+2c2df,U+2c2e8-2c2e9,U+2c2eb,U+2c2f0-2c2f3,U+2c2fc,U+2c30c,U+2c313-2c318,U+2c323,U+2c325,U+2c32a,U+2c331,U+2c334,U+2c33d,U+2c340,U+2c344,U+2c346,U+2c349,U+2c34c-2c34f,U+2c359,U+2c35b,U+2c361,U+2c364,U+2c3a3,U+2c3ad,U+2c3b3,U+2c3b5-2c3b9,U+2c3bb-2c3bd,U+2c3bf,U+2c3c7,U+2c3ca,U+2c3cc,U+2c3ce,U+2c3d1-2c3d3,U+2c3d6-2c3d8,U+2c3da,U+2c3dd,U+2c3e0-2c3e1,U+2c3e4-2c3e5,U+2c3e7-2c3ea,U+2c3ef,U+2c3f1,U+2c3f4,U+2c3f8,U+2c3fb,U+2c3fd,U+2c402,U+2c406,U+2c409,U+2c40c,U+2c40e,U+2c410,U+2c412,U+2c41a,U+2c429,U+2c438,U+2c43c,U+2c43e,U+2c445,U+2c449,U+2c451,U+2c45a-2c45b,U+2c465-2c466,U+2c469,U+2c46b,U+2c470,U+2c475-2c476,U+2c478,U+2c47e,U+2c482,U+2c488,U+2c48a,U+2c48f,U+2c494,U+2c497,U+2c499,U+2c49c,U+2c49e,U+2c4a7,U+2c4a9,U+2c4ad,U+2c4b1,U+2c4b8,U+2c4bc-2c4bf,U+2c4c6;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./12fc8a1ba2e8085636178565b9e07748.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2be2c,U+2be31,U+2be36-2be37,U+2be3d,U+2be3f,U+2be44,U+2be4a,U+2be4d,U+2be57,U+2be63,U+2be66,U+2be6d,U+2be6f-2be70,U+2be75,U+2be88,U+2be8e,U+2be92-2be93,U+2be99,U+2bea6-2bea7,U+2beab,U+2beb1-2beb2,U+2beb4,U+2beb6,U+2bebc-2bebe,U+2bec2,U+2bec5,U+2bec8-2bec9,U+2becb,U+2bece,U+2bed0,U+2bed4,U+2bedc,U+2bee0,U+2bee3-2bee4,U+2befd,U+2bf0c,U+2bf0e,U+2bf14,U+2bf19-2bf1c,U+2bf20,U+2bf22,U+2bf29,U+2bf2c,U+2bf30,U+2bf32-2bf34,U+2bf39,U+2bf3b,U+2bf3f,U+2bf42,U+2bf44-2bf46,U+2bf4c,U+2bf4e,U+2bf51,U+2bf55-2bf58,U+2bf5a,U+2bf5d,U+2bf5f-2bf61,U+2bf64,U+2bf66,U+2bf6d,U+2bf6f-2bf70,U+2bf76,U+2bf79-2bf7b,U+2bf7f,U+2bf84,U+2bf86,U+2bf8c-2bf8d,U+2bf90-2bf91,U+2bf95-2bf97,U+2bf9b,U+2bf9d,U+2bfad,U+2bfc7,U+2bfd0,U+2bfd2,U+2bfd5,U+2bfdf,U+2bfe8,U+2bff0,U+2bff4,U+2bff8-2bff9,U+2bfff,U+2c01b,U+2c026,U+2c029-2c02b,U+2c02d,U+2c030,U+2c036-2c037,U+2c03a,U+2c040,U+2c044,U+2c049-2c04a,U+2c04c,U+2c04e-2c052,U+2c056,U+2c05a,U+2c05c,U+2c05f,U+2c065,U+2c06b,U+2c06e,U+2c076-2c077,U+2c07b-2c07c,U+2c086-2c087,U+2c08c-2c08d,U+2c091,U+2c099,U+2c0a5,U+2c0a8-2c0a9,U+2c0ad,U+2c0b8,U+2c0bb,U+2c0bd,U+2c0c0,U+2c0c2,U+2c0c8,U+2c0ca,U+2c0d1,U+2c0d7,U+2c0e0,U+2c0e4,U+2c0ea,U+2c0f1,U+2c0f4,U+2c0fb,U+2c101,U+2c103,U+2c105,U+2c10f,U+2c113,U+2c11e,U+2c126,U+2c128,U+2c12a,U+2c12e-2c12f,U+2c131,U+2c139-2c13a,U+2c140,U+2c142,U+2c149,U+2c153,U+2c167,U+2c173,U+2c177,U+2c17d,U+2c183-2c186,U+2c188,U+2c18e,U+2c192;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./3601fdd91265f229e7df227aabf2d432.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2c4d0,U+2c4d3,U+2c4d7,U+2c4db,U+2c4de-2c4e0,U+2c4f0,U+2c4f2,U+2c508,U+2c50b-2c50c,U+2c511-2c512,U+2c514-2c515,U+2c517,U+2c519,U+2c526,U+2c52b,U+2c537,U+2c53d,U+2c542,U+2c54c,U+2c553,U+2c568-2c569,U+2c56b,U+2c570,U+2c573,U+2c577,U+2c579-2c57c,U+2c57e,U+2c585-2c586,U+2c589,U+2c58b,U+2c58f,U+2c593,U+2c597,U+2c5a9-2c5ab,U+2c5ad-2c5ae,U+2c5b0,U+2c5b5,U+2c5b7,U+2c5bc-2c5be,U+2c5c0-2c5c2,U+2c5c4,U+2c5c6,U+2c5ca,U+2c5cf,U+2c5d3-2c5d4,U+2c5d7,U+2c5d9,U+2c5dc-2c5dd,U+2c5df-2c5e0,U+2c5e2,U+2c5e5,U+2c5e9,U+2c5f0-2c5f2,U+2c5f5,U+2c5fb,U+2c5fd,U+2c600,U+2c602,U+2c605,U+2c60a-2c60b,U+2c60f,U+2c613,U+2c618,U+2c621,U+2c629,U+2c62b-2c62d,U+2c62f,U+2c642,U+2c64a-2c64b,U+2c64e,U+2c656,U+2c659-2c65b,U+2c65e,U+2c674-2c675,U+2c67a,U+2c67c-2c682,U+2c686-2c688,U+2c68a,U+2c68c-2c68e,U+2c69e,U+2c6a3,U+2c6af,U+2c6b1-2c6b2,U+2c6b4,U+2c6b6,U+2c6b8,U+2c6ba,U+2c6bc,U+2c6bf-2c6c0,U+2c6c3,U+2c6c8-2c6ca,U+2c6ce-2c6cf,U+2c6d1-2c6d3,U+2c6d5,U+2c6d7-2c6d9,U+2c6db,U+2c6dd-2c6e1,U+2c6e6,U+2c6e9,U+2c6ef,U+2c6f2,U+2c706-2c707,U+2c709-2c70a,U+2c70c,U+2c70e,U+2c711,U+2c715,U+2c71b-2c71e,U+2c725,U+2c728,U+2c72c,U+2c72f,U+2c734,U+2c73d,U+2c749,U+2c750,U+2c757,U+2c761,U+2c76e,U+2c774,U+2c781,U+2c78a,U+2c78e,U+2c791,U+2c795,U+2c79a,U+2c79f-2c7a0,U+2c7a2,U+2c7a8,U+2c7ae;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./1d4831145ec4e15ec8ea49b2cbf415b0.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2cb64,U+2cb69,U+2cb6c,U+2cb6f,U+2cb73,U+2cb76,U+2cb78,U+2cb7c,U+2cb8c,U+2cb8e,U+2cb99,U+2cb9c,U+2cba4,U+2cba6,U+2cbab,U+2cbb1,U+2cbb6-2cbb7,U+2cbbf-2cbc0,U+2cbc4,U+2cbce,U+2cbdc,U+2cbe9,U+2cbf0,U+2cbf5,U+2cbf9,U+2cbfd,U+2cc05-2cc06,U+2cc09-2cc0a,U+2cc0c,U+2cc13,U+2cc16,U+2cc18,U+2cc1b,U+2cc20,U+2cc22,U+2cc39,U+2cc4d-2cc4e,U+2cc56,U+2cc5f,U+2cc76,U+2cc79,U+2cc7b,U+2cc81-2cc84,U+2cc87-2cc8a,U+2cc91,U+2cc9e,U+2cca0,U+2cca2,U+2ccd5-2ccd6,U+2ccd8-2ccdc,U+2cce0,U+2cce4,U+2cce6,U+2cce8,U+2ccf5-2ccf6,U+2ccfd,U+2ccff,U+2cd02-2cd03,U+2cd0a,U+2cd11,U+2cd13,U+2cd15,U+2cd1a,U+2cd1d,U+2cd1f,U+2cd2b,U+2cd2d,U+2cd42,U+2cd4e,U+2cd52,U+2cd58,U+2cd64,U+2cd7f,U+2cd8b,U+2cd8d,U+2cd8f-2cd90,U+2cd9f-2cda0,U+2cda8,U+2cdad-2cdae,U+2cdca,U+2cdce,U+2cdd5,U+2cdde,U+2cde4,U+2cde8-2cde9,U+2cdec,U+2cdf2,U+2cdf6,U+2cdf8,U+2ce18,U+2ce1a,U+2ce23,U+2ce26,U+2ce2a,U+2ce34,U+2ce3c,U+2ce40,U+2ce4f,U+2ce53,U+2ce59,U+2ce6f-2ce70,U+2ce77,U+2ce79,U+2ce7c,U+2ce88,U+2ce8d,U+2ce93,U+2ce97-2ce9a,U+2ced7,U+2cf00,U+2cf65,U+2cf6d,U+2cf7e,U+2cf9f-2cfa0,U+2cfc8,U+2cfd5,U+2cfe6,U+2cfea,U+2cfee,U+2cffa,U+2d026,U+2d030,U+2d048,U+2d08b,U+2d0c2,U+2d0cb,U+2d101,U+2d13f,U+2d176,U+2d1ba,U+2d1bf,U+2d1d3,U+2d1db,U+2d1ec,U+2d1ee;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./79d185637935373bdf9533292e4032db.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2c7b0,U+2c7b2,U+2c7b7,U+2c7ba,U+2c7c1,U+2c7c3,U+2c7ce,U+2c7d2,U+2c7d6-2c7d7,U+2c7d9,U+2c7db-2c7dc,U+2c7e0,U+2c7e3,U+2c7e5,U+2c7f4-2c7f5,U+2c7fb-2c7fd,U+2c804,U+2c807,U+2c80c,U+2c813,U+2c822,U+2c825,U+2c828-2c829,U+2c82b-2c82c,U+2c831-2c833,U+2c836,U+2c83a,U+2c83c-2c83d,U+2c84b,U+2c85e-2c85f,U+2c862-2c863,U+2c86c,U+2c86e-2c870,U+2c874,U+2c876,U+2c879,U+2c880,U+2c897,U+2c89e,U+2c8b1,U+2c8b3,U+2c8c8,U+2c8cc,U+2c8ce,U+2c8d9,U+2c8de,U+2c8e1,U+2c8e3,U+2c8f3,U+2c900,U+2c907,U+2c90a,U+2c91d,U+2c934,U+2c93b,U+2c946,U+2c953-2c954,U+2c960,U+2c963,U+2c96a,U+2c96c,U+2c96e-2c96f,U+2c983-2c984,U+2c98b,U+2c997,U+2c9a1-2c9a2,U+2c9a8,U+2c9aa,U+2c9ae,U+2c9b3,U+2c9b6,U+2c9b8-2c9b9,U+2c9bc,U+2c9bf,U+2c9c6-2c9c7,U+2c9d0,U+2c9d2,U+2c9d5-2c9d9,U+2c9dc,U+2c9e1,U+2c9ee,U+2c9f2,U+2c9fa-2c9fb,U+2c9fe,U+2ca02,U+2ca0e,U+2ca16,U+2ca19,U+2ca1f-2ca20,U+2ca22,U+2ca28-2ca29,U+2ca2c,U+2ca2e,U+2ca31,U+2ca33-2ca35,U+2ca38,U+2ca3a-2ca3b,U+2ca3f-2ca41,U+2ca44-2ca45,U+2ca47,U+2ca49,U+2ca4b-2ca4d,U+2ca4f-2ca50,U+2ca53-2ca55,U+2ca5b,U+2ca5f,U+2ca62,U+2ca65,U+2ca67,U+2ca69,U+2ca6d,U+2ca6f,U+2ca71-2ca72,U+2ca7d,U+2ca81,U+2ca84,U+2ca90,U+2caa9-2caaa,U+2caae,U+2cabc-2cabe,U+2cac0,U+2cac2,U+2cac9,U+2cacd,U+2cada,U+2cae4,U+2cb03,U+2cb0c,U+2cb10,U+2cb29,U+2cb2d-2cb2e,U+2cb31,U+2cb38-2cb39,U+2cb3b,U+2cb3f,U+2cb41,U+2cb4a,U+2cb4d-2cb4e,U+2cb56,U+2cb5a-2cb5b;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./72d576bfc4c4aaea6258c2e5fe1a5174.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2d1f6,U+2d1f9-2d1fa,U+2d202,U+2d212,U+2d216,U+2d219,U+2d21c,U+2d237,U+2d23b,U+2d23f,U+2d246,U+2d24f,U+2d251,U+2d25d,U+2d269-2d26b,U+2d272,U+2d274,U+2d277,U+2d295,U+2d29b,U+2d2aa-2d2ab,U+2d2b3,U+2d2bb,U+2d2c8,U+2d2d9,U+2d2fc,U+2d304,U+2d307,U+2d31f,U+2d332,U+2d346,U+2d34c,U+2d34e,U+2d35d,U+2d375,U+2d37d,U+2d391,U+2d39f,U+2d3c5,U+2d3eb,U+2d476,U+2d4c7,U+2d4ca,U+2d4e1,U+2d4e8-2d4e9,U+2d503,U+2d50e,U+2d530,U+2d546,U+2d58c,U+2d5ea,U+2d612,U+2d61c,U+2d64f,U+2d655,U+2d661,U+2d6c1,U+2d6dc,U+2d6e3-2d6e4,U+2d6ed,U+2d709,U+2d70c,U+2d728,U+2d738,U+2d74e,U+2d769,U+2d76b,U+2d773,U+2d7a3,U+2d7ec,U+2d7ef,U+2d818,U+2d823,U+2d83f,U+2d845,U+2d84f,U+2d85a,U+2d86d,U+2d87d,U+2d88b-2d88c,U+2d898,U+2d8a5,U+2d8a8,U+2d8c0,U+2d8c4,U+2d915,U+2d9a3,U+2d9ad,U+2d9d9,U+2da26,U+2da2b,U+2da64,U+2da6f,U+2da79,U+2da7d,U+2da8c,U+2daa7,U+2daca,U+2dae8,U+2db19,U+2dbfa,U+2dc03,U+2dc2a-2dc2b,U+2dc3a,U+2dcbf,U+2dcff,U+2dd08,U+2dd23-2dd24,U+2dd99-2dd9a,U+2ddd4,U+2ddfd,U+2de13,U+2de35,U+2de97,U+2dea8,U+2deb2,U+2defe,U+2df12,U+2df81,U+2df98,U+2dfa8,U+2dfab,U+2dfc1,U+2dfc4,U+2dfcd,U+2dff3,U+2e00a,U+2e061,U+2e0b1,U+2e0b9,U+2e0c4,U+2e1d5,U+2e1d8,U+2e1dc,U+2e1df,U+2e209,U+2e214,U+2e229,U+2e235,U+2e26a,U+2e2ad,U+2e310,U+2e331,U+2e344,U+2e39f,U+2e3a6,U+2e3ab,U+2e3c0,U+2e41d,U+2e46b,U+2e471,U+2e4af,U+2e4f3,U+2e510,U+2e514,U+2e519,U+2e524,U+2e54d,U+2e571,U+2e5d3,U+2e603,U+2e615,U+2e67b,U+2e6d5,U+2e6e2,U+2e6f5,U+2e713,U+2e732,U+2e734,U+2e73f,U+2e769,U+2e78a,U+2e78f,U+2e791,U+2e7b6,U+2e7bf,U+2e81f,U+2e878,U+2e8a3,U+2e8aa,U+2e8b6,U+2e8cc,U+2e92d,U+2e95b,U+2e960,U+2e977,U+2e989;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./644c8a41b484f1de63684f28d3dc9638.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+2e9a1,U+2e9ee,U+2ea36,U+2ea4c,U+2ea5d,U+2eaed,U+2eaf7,U+2eaff,U+2eb04,U+2eb29,U+2eb91,U+2eb99,U+2f817,U+2f82b,U+2f851,U+2f86b,U+2f878,U+2f87e,U+2f885,U+2f8a6,U+2f8af,U+2f8cf,U+2f8db,U+2f90b-2f90c,U+2f928,U+2f940,U+2f957,U+2f95a,U+30001-30002,U+30005,U+30008-30009,U+30021-30022,U+30053,U+30061,U+3006c,U+30072,U+3007c,U+30082,U+30094,U+300c0,U+300dd,U+3011d,U+30149-3014a,U+30170,U+30174,U+30179,U+3018a,U+30196,U+301b3,U+301bb,U+301e2,U+301e5,U+301ee,U+301f0,U+301f3,U+301f5,U+301f7,U+301fc,U+30202,U+3020f,U+30212,U+30223-30224,U+30228,U+30238,U+3023c,U+30244,U+30270,U+302a3,U+302d9,U+302db-302de,U+302f7,U+302fb,U+30335,U+3033b,U+3034e,U+30370-30371,U+30389,U+3039f,U+303b9,U+30401,U+30409,U+30414,U+3043a,U+3043f,U+3044a,U+3044c,U+30450,U+3045d-3045e,U+30473,U+304bc,U+304ca,U+304cc,U+304d6,U+304e2-304e3,U+304e8,U+304ed,U+30510,U+30523,U+3052b,U+3053a,U+3054a,U+3057a,U+305c9,U+305d1,U+305dd,U+305ef,U+305f6,U+3061d,U+30654,U+3067d,U+306d1,U+306d3,U+306ec,U+306f4,U+306fa-306fb,U+30700,U+30708,U+30720,U+3073a,U+30745,U+3075c,U+30776,U+3077a,U+30783,U+307a9,U+307c5,U+307de,U+3081e,U+30828,U+30831,U+30842,U+3085a,U+3085c,U+308f2,U+30931,U+3094c,U+30955,U+30969,U+30993,U+309aa-309ab,U+309c0,U+309c4,U+30a02,U+30a1b,U+30a25,U+30a62,U+30a72,U+30a75,U+30a9e,U+30aa9,U+30aab,U+30aaf,U+30ab1,U+30abe,U+30ad5,U+30af2,U+30af7,U+30afe,U+30b04,U+30b0a,U+30b15,U+30b42-30b43,U+30b4b,U+30b5d,U+30b79,U+30b90,U+30b92,U+30bb3,U+30be1,U+30bf6,U+30c1d,U+30c1f,U+30c21,U+30c24,U+30c2b-30c2c,U+30cb2,U+30cbd-30cbe,U+30cc7;
}
@font-face {
font-family: "KingHwa_OldSong";
src:local("KingHwa_OldSong"),url("./66e0b2a601ce3605a3feacec6a68bcba.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
unicode-range:U+30cd9,U+30d02,U+30d4b,U+30d55,U+30d5f,U+30d67,U+30ddf,U+30de3,U+30e01,U+30e04,U+30e11,U+30e23,U+30e70,U+30e77,U+30e79,U+30ea5,U+30ebd,U+30edd-30ede,U+30f03,U+30f24,U+30f45,U+30f59,U+30f5f,U+30f62,U+30f64,U+30f82,U+30fd8,U+310a8,U+310aa,U+310e8,U+3114c,U+31151,U+311a4,U+311a7,U+311b2,U+311b4,U+31258,U+3132e,U+3137f,U+313a2,U+313b0,U+313b8-313b9,U+31401-31402,U+3141f,U+31455,U+3145b,U+31487,U+3148c,U+314a1-314a2,U+314a5,U+314f2,U+3150e,U+31554,U+31597,U+31603,U+31661,U+3170f,U+31722,U+31781,U+3178b,U+317a6,U+317c4,U+317d3,U+317e7,U+31806,U+31832,U+31834,U+31841,U+31843,U+318a4,U+31ab3,U+31ab5,U+31b92,U+31bda,U+31c3c,U+31c47,U+31c7b,U+31d6d,U+31eb5,U+31eca,U+31f57,U+31fc6,U+320aa,U+320df,U+3210c,U+321e5,U+3222b,U+32287,U+322ca,U+32314,U+ffffd;
}