@import "../scss/theme-base-background.scss";

.shot {
    .background {
        width: 100%;
        height: 100%;
    }

    @for $i from 0 through $menu-background-count {
        .background-#{$i} {
            @extend .theme-bg-#{$i};
        }
    }

    // .background-backgroundimage-bing {
    //     //--background-image: var(--shot-backgoundimage);
    //     background-image: URL('https://baotangguo.cn:8081/');
        
    // }
}

.fav-list .ant-space .ant-space-item .fav-item {
    @for $i from 0 through $menu-background-count {
        .background-#{$i} {
            @extend .theme-bg-#{$i};
        }
    }
}