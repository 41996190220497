@import './theme-base-background.scss';

.theme-panda {
    @extend .bg-Panda;

    .content {
        .content-text,.device-text {
            background-color: #393A3B;
            color: #ffffff;
            >pre {
                font-family: "Jetbrains Mono" !important;
                color: #ffffff;

                code.hljs {
                    display: block;
                    overflow-x: auto;
                    padding: 1em
                }

                code.hljs {
                    padding: 3px 5px
                }

                .hljs {
                    color: #e6e6e6;
                    background: #2a2c2d
                }

                .hljs-emphasis {
                    font-style: italic
                }

                .hljs-strong {
                    font-weight: 700
                }

                .hljs-link {
                    text-decoration: underline
                }

                .hljs-comment,
                .hljs-quote {
                    color: #bbb;
                    font-style: italic
                }

                .hljs-params {
                    color: #bbb
                }

                .hljs-attr,
                .hljs-punctuation {
                    color: #e6e6e6
                }

                .hljs-meta,
                .hljs-name,
                .hljs-selector-tag {
                    color: #ff4b82
                }

                .hljs-char.escape_,
                .hljs-operator {
                    color: #b084eb
                }

                .hljs-deletion,
                .hljs-keyword {
                    color: #ff75b5
                }

                .hljs-regexp,
                .hljs-selector-attr,
                .hljs-selector-pseudo,
                .hljs-variable.language_ {
                    color: #ff9ac1
                }

                .hljs-code,
                .hljs-formula,
                .hljs-property,
                .hljs-section,
                .hljs-subst,
                .hljs-title.function_ {
                    color: #45a9f9
                }

                .hljs-addition,
                .hljs-bullet,
                .hljs-meta .hljs-string,
                .hljs-selector-class,
                .hljs-string,
                .hljs-symbol,
                .hljs-title.class_,
                .hljs-title.class_.inherited__ {
                    color: #19f9d8
                }

                .hljs-attribute,
                .hljs-built_in,
                .hljs-doctag,
                .hljs-link,
                .hljs-literal,
                .hljs-meta .hljs-keyword,
                .hljs-number,
                .hljs-punctuation,
                .hljs-selector-id,
                .hljs-tag,
                .hljs-template-tag,
                .hljs-template-variable,
                .hljs-title,
                .hljs-type,
                .hljs-variable {
                    color: #ffb86c
                }
            }
        }
    }
}