@import '../scss/config.scss';

.box-input {
    position: fixed;
    width: 320px;
    top: 64px;
    bottom: 0;
    z-index: 2;
    background: #111;
    padding: 0 16px;


    >div.ant-space {
        width: 100%;
        overflow-y: auto;
        div.ant-space-item {
            width: 100%;
            #boxInputImage {
                width: 240px;
                height: 240px;
                background-color: #666;
                margin:0 auto;
                border-radius: 4px;
                padding: 120px;
                img {
                    position: absolute;
                    width: auto;
                    height: auto;
                    max-width: 200px;
                    max-height: 200px;
                    top:50%;
                    left:50%;
                    transform:translateX(-50%) translateY(-50%);
                }
            }
        }
    }
}


@include media-query($on-phone) {

    .box-input {
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        z-index: 20;
        background: #111111cc;
        padding: 0;

        >div {
            margin: 0;
            padding: 0 16px 16px;
            position: fixed;
            width: 100%;
            top: 0;
            bottom: 0;
            z-index: 3;
            background: #111111;
            border-top: 1px solid #333333;

            div.ant-space-item {
                content: "";
            }
        }

        textarea {
            height: 375px;
        }

        .box-input-close {
            position: absolute;
            width: 44px;
            height: 44px;
            border: 1px solid #333333;
            background: #cccccc;
            right: 0;

            &::before {
                content: '';
                width: 29px;
                height: 1px;
                background-color: #333333;
                transform: rotate(-45deg) translateY(8px) translateX(-22px);
                display: block;
            }

            &::after {
                content: '';
                width: 29px;
                height: 1px;
                background-color: #333333;
                transform: rotate(45deg) translateY(20px) translateX(8px);
                display: block;
            }
        }
    }

    .box-input-0 {
        display: none;
    }

    .box-input-1 {
        display: block;
    }
}