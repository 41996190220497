@import './theme-base-background.scss';

.theme-simple {
    @extend .bg-simple;
    .content-container-shadow {
        &::after {
            background-image: none !important;
        }


        .content {
            border-radius: 0 !important;

            .content-text,.device-text {
                background-color: transparent;
                color: #212121;

                >pre {
                    color: #212121;
                }
            }
        }
        .content-mode-image>.content-image {
            img {
                width:100%;
                height:auto;
            }
        }

    }
}