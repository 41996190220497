@import '../scss/config.scss';

.show-container {
    position: fixed;
    width: 100%;
    height: 300%;
    bottom: 0;
    top: -100%;
    overflow: hidden;
}

.shot {
    will-change: auto;
     margin: 0;
     padding: 0;
    // transition-duration: 300ms;
    // transition-property: all;
    width: 100%;
    height: 100%;
    align-items: center;
    display: grid;

    .shot-html {
        position: relative;
        margin: 0 auto;
        width: calc(var(--shot-width));
        height: calc(var(--shot-height));

        transform:scale(var(--shot-zoom));
        //zoom: var(--shot-zoom);
        //transform: translateX(50%) translateY(50%);
        .shot-backgroundimage {
            display: none;
        }

        .shot-layout {

            .content-container {
                align-items: center;
                display: grid;
                width: auto;
                height: 100%;
                //overflow: hidden;

                .content-container-shadow {
                    position: relative;

                    .content {
                        overflow: hidden;
                        position: relative;
                        font-size: 24px;
                        border-radius: 20px;
                        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                        // width: 96%;
                        // max-height: 96%;
                        // margin: 2%;
                        // top: 50%;
                        height: auto;
                        min-height: 10%;
                        line-height: 0;

                        // transform: translateY(-50%);
                        // left: 0;
                        // margin-top: 100px;
                        // margin-bottom: 100px;
                        .content-text,
                        .device-text {
                            padding: 20px 40px;
                            line-height: 1.4;

                            pre {


                                white-space: pre-wrap;
                                /* Since CSS 2.1 */
                                white-space: -moz-pre-wrap;
                                /* Mozilla, since 1999 */
                                white-space: -pre-wrap;
                                /* Opera 4-6 */
                                white-space: -o-pre-wrap;
                                /* Opera 7 */
                                word-wrap: break-word;
                                /* Internet Explorer 5.5+ */

                                h1,
                                h2,
                                h3,
                                h4,
                                h5,
                                h6,
                                p,
                                div,
                                ul,
                                ol,
                                li {
                                    margin: 0;
                                    padding: 0;
                                    border: 0;
                                    font-weight: normal !important;
                                    display: inline-block;
                                }

                                h1,
                                h2,
                                h3 {
                                    line-height: 1.2;
                                    //text-align: center;
                                    //line-height: 0.6;
                                    //white-space: nowrap;word-wrap: normal;word-break: keep-all;
                                }

                                h1,
                                h2,
                                h3,
                                h4,
                                h5,
                                h6 {
                                    white-space-collapse: inherit;
                                }

                                ul,
                                ol {
                                    display: grid;
                                }

                                li {
                                    margin-left: 2em;
                                }
                            }
                        }

                        .content-image,
                        .device-image {
                            img {
                                width: 100%;
                            }
                        }


                        .device-text-box,
                        .device-image-box {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                        }

                        .device-text {
                            padding: 5%;
                            width: 100%;
                            height: 100%;

                            pre {
                                text-wrap: balance;
                            }
                        }
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 100px;
                        //bottom: -1px;
                        left: 0;
                        background-image: url(/images/shadow.png);
                        background-size: 100% 100px;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }


    #shot_html {
        // transform: scale(var(--shot-zoom)) translateY(calc(var(--shot-height) / -2)) translateX(calc(var(--shot-width) / -2));
        // top: calc(50% - var(--shot-height)/4);
        // left: calc(50% - var(--shot-width)/4);
    }

    #shot_to_make {
        position: absolute;
        //opacity: 0;
        transform: translateY(-50%) translateX(-50%);
        top: 50%;
        left: 50%;
        //z-index: 99;
        //opacity: 1;
        top: -100%;
    }


    .shot-image {
        position: absolute;
        margin: 0;
        width: calc(var(--shot-width));
        height: calc(var(--shot-height));
        top: -100%;
        //zoom: var(--shot-zoom);
        //transform:scale(--shot-zoom);
        opacity: 0;
        // DEBUG
        // opacity: 0.25;
        // top: -50%;
        // left: -50%;
        // width: calc(var(--shot-width) );
        // height: calc(var(--shot-height));
    }

}

@import './ShotMedia.scss';
@import './ShotMode.scss';
@import './ShotPadding.scss';
@import './ShotFont.scss';
@import './ShotTheme.scss';
@import './ShotHeader.scss';
@import './ShotWatermark.scss';
@import './ShotDevice.scss';

// @import './ShotDeviceiPhonex.scss';
// @import './ShotDeviceiPhonexL.scss';
// @import './ShotDeviceiPhone15.scss';
// @import './ShotDeviceiPhone15L.scss';

// @import './ShotDeviceiPad.scss';
// @import './ShotDeviceiPadL.scss';