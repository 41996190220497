.text-color-0 {
    content: '';
}

.text-background-0 {
    content: '';
}

.text-color-1 {
    color: #111111 !important;
}


.text-background-1 {
    background-color: #222222 !important;
}

.text-color-2 {
    color: #555555 !important;

}

.text-background-2 {
    background-color: #666666 !important;
}

.text-color-3 {
    color: #AAAAAA !important;

}

.text-background-3 {
    background-color: #CCCCCC !important;
}

.text-color-4 {
    color: #FAFAFA !important;

}
.text-background-4 {
    background-color: #DDDDDD !important;
}

.text-color-5 {
    color: #fff1f0 !important;

}
.text-background-5 {
    background-color: #ffccc7 !important;
}

.text-color-6 {
    color: #ffa39e !important;

}
.text-background-6 {
    background-color: #ff7875 !important;
}

.text-color-7 {
    color: #ff4d4f !important;

}
.text-background-7 {
    background-color: #f5222d !important;
}

.text-color-8 {
    color: #cf1322 !important;

}
.text-background-8 {
    background-color: #a8071a !important;
}

.text-color-9 {
    color: #820014 !important;

}
.text-background-9 {
    background-color: #5c0011 !important;
}

.text-color-10 {
    color: #fff2e8 !important;

}
.text-background-10 {
    background-color: #ffd8bf !important;
}
.text-color-11 {
    color: #ffbb96 !important;

}
.text-background-11 {
    background-color: #ff9c6e !important;
}

.text-color-12 {
    color: #ff7a45 !important;

}
.text-background-12 {
    background-color: #fa541c !important;
}

.text-color-13 {
    color: #d4380d !important;

}
.text-background-13 {
    background-color: #ad2102 !important;
}
.text-color-14 {
    color: #871400 !important;

}
.text-background-14 {
    background-color: #610b00 !important;
}

.text-color-15 {
    color: #fff7e6 !important;

}
.text-background-15 {
    background-color: #ffe7ba !important;
}

.text-color-16 {
    color: #ffd591 !important;

}
.text-background-16 {
    background-color: #ffc069 !important;
}

.text-color-17 {
    color: #ffa940 !important;

}
.text-background-17 {
    background-color: #fa8c16 !important;
}

.text-color-18 {
    color: #d46b08 !important;

}
.text-background-18 {
    background-color: #ad4e00 !important;
}

.text-color-19 {
    color: #873800 !important;

}
.text-background-19 {
    background-color: #612500 !important;
}

.text-color-20 {
    color: #fffbe6 !important;

}
.text-background-20 {
    background-color: #fff1b8 !important;
}
.text-color-21 {
    color: #ffe58f !important;

}
.text-background-21 {
    background-color: #ffd666 !important;
}

.text-color-22 {
    color: #ffc53d !important;

}
.text-background-22 {
    background-color: #faad14 !important;
}

.text-color-23 {
    color: #d48806 !important;

}
.text-background-23 {
    background-color: #ad6800 !important;
}
.text-color-24 {
    color: #874d00 !important;

}
.text-background-24 {
    background-color: #613400 !important;
}

.text-color-25 {
    color: #feffe6 !important;

}
.text-background-25 {
    background-color: #ffffb8 !important;
}

.text-color-26 {
    color: #fffb8f !important;

}
.text-background-26 {
    background-color: #fff566 !important;
}

.text-color-27 {
    color: #ffec3d !important;

}
.text-background-27 {
    background-color: #fadb14 !important;
}

.text-color-28 {
    color: #d4b106 !important;

}
.text-background-28 {
    background-color: #ad8b00 !important;
}

.text-color-29 {
    color: #876800 !important;

}
.text-background-29 {
    background-color: #614700 !important;
}

.text-color-30 {
    color: #fcffe6 !important;

}
.text-background-30 {
    background-color: #f4ffb8 !important;
}
.text-color-31 {
    color: #eaff8f !important;

}
.text-background-31 {
    background-color: #d3f261 !important;
}

.text-color-32 {
    color: #bae637 !important;

}
.text-background-32 {
    background-color: #a0d911 !important;
}

.text-color-33 {
    color: #7cb305 !important;

}
.text-background-33 {
    background-color: #5b8c00 !important;
}
.text-color-34 {
    color: #3f6600 !important;

}
.text-background-34 {
    background-color: #254000 !important;
}

.text-color-35 {
    color: #f6ffed !important;

}
.text-background-35 {
    background-color: #d9f7be !important;
}

.text-color-36 {
    color: #b7eb8f !important;

}
.text-background-36 {
    background-color: #95de64 !important;
}

.text-color-37 {
    color: #73d13d !important;

}
.text-background-37 {
    background-color: #52c41a !important;
}

.text-color-38 {
    color: #389e0d !important;

}
.text-background-38 {
    background-color: #237804 !important;
}

.text-color-39 {
    color: #135200 !important;

}
.text-background-39 {
    background-color: #092b00 !important;
}

.text-color-40 {
    color: #e6fffb !important;

}
.text-background-40 {
    background-color: #b5f5ec !important;
}
.text-color-41 {
    color: #87e8de !important;

}
.text-background-41 {
    background-color: #5cdbd3 !important;
}

.text-color-42 {
    color: #36cfc9 !important;

}
.text-background-42 {
    background-color: #13c2c2 !important;
}

.text-color-43 {
    color: #08979c !important;

}
.text-background-43 {
    background-color: #006d75 !important;
}
.text-color-44 {
    color: #00474f !important;

}
.text-background-44 {
    background-color: #002329 !important;
}

.text-color-45 {
    color: #e6f4ff !important;

}
.text-background-45 {
    background-color: #bae0ff !important;
}

.text-color-46 {
    color: #91caff !important;

}
.text-background-46 {
    background-color: #69b1ff !important;
}

.text-color-47 {
    color: #4096ff !important;

}
.text-background-47 {
    background-color: #1677ff !important;
}

.text-color-48 {
    color: #0958d9 !important;

}
.text-background-48 {
    background-color: #003eb3 !important;
}

.text-color-49 {
    color: #002c8c !important;

}
.text-background-49 {
    background-color: #001d66 !important;
}

.text-color-50 {
    color: #f0f5ff !important;

}
.text-background-50 {
    background-color: #d6e4ff !important;
}
.text-color-51 {
    color: #adc6ff !important;

}
.text-background-51 {
    background-color: #85a5ff !important;
}

.text-color-52 {
    color: #597ef7 !important;

}
.text-background-52 {
    background-color: #2f54eb !important;
}

.text-color-53 {
    color: #1d39c4 !important;

}
.text-background-53 {
    background-color: #10239e !important;
}
.text-color-54 {
    color: #061178 !important;

}
.text-background-54 {
    background-color: #030852 !important;
}

.text-color-55 {
    color: #f9f0ff !important;

}
.text-background-55 {
    background-color: #efdbff !important;
}

.text-color-56 {
    color: #d3adf7 !important;

}
.text-background-56 {
    background-color: #b37feb !important;
}

.text-color-57 {
    color: #9254de !important;

}
.text-background-57 {
    background-color: #9254de !important;
}

.text-color-58 {
    color: #531dab !important;

}
.text-background-58 {
    background-color: #391085 !important;
}

.text-color-59 {
    color: #22075e !important;

}
.text-background-59 {
    background-color: #120338 !important;
}

.text-color-60 {
    color: #fff0f6 !important;

}
.text-background-60 {
    background-color: #ffd6e7 !important;
}
.text-color-61 {
    color: #ffadd2 !important;

}
.text-background-61 {
    background-color: #ff85c0 !important;
}

.text-color-62 {
    color: #f759ab !important;

}
.text-background-62 {
    background-color: #eb2f96 !important;
}

.text-color-63 {
    color: #c41d7f !important;

}
.text-background-63 {
    background-color: #9e1068 !important;
}
.text-color-64 {
    color: #780650 !important;

}
.text-background-64 {
    background-color: #520339 !important;
}

.text-color-65 {
    color: #FFFFFFCC !important;

}
.text-background-65 {
    background-color: #EEEEEECC !important;
}

.text-color-66 {
    color: #FFFFFFAA !important;

}
.text-background-66 {
    background-color: #EEEEEEAA !important;
}

.text-color-67 {
    color: #FFFFFF99 !important;

}
.text-background-67 {
    background-color: #EEEEEE99 !important;
}

.text-color-68 {
    color: #FFFFFF66 !important;

}
.text-background-68 {
    background-color: #EEEEEE66 !important;
}

.text-color-69 {
    color: #FFFFFF22 !important;

}
.text-background-69 {
    background-color: #EEEEEE22 !important;
}


.text-color-70 {
    color: #888888CC !important;
}
.text-background-70 {
    background-color: #777777CC !important;
}
.text-color-71 {
    color: #888888AA !important;

}
.text-background-71 {
    background-color: #777777AA !important;
}

.text-color-72 {
    color: #88888899 !important;

}
.text-background-72 {
    background-color: #77777799 !important;
}

.text-color-73 {
    color: #88888866 !important;

}
.text-background-73 {
    background-color: #77777766 !important;
}
.text-color-74 {
    color: #77777722 !important;

}
.text-background-74 {
    background-color: #77777722 !important;
}

.text-color-75 {
    color: #111111CC !important;

}
.text-background-75 {
    background-color: #000000CC !important;
}

.text-color-76 {
    color: #111111AA !important;

}
.text-background-76 {
    background-color: #000000AA !important;
}

.text-color-77 {
    color: #11111199 !important;

}
.text-background-77 {
    background-color: #00000099 !important;
}

.text-color-78 {
    color: #11111166 !important;

}
.text-background-78 {
    background-color: #00000066 !important;
}

.text-color-79 {
    color: #11111122 !important;

}
.text-background-79 {
    background-color: #00000022 !important;
}


.shot {

    .shot-html {
        .shot-layout {

            @for $i from 1 through $menu-text-background-count {
                &.shot-text-background-#{$i} {
                    .content-container .content-container-shadow .content {

                        .device-text,
                        .content-text {

                            @extend .text-background-#{$i};
                            background-image: none!important;
                        }
                    }
                }
            }
        }
    }
}

.shot {

    .shot-html {
        .shot-layout {

            @for $i from 1 through $menu-text-background-count {
                &.shot-text-color-#{$i} {
                    .content-container .content-container-shadow .content {

                        .device-text,
                        .content-text {

                            >pre {
                                @extend .text-color-#{$i};

                            }
                        }
                    }
                }
            }
        }
    }
}



.fav-list .ant-space .ant-space-item .fav-item .fav-item-thumbnail {
    @for $i from 1 through $menu-text-background-count {
        .shot-text-color-#{$i} {
            @extend .text-color-#{$i};
        }
    }
}

.fav-list .ant-space .ant-space-item .fav-item .fav-item-thumbnail {
    @for $i from 1 through $menu-text-background-count {
        .shot-text-background-#{$i} {
            @extend .text-background-#{$i};
            background-image: none!important;
        }
    }
}