@import '../scss/config.scss';


.box-menu {
    position: fixed;
    width: 320px;
    top: 64px;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: #111;
    padding: 0 0 0 8px;

    >div {
        width: 100%;

        div.ant-tabs-nav-wrap {
            background-color: transparent;
        }

        div.ant-space-item {
            width: 100%;
        }

        div.ant-tabs-content-holder {
            background-color: #141414;
            border-top: 1px solid #303030;
            overflow: auto;
            .ant-tabs-tabpane{
                .ant-radio-group.smallgrid{
                    .ant-radio-button-wrapper{
                        padding-inline:12px;
                    }
                }
            }
        }
    }
}

@include media-query($on-phone) {

    .box-menu {
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        z-index: 20;
        background: #111111cc;
        padding: 0;

        >div {
            margin: 0;
            padding: 0 16px 16px;
            position: fixed;
            width: 100%;
            top: 0;
            bottom: 0;
            z-index: 3;
            background: #111111;
            border-top: 1px solid #333333;

            div.ant-space-item {
                content: "";
            }
        }

        .box-menu-close {
            position: absolute;
            width: 44px;
            height: 44px;
            border: 1px solid #333333;
            background: #cccccc;
            right: 0;

            &::before {
                content: '';
                width: 29px;
                height: 1px;
                background-color: #333333;
                transform: rotate(-45deg) translateY(8px) translateX(-22px);
                display: block;
            }

            &::after {
                content: '';
                width: 29px;
                height: 1px;
                background-color: #333333;
                transform: rotate(45deg) translateY(20px) translateX(8px);
                display: block;
            }
        }
    }

    .box-menu-0 {
        display: none;
    }

    .box-menu-1 {
        display: block;
    }

}

#RadioWatermark {
    width: 100%;

    >div>label {
        width: 32%;
        text-align: center;
    }

    >div:nth-child(1)>label:nth-child(1) {
        border-end-start-radius: 0 !important;
    }

    >div:nth-child(1)>label:nth-child(3) {
        border-end-end-radius: 0 !important;
    }

    >div:nth-child(2)>label {
        top: -1px;
    }

    >div:nth-child(2)>label:nth-child(1) {
        border-start-start-radius: 0 !important;
    }

    >div:nth-child(2)>label:nth-child(3) {
        border-start-end-radius: 0 !important;
    }
}

@import "./ShotBackground.scss";
@import './ShotTextColor.scss';

#RadioBackground,
#RadioTextColor,
#RadioTextBackground {

    >div>label:first-child {
        border-end-start-radius: 0 !important;
        border-start-start-radius: 0 !important;
    }

    >div>label:last-child {
        border-end-end-radius: 0 !important;
        border-start-end-radius: 0 !important;
    }

    .menu-background,
    .menu-text-color,
    .menu-text-background {
        display: block;
        position: relative;
        width: 0;
        height: 30px;

        &::after {
            content: '';
            display: block;
            width: 28px;
            height: 28px;
            transform: translateX(-14px) translateY(1px);
            border-radius: 1px;
        }
    }

    .menu-background-0::after,
    .menu-text-color-0::after,
    .menu-text-background-0::after {
        background: #111;
        background-image:
            linear-gradient(45deg, #252525 25%, transparent 0, transparent 75%, #252525 0),
            linear-gradient(45deg, #252525 25%, transparent 0, transparent 75%, #252525 0);
        background-position: 0 0, 14px 14px;
        background-size: 28px 28px;
    }

    @for $i from 1 through $menu-background-count {
        .menu-background-#{$i}::after {
            @extend .theme-bg-#{$i};
        }
    }


    @for $i from 1 through $menu-text-color-count {
        .menu-text-color-#{$i}::after {
            @extend .text-background-#{$i};
        }
    }


    @for $i from 1 through $menu-text-background-count {
        .menu-text-background-#{$i}::after {
            @extend .text-background-#{$i};
        }
    }
}
