@import './theme-base-background.scss';
@import './sarasa-fixed-sc-regular/result.css';

.theme-chinese {
    @extend .theme-bg-19;

    .content-container-shadow {
        &::after {
            background-image: url(/images/shadow-paper.png) !important;
        }

        .content {
            border-radius: 0 !important;

            .content-text,.device-text {
                background-color: #F4F8FB;
                color: #606060;
                background-image: linear-gradient(180deg, #F4F8FB 0%, #E4E8EB 60%, #F4F8FB 100%);

                >pre {
                    text-wrap: balance;
                    font-family: "Sarasa Fixed SC" !important;
                    color: #606060;

                    p {
                        line-height: 2;
                    }
                }
            }
        }
    }
    // .content{
    //     background-color: #F4F8FB;
    //     .content-text{
    //         color: #606060;
    //     }
    // }
}