@import './config.scss';
// @import './reset.scss';
#root,
html,
body {
    height: 100%;
}

.ant-layout {
    display: flex;
    min-height: 100%;
    width: 100%;
    background-color: #111111;
}

header {
    background-color: #111111 !important;
}

#content {
    display: grid;
    min-height: 100%;
    min-width: 1180px;
}

#box-content {
    background-color: #000000;
    position: relative;
    align-items: center;
    display: grid;
}
#box-markdown,
#box-canvas-save {
    display: none;
}
@keyframes textShine {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}

@import './style-phone.scss';
@import './iconfont.scss';
@import './theme-base-font.scss';

@import './theme-dark.scss';
@import './theme-github-dark.scss';
@import './theme-github-dark-dimmed.scss';
@import './theme-github-light.scss';
@import './theme-night-owl.scss';
@import './theme-xcode.scss';
@import './theme-panda.scss';
@import './theme-simple.scss';
@import './theme-chinese.scss';
@import './theme-chinese-handwriting.scss';
@import './theme-chinese-ming.scss';
@import './theme-chinese-ancient.scss';
@import './theme-chinese-zen.scss';
@import './theme-chinese-peom.scss';
@import './theme-chinese-newspaper.scss';
@import './theme-barbie.scss';
@import './theme-japanese-mplus2.scss';
@import './theme-japanese-makinas-4-square.scss';