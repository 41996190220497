@import '../scss/config.scss';


#box-content-topbar {
    z-index: 10;
    display: none;
}


@include media-query($on-phone) {

    #box-content-topbar {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 48px;
        text-align: center;

        >div {
            margin: 8px;
        }
    }
}