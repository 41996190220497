$gradient-angle: 110deg;
$on-phone: 600px;
$on-pad: 1080px;
$on-pc: 1240px;
$menu-background-count: 89;
$menu-text-color-count: 79;
$menu-text-background-count: 79;


// Use media queries like this:
// @include media-query($on-phone) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



:root {
    --gradient-angle: #{$gradient-angle};
    --shot-zoom: 0.5;
    --shot-aspect: square;
    --shot-width: 1200.0px;
    --shot-height: 1200.0px;
    --shot-padding: 60.0px;
    --shot-backgoundimage: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    --shot-device-frame-zoom:1.0;
    --shot-device-frame-width:1280.0px;
    --shot-device-frame-height:1280.0px;
    --shot-device-content-width:506.0px;
    --shot-device-content-height:1100.0px;
    --shot-device-content-top:90.0px;
    --shot-device-content-left:387.0px;
    --shot-device-content-radius:50.0px;
    --shot-device-image: url('../images/iphonex.png');
    --shot-device-image-mockup: url('../images/iphonexmockup.png');
}
