@import './theme-base-background.scss';

.theme-xcode {
    @extend .bg-Material-Light;

    .content {

        .content-text,.device-text {
            background-color: #eeeeee;
            color: #5f7e97;

            >pre {
                font-family: 'Source Code Pro' !important;
                color: #5f7e97;

                code.hljs {
                    display: block;
                    overflow-x: auto;
                    padding: 1em
                }

                code.hljs {
                    padding: 3px 5px
                }

                .hljs {
                    background: #fff;
                    color: #000
                }

                .xml .hljs-meta {
                    color: silver
                }

                .hljs-comment,
                .hljs-quote {
                    color: #007400
                }

                .hljs-attribute,
                .hljs-keyword,
                .hljs-literal,
                .hljs-name,
                .hljs-selector-tag,
                .hljs-tag {
                    color: #aa0d91
                }

                .hljs-template-variable,
                .hljs-variable {
                    color: #3f6e74
                }

                .hljs-code,
                .hljs-meta .hljs-string,
                .hljs-string {
                    color: #c41a16
                }

                .hljs-link,
                .hljs-regexp {
                    color: #0e0eff
                }

                .hljs-bullet,
                .hljs-number,
                .hljs-symbol,
                .hljs-title {
                    color: #1c00cf
                }

                .hljs-meta,
                .hljs-section {
                    color: #643820
                }

                .hljs-built_in,
                .hljs-class .hljs-title,
                .hljs-params,
                .hljs-title.class_,
                .hljs-type {
                    color: #5c2699
                }

                .hljs-attr {
                    color: #836c28
                }

                .hljs-subst {
                    color: #000
                }

                .hljs-formula {
                    background-color: #eee;
                    font-style: italic
                }

                .hljs-addition {
                    background-color: #baeeba
                }

                .hljs-deletion {
                    background-color: #ffc8bd
                }

                .hljs-selector-class,
                .hljs-selector-id {
                    color: #9b703f
                }

                .hljs-doctag,
                .hljs-strong {
                    font-weight: 700
                }

                .hljs-emphasis {
                    font-style: italic
                }
            }
        }
    }
}