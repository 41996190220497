@import './theme-base-background.scss';

.theme-japanese-makinas {
    @extend .theme-bg-48;

    .content-container-shadow {
        &::after {
            background-image: url(/images/shadow-paper.png) !important;
        }

        .content {
            border-radius: 0 !important;

            .content-text,.device-text {
                background-color: #F4F8FB;
                color: #303030;
               
                >pre {
                    text-wrap: balance;
                    font-family: 'Makinas 4 Square' !important;
                    color: #303030;

                    p {
                        line-height: 2;
                    }
                }
            }
        }
    }
}