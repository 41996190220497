.shot {
    .header-1 {
        .content-container .content-container-shadow .content {
            padding-top: 80px;

            .header-bar {

                position: absolute;
                height: 80px;
                width: 100%;
                top: 0;
                left: 0;
                border-radius: 20px 20px 0 0;

                >span {
                    float: left;
                    display: block;
                    width: 24px;
                    height: 24px;
                    margin: ((80px-24px)/2) 16px ((80px-24px)/2) 0;
                    border-radius: 100%;
                }

                >span:nth-child(1) {
                    margin: ((80px-24px)/2) 16px ((80px-24px)/2) 40px;
                    background-color: #ff5f57;
                }

                >span:nth-child(2) {
                    background-color: #febc2e;
                }

                >span:nth-child(3) {
                    background-color: #28c840;
                }
            }
        }
    }

    .header-2 {
        .content-container .content-container-shadow .content {
            padding-top: 80px;

            .header-bar {

                position: absolute;
                background-color: #2f2d3a;
                height: 80px;
                width: 100%;
                top: 0;
                left: 0;
                border-radius: 20px 20px 0 0;

                >span {
                    float: left;
                    display: block;
                    width: 24px;
                    height: 24px;
                    margin: ((80px-24px)/2) 16px ((80px-24px)/2) 0;
                    border-radius: 100%;
                }

                >span:nth-child(1) {
                    margin: ((80px-24px)/2) 16px ((80px-24px)/2) 40px;
                    background-color: #ff5f57;
                }

                >span:nth-child(2) {
                    background-color: #febc2e;
                }

                >span:nth-child(3) {
                    background-color: #28c840;
                }
            }
        }
    }

    .header-3 {
        .content-container .content-container-shadow .content {
            padding-top: 80px;

            .header-bar {
                position: absolute;
                background-color: #2f2d3a;
                height: 80px;
                width: 100%;
                top: 0;
                left: 0;
                border-radius: 20px 20px 0 0;

                >span {
                    float: right;
                    display: block;
                    width: 24px;
                    height: 24px;
                    margin: ((80px-24px)/2) 20px ((80px-24px)/2) 20px;
                }

                >span:nth-child(3) {
                    &::after {
                        content: '';
                        position: absolute;
                        width: 24px;
                        height: 4px;
                        background-color: #fff;
                        display: block;
                        transform: translateY(10px);
                    }
                }

                >span:nth-child(2) {
                    &::after {
                        content: '';
                        width: 16px;
                        height: 16px;
                        border: 4px solid #fff;
                        display: block;
                    }
                }

                >span:nth-child(1) {
                    margin: ((80px-24px)/2) 40px ((80px-24px)/2) 20px;

                    &::before {
                        content: '';
                        width: 29px;
                        height: 4px;
                        background-color: #fff;
                        transform: rotate(-45deg) translateY(5px) translateX(-9px);
                        display: block;
                    }

                    &::after {
                        content: '';
                        width: 29px;
                        height: 4px;
                        background-color: #fff;
                        transform: rotate(45deg) translateY(6px) translateX(2px);
                        display: block;
                    }
                }
            }
        }
    }
}

.shot {
    &.shot-shadow-1 .shot-html .shot-layout .content-container .content-container-shadow {
        &::after {
            display: none !important;
        }
    }

    &.shot-shadow-2 .shot-html .shot-layout .content-container .content-container-shadow {
        &::after {
            background-image: url(/images/shadow.png) !important;
        }
    }

    &.shot-shadow-3 .shot-html .shot-layout .content-container .content-container-shadow {
        &::after {
            background-image: url(/images/shadow-paper.png) !important;
        }
    }

    &.shot-shadow-4 .shot-html .shot-layout .content-container .content-container-shadow {
        &::after {
            background-image: url(/images/shadow.png) !important;
            background-size: 100% 50%;
            opacity: 0.5;
        }
    }
    &.shot-shadow-5 .shot-html .shot-layout .content-container .content-container-shadow {
        &::after {
            background-image: none !important;
        }
        .content {
            box-shadow: #2f2d3a 0 0 30px;
        }
    }

    &.shot-shadow-6 .shot-html .shot-layout .content-container .content-container-shadow {
        &::after {
            content:none;
            background-image: none !important;
        }
        &::before {
            content:"";
            position: absolute;
            display: block;
            background-image: url(/images/shadow-round.png);
            width:100%;
            height:100%;
            background-size: 100% 100%;
        }
        &::before::before {
            content:"";
            position: absolute;
            display: block;
            background-image: url(/images/shadow.png);
            width:100%;
            height:100%;
            background-size: 100% 100%;
        }
        .content {
            margin:32px;
        }
    }
}