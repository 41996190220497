@import '../scss/iconfont.scss';

$watermarkPadding: 24px;
.shot {

    .watermark {
        position: absolute;
        bottom: $watermarkPadding;
        right: $watermarkPadding;
        z-index: 10;

        .watermark-content-logo {
            float:left;
            display: none;
            border-radius: 100%;

            &::after {
                @extend .FSLfont;
                display: block;
                position: absolute;
                text-align: center;
                border-radius: 100%;
                color:#fff;
            }
        }
        .watermark-content-user
        {
            float:left;
            .watermark-content-user-id {
                display: none;
                width: auto;
            }
    
            .watermark-content-user-name {
                display: none;
                width: auto;
            }
        }

    }

    .watermark-location-1 {
        left: $watermarkPadding;
        top: $watermarkPadding;
        bottom: auto;
        right: auto;
        &::after {
            top: 0;
            left: 0;
        }
    }
    .watermark-location-2 {
        left: 50%;
        top: $watermarkPadding;
        transform: translateX(-50%);
        bottom: auto;
        right: auto;
        &::after {
            top: 0;
            left: 0;
            transform: translateX(-50%);
        }
    }
    .watermark-location-3 {
        left: auto;
        right: $watermarkPadding;
        top: $watermarkPadding;
        bottom: auto;
        &::after {
            top: 0;
            left: -64px;
        }
    }

    .watermark-location-4 {
        left: $watermarkPadding;
        right: auto;
        top: auto;
        bottom: $watermarkPadding;
        &::after {
            top: -64px;
            left: 0;
        }
    }

    .watermark-location-5 {
        left: 50%;
        right: auto;
        top: auto;
        bottom: $watermarkPadding;
        transform: translateX(-50%);
        &::after {
            top: -64px;
            left:0;
            transform: translateX(-50%);
        }
    }
    .watermark-style-0 {
        display: none;
    }

    .watermark-style-1 {
        .watermark-content-logo {
            display: block;
            width: 48px;
            height: 48px;
            &::after {
                width: 48px;
                height: 48px;
                line-height: 48px;
                font-size: 48px;
            }
        }

    }

    .watermark-style-2 {
        .watermark-content-logo {
            display: block;
            width: 64px;
            height: 64px;
            &::after {
                width: 64px;
                height: 64px;
                line-height: 64px;
                font-size: 64px;
            }
        }
    }
    .watermark-style-3 {
        @extend .watermark-style-2;
        width:auto;
        .watermark-content-user {
            .watermark-content-user-name {
                display: block;
                padding: 0 16px;
                height: 64px;
                line-height: 64px;
                font-size:28px;
                color:#fff;
            }
        }
    }
    .watermark-style-4 {
        @extend .watermark-style-3;
        .watermark-content-user {
            .watermark-content-user-name {
                height: 36px;
                padding: 8px 16px 0;
                line-height: 28px;
            }
            .watermark-content-user-id {
                display: block;
                padding: 0 16px;
                height: 28px;
                line-height: 28px;
                font-size:20px;
                color:#ffffffaa;
                font-family: 'Helvetica Light',Arial;
                &::before {
                    content:'@';
                }
            }
        }
    }
    .watermark-style-5 {
        @extend .watermark-style-4;
        height:80px;
        padding:8px 16px 8px 8px;
        background-color: #ffffffcc;
        border-radius: 40px;
        .watermark-content-user {
            .watermark-content-user-name {
                color:#000000CC;
            }
            .watermark-content-user-id {
                color:#00000099;
            }
        }
    }
    .watermark-style-6 {
        @extend .watermark-style-4;
        height:80px;
        padding:8px 16px 8px 8px;
        background-color: #000000aa;
        border-radius: 40px;
        .watermark-content-user {
            .watermark-content-user-name {
                color:#ffffffCC;
            }
            .watermark-content-user-id {
                color:#ffffff99;
            }
        }
    }
    .watermark-style-7 {
        height:80px;
        width:auto;
        padding:8px;
        .watermark-content-logo {
            display: none;
        }
        .watermark-content-user {
            .watermark-content-user-name {
                letter-spacing: 0.5em;
                display: block;
                padding: 0 0 0 0.5em;
                height: 64px;
                line-height: 64px;
                font-size:28px;
                color:#ffffffaa;
            }
            .watermark-content-user-id {
                display: none;
            }
        }
    }
    .watermark-style-8 {
        height:80px;
        width:auto;
        padding:8px;
        .watermark-content-logo {
            display: none;
        }
        .watermark-content-user {
            .watermark-content-user-name {
                display: none;
            }
            .watermark-content-user-id {
                letter-spacing: 0.5em;
                display: block;
                padding: 0 0 0 0.5em;
                height: 64px;
                line-height: 64px;
                font-size:28px;
                color:#ffffffaa;
            }
        }
    }
}


@include media-query($on-phone) {

    .watermark{
        transform: scale(--shot-zoom);
    }
}