@import '../scss/config.scss';
header {
    z-index: 1;
}
.head-box {
    width: 100%;

    .logo {
        height: 64px;
        min-width: 640px;

        div {
            float: left;
        }

        .logo-image {
            width: 32px;
            height: 32px;
            border-radius: 100%;
            background-image: url(/images/logo.png);
            background-size: contain;
            margin: 16px 4px;
        }

        .logo-title {
            font-size: 32px;
            line-height: 64px;
            color: #ffffff;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 500;
            letter-spacing: -0.05em;
        }

        .logo-title2 {
            @extend .logo-title;
            color: #a52ca8;
            background: linear-gradient(to right, #BB2698, #a52ca8);
            background: linear-gradient(to right,
                    #BB2698 20%,
                    #ff6b08 40%,
                    #cf23cf 60%,
                    #a52ca8 80%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            text-fill-color: transparent;
            background-size: 500% auto;
            animation: textShine 5s ease-in-out infinite alternate;

        }

        .logo-text {
            font-family: 'JetBrains Mono';
            font-size: 16px;
            line-height: 32px;
            margin: 22px 8px;
            color: #666666;
        }
    }
    .menu {
        height: 64px;
        text-align: right;
        a.logo-github {
            font-size:24px;
            display: block;
            margin: 0 8px;
        }
    }

}

@include media-query($on-phone) {

    .head-box {
        width: 100%;

        .logo {

            .logo-text {
                display: none;
            }
        }
        .github-icon {
            display: none;
        }
    }

}