.theme-bg-0 {

    content: '';
    // background-image: none;
    // background-color: transparent;
}

.theme-bg-1,
.bg-vscode-dark {

    background-image: linear-gradient(to right bottom, #1cb1f2, #00a9f2, #00a0f2, #0097f1, #008def, #0086f1, #007ff2, #0078f2, #0071f6, #006afa, #0062fd, #0059ff);
}

.theme-bg-42,
.bg-night-owl {

    background-image: linear-gradient(140deg, rgb(9, 171, 241), rgb(5, 105, 148), rgb(4, 84, 118), rgb(6, 119, 167));
}

.theme-bg-3,
.bg-github-dark {

    background-image: linear-gradient(135deg, #E233FF 0%, #FF6B00 100%);
}

.theme-bg-4,
.bg-github-dark-dimmed {

    background-image: linear-gradient(140deg, rgb(241, 160, 61), rgb(192, 74, 65), rgb(115, 52, 52));
}

.theme-bg-5,
.bg-dracula {

    background-image: linear-gradient(135deg, rgba(171, 73, 222, 1) 0%, rgba(73, 84, 222, 1) 100%);
}

.theme-bg-6,
.bg-Material-Ocean {

    background-image: linear-gradient(to right bottom, #2be7b5, #1edea2, #16d58f, #13cb7c, #16c268, #0db866, #04ae64, #00a462, #00976c, #008971, #007b72, #006d6d);
}

.theme-bg-7,
.bg-Synthwave-84 {

    background-image: linear-gradient(to right top, #7f469d, #8242aa, #833db7, #8338c4, #8233d2, #8a35da, #9336e2, #9b38ea, #af41ee, #c24af2, #d554f7, #e65ffb);
}

.theme-bg-8,
.bg-Poimandres {

    background-image: linear-gradient(140deg, rgb(165, 142, 251), rgb(65, 206, 189));
}

.theme-bg-9,
.bg-Material-Volcano {

    background-image: linear-gradient(140deg, rgb(241, 160, 61), rgb(192, 74, 65), rgb(115, 52, 52));
}

.theme-bg-10,
.bg-One-dark {

    background-image: linear-gradient(135deg, #915CF2 0%, #814CE2 100%);
}

.theme-bg-11,
.bg-Panda {

    background-image: linear-gradient(135deg, #2a2a2a 0%, #1a1a1a 100%);
}

.theme-bg-12,
.bg-Aura-Dark {

    background-image: linear-gradient(135deg, rgba(171, 73, 222, 1) 0%, rgba(73, 84, 222, 1) 100%);
}

.theme-bg-13,
.bg-One-light {

    background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);
}

.theme-bg-14,
.bg-Material-Palenight {

    background-image: linear-gradient(135deg, #54D2EF 0%, #2AA6DA 100%);
}

.theme-bg-15,
.bg-github-light {

    background-image: linear-gradient(-45deg, rgba(73, 84, 222, 1) 0%, rgba(73, 221, 216, 1) 100%);
}

.theme-bg-16,
.bg-Moonlight {

    background-image: linear-gradient(135deg, #6a3cc0 0%, #240573 100%);
}

.theme-bg-17,
.bg-Vitesse-Dark {

    background-image: linear-gradient(0deg, #6394bf, #a1b567);
}

.theme-bg-18,
.bg-Duotone-Sea {

    background-image: linear-gradient(to right bottom, #1e737e, #186b76, #13636d, #0d5b65, #06535d);
}

.theme-bg-19,
.bg-Duotone-Dark {

    background-image: linear-gradient(135deg, #ffdca9 0%, #ffcc99 100%);
}

.theme-bg-20,
.bg-Shades-Of-Purple {

    background-image: linear-gradient(135deg, #9673fd 0%, #8663ed 100%);
}

.theme-bg-21,
.bg-Coldark-Cold {

    background-image: linear-gradient(140deg, rgb(165, 142, 251), rgb(233, 191, 248));
}

.theme-bg-22,
.bg-Coldark-Dark {

    background-image: linear-gradient(to left top, #162b46, #192c45, #1c2e45, #1e2f44, #213043);
}

.theme-bg-23,
.bg-Material-Light {

    background-image: linear-gradient(135deg, #54D2EF 0%, #2AA6DA 100%);
}



.theme-bg-24 {

    background-image: radial-gradient(circle at 12.3% 19.3%, rgb(85, 88, 218) 0%, rgb(95, 209, 249) 100.2%);
}

.theme-bg-25 {

    background-image: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}

.theme-bg-26 {

    background-image: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
}

.theme-bg-27 {

    background-image: linear-gradient(109.6deg, rgb(156, 252, 248) 11.2%, rgb(110, 123, 251) 91.1%);
}

.theme-bg-28 {

    background-image: linear-gradient(109.6deg, rgb(253, 199, 141) 11.3%, rgb(249, 143, 253) 100.2%);
}

.theme-bg-29 {

    background-image: linear-gradient(to top, rgb(161, 140, 209) 0%, rgb(251, 194, 235) 100%);
}

.theme-bg-30 {

    background-image: radial-gradient(circle at 10% 20%, rgb(253, 193, 104) 0%, rgb(251, 128, 128) 90%);
}

.theme-bg-31 {

    background-image: linear-gradient(109.6deg, rgb(218, 185, 252) 11.2%, rgb(125, 89, 252) 91.1%);
}

.theme-bg-32 {

    background-image: linear-gradient(25deg, rgb(214, 76, 127), rgb(238, 71, 88) 50%);
}

.theme-bg-33 {

    background-image: radial-gradient(circle at 10% 20%, rgb(90, 92, 106) 0%, rgb(32, 45, 58) 81.3%);
}

.theme-bg-34 {

    background-image: linear-gradient(68.4deg, rgb(248, 182, 204) 0.5%, rgb(192, 198, 230) 49%, rgb(225, 246, 240) 99.8%);
}

.theme-bg-35 {

    background-image: linear-gradient(109.6deg, rgb(62, 161, 219) 11.2%, rgb(93, 52, 236) 100.2%);
}

.theme-bg-36 {

    background-image: linear-gradient(109.6deg, rgb(238, 58, 136) 11.2%, rgb(128, 162, 245) 91.1%);
}

.theme-bg-37 {

    background-image: radial-gradient(circle at 10% 20%, rgb(255, 200, 124) 0%, rgb(252, 251, 121) 90%);
}

.theme-bg-38 {

    background-image: linear-gradient(135deg, rgb(94, 252, 232) 10%, rgb(115, 110, 254) 100%);
}

.theme-bg-39 {

    background-image: radial-gradient(circle at 10% 20%, rgb(222, 168, 248) 0%, rgb(168, 222, 248) 21.8%, rgb(189, 250, 205) 35.6%, rgb(243, 250, 189) 52.9%, rgb(250, 227, 189) 66.8%, rgb(248, 172, 172) 90%, rgb(254, 211, 252) 99.7%);
}

.theme-bg-40 {

    background-image: radial-gradient(321px at 8.3% 75.7%, rgb(209, 247, 241) 0%, rgb(249, 213, 213) 81%);
}

.theme-bg-41 {

    background-image: linear-gradient(90.6deg, rgb(59, 158, 255) -1.2%, rgb(246, 135, 255) 91.6%);
}

.theme-bg-2 {

    background-image: linear-gradient(106.4deg, rgb(255, 104, 192) 11.1%, rgb(104, 84, 249) 81.3%);
}

.theme-bg-43 {

    background-image: linear-gradient(114.2deg, rgb(121, 194, 243) 22.6%, rgb(255, 180, 239) 67.7%);
}


.theme-bg-44,
.evening-sunshine {

    background: #b92b27;
    background-image: linear-gradient(0deg, #1565c0, #b92b27);
    background-image: linear-gradient(var(--gradient-angle), #1565c0, #b92b27)
}

.theme-bg-45,
.cool-white {

    background-image: linear-gradient(0deg, #fff, #f1f1f1);
    background-image: linear-gradient(var(--gradient-angle), #fff, #f1f1f1)
}

.theme-bg-46,
.cool-white-2 {

    background-image: linear-gradient(0deg, #d7e1ec, #fff);
    background-image: linear-gradient(var(--gradient-angle), #d7e1ec, #fff)
}

.theme-bg-47,
.cool-red-blue {

    background-image: linear-gradient(0deg, #dcccff, #8b2ec8, #f24533);
    background-image: linear-gradient(var(--gradient-angle), #dcccff, #8b2ec8, #f24533)
}

.theme-bg-48,
.cool-light-blue {

    background-image: linear-gradient(0deg, #ffface, #b0dcf6, #e5ffee);
    background-image: linear-gradient(var(--gradient-angle), #ffface, #b0dcf6, #e5ffee)
}

.theme-bg-49,
.cool-blue {

    background-image: #2193b0;
    background-image: linear-gradient(0deg, #6dd5ed, #2193b0);
    background-image: linear-gradient(var(--gradient-angle), #6dd5ed, #2193b0)
}

.theme-bg-50,
.mega-tron {

    background-image: #c6ffdd;
    background-image: linear-gradient(0deg, #f7797d, #fbd786, #c6ffdd);
    background-image: linear-gradient(var(--gradient-angle), #f7797d, #fbd786, #c6ffdd)
}

.theme-bg-51,
.cool-sky {

    background-image: #2980b9;
    background-image: linear-gradient(0deg, #fff, #6dd5fa, #2980b9);
    background-image: linear-gradient(var(--gradient-angle), #fff, #6dd5fa, #2980b9)
}

.theme-bg-52,
.neuromancer {

    background-image: #f953c6;
    background-image: linear-gradient(0deg, #b91d73, #f953c6);
    background-image: linear-gradient(var(--gradient-angle), #b91d73, #f953c6)
}

.theme-bg-53,
.kyoo-pal {

    background-image: #dd3e54;
    background-image: linear-gradient(0deg, #6be585, #dd3e54);
    background-image: linear-gradient(var(--gradient-angle), #6be585, #dd3e54)
}

.theme-bg-54,
.burning-orange {

    background-image: #ff416c;
    background-image: linear-gradient(0deg, #ff4b2b, #ff416c);
    background-image: linear-gradient(var(--gradient-angle), #ff4b2b, #ff416c)
}

.theme-bg-55,
.summer-dog {

    background-image: #a8ff78;
    background-image: linear-gradient(0deg, #78ffd6, #a8ff78);
    background-image: linear-gradient(var(--gradient-angle), #78ffd6, #a8ff78)
}

.theme-bg-56,
.magic {

    background-image: #59c173;
    background-image: linear-gradient(0deg, #5d26c1, #a17fe0, #59c173);
    background-image: linear-gradient(var(--gradient-angle), #5d26c1, #a17fe0, #59c173)
}

.theme-bg-57,
.slight-ocean-view {

    background-image: #a8c0ff;
    background-image: linear-gradient(0deg, #3f2b96, #a8c0ff);
    background-image: linear-gradient(var(--gradient-angle), #3f2b96, #a8c0ff)
}

.theme-bg-58,
.purpink {

    background-image: #7f00ff;
    background-image: linear-gradient(0deg, #e100ff, #7f00ff);
    background-image: linear-gradient(var(--gradient-angle), #e100ff, #7f00ff)
}

.theme-bg-59,
.relaxing-red {

    background-image: #fffbd5;
    background-image: linear-gradient(0deg, #b20a2c, #fffbd5);
    background-image: linear-gradient(var(--gradient-angle), #b20a2c, #fffbd5)
}

.theme-bg-60,
.argon {

    background-image: #03001e;
    background-image: linear-gradient(0deg, #fdeff9, #ec38bc, #7303c0, #03001e);
    background-image: linear-gradient(var(--gradient-angle), #fdeff9, #ec38bc, #7303c0, #03001e)
}

.theme-bg-61,
.crimson-tide {

    background-image: #642b73;
    background-image: linear-gradient(0deg, #c6426e, #642b73);
    background-image: linear-gradient(var(--gradient-angle), #c6426e, #642b73)
}

.theme-bg-62,
.compare-now {

    background-image: #ef3b36;
    background-image: linear-gradient(0deg, #fff, #ef3b36);
    background-image: linear-gradient(var(--gradient-angle), #fff, #ef3b36)
}

.theme-bg-63,
.honey-dew {

    background-image: #43c6ac;
    background-image: linear-gradient(0deg, #f8ffae, #43c6ac);
    background-image: linear-gradient(var(--gradient-angle), #f8ffae, #43c6ac)
}

.theme-bg-64,
.radar {

    background-image: #a770ef;
    background-image: linear-gradient(0deg, #fdb99b, #cf8bf3, #a770ef);
    background-image: linear-gradient(var(--gradient-angle), #fdb99b, #cf8bf3, #a770ef)
}

.theme-bg-65,
.vice-city {

    background-image: #3494e6;
    background-image: linear-gradient(0deg, #ec6ead, #3494e6);
    background-image: linear-gradient(var(--gradient-angle), #ec6ead, #3494e6)
}

.theme-bg-66,
.black-rose {

    background-image: #f4c4f3;
    background-image: linear-gradient(0deg, #fc67fa, #f4c4f3);
    background-image: linear-gradient(var(--gradient-angle), #fc67fa, #f4c4f3)
}

.theme-bg-67,
.snapchat {

    background-image: #fffc00;
    background-image: linear-gradient(0deg, #fff, #fffc00);
    background-image: linear-gradient(var(--gradient-angle), #fff, #fffc00)
}

.theme-bg-68,
.limeade {

    background-image: #a1ffce;
    background-image: linear-gradient(0deg, #faffd1, #a1ffce);
    background-image: linear-gradient(var(--gradient-angle), #faffd1, #a1ffce)
}

.theme-bg-69,
.tranquil {

    background-image: #eecda3;
    background-image: linear-gradient(0deg, #ef629f, #eecda3);
    background-image: linear-gradient(var(--gradient-angle), #ef629f, #eecda3)
}

.theme-bg-70,
.back-to-earth {

    background-image: #00c9ff;
    background-image: linear-gradient(0deg, #92fe9d 25%, 60%, #00c9ff);
    background-image: linear-gradient(var(--gradient-angle), #92fe9d 25%, 60%, #00c9ff)
}

.theme-bg-71,
.instagram {

    background-image: #833ab4;
    background-image: linear-gradient(0deg, #fcb045, #fd1d1d, #833ab4);
    background-image: linear-gradient(var(--gradient-angle), #fcb045, #fd1d1d, #833ab4)
}

.theme-bg-72,
.martini {

    background-image: #fdfc47;
    background-image: linear-gradient(0deg, #24fe41, #fdfc47);
    background-image: linear-gradient(var(--gradient-angle), #24fe41, #fdfc47)
}

.theme-bg-73,
.virgin {

    background-image: #c9ffbf;
    background-image: linear-gradient(0deg, #ffafbd, #c9ffbf);
    background-image: linear-gradient(var(--gradient-angle), #ffafbd, #c9ffbf)
}

.theme-bg-74,
.almost {

    background-image: #ddd6f3;
    background-image: linear-gradient(0deg, #faaca8, #ddd6f3);
    background-image: linear-gradient(var(--gradient-angle), #faaca8, #ddd6f3)
}

.theme-bg-75,
.miaka {

    background-image: #fc354c;
    background-image: linear-gradient(0deg, #0abfbc, #fc354c);
    background-image: linear-gradient(var(--gradient-angle), #0abfbc, #fc354c)
}

.theme-bg-76,
.peach {

    background-image: #ed4264;
    background-image: linear-gradient(0deg, #ffedbc, #ed4264);
    background-image: linear-gradient(var(--gradient-angle), #ffedbc, #ed4264)
}

.theme-bg-77,
.theme-bg-48,
.venice-blue {

    background-image: #085078;
    background-image: linear-gradient(0deg, #85d8ce, #085078);
    background-image: linear-gradient(var(--gradient-angle), #85d8ce, #085078)
}

.theme-bg-78,
.hazel {

    background-image: #77a1d3;
    background-image: linear-gradient(0deg, #e684ae, #79cbca, #77a1d3);
    background-image: linear-gradient(var(--gradient-angle), #e684ae, #79cbca, #77a1d3)
}

.theme-bg-79,
.a-lost-memory {

    background-image: #de6262;
    background-image: linear-gradient(0deg, #ffb88c, #de6262);
    background-image: linear-gradient(var(--gradient-angle), #ffb88c, #de6262)
}

.theme-bg-80,
.light-orange {

    background-image: #ffb75e;
    background-image: linear-gradient(0deg, #ed8f03, #ffb75e);
    background-image: linear-gradient(var(--gradient-angle), #ed8f03, #ffb75e)
}

.theme-bg-81,
.little-leaf {

    background-image: #76b852;
    background-image: linear-gradient(0deg, #8dc26f, #76b852);
    background-image: linear-gradient(var(--gradient-angle), #8dc26f, #76b852)
}

.theme-bg-82,
.vine {

    background-image: #00bf8f;
    background-image: linear-gradient(0deg, #001510, #00bf8f);
    background-image: linear-gradient(var(--gradient-angle), #001510, #00bf8f)
}

.theme-bg-83,
.single-red {

    background-image: linear-gradient(0deg, #ffc9c9, 40%, #fa5252);
    background-image: linear-gradient(var(--gradient-angle), #ffc9c9, 40%, #fa5252)
}

.theme-bg-84,
.single-green {

    background-image: linear-gradient(0deg, #bdfff3, 40%, #4ac29a);
    background-image: linear-gradient(var(--gradient-angle), #bdfff3, 40%, #4ac29a)
}

.theme-bg-85,
.single-purple {

    background-image: linear-gradient(0deg, #b197fc, #6741d9);
    background-image: linear-gradient(var(--gradient-angle), #b197fc, #6741d9)
}

.theme-bg-86,
.single-grey {

    background-image: linear-gradient(0deg, #5f5d5c, 50%, #304352);
    background-image: linear-gradient(var(--gradient-angle), #5f5d5c, 50%, #304352)
}

.theme-bg-87,
.single-skin {

    background-image: linear-gradient(0deg, #fff4e6, #ffd8a8);
    background-image: linear-gradient(var(--gradient-angle), #fff4e6, #ffd8a8)
}

.theme-bg-88,
.angle-blue {

    background-image: linear-gradient(0deg, #0033f4, #687eff, #dbdeff);
    background-image: linear-gradient(var(--gradient-angle), #0033f4, #687eff, #dbdeff)
}

.theme-bg-89,
.angle-green {

    background-image: linear-gradient(0deg, #cfffeb, #6cfcd2, #0df6cf);
    background-image: linear-gradient(var(--gradient-angle), #cfffeb, #6cfcd2, #0df6cf)
}

.multi-1 {

    background-image: linear-gradient(0deg, #d6e9ff, #d6e5ff, #d1d6ff, #ddd1ff, #f3d1ff, #ffccf5, #ffccdf, #ffc8c7, #ffd8c7, #ffddc7);
    background-image: linear-gradient(var(--gradient-angle), #d6e9ff, #d6e5ff, #d1d6ff, #ddd1ff, #f3d1ff, #ffccf5, #ffccdf, #ffc8c7, #ffd8c7, #ffddc7)
}

.multi-2 {

    background-image: linear-gradient(0deg, #ccfbfc, #c5eafe, #bdd3ff);
    background-image: linear-gradient(var(--gradient-angle), #ccfbfc, #c5eafe, #bdd3ff)
}

.multi-3 {

    background-image: linear-gradient(0deg, #d359ff, #e463ff, #ff7bf7, #ff9ada, #ffb9d0, #ffd1d6, #ffdbdb);
    background-image: linear-gradient(var(--gradient-angle), #d359ff, #e463ff, #ff7bf7, #ff9ada, #ffb9d0, #ffd1d6, #ffdbdb)
}

.multi-4 {

    background-image: linear-gradient(0deg, #00e0f5, #1f9eff, #35f);
    background-image: linear-gradient(var(--gradient-angle), #00e0f5, #1f9eff, #35f)
}

.multi-5 {

    background-image: radial-gradient(circle farthest-corner at 10% 20%, #dea8f8 0, #a8def8 21.8%, #bdfacd 35.6%, #f3fabd 52.9%, #fae3bd 66.8%, #f8acac 90%, #fed3fc 99.7%)
}

.multi-6 {

    background-image: linear-gradient(0deg, #dab9fc 11.2%, #7d59fc 91.1%);
    background-image: linear-gradient(var(--gradient-angle), #dab9fc 11.2%, #7d59fc 91.1%)
}

.multi-7 {

    background-image: linear-gradient(0deg, #fff2a7, #d3acee, #80f1fb);
    background-image: linear-gradient(var(--gradient-angle), #fff2a7, #d3acee, #80f1fb)
}

.multi-8 {

    background-image: linear-gradient(0deg, #c4fab1, #b4eef5, #fdd993);
    background-image: linear-gradient(var(--gradient-angle), #c4fab1, #b4eef5, #fdd993)
}

.new-1 {

    background-image: linear-gradient(0deg, #ff197d, #2d0dff, #00ffb3);
    background-image: linear-gradient(var(--gradient-angle), #ff197d, #2d0dff, #00ffb3)
}

.new-2 {

    background-image: linear-gradient(0deg, #212121, #4f4f4f);
    background-image: linear-gradient(var(--gradient-angle), #212121, #4f4f4f)
}

.new-3 {

    background-image: linear-gradient(0deg, #1f005c, #940d60, #da5f5c, #ffb56b);
    background-image: linear-gradient(var(--gradient-angle), #1f005c, #940d60, #da5f5c, #ffb56b)
}

.new-4 {

    background-image: linear-gradient(0deg, #d9f4ff, #ff8fa7, #5d68ff);
    background-image: linear-gradient(var(--gradient-angle), #d9f4ff, #ff8fa7, #5d68ff)
}

.new-5 {

    background-image: linear-gradient(0deg, #fdef84, #f7c6a9, #15bac4);
    background-image: linear-gradient(var(--gradient-angle), #fdef84, #f7c6a9, #15bac4)
}

.new-6 {

    background-image: linear-gradient(0deg, #f9c7ff, #ff63d6, #ff005c);
    background-image: linear-gradient(var(--gradient-angle), #f9c7ff, #ff63d6, #ff005c)
}

.new-7 {

    background-image: linear-gradient(0deg, #f6e0ff, #f5c0ff, #fc9fff);
    background-image: linear-gradient(var(--gradient-angle), #f6e0ff, #f5c0ff, #fc9fff)
}

.new-8 {

    background: linear-gradient(0deg, #08d6f2, #09f6f0, #0df6cf);
    background: linear-gradient(var(--gradient-angle), #08d6f2, #09f6f0, #0df6cf)
}

.ripe-malinka {

    background-image: linear-gradient(0deg, #f093fb, #f5576c);
    background-image: linear-gradient(var(--gradient-angle), #f093fb, #f5576c)
}

.mixed-hope {

    background-image: linear-gradient(0deg, #c471f5, #fa71cd);
    background-image: linear-gradient(var(--gradient-angle), #c471f5, #fa71cd)
}

.pk-1 {

    background-image: linear-gradient(0deg, #b9fbc0, #a3c4f3, #f2c2e9);
    background-image: linear-gradient(var(--gradient-angle), #b9fbc0, #a3c4f3, #f2c2e9)
}

.pk-2 {

    background-image: linear-gradient(0deg, #a3ffe8, #7a6bfb, #ff90c8);
    background-image: linear-gradient(var(--gradient-angle), #a3ffe8, #7a6bfb, #ff90c8)
}

.mixed-color-1 {

    background-color: red;
    background-image: radial-gradient(at 40% 20%, #ffb87a 0, transparent 50%), radial-gradient(at 80% 0, #1fddff 0, transparent 50%), radial-gradient(at 0 50%, #ffdbde 0, transparent 50%), radial-gradient(at 80% 50%, #ff85ad 0, transparent 50%), radial-gradient(at 0 100%, #ffb58a 0, transparent 50%), radial-gradient(at 80% 100%, #6b66ff 0, transparent 50%), radial-gradient(at 0 0, #ff85a7 0, transparent 50%)
}

.mixed-color-2 {

    background-color: #006aff;
    background-image: radial-gradient(at 40% 20%, #ffb87a 0, transparent 50%), radial-gradient(at 80% 0, #1fddff 0, transparent 50%), radial-gradient(at 0 50%, #ffdbde 0, transparent 50%), radial-gradient(at 80% 50%, #ff85ad 0, transparent 50%), radial-gradient(at 0 100%, #ffb58a 0, transparent 50%), radial-gradient(at 80% 100%, #e9512b 0, transparent 50%), radial-gradient(at 0 0, #ff85a7 0, transparent 50%)
}

.mixed-color-3 {

    background-color: #3cff00;
    background-image: radial-gradient(at 42% 69%, #ffe47a 0, transparent 50%), radial-gradient(at 80% 0, #1f93ff 0, transparent 50%), radial-gradient(at 46% 40%, #ffe4db 0, transparent 50%), radial-gradient(at 80% 68%, #ff8585 0, transparent 50%), radial-gradient(at 0 100%, #ffdc8a 0, transparent 50%), radial-gradient(at 0 0, #ff8b85 0, transparent 50%)
}

.mixed-color-4 {

    background-color: #dbdf16;
    background-image: radial-gradient(at 33% 76%, #7affed 0, transparent 50%), radial-gradient(at 80% 0, #ff1f84 0, transparent 50%), radial-gradient(at 69% 22%, #dbffe7 0, transparent 50%), radial-gradient(at 82% 72%, #85ff8d 0, transparent 50%), radial-gradient(at 0 100%, #d52ac4 0, transparent 50%), radial-gradient(at 0 0, #85ff93 0, transparent 50%)
}

.mixed-color-5 {

    background-color: red;
    background-image: radial-gradient(at 40% 20%, #ba7aff 0, transparent 50%), radial-gradient(at 80% 0, #daff1f 0, transparent 50%), radial-gradient(at 0 50%, #dbdeff 0, transparent 50%), radial-gradient(at 80% 50%, #85abff 0, transparent 50%), radial-gradient(at 0 100%, #b78aff 0, transparent 50%), radial-gradient(at 80% 100%, #66ff6e 0, transparent 50%), radial-gradient(at 0 0, #85a5ff 0, transparent 50%)
}

.mixed-color-6 {

    background-color: #26004d;
    background-image: radial-gradient(at 41% 42%, #f344c1 0, rgba(243, 68, 193, 0) 50%), radial-gradient(at 21% 24%, #f561a8 0, rgba(245, 97, 168, 0) 50%), radial-gradient(at 11% 15%, #cd38fa 0, rgba(205, 56, 250, 0) 50%), radial-gradient(at 30% 5%, #e9f54d 0, rgba(233, 245, 77, 0) 50%), radial-gradient(at 69% 43%, #bf30f3 0, rgba(191, 48, 243, 0) 50%), radial-gradient(at 91% 91%, #4af7a4 0, rgba(74, 247, 164, 0) 50%), radial-gradient(at 27% 3%, #20ee46 0, rgba(32, 238, 70, 0) 50%)
}

.mixed-color-7 {

    background-color: #26004d;
    background-image: radial-gradient(at 3% 39%, #97f35e 0, rgba(151, 243, 94, 0) 50%), radial-gradient(at 64% 23%, #e4f35e 0, rgba(228, 243, 94, 0) 50%), radial-gradient(at 45% 95%, #5ceafa 0, rgba(92, 234, 250, 0) 50%), radial-gradient(at 16% 69%, #c670f5 0, rgba(198, 112, 245, 0) 50%), radial-gradient(at 95% 9%, #1bf823 0, rgba(27, 248, 35, 0) 50%), radial-gradient(at 21% 57%, #cc4ff3 0, rgba(204, 79, 243, 0) 50%), radial-gradient(at 58% 72%, #1f95ef 0, rgba(31, 149, 239, 0) 50%)
}


.bg-barbie {

    background-image: linear-gradient(135deg, #3c99e9, #1c79c9);
}

.bg-simple {

    background-color: #fff;
}